import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  margin: 0;
  min-height: 120px;
  background-color: ${({ theme }) => theme.palette.error.dark};
  padding: 0;
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
