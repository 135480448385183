import { EventScheduleModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import RoutinesUpsertForm, {
  FormValues,
} from '../../../components/RoutinesUpsertForm';
import {
  formDataToRoutineModel,
  routineModelToFormValues,
} from '../../../components/RoutinesUpsertForm/utils';
import { AuthContext } from '../../../contexts/auth';
import useRoutine from '../../../hooks/useRoutine';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress } from '../styles';
import { Container } from './styles';
import { PscButton } from '../../../components/PscButton'
import { Header } from '../../../components/Header'
import { BackButton } from '../../../components/BackButton'
import { PageTitle } from '../../../components/PageTitle'

const formInitialValue: EventScheduleModel = {
  name: '',
  description: '',
  subCategory: [],
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
  frequencyStartsAt: '',
  frequencyRule: '',
};

const RoutinesInsert: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { post, loading: inserting } = useRoutine();
  const history = useHistory();

  const handleSubmit = (values: FormValues, continuosAdd?: boolean) => {
    const postData = formDataToRoutineModel(values, patientId!);
    return post(postData)
      .then(() => {
        if (!continuosAdd) history.replace('/agendar/rotina');
        toast.success('Rotina adicionada com sucesso!');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Adicionar Rotina' />
        }
        rightContent={
          <PscButton />
        }
      />
      <Container>
        <RoutinesUpsertForm
          initialValues={routineModelToFormValues(formInitialValue)}
          onSubmit={handleSubmit}
        />
      </Container>

      {/* Loading while inserting */}
      {inserting && (
        <Backdrop open={inserting}>
          {inserting && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      )}
    </>
  );
};

export default RoutinesInsert;
