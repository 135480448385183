import { CardDataType, PlanModel } from '@cuidador/database';
import * as yup from 'yup';
import { validateCpf } from '../../utils/inputs';
import { FormValues } from '../SubscriptionActivation/utils';

export const initialCardValues = {
  name: '',
  cardNumber: '',
  expiry: '',
  cvc: '',
  cpf: '',
};

export type FormCardValues = {
  name: string;
  cardNumber: string;
  expiry: string;
  cvc: string;
  cpf: string;
};

export const initialAddressValues = {
  postalCode: '',
  country: '',
  province: '',
  city: '',
  address: '',
  addressNumber: '',
  additional: '',
};

export type FormAddressValues = {
  postalCode: string;
  country: string;
  province: string;
  city: string;
  address: string;
  addressNumber: string;
  additional: string;
};

export const validationCardSchema = yup.object().shape({
  name: yup
    .string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  cpf: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      validateCpf(value || '')
    ),
  cardNumber: yup
    .string()
    .required('Por favor, insira um número de cartão')
    .min(14, 'Por favor, insira um número de cartão válido')
    .max(22, 'Por favor, insira um número de cartão válido'),
  expiry: yup
    .string()
    .required('Por favor, insira a validade do cartão')
    .min(5, 'Por favor, insira a data no formato MM/AA')
    .max(6, 'Por favor, insira a data no formato MM/AA'),
  cvc: yup
    .string()
    .required('Por favor, insira o código de verificação do cartão')
    .min(3, 'Por favor, insira um código de verificação do cartão válido')
    .max(4, 'Por favor, insira um código de verificação do cartão válido'),
});

export const validationAddressSchema = yup.object().shape({
  postalCode: yup
    .string()
    .required('Por favor, insira o CEP')
    .min(9, 'Por favor, insira um CEP válido')
    .max(10, 'Por favor, insira um CEP válido'),
  country: yup.string().required('Por favor, insira o país'),
  province: yup
    .string()
    .required('Por favor, insira a UF')
    .min(2, 'Por favor, insira uma UF válida')
    .max(3, 'Por favor, insira uma UF válida'),
  city: yup.string().required('Por favor, insira a cidade'),
  address: yup
    .string()
    .required('Por favor, insira o endereço')
    .min(3, 'Por favor, insira um endereço válido'),
  addressNumber: yup.string().required('Por favor, insira o número'),
});

export const getPlanName = (plan: PlanModel) => {
  const getPlanFrequency = (interval: string, intervalCount: number) => {
    if (interval === 'year') return 'Anual';
    if (interval === 'month' && intervalCount === 6) return 'Semestral';
    if (interval === 'month' && intervalCount === 1) return 'Mensal';
    return '';
  };

  return `${plan.name} ${getPlanFrequency(
    String(plan.interval),
    Number(plan.intervalCount)
  )}`;
};

export const formValuesToPostRequisitionBody = (
  cardValues: FormCardValues,
  billingAddressValues: FormAddressValues,
  district: string,
  promotionalCodeValues?: FormValues
) => {
  const [expirationMonth, expirationYear] = cardValues.expiry.split('/');
  const cardNumber = cardValues.cardNumber.replace(/\s/g, '');
  const postalCode = billingAddressValues.postalCode.replace('-', '');
  const cpf = cardValues.cpf.replace('-', '').split('.').join('');

  const body: CardDataType = {
    promotionalCode: promotionalCodeValues?.promotionalCode,
    holderName: cardValues.name,
    number: cardNumber,
    expMonth: Number(expirationMonth),
    expYear: Number(expirationYear),
    cvv: cardValues.cvc,
    cpf: cpf,
    billingAddress: {
      line1: `${billingAddressValues.addressNumber}, ${billingAddressValues.address}, ${district}`,
      zipCode: postalCode,
      city: billingAddressValues.city,
      state: billingAddressValues.province,
      country: 'BR',
    },
  };
  if (billingAddressValues.additional)
    body.billingAddress.line2 = billingAddressValues.additional;

  return body;
};
