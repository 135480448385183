import { APIError } from '@cuidador/lib'
import { AxiosError } from 'axios'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContactsUpsertForm from '../../../components/ContactsUpsertForm'
import {
  formDataToEmergencyContactsModel,
  FormValues,
} from '../../../components/ContactsUpsertForm/utils'
import { AuthContext } from '../../../contexts/auth'
import useCanAccess from '../../../hooks/useCanAccess'
import useEmergencyContacts from '../../../hooks/useEmergencyContacts'
import { resolveErrorMessage } from '../../../utils/error'
import { Container } from './styles'
import { Header } from '../../../components/Header'
import { PageTitle } from '../../../components/PageTitle'
import { BackButton } from '../../../components/BackButton'
import { PscButton } from '../../../components/PscButton'

const ContactsInsert: React.FC = () => {
  const { userInfo } = useContext(AuthContext)
  const patientId = userInfo?.activePatientId
  const { isAllowedToCreate } = useCanAccess('care/emergency-contact')

  const { post } = useEmergencyContacts()
  const history = useHistory()

  useEffect(() => {
    if (!isAllowedToCreate) {
      toast.error('Você não tem permissão para visualizar essa página')
      history.goBack()
    }
  }, [])

  const handleSubmit = (values: FormValues) => {
    return post(formDataToEmergencyContactsModel(values, patientId!))
      .then(() => {
        history.replace('/contatos')
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err)
        toast.error(displayMessage)
      })
  }

  const formInitialValue = {
    name: '',
    phoneNumber: '',
    callingCondition: '',
    relevantInformation: '',
    link: '',
  }

  return (
    <>
      <Header
        leftContent={<BackButton />}
        centerContent={<PageTitle title='Contatos de Emergência' />}
        rightContent={<PscButton />}
      />
      <Container>
        <ContactsUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  )
}

export default ContactsInsert
