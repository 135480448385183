import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Form } from 'formik';
import StyledButton from '../../components/StyledButton';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const LeftAlignedTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-right: auto;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(15)}px;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(3, 0)};
  padding: ${({ theme }) => theme.spacing(2)}px;
  box-sizing: border-box;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const RightAlignedTypography = styled(Typography)`
  margin-left: auto;
  font-weight: bold;
`;

export const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

export const CardContent = styled.div`
  font-size: 0.9em;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSubmitButton = styled(StyledButton)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-inline: auto;
`;

export const InputLabel = styled.label`
  font-size: 0.95rem;
  font-weight: 700;
`;

export const InputRequired = styled.span`
  font-size: 0.8rem;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
