import { Stepper } from '@material-ui/core';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 3)};
`;

export const NavigationButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const StyledStepper = styled(Stepper)`
  width: 100%;
  background-color: transparent;
  overflow: auto;
`;

export const TextContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;

  p {
    color: ${({ theme }) => theme.palette.extra.color.grey.main};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const EditButton = styled.p`
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.7)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing(0.4, 1)};
`;
