import React from 'react';
import { clientSideScheme } from '@cuidador/whitelabel';
import { Text, StyledFavorite, StyledLogoInline, Container } from './styles';

const MadeBy: React.FC = () => {
  const { schemeId } = clientSideScheme();

  if (schemeId === 'default') return null;

  return (
    <Container>
      <Text>
        Feito com <StyledFavorite /> por <StyledLogoInline />
      </Text>
    </Container>
  );
};

export default MadeBy;
