import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import StyledButton from '../../../../components/StyledButton';
import StyledSimpleModal from '../../../../components/StyledSimpleModal';
import { ButtonContainer, Centralizer } from './styles';

interface ModalProps {
  onClose: () => void;
  opened: boolean;
  onConfirm: () => void;
}

const AutoRelateModal: React.FC<ModalProps> = ({
  onClose,
  opened,
  onConfirm,
}) => {
  return (
    <StyledSimpleModal open={opened}>
      <Centralizer>
        <WarningIcon fontSize="large" />
        <Typography variant="subtitle1" align="center">
          Ao confirmar, você estará{' '}
          <b>
            vinculando automaticamente todos os pacientes cadastrados à essa
            pessoa.
          </b>
        </Typography>
        <Typography variant="subtitle1" align="center">
          Tem certeza de que deseja continuar?
        </Typography>
        <ButtonContainer>
          <StyledButton size="medium" color="inherit" onClick={onConfirm}>
            Confirmar
          </StyledButton>
          <StyledButton size="medium" color="inherit" onClick={onClose}>
            Voltar
          </StyledButton>
        </ButtonContainer>
      </Centralizer>
    </StyledSimpleModal>
  );
};

export default AutoRelateModal;
