import { EventSubCategoryModel, MeasurementModel, SymptomModel } from '@cuidador/database'
import Typography from '@material-ui/core/Typography'
import { format } from 'date-fns'
import React from 'react'
import DoneIcon from '../../../../assets/Icon-done.svg'
import MesurementIcon from '../../../../assets/Icon-mesurement.svg'
import NotDoneIcon from '../../../../assets/Icon-notdone.svg'
import {
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles'
import { CardBox } from './styles'

interface CardProps {
  id?: Id
  eventHappensAt?: string
  description?: string
  status?: string
  subCategory?: EventSubCategoryModel
  measurement?: MeasurementModel
  comment?: string
  caregiverName?: string
}

const foodCondition = (condition: string) => {
  switch (condition) {
    case 'preprandial':
      return 'Condição alimentar: pré-refeição'
    case 'fasting':
      return 'Condição alimentar: jejum'
    case 'postprandial':
      return 'Condição alimentar: pós-refeição'
    case 'not_available':
      return 'Condição alimentar: não informado'
    default:
      return 'não informado'
  }
}

const getValueWithUnit = (measurement?: MeasurementModel, subCategory?: EventSubCategoryModel) => {
  if (!measurement) return 'nenhum'
  switch (subCategory?.name) {
    case 'Glicemia':
      return measurement?.measurementValue + ' ml/dL'
    case 'Frequência cardíaca':
      return measurement?.measurementValue + ' bpm'
    case 'Frequência respiratória':
      return measurement?.measurementValue + ' mrm'
    case 'Oximetria':
      return measurement?.measurementValue + ' %SaO2'
    case 'Pressão arterial':
      return measurement?.measurementValue + ' mmHg'
    case 'Temperatura ':
      return measurement?.measurementValue + ' ºC'
  }
  return measurement?.measurementValue
}

export const getConditionWithDetail = (condition: string, subCategory?: EventSubCategoryModel) => {
  switch (subCategory?.name) {
    case 'Glicemia':
      return foodCondition(condition)
    case 'Frequência cardíaca':
      return condition
    case 'Frequência respiratória':
      return condition
    case 'Oximetria':
      return condition
    case 'Pressão arterial':
      return condition
    case 'Temperatura ':
      return condition
  }
  return condition
}

export const getFormattedSymptoms = (symptoms?: SymptomModel[]) => {
  let result = 'Sintomas: '

  symptoms?.forEach((symptom, index) => {
    if (symptoms && symptoms?.length > 1 && index > 0) {
      result += '; ' + symptom.symptomName
    } else {
      result += symptom.symptomName
    }
  })

  return result
}

const MeasurementCard: React.FC<CardProps> = ({
  id,
  eventHappensAt,
  description,
  status,
  subCategory,
  measurement,
  comment,
  caregiverName,
}) => {
  const formattedDate = format(new Date(`${eventHappensAt}`), 'HH:mm')
  return (
    <CardBox
      data-testid={`mesurementCard-${id}`}
      styledHighlight={
        measurement?.isDangerousComplication
          ? 'red'
          : Math.abs(measurement?.complicationLevel ?? 0) === 1
            ? 'yellow'
            : undefined
      }
    >
      <LeftContainer>
        <TimerContainer>
          <Typography variant='h6'>{formattedDate}</Typography>
        </TimerContainer>
        <img src={MesurementIcon} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant='h6'>{subCategory?.name}</Typography>
          {description && (
            <Typography variant='subtitle2'>Instruções: {description}</Typography>
          )}
          <Typography variant='subtitle2'>
            Valores medidos: {getValueWithUnit(measurement, subCategory)}
          </Typography>
          <DescriptionContainer>
            <Typography variant='subtitle2'>
              {measurement?.measurementDescription}
            </Typography>
            <Typography variant='subtitle2'>
              {getConditionWithDetail(measurement?.additionalDetails || '', subCategory)}
            </Typography>
            {measurement?.symptoms && measurement?.symptoms?.length > 0 && (
              <Typography variant='subtitle2'>{getFormattedSymptoms(measurement?.symptoms)}</Typography>
            )}
            {comment && (
              <Typography variant='subtitle2'>Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          <StatusContainer>
            {status === 'awaiting' && <></>}
            {status === 'accomplished' && <img src={DoneIcon} />}
            {status === 'not_accomplished' && <img src={NotDoneIcon} />}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  )
}

export default MeasurementCard
