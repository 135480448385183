import React from 'react'
import { StyledIconButton } from '../StyledIconButton'
import { ReactComponent as MenuIcon } from '../../assets/burger-menu.svg'

export type MenuButtonProps = {
  action: () => void
}

export function MenuButton (props: MenuButtonProps) {
  const { action } = props

  return (
    <StyledIconButton
      icon={
        <MenuIcon style={{
          fontSize: 40,
          color: 'inherit',
        }} />
      }
      action={() => action()}
    />
  )
}
