import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
