import React from 'react';
import { CardBox, TypographyContainer } from './styles';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import { ShiftScheduleModel } from '@cuidador/database';
import { convertTimeToDate } from '../../utils/date';
import { formatWeekDaysFromShiftSchedule } from '../ShiftScheduleUpsertForm/utils';

const ShiftScheduleCard: React.FC<ShiftScheduleModel> = ({
  name,
  id,
  startingTime,
  endingTime,
  mon,
  tue,
  wed,
  thu,
  fri,
  sat,
  sun,
}) => {
  // Builds active weekdays array
  const weekDays = formatWeekDaysFromShiftSchedule({
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    sun,
    startingTime,
  });

  const weekdaysLabel = weekDays.join(' - ');

  return (
    <CardBox data-testid={`shiftCard-${id}`}>
      <TypographyContainer>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="subtitle1">{weekdaysLabel}</Typography>
        <Typography variant="subtitle1">
          {/* Format from UTC to local time for correct rendering */}
          {format(convertTimeToDate(String(startingTime)), 'HH:mm')} às{' '}
          {format(convertTimeToDate(String(endingTime)), 'HH:mm')}
        </Typography>
      </TypographyContainer>
    </CardBox>
  );
};

export default ShiftScheduleCard;
