import React from 'react';
import { Container, StyledSpan } from './styles';

const InformativeText: React.FC = () => {
  return (
    <Container>
      <StyledSpan>
        Você pode agendar um ou mais controles e sinais vitais agrupados por
        horário.
      </StyledSpan>
      <StyledSpan>
        Comece escolhendo os horários e frequências para a realização das
        medições.
      </StyledSpan>
    </Container>
  );
};

export default InformativeText;
