import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;

  width: 100%;
  height: 73px;

  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 21px 21px 0 0;
`;

export const PreviousButton = styled.button`
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #ffffff;
  height: 2.5rem;
  font-size: 12px;
  font-weight: bold;

  display: flex;
  align-items: center;

  svg {
    width: 2rem;
  }

  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: #ffffff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShiftDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2rem;
  padding: 0 0.5rem;
  width: 10rem;
  text-align: center;

  /* Medium screen  */
  @media only screen and (min-width: 768px) {
    width: 30rem;
  }
`;
