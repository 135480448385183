import { ShiftModel } from '@cuidador/database';
import { format } from 'date-fns';
import { default as React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCanAccess from '../../../hooks/useCanAccess';
import useCaregiver from '../../../hooks/useCaregiver';
import useShift from '../../../hooks/useShift';
import DropdownMenu from '../../DropdownMenu';
import ExpelCaregiverButton from '../../ExpelCaregiverButton';
import ExpelCaregiverDialog from '../../ExpelCaregiverDialog';
import {
  ShiftBodyDetails,
  ShiftBodyText,
  ShiftCardContainer,
  ShiftCardTitle,
  StyledName,
} from '../styles';
import {
  ShiftDetailsIcon,
  TimeCorrectionIcon,
  EndExecutionIcon,
} from './styles';
import {
  getShiftCardBackgroundColor,
  isShiftExpelCaregiverButtonVisible,
  isShiftMenuVisible,
} from '../utils';
import ShiftCardMessage from './ShiftCardMessage';
import ShiftInProgressIndicator from './ShiftInProgressIndicator';
import ShiftTimeMessage from './ShiftTimeMessage';
import { sortBy } from 'lodash';

interface ShiftCardProps {
  shift: ShiftModel;
  onExpelCaregiver: () => void;
}

const ShiftCard: React.FC<ShiftCardProps> = ({ shift, onExpelCaregiver }) => {
  const [isExpelDialogOpen, setIsExpelDialogOpen] = useState(false);
  const { expelCaregiver } = useShift();

  const { isAllowedToInvoke: isAllowedToExpel } = useCanAccess(
    'care/shift.expel'
  );
  const { getById, byId, loading: caregiverLoading } = useCaregiver();

  useEffect(() => {
    if (!shift?.executionInProgress?.caregiverId) return;
    getById(shift.executionInProgress.caregiverId);
  }, [shift?.executionInProgress]);

  const history = useHistory();

  const redirectToShiftDetails = (shiftId?: number) => {
    history.push(`/historico/plantao/${shiftId}`);
  };

  const redirectToShiftExecutions = (shiftId?: number) => {
    history.push(`/correcao-de-horarios/plantao/${shiftId}`);
  };

  const menuItems = [
    {
      label: 'Detalhes do plantão',
      icon: <ShiftDetailsIcon />,
      handleClickItem: () => redirectToShiftDetails(shift?.id),
      shouldRender: true,
    },
    {
      label: 'Correção de horários',
      icon: <TimeCorrectionIcon />,
      handleClickItem: () => redirectToShiftExecutions(shift.id),
      shouldRender: true,
    },
    {
      label: 'Encerrar execução',
      icon: <EndExecutionIcon />,
      handleClickItem: () => null,
      shouldRender: !!shift.executionInProgress,
    },
  ];

  const menuItemsToBeRendered = menuItems.filter(
    ({ shouldRender }) => shouldRender
  );

  const handleExpelCaregiver = async (option: 'keep' | 'erase') => {
    setIsExpelDialogOpen(false);
    try {
      await expelCaregiver(shift.id!, option !== 'erase');
      toast.success('Usuário removido do plantão com sucesso');
    } catch {
      toast.error('Não foi possível remover usuário do plantão.');
    } finally {
      onExpelCaregiver();
    }
  };

  return (
    <ShiftCardContainer
      backgroundColor={getShiftCardBackgroundColor(shift)}
      key={shift.id}
      data-testid={`shift-card-${shift.id}`}
    >
      <ShiftCardTitle>
        <StyledName>
          {`${format(new Date(shift.plannedToStartAt || ''), 'HH:mm')} ${
            shift.name || shift.shiftSchedule?.name || ''
          }`}
        </StyledName>
        {!!isShiftMenuVisible(shift) && (
          <DropdownMenu menuId={shift.id} menuItems={menuItemsToBeRendered} />
        )}
        {isShiftExpelCaregiverButtonVisible(shift, isAllowedToExpel) &&
          shift?.executionInProgress?.caregiverId && (
            <>
              <ExpelCaregiverButton
                onClick={() => setIsExpelDialogOpen(true)}
                data-testid={`expel-button-${shift.id}`}
              />
              <ExpelCaregiverDialog
                isOpen={isExpelDialogOpen}
                onClose={() => setIsExpelDialogOpen(false)}
                onConfirm={(option: 'keep' | 'erase') => {
                  handleExpelCaregiver(option);
                }}
                isLoading={caregiverLoading}
                caregiver={byId[shift?.executionInProgress.caregiverId]}
              />
            </>
          )}
      </ShiftCardTitle>
      <ShiftBodyDetails>
        <div>
          {sortBy(shift.executions, 'startedAt').map((execution, index) => (
            <ShiftBodyText key={index} isAnnulled={execution.isAnnulled}>
              <ShiftTimeMessage shift={shift} execution={execution} />
              <ShiftCardMessage shift={shift} execution={execution} />
            </ShiftBodyText>
          ))}
          {shift.status === 'in_progress' && (
            <ShiftInProgressIndicator shift={shift} />
          )}
        </div>
      </ShiftBodyDetails>
    </ShiftCardContainer>
  );
};

export default ShiftCard;
