import React from 'react';
import StyledSimpleModal from '../../StyledSimpleModal';
import { Button, Centralizer, StyledTypography } from './styles';

export type ModalProps = {
  onClose: () => void;
  opened: boolean;
  maxPatients: number;
};

const MaxPatientModal: React.FC<ModalProps> = ({
  opened,
  onClose,
  maxPatients,
}) => {
  return (
    <StyledSimpleModal open={opened} title={'Atenção'}>
      <Centralizer>
        <StyledTypography>
          {`O seu plano atual permite o cadastro de ${maxPatients} pessoa(s).`}
          <p>
            Para contratar um plano adicional, entre em contato conosco pelo
            WhatsApp (11) 96189 6764.
          </p>
        </StyledTypography>
        <Button size="medium" color="inherit" onClick={onClose}>
          Fechar
        </Button>
      </Centralizer>
    </StyledSimpleModal>
  );
};

export default MaxPatientModal;
