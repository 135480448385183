import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { Tab, Tabs, useTheme } from '@material-ui/core'

export type TabsBarProps = {
  tabItems: {
    path: string,
    label: string,
    enabled: boolean,
  }[]
  selectedTab: string
  redirectionPath: string
}

export function TabsBar (props: TabsBarProps) {
  const { tabItems, selectedTab, redirectionPath } = props
  const history = useHistory()
  const theme = useTheme()

  function handleChange (path: string): void {
    history.replace(path)
  }

  if (!selectedTab || !tabItems.some((item) => item.path === selectedTab)) {
    return <Redirect to={redirectionPath} />
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBlock: theme.spacing(1),
    }}>
      <Tabs value={selectedTab} onChange={(_, value) => handleChange(value)}>
        {tabItems.map((item, index) => (
          <Tab
            key={index}
            value={item.path}
            label={item.label}
            style={{ color: theme.palette.primary.main }}
          />
        ))}
      </Tabs>
    </div>
  )
}
