import { UserModel } from '@cuidador/database';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import jwt from 'jsonwebtoken';
import * as yup from 'yup';
import { CompleteSignupBody } from '../../hooks/useUser';
import {
  numberMask,
  phoneMask,
  validateCpf,
  validatePasswordStrength,
} from '../../utils/inputs';

export type PersonalDataFormValues = typeof personalDataInitialValues;

export const resolveValidationSchema = () => {
  return defaultValidationSchema;
};

export const personalDataInitialValues: {
  picture: File | null;
  name: UserModel['name'];
  cpf: NonNullable<UserModel['cpf']>;
  email: UserModel['email'];
  phoneNumber: UserModel['phoneNumber'];
  gender: UserModel['gender'] | '';
  dateOfBirth: UserModel['dateOfBirth'];
} = {
  picture: null,
  name: '',
  cpf: '',
  email: '',
  phoneNumber: '',
  gender: '',
  dateOfBirth: '',
};

const personalDataValidationSchema = {
  name: yup
    .string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  cpf: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      validateCpf(value || '')
    ),
  email: yup
    .string()
    .required('Por favor, insira um email')
    .email('Por favor, insira um e-mail válido'),
  phoneNumber: yup
    .string()
    .required('Por favor, insira um telefone')
    .min(14, 'Por favor, insira um telefone válido')
    .max(15, 'Por favor, insira um telefone válido'),
  gender: yup
    .string()
    .required('Por favor, insira o sexo')
    .oneOf(['male', 'female', 'other']),
  dateOfBirth: yup
    .date()
    .required('Por favor, insira a data de nascimento')
    .max(new Date(), 'Por favor, insira uma data anterior a hoje')
    .nullable()
    .typeError('Por favor, insira uma data válida'),
};

export type PasswordFormValues = {
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;
};

export const passwordInitialValues = {
  password: '',
  passwordConfirmation: '',
  termsAccepted: false,
};

const passwordValidationSchema = {
  password: yup
    .string()
    .required('Por favor, insira a senha')
    .test(
      'isValidPassword',
      'Por favor, insira uma senha que atenda aos critérios listados abaixo',
      (value) => validatePasswordStrength(value || '')
    ),
  passwordConfirmation: yup
    .string()
    .required('Por favor, insira a confirmação da senha')
    .oneOf([yup.ref('password'), ''], 'As senhas devem ser iguais'),
  termsAccepted: yup
    .boolean()
    .equals(
      [true],
      'Por favor, leia e aceite nossos termos e políticas de privacidade'
    ),
};

export const genderOptions = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' },
  { value: 'other', label: 'N/A' },
];

export type FormValues = PersonalDataFormValues & PasswordFormValues;

export const formDataToCompleteSignupRequest = (
  values: FormValues
): CompleteSignupBody => ({
  userData: resolveUserData(values),
});

const resolveUserData = (values: FormValues): UserModel => {
  return {
    name: values.name,
    cpf: numberMask(values.cpf),
    email: values.email,
    phoneNumber: numberMask(`55${values.phoneNumber}`),
    password: values.password,
    gender: values.gender || 'other', // [TODO] Change 'other' to 'not-informed' when exists
    dateOfBirth: format(new Date(values.dateOfBirth || ''), 'yyyy-MM-dd'),
  };
};

export type CompleteSignupTokenPayload = {
  id: number;
  roleId: number;
  name: UserModel['name'];
  cpf: NonNullable<UserModel['cpf']>;
  phoneNumber: UserModel['phoneNumber'];
  email: UserModel['email'];
};

export const resolvePendingSignupJWTData = (
  token: string
): CompleteSignupTokenPayload | null => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = jwt.decode(token) as any;
    return {
      id: payload.id,
      roleId: payload.roleId,
      name: payload.name || '',
      cpf: payload.cpf || '',
      phoneNumber: phoneMask(payload.phoneNumber || ''),
      email: payload.email || '',
    };
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

const defaultValidationSchema = yup.object().shape({
  ...personalDataValidationSchema,
  ...passwordValidationSchema,
});

export const formInitialValues = {
  ...personalDataInitialValues,
  ...passwordInitialValues,
};
