import { Typography } from '@material-ui/core';
import formatDate from 'date-fns/format';
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import { timezoneUnawareDate } from '../../../utils/date';
import { Backdrop, BackdropCircularProgress } from '../styles';
import {
  Container,
  DateContainer,
  MessageContainer,
  SubTitleContainer,
} from './styles';

const Payment: React.FC = () => {
  const { isAllowedToRead: isAllowedToReadSubscription } = useCanAccess(
    'user/subscription'
  );
  const { userInfo, loading, refreshUserInfo } = useContext(AuthContext);

  useEffect(() => {
    refreshUserInfo();
  }, []);

  const patient = userInfo?.activePatient;

  if (!isAllowedToReadSubscription) {
    return <Redirect to={'/'} />;
  }

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const hasPlanExpirationDate = () => {
    if (!patient || !patient.planExpirationDate) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Container>
        {hasPlanExpirationDate() ? (
          <>
            <SubTitleContainer>Seu plano vence no dia</SubTitleContainer>
            <DateContainer>
              <Typography variant="h5">
                {formatDate(
                  timezoneUnawareDate(patient!.planExpirationDate!),
                  'dd/MM/yyyy'
                )}
              </Typography>
            </DateContainer>
          </>
        ) : (
          <MessageContainer>
            Seu plano não tem data de vencimento configurada. Se tiver algum
            problema, contacte o suporte.
          </MessageContainer>
        )}
      </Container>
    </>
  );
};

export default Payment;
