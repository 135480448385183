import React from 'react';
import { ErrorOutline } from '@material-ui/icons';
import { Container, StyledSpan } from './styles';

interface ErrorTextProps {
  errorMessage: string;
}

const ErrorText: React.FC<ErrorTextProps> = ({ errorMessage }) => {
  return (
    <Container>
      {errorMessage && (
        <>
          <ErrorOutline />
          <StyledSpan>{errorMessage}</StyledSpan>
        </>
      )}
    </Container>
  );
};

export default ErrorText;
