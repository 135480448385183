import styled from 'styled-components';
import { IconButton, Typography } from '@material-ui/core';

interface CardBoxProps {
  isActive?: boolean;
}

interface StyledTypographyProps {
  statusColor: string;
}

interface StatusLabelProps {
  statusLabel: string;
}

export const CardBox = styled.div<CardBoxProps>`
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.extra.color.grey.light
      : props.theme.palette.common.white};
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(8)}px;
  box-sizing: border-box;
  padding-inline: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(1.5)}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;
  width: 100%;
`;

export const UserInfo = styled(Typography)<StatusLabelProps>`
  color: ${(props) =>
    props.statusLabel === 'INATIVA'
      ? ({ theme }) => theme.palette.extra.color.grey.main
      : ({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: clamp(0.95rem, 75%, 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const RoleTitle = styled(Typography)<StatusLabelProps>`
  color: ${(props) =>
    props.statusLabel === 'INATIVA'
      ? ({ theme }) => theme.palette.extra.color.grey.main
      : ({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: clamp(0.85rem, 75%, 2rem);
  display: -webkit-box;
  max-width: 350px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const RoleTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ClickableArea = styled.div`
  cursor: pointer;
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2.5)}px;
`;
