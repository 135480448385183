import { EventModel } from '@cuidador/database';
import { FormGroup, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import StyledFormControlLabel from '../StyledFormControlLabel';

import useSubCategory from '../../hooks/useSubCategory';
import { floatMask } from '../../utils/inputs';
import AutocompleteTextField, {
  Option as AutoCompleteOption,
  Value as AutoCompleteValue,
} from '../AutocompleteTextField';
import StyledSelect from '../Forms/FormikSelect';
import FormikTextField from '../Forms/FormikTextField';
import StyledButton from '../StyledButton';
import StyledMenuItem from '../StyledMenuItem';
import {
  Centralizer,
  Form,
  StyledCheckbox,
  TypographyContainer,
} from './styles';
import {
  administeredNames,
  dosesNames,
  FormValues,
  frequencyOptions,
  needScheduleData,
  validationSchema,
} from './utils';

export interface Props {
  page?: number;
  handlePage: (page: number) => void;
}

const MedicationUpsertForm: React.FC<
  {
    initialValues: FormValues;
    innerRef?: React.Ref<FormikProps<FormValues>>;
    onSubmit: (values: FormValues) => void;
  } & Props
> = ({ initialValues, innerRef, onSubmit, page, handlePage }) => {
  const { getOnlyForMedication, byId } = useSubCategory();

  const handleMedicationAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOption[]> => {
    const params = {
      'eventSubCategory.name': `%${textValue}%`,
    };
    const data = await getOnlyForMedication({ ...params, limit: 20 });
    return data?.map((medication: EventModel) => ({
      text: medication.name,
      value: medication.id,
    })) as AutoCompleteOption[];
  };

  const medicationAutoCompleteInitialValue = initialValues.subCategory
    ? {
        text: `${initialValues.subCategory.name}`,
        value: Number(initialValues.subCategory.id),
      }
    : undefined;

  const nextStepAllowed = (values: FormValues) => {
    if (
      !values.subCategoryId ||
      !values.dosageFormat ||
      !values.dosageQuantity ||
      !values.administeredBy
    ) {
      return false;
    } else {
      return true;
    }
  };

  const now = format(new Date(), 'yyyy-MM-dd');
  const MAX_DATE = format(new Date('12/31/9999'), 'yyyy-MM-dd');

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        innerRef={innerRef}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <Form
              // noValidate disables browser default validation
              noValidate={true}
            >
              {page === 1 ? (
                <>
                  <AutocompleteTextField
                    label="Medicamento"
                    initialValue={medicationAutoCompleteInitialValue}
                    onChangeDebounced={handleMedicationAutoCompleteOnChange}
                    onValueChange={(item: AutoCompleteValue) => {
                      setFieldValue('subCategoryId', item?.value);
                    }}
                    color="secondary"
                    margin="normal"
                    testId="subCategoryId"
                    name="subCategoryId"
                  />

                  <StyledSelect
                    name="dosageFormat"
                    label="Formato da Dose"
                    value={values?.dosageFormat || ''}
                    SelectDisplayProps={{
                      'data-testid': 'dosageFormat',
                    }}
                    color="secondary"
                  >
                    {dosesNames.map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.value}
                        color="secondary"
                      >
                        {item.name}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                  <FormikTextField
                    value={values.dosageQuantity}
                    color="secondary"
                    id="dosageQuantity"
                    inputProps={{ 'data-testid': 'dosageQuantity' }}
                    label="Quantidade (comprimidos, gotas, etc.)"
                    name="dosageQuantity"
                    margin="normal"
                    autoComplete="off"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        'dosageQuantity',
                        floatMask(e.target.value)
                      );
                    }}
                  />
                  <StyledSelect
                    name="administeredBy"
                    value={values?.administeredBy || ''}
                    label="Vias de Aplicação"
                    SelectDisplayProps={{
                      'data-testid': 'administeredBy',
                    }}
                    color="secondary"
                  >
                    {administeredNames.map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.value}
                        color="secondary"
                      >
                        {item.name}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                  <Centralizer>
                    <StyledButton
                      data-testid="next-button"
                      onClick={() => handlePage(2)}
                      size="large"
                      color="inherit"
                      disabled={!nextStepAllowed(values)}
                    >
                      Próximo
                    </StyledButton>
                  </Centralizer>
                </>
              ) : (
                <>
                  <TypographyContainer>
                    <Typography variant="h6">
                      {values?.subCategoryId
                        ? byId[values?.subCategoryId]?.name
                        : ''}
                    </Typography>
                  </TypographyContainer>
                  <FormGroup aria-label="position">
                    <StyledFormControlLabel
                      value="continuous"
                      name="continuous"
                      control={
                        <StyledCheckbox
                          checked={values.continuous}
                          onChange={(e) =>
                            setFieldValue('continuous', e.target.checked)
                          }
                        />
                      }
                      label="Uso contínuo"
                    />
                  </FormGroup>
                  <FormGroup aria-label="position">
                    <StyledFormControlLabel
                      value="ifNecessary"
                      name="ifNecessary"
                      control={
                        <StyledCheckbox
                          checked={values.ifNecessary}
                          onChange={(e) =>
                            setFieldValue('ifNecessary', e.target.checked)
                          }
                        />
                      }
                      label="Medicamento de uso apenas se necessário"
                    />
                  </FormGroup>
                  {needScheduleData(values) && (
                    <>
                      <StyledSelect
                        name="frequencyRule"
                        value={values?.frequencyRule || ''}
                        label="Qual Frequência ?"
                        SelectDisplayProps={{
                          'data-testid': 'frequencyRule',
                        }}
                        color="secondary"
                      >
                        {frequencyOptions.map((item) => (
                          <StyledMenuItem
                            key={item.id}
                            value={item.value}
                            color="secondary"
                          >
                            {item.name}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                      <FormikTextField
                        value={values?.startDate || ''}
                        color="secondary"
                        id="startDate"
                        inputProps={{
                          'data-testid': 'startDate',
                          min: now,
                          max: MAX_DATE,
                        }}
                        label="Data de Início"
                        name="startDate"
                        margin="normal"
                        autoComplete="off"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                      <FormikTextField
                        value={values?.time || ''}
                        color="secondary"
                        id="time"
                        inputProps={{ 'data-testid': 'time' }}
                        label="Horário de Início"
                        name="time"
                        margin="normal"
                        autoComplete="off"
                        type="time"
                        InputLabelProps={{ shrink: true }}
                      />
                      {!values?.continuous && (
                        // If not continuous get endDate
                        <>
                          <FormikTextField
                            value={values?.endDate || ''}
                            color="secondary"
                            id="endDate"
                            inputProps={{
                              'data-testid': 'endDate',
                              min: now,
                              max: MAX_DATE,
                            }}
                            label="Data de Fim"
                            name="endDate"
                            margin="normal"
                            autoComplete="off"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                    </>
                  )}
                  <FormikTextField
                    value={values?.medicalNotes || ''}
                    color="secondary"
                    id="medicalNotes"
                    inputProps={{ 'data-testid': 'medicalNotes' }}
                    label="Orientações Médicas"
                    name="medicalNotes"
                    margin="normal"
                    autoComplete="off"
                  />
                  <Centralizer>
                    <StyledButton
                      data-testid="submit-medication"
                      type="submit"
                      size="large"
                      color="inherit"
                      disabled={isSubmitting || isEqual(initialValues, values)}
                    >
                      Salvar
                    </StyledButton>
                  </Centralizer>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MedicationUpsertForm;
