import { PatientModel } from '@cuidador/database'
import { resolveShiftStatusLabel } from '../../components/SelectCard/utils'

export type PatientListOccurrences = { patientId: number; occurrencesOfPatient: number[] }[]

export function getFilteredPatientList (patientList: PatientModel[], occurrences: PatientListOccurrences, filter: string | null): PatientModel[] {
  let listResult: PatientModel[] = []

  switch(filter){
    case 'plan_atrasado': {
      listResult = patientList.filter(patient => resolveShiftStatusLabel(patient.shifts) === 'Atrasado' && patient.status === 'enabled')
      break
    }
    case 'intercorrencia': {
      const patientIds = occurrences.filter(occurrence => occurrence.occurrencesOfPatient[0]).map(item => item.patientId)
      listResult = patientList.filter(patient => patientIds.find(patientId => patientId === patient.id))
      break
    }
    case 'med_fora_do_horario': {
      const patientIds = occurrences.filter(occurrence => occurrence.occurrencesOfPatient[1]).map(item => item.patientId)
      listResult = patientList.filter(patient => patientIds.find(patientId => patientId === patient.id))
      break
    }
    case 'med_alterada': {
      const patientIds = occurrences.filter(occurrence => occurrence.occurrencesOfPatient[2]).map(item => item.patientId)
      listResult = patientList.filter(patient => patientIds.find(patientId => patientId === patient.id))
      break
    }
    case 'compromisso': {
      const patientIds = occurrences.filter(occurrence => occurrence.occurrencesOfPatient[4]).map(item => item.patientId)
      listResult = patientList.filter(patient => patientIds.find(patientId => patientId === patient.id))
      break
    }
    default:
      listResult = patientList
      break
  }
  return listResult
}
