import { Typography } from '@material-ui/core';
import React from 'react';
import { CardBox, StyledCardName } from './styles';

type AdditionalCoverageCardProps = {
  id?: number;
  name?: string;
  content?: string;
};

const AdditionalCoverageCard: React.FC<AdditionalCoverageCardProps> = ({
  id,
  name,
  content,
}) => {
  return (
    <CardBox data-testid={`additional-coverage-${id}`}>
      <StyledCardName>{name}</StyledCardName>
      <Typography>{content}</Typography>
    </CardBox>
  );
};

export default AdditionalCoverageCard;
