import { clientSideScheme } from '@cuidador/whitelabel';
import styled from 'styled-components';
import BackgroundImage from '../../assets/loginBackground.png';

export const Background = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OutterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  max-width: 500px;
  color: white;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;

  img {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .MuiButton-root {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
    }
  }

  a {
    font-size: ${({ theme }) => theme.spacing(2)}px;
    color: white;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const TitleTypography = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const Logo = styled.img.attrs(() => {
  const { logoLightUrl } = clientSideScheme();
  return { src: logoLightUrl };
})`
  margin: ${({ theme }) => theme.spacing(0, 2, 0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
`;

export const BackButton = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;
