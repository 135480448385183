import styled from 'styled-components';
import { CardBoxProps } from './utils';

export const CardBox = styled.div<CardBoxProps>`
  position: relative;
  background-color: ${({ color }) => color};
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  box-shadow: 0.1em 0.1em 0.2em gray;
  margin-bottom: 1em;
  margin-top: 2em;
`;

export const TextContainer = styled.div`
  width: 85%;
  margin: 0.75em 0 0.75em 0.75em;
`;

export const ButtonContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  font-size: 3em;
  margin-right: 0.2em;
`;

export const TitleContainer = styled.div`
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0.25em;
`;
