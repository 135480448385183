import Typography from '@material-ui/core/Typography';
import { CloudDownload } from '@material-ui/icons';
import React from 'react';
import { DownloadLink, ReportButtonContainer, StyledButton } from './styles';

const ReportDownloadButton: React.FC<{
  onGenerateReportClick: () => Promise<void>;
  downloadtitle: string;
  createTitle: string;
  downloadLink: string;
}> = ({ onGenerateReportClick, downloadtitle, createTitle, downloadLink }) => {
  return (
    <ReportButtonContainer>
      {downloadLink ? (
        <DownloadLink href={downloadLink}>
          <CloudDownload />
          {downloadtitle}
        </DownloadLink>
      ) : (
        <>
          <StyledButton onClick={onGenerateReportClick}>
            {createTitle}
          </StyledButton>
          <Typography variant="body2" align="center">
            Quando o relatório estiver pronto, o botão acima será substituído
            pelo link para baixar o arquivo.
          </Typography>
        </>
      )}
    </ReportButtonContainer>
  );
};

export default ReportDownloadButton;
