import { ProfessionalReportModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer } from '../utils/store';
import {
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';

const endpoint = '/report/professional';

const initialData: ReducerData<ProfessionalReportModel> = {
  byId: {} as Record<string, Item<ProfessionalReportModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useProfessionalReport = () => {
  const [state, dispatch] = useReducer(
    createReducer<ProfessionalReportModel>(),
    initialData
  );

  const getByPatientId = useCallback(
    async (patientId, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<ProfessionalReportModel>[];
          total: number;
        }>(`${endpoint}/by-patient/${patientId}?isDraft=0`, {
          params,
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response.data);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response.data as ProfessionalReportModel);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getByPatientId,
    getById,
  };
};

export default useProfessionalReport;
