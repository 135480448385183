import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Fab from '../../components/Fab';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-color: #fafafa;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
`;

export const StyledTabItem = styled(Tab)`
  width: 120px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px 5px 0 0;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledFab = styled(Fab)`
  bottom: ${({ theme }) => theme.spacing(8)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;
