import { ShiftScheduleModel } from '@cuidador/database';
import { getLocalWeeklySchedule, getUTCWeeklySchedule } from '../../utils/date';
import { FormValues } from './';

export type WeekDays = Pick<
  ShiftScheduleModel,
  'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
>;

/**
 * Format week days from FormValues according to the UTC and local date time.
 */
export const formatWeekDaysFromFormValues = (
  formValues: FormValues
): WeekDays => {
  const weeklySchedule = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };
  const time = formValues.startingTime;

  formValues.days.forEach((day) => {
    if (day === 'Dom') {
      weeklySchedule.sun = true;
    }
    if (day === 'Seg') {
      weeklySchedule.mon = true;
    }
    if (day === 'Ter') {
      weeklySchedule.tue = true;
    }
    if (day === 'Qua') {
      weeklySchedule.wed = true;
    }
    if (day === 'Qui') {
      weeklySchedule.thu = true;
    }
    if (day === 'Sex') {
      weeklySchedule.fri = true;
    }
    if (day === 'Sab') {
      weeklySchedule.sat = true;
    }
  });

  return getUTCWeeklySchedule(weeklySchedule, time);
};

/**
 * Format week days from ShiftScheduleModel according to the UTC and local date time.
 */
export const formatWeekDaysFromShiftSchedule = (
  shiftSchedule: Partial<ShiftScheduleModel>
): FormValues['days'] => {
  const days: FormValues['days'] = [];
  const time = shiftSchedule.startingTime!;

  const localWeeklySchedule = getLocalWeeklySchedule(shiftSchedule, time);

  if (localWeeklySchedule.sun) {
    days.push('Dom');
  }
  if (localWeeklySchedule.mon) {
    days.push('Seg');
  }
  if (localWeeklySchedule.tue) {
    days.push('Ter');
  }
  if (localWeeklySchedule.wed) {
    days.push('Qua');
  }
  if (localWeeklySchedule.thu) {
    days.push('Qui');
  }
  if (localWeeklySchedule.fri) {
    days.push('Sex');
  }
  if (localWeeklySchedule.sat) {
    days.push('Sab');
  }

  return days;
};
