import React from 'react';
import { Centralizer, Form } from './styles';

import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FormValues } from './utils';

import useCanAccess from '../../hooks/useCanAccess';
import FormikTextField from '../Forms/FormikTextField';
import StyledButton from '../StyledButton';

const ShoppingListUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, innerRef, onSubmit }) => {
  const { isAllowedToInvoke: isAllowedToBuy } = useCanAccess(
    'care/shopping-list.buy'
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {() => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <FormikTextField
              color="secondary"
              id="shoppingText"
              inputProps={{ 'data-testid': 'shoppingText' }}
              placeholder="No momento, não há nenhuma solicitação de compra."
              name="shoppingText"
              margin="normal"
              autoComplete="off"
              multiline
              rows={10}
              disabled
              fullWidth
            />
            {isAllowedToBuy && (
              <Centralizer>
                <StyledButton
                  data-testid="submit-list"
                  type="submit"
                  size="large"
                  color="inherit"
                >
                  Comprado
                </StyledButton>
              </Centralizer>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ShoppingListUpsertForm;
