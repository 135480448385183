import { AnswerType, QuestionAnswerOption } from '@cuidador/database';
import React, { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { QuestionContainer, QuestionTitle } from '../styles';
import InterviewAnswerOption from './InterviewAnswerOption';

type FormProps = {
  readOnly?: boolean;
  careQuestionId?: Id;
  name?: string;
  careQuestionText?: string;
  questionAnswerData: AnswerType;
  onAnswerChange: (careQuestionId: Id, questionAnswerData: AnswerType) => void;
  onAnswerChangeDebounce: (
    careQuestionId: number,
    questionAnswerData: AnswerType
  ) => void;
};

const InterviewAnswer: React.FC<FormProps> = React.memo(
  ({
    careQuestionId,
    name,
    careQuestionText,
    questionAnswerData,
    readOnly,
    onAnswerChange,
    onAnswerChangeDebounce,
  }: FormProps) => {
    const debounced = useDebouncedCallback(
      (careQuestionId: number, questionAnswerData: AnswerType) =>
        onAnswerChangeDebounce(careQuestionId, questionAnswerData),
      500
    );
    /**
     * Build new answer when any option changes
     */
    const handleOptionsChange = useCallback(
      (options: QuestionAnswerOption[]) => {
        const newAnswerData: AnswerType = {
          ...questionAnswerData,
          options,
        };
        onAnswerChange(careQuestionId!, newAnswerData);
        debounced.callback(Number(careQuestionId!), newAnswerData);
      },
      [careQuestionId, questionAnswerData]
    );
    return (
      <QuestionContainer key={careQuestionId}>
        <QuestionTitle variant="subtitle1" color="primary">
          {name}: {careQuestionText}
        </QuestionTitle>
        <InterviewAnswerOption
          readOnly={readOnly}
          type={questionAnswerData.type}
          options={questionAnswerData.options}
          onOptionsChange={handleOptionsChange}
        />
      </QuestionContainer>
    );
  }
);
InterviewAnswer.displayName = 'InterviewAnswer';

export default InterviewAnswer;
