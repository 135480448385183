import * as yup from 'yup';

export type FormValues = {
  promotionalCode: string;
};

export const initialValues: FormValues = {
  promotionalCode: '',
};

const validationSchema = yup.object().shape({
  promotionalCode: yup.string(),
});

export const makeValidationSchema = (
  requirePromotionalCode: boolean,
  message?: string
) => {
  if (!requirePromotionalCode) {
    return validationSchema;
  } else {
    return validationSchema.shape({
      promotionalCode: yup
        .string()
        .required(message || 'Por favor, informe um cupom'),
    });
  }
};
