import styled from 'styled-components';

interface ButtonContainerProps {
  buttonStyle: 'light' | 'dark';
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  button,
  button:hover {
    background: ${(props) =>
      props.buttonStyle === 'dark'
        ? ({ theme }) => theme.palette.primary.main
        : ({ theme }) => theme.palette.common.white};
    outline: 1px solid ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(0.5, 0.1)};
    min-width: 40px;
    min-height: 40px;

    svg path {
      stroke: ${(props) =>
        props.buttonStyle === 'dark'
          ? ({ theme }) => theme.palette.common.white
          : ({ theme }) => theme.palette.primary.main};
    }
  }
`;
