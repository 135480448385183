import { Typography } from '@material-ui/core';
import React from 'react';
import useCanAccess from '../../hooks/useCanAccess';
import {
  Button,
  ButtonContainer,
  CardBox,
  DescriptionList,
  DiscountContainer,
  DiscountTypography,
  Titles,
  TypographyContainer,
  ValueTypography,
} from './styles';
export type AssignPlanCardProps = {
  name?: string;
  value?: number;
  discount?: number;
  interval?: string;
  intervalCount?: number;
  handleClick: () => void;
};

export const AssignPlanCardProps: React.FC<AssignPlanCardProps> = ({
  name,
  value,
  interval,
  intervalCount,
  discount,
  handleClick,
}) => {
  const isMonthly = () => interval === 'month' && intervalCount === 1;
  const { isAllowedToInvoke } = useCanAccess('user/plan/paid.subscribe');

  return (
    <CardBox data-testid={`card-${name}`}>
      <TypographyContainer>
        <Titles>
          <Typography variant="h6">{name}</Typography>
          {value && (
            <ValueTypography variant="body2">
              R$ {(value / 100).toFixed(2)}
            </ValueTypography>
          )}
        </Titles>
        {discount && (
          <DiscountContainer>
            <DiscountTypography variant="body2">
              {discount * 100}% OFF
            </DiscountTypography>
          </DiscountContainer>
        )}
      </TypographyContainer>
      <DescriptionList>
        <li>Acesso a todas as funcionalidades</li>
        <li>Plano de cuidado personalizado</li>
        <li>Cadastro de cuidadores ilimitado</li>
        {isMonthly() && <li>Cancelamento a qualquer momento</li>}
        <li>
          A assinatura se inicia assim que for confirmado o pagamento
          {isMonthly() && ' e é renovada todo mês, de maneira recorrente'}
        </li>
      </DescriptionList>
      <ButtonContainer>
        <Button disabled={!isAllowedToInvoke} onClick={() => handleClick()}>
          Assinar
        </Button>
      </ButtonContainer>
    </CardBox>
  );
};

export default AssignPlanCardProps;
