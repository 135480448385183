import {
  EventSchedule,
  EventSubCategoryModel,
  MedicationModel,
} from '@cuidador/database';
import { FrequencyType } from '../DaysSelector';
import { PeriodType } from '../PeriodSelector';

export enum CustomMedicationInterval {
  WeekDays = 'Apenas alguns dias',
  Custom = 'Personalizado',
}

export enum MedicationInsertFormStep {
  SelectMedication,
  FillDetails,
}

export interface FormValues {
  dosageFormat: MedicationModel['dosageFormat'];
  dosageQuantity: number;
  administeredBy: MedicationModel['administeredBy'];
  subCategory?: EventSubCategoryModel;
  subCategoryId?: Id;
  name?: string;
  scheduleType?: EventSchedule['scheduleType'];
  frequencyRule?: string;
  ifNecessary?: boolean;
  medicalNotes?: string;
  eventScheduleId?: Id;
  dayOptions?: FrequencyType;
  days?: string[];
  rangeInDays?: '2d' | '3d' | '5d' | '7d' | '15d' | '30d' | 'custom';
  customRangeInDays?: number;
  timeSchedule?: string;
  time?: string;
  customTimes?: string[];
  frequencyStartsAt?: string;
  frequencyEndsAt?: string;
  periodType: PeriodType;
}

export const dosesNames = [
  { id: 1, value: 'pill', name: 'Comprimido' },
  { id: 2, value: 'capsule', name: 'Cápsula' },
  { id: 3, value: 'sachet', name: 'Sachê' },
  { id: 4, value: 'drops', name: 'Gota' },
  { id: 5, value: 'ampoule', name: 'Ampola' },
  { id: 6, value: 'suppository', name: 'Supositório' },
  { id: 7, value: 'inhaled', name: 'Jato/Puff' },
  { id: 8, value: 'unit', name: 'Unidade internacional (UI)' },
  { id: 9, value: 'ml', name: 'ml' },
  { id: 10, value: 'spoon', name: 'Colher' },
  { id: 11, value: 'grams', name: 'Grama' },
  { id: 12, value: 'strip', name: 'Placa/Tira/Fita' },
  { id: 13, value: 'sticker', name: 'Adesivo' },
];

export const administeredNames = [
  { id: 1, value: 'oral', name: 'Oral' },
  { id: 2, value: 'sublingual', name: 'Sublingual' },
  { id: 3, value: 'subcutaneous', name: 'Subcutânea' },
  { id: 4, value: 'intravenous', name: 'Intravenosa' },
  { id: 5, value: 'intramuscular', name: 'Intramuscular' },
  { id: 6, value: 'tube', name: 'Sonda' },
  { id: 7, value: 'rectal', name: 'Retal' },
  { id: 8, value: 'inhalation', name: 'Inalatório' },
  { id: 9, value: 'topic', name: 'Tópico (Cutânea)' },
  { id: 10, value: 'ocular', name: 'Ocular' },
  { id: 11, value: 'auricular', name: 'Auricular (Otológica)' },
  { id: 12, value: 'vaginal', name: 'Vaginal' },
];

export const frequencyOptions = [
  { value: '2h', label: '2h/2h' },
  { value: '4h', label: '4h/4h' },
  { value: '6h', label: '6h/6h' },
  { value: '8h', label: '8h/8h' },
  { value: '12h', label: '12h/12h' },
  { value: '24h', label: '24h/24h (1 vez ao dia)' },
  { value: '48h', label: '48h/48h (a cada 2 dias)' },
  { value: '72h', label: '72h/72h (a cada 3 dias)' },
  { value: '1w', label: 'Semanal' },
  { value: '2w', label: 'Quinzenal' },
  { value: '1M', label: 'Mensal' },
  { value: '6M', label: 'Semestral' },
];

export interface MedicationItem {
  description?: string;
  name: string;
  id: number;
}
