import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledButton from '../../../StyledButton';

export const MedicationTextRow = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => theme.spacing(20)}px;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const CustomTimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
`;

export const AddButtonContainer = styled.div`
  width: 10%;
`;

export const StyledAddButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  min-width: 0px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: 0;
`;

export const StyledRemoveButton = styled(StyledButton)`
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  min-width: 0px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: 0;
`;
