import { PlanModel } from '@cuidador/database';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ReactComponent as HandsIcon } from '../../assets/hands.svg';
import AssignPlanCardProps from '../../components/AssignPlanCard';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import usePlan from '../../hooks/usePlan';
import {
  Container,
  StyledTypography,
  TextTitleContainer,
  TitleContainer,
} from './styles';
import { Header } from '../../components/Header'
import { AuthContext } from '../../contexts/auth'
import LogoutDialog from '../../components/LogoutDialog'
import { BackButton } from '../../components/BackButton'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'

const PlanList: React.FC = () => {
  const [plans, setPlans] = useState<PlanModel[]>([]);
  const { loading, getAvailablePlans } = usePlan();
  const history = useHistory();
  const [signOutDialogOpen, setSignOutDialogOpen] = useState<boolean>(false);

  const { signOut } = useContext(AuthContext);

  const handleToggleDialog = () => {
    setSignOutDialogOpen(!signOutDialogOpen);
  };

  useEffect(() => {
    getAvailablePlans()
      .then((data) => {
        setPlans(data);
      })
      .catch(() => toast.error('Erro ao carregar planos.'));
  }, []);

  return (
    <>
      <Header
        leftContent={
          <BackButton
            action={() => setSignOutDialogOpen(true)}
          />
        }
        centerContent={
          <PageTitle title='Planos de assinatura' />
        }
        rightContent={<PscButton />}
      />
      <LogoutDialog
        open={signOutDialogOpen}
        onClose={handleToggleDialog}
        onConfirm={signOut}
      />
      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        <>
          <TitleContainer>
            <HandsIcon data-testid="handsImage" />
            <TextTitleContainer>
              <StyledTypography>
                Contrate o plano que melhor se ajusta à sua necessidade.
              </StyledTypography>
            </TextTitleContainer>
          </TitleContainer>

          <Container>
            {plans.map((plan) => (
              <AssignPlanCardProps
                handleClick={() =>
                  history.push('/assinatura', { selectedPlan: plan })
                }
                name={plan.planCardTitle || plan.name}
                value={plan.priceInCents}
                discount={plan.discountInPercentage}
                interval={plan.interval}
                intervalCount={plan.intervalCount}
                key={plan.id}
              />
            ))}
          </Container>
        </>
      )}
    </>
  );
};

export default PlanList;
