import { ShiftModel } from '@cuidador/database';
import React from 'react';
import { useTheme } from 'styled-components';
import { Container, IndicatorCircle, IndicatorLabel } from './styles';

const ShiftInProgressIndicator: React.FC<{ shift: ShiftModel }> = ({
  shift,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <IndicatorCircle
        color={
          shift.executionInProgress
            ? theme.palette.success.main
            : theme.palette.warning.main
        }
      />
      <IndicatorLabel>
        {shift.executionInProgress ? (
          'Plantão em andamento'
        ) : (
          <b>{'Plantão incompleto'}</b>
        )}
      </IndicatorLabel>
    </Container>
  );
};

export default ShiftInProgressIndicator;
