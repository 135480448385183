import * as Yup from 'yup';
import { UserModel, GuardianModel } from '@cuidador/database';

import { phoneMask, numberMask } from '../../../utils/inputs';

export interface FormValues {
  name: string;
  cpf: string;
  phoneNumber: string;
  email: string;
}

export const userModelToFormData = (user: UserModel): FormValues => ({
  name: user?.name || '',
  cpf: user?.cpf || '',
  email: user?.email || '',
  phoneNumber: user?.phoneNumber ? phoneMask(user.phoneNumber) : '',
});

export const formDataToGuardianModel = (values: FormValues) =>
  ({
    user: {
      name: values.name,
      phoneNumber: numberMask(`55${values.phoneNumber}`),
    },
  } as GuardianModel);

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  phoneNumber: Yup.string()
    .required('Por favor, insira um celular')
    .min(15, 'Por favor, insira um celular válido')
    .max(15, 'Por favor, insira um celular válido'),
});
