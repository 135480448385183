import React from 'react';
import {
  CardBox,
  TypographyContainer,
  IconContainer,
  WeekDayContainer,
  TimerContainer,
  TitleContainer,
} from './styles';
import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ClockIcon } from '../../assets/Icon-clock.svg';
import format from 'date-fns/format';
import {
  frequencyOptions,
  getSelectedWeekDays,
  getCustomInterval,
  CustomInterval,
} from '../RoutinesUpsertForm/utils';
import ptBrLocale from 'date-fns/locale/pt-BR';

interface CardProps {
  id: Id;
  subCategory?: EventSubCategoryModel[];
  name?: string;
  description?: string;
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
  frequencyRule?: string;
  frequencyStartsAt?: string;
  frequencyEndsAt?: string | undefined | null;
}

const RoutineCard: React.FC<CardProps> = ({
  id,
  subCategory,
  name,
  description,
  mon,
  tue,
  wed,
  thu,
  fri,
  sat,
  sun,
  frequencyRule,
  frequencyStartsAt,
  frequencyEndsAt
}) => {
  const frequency = frequencyOptions.find(
    (frequency) => frequency.value === frequencyRule
  );

  const getWeekDay = () => {
    const frequencyCardTypes = ['1w', '2w'];
    if (frequency && frequencyCardTypes.includes(frequency.value))
      return format(new Date(frequencyStartsAt || ''), 'EEE', {
        locale: ptBrLocale,
      });
    return '';
  };

  const getFrequency = () => {
    if (getCustomInterval(frequencyRule) === CustomInterval.WeekDays) {
      return getSelectedWeekDays({
        subCategory,
        name,
        description,
        mon,
        tue,
        wed,
        thu,
        fri,
        sat,
        sun,
        frequencyRule,
        frequencyStartsAt,
      })?.join(',');
    } else {
      return frequency?.name;
    }
  };

  const routineEnded = new Date(frequencyEndsAt || '') < new Date()

  const fixedEventTimeLocal = format(new Date(frequencyStartsAt!), 'HH:mm');

  return (
    <CardBox data-testid={`routineCard-${id}`}>
      <IconContainer>
        <TimerContainer>
          <Typography variant="h6">
            {frequency && getWeekDay() && `${getWeekDay()} - `}
            {fixedEventTimeLocal}
          </Typography>
        </TimerContainer>

        <ClockIcon />
      </IconContainer>

      <TypographyContainer>
        <TitleContainer>
          <Typography variant="h6">
            {subCategory && subCategory.length > 0 && subCategory[0].name}
          </Typography>
        </TitleContainer>

        <Typography variant="subtitle1">
          {name?.trim()}
        </Typography>

        <Typography variant="subtitle1">
          <WeekDayContainer>
            {getFrequency()}
          </WeekDayContainer>
        </Typography>

        <Typography variant="subtitle2">
          {description}
        </Typography>

        <Typography variant='subtitle2' style={{ color: routineEnded ? 'yellow' : '' }}>
          {routineEnded ? 'Rotina finalizada' : 'Rotina em andamento'}
        </Typography>
      </TypographyContainer>
    </CardBox>
  );
};

export default RoutineCard;
