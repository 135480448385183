import {
  Backdrop as MaterialBackdrop,
  Checkbox,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const StyledCheckbox = styled(Checkbox).attrs({
  color: 'secondary',
})`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
