import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fafafa;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const StyledIcon = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  vertical-align: sub;
`;

export const StyledDescriptionIcon = styled(StyledIcon)`
  vertical-align: top;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTitle = styled(StyledTypography)`
  font-weight: 600;
`;

export const StyledPlan = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: #f3f3f3;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledChangePlan = styled(StyledButton)`
  float: right;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.dark};
`;

export const StyledText = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
`;

export const StyledLine = styled.div`
  border-bottom: 1px solid #cccccc;
  margin: ${({ theme }) => theme.spacing(2, 2)};
`;

export const StyledPay = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  border: 3px solid #cccccc;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledChangeCard = styled(StyledButton)`
  width: 30%;
  margin-left: 70%;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  border: none;
`;

export const StyledChangeContract = styled(StyledButton)`
  align-self: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  border: none;
`;
