import { IconButton } from '@material-ui/core';
import { Group as GroupIcon, KeyboardArrowDown } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import { ReactComponent as GraphIcon } from '../../../assets/icon_graph.svg';

export const StyledGroupIcon = styled(GroupIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledGraphIcon = styled(GraphIcon)`
  width: 15px;
  height: 15px;
`;

export const ExpandButton = styled(IconButton)`
  width: 70px;
  height: 70px;
`;

export const StyledArrow = styled(KeyboardArrowDown)<{ isExpanded: boolean }>`
  width: 100%;
  height: 40px;
  transition: all 0.25s ease-in-out;

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;

export const Row = styled.div`
  display: flex;
`;
