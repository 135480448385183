import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import StyledButton from '../../StyledButton';

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const CustomTimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 9;
  width: 100%;
`;

export const AddButtonContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  flex: 1;
`;

export const StyledAddButton = styled(StyledButton)<{ disabled: boolean }>`
  color: ${({ theme }) => theme.palette.secondary.main};
  min-width: 0px;
  align-self: center;
  justify-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  padding: 0;
  border: none !important;
  height: 100%;
  flex: 1;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled
            ? theme.palette.text.disabled
            : theme.palette.secondary.main};
      border-radius: 50%;
    }
    span {
      font-size: 10px;
      line-height: 1;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;

export const StyledRemoveButton = styled(StyledButton)`
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  min-width: 0px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: 0;
`;
