import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  border-bottom: 2px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
`;

export const TextTitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
  width: 85%;
`;

export const StyledTypography = styled(Typography)`
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-family: Helvetica;
`;
