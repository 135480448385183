import React, { createContext, useContext, useEffect, useState } from 'react';
import useAppointment from '../hooks/useAppointment';
import useCaregiver from '../hooks/useCaregiver';
import useEmergencyContacts from '../hooks/useEmergencyContacts';
import useMeasurementSchedule from '../hooks/useMeasurementSchedule';
import useMedication from '../hooks/useMedication';
import useRoutine from '../hooks/useRoutine';
import useShiftSchedule from '../hooks/useShiftSchedule';
import { AuthContext } from './auth';

const objectMap = (
  object: Record<string, unknown>,
  callback: (value: unknown) => unknown
) =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, callback(value)])
  );

const KEY_TO_HOOK = Object.freeze({
  appointment: useAppointment,
  caregiver: useCaregiver,
  emergencyContact: useEmergencyContacts,
  medication: useMedication,
  routine: useRoutine,
  shiftSchedule: useShiftSchedule,
  measurementSchedule: useMeasurementSchedule,
});
export const DEFAULT_COUNT = Object.freeze(
  objectMap(KEY_TO_HOOK, () => 0) as Record<keyof typeof KEY_TO_HOOK, number>
);
export const DEFAULT_IS_COUNTING = Object.freeze(
  objectMap(KEY_TO_HOOK, () => true) as Record<
    keyof typeof KEY_TO_HOOK,
    boolean
  >
);

export type CountType = Record<keyof typeof KEY_TO_HOOK, number>;
export type IsCountingType = Record<keyof typeof KEY_TO_HOOK, boolean>;

type ContextProps = {
  count: CountType;
  isCounting: IsCountingType;
  triggerRefresh: () => void;
};

export const RegistrationDashboardContext = createContext<ContextProps>({
  count: DEFAULT_COUNT,
  isCounting: DEFAULT_IS_COUNTING,
  triggerRefresh: () => undefined,
});

const RegistrationDashboardProvider: React.FC = ({ children }) => {
  const [count, setCount] = useState<CountType>(DEFAULT_COUNT);
  const [isCounting, setIsCounting] = useState<IsCountingType>(
    DEFAULT_IS_COUNTING
  );

  const { userInfo } = useContext(AuthContext);

  const patientId = userInfo?.activePatientId;

  // since KEY_TO_HOOK object is const and frozen, and also Object.entries(KEY_TO_HOOK) preserves order
  // this will NOT break React's rule of hooks, all hooks are called the same amount of times and in the same order
  // that's because KEY_TO_HOOK behaves as a true constant, it can NEVER change in runtime, only in devtime
  // therefore, this block is equivalent to writing each useEffect for each entry of the KEY_TO_HOOK object
  const getAllByPatientIdFunctions: Array<
    (id: Id) => Promise<void>
  > = Object.entries(KEY_TO_HOOK).map(([key, useHook]) => {
    const assertedKey = key as keyof typeof KEY_TO_HOOK;

    const { getAllByPatientId, loading, total } = useHook();

    useEffect(() => {
      const newIsCounting = { ...isCounting };
      newIsCounting[assertedKey] = loading;
      setIsCounting(newIsCounting);
    }, [loading]);

    useEffect(() => {
      const newCount = { ...count };
      newCount[assertedKey] = total;
      setCount(newCount);
    }, [total]);

    return getAllByPatientId;
  });

  const triggerRefresh = () => {
    if (!patientId) return;
    getAllByPatientIdFunctions.forEach((getAllByPatientId) => {
      getAllByPatientId(patientId);
    });
    setIsCounting(DEFAULT_IS_COUNTING);
  };

  useEffect(() => {
    triggerRefresh();
  }, [patientId]);

  return (
    <RegistrationDashboardContext.Provider
      value={{
        count,
        isCounting,
        triggerRefresh,
      }}
    >
      {children}
    </RegistrationDashboardContext.Provider>
  );
};

export default RegistrationDashboardProvider;
