import { Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns';
import React from 'react';
import {
  HeaderContainer,
  HeaderSubtitle,
  TimerBox,
  TimerContainer,
} from './styles';

interface ShiftTimeContestationDateBlockProps {
  startingDatetime?: Date;
  endingDatetime?: Date;
  startingLabel: string;
  endingLabel: string;
  responsibleName?: string;
}

const ShiftTimeContestationDateBlock: React.FC<ShiftTimeContestationDateBlockProps> = ({
  startingDatetime,
  endingDatetime,
  startingLabel,
  endingLabel,
  responsibleName,
}) => {
  const getFormattedDate = (dateTime?: Date) => {
    if (dateTime) return format(dateTime, 'dd/MM/yyyy');
    return '';
  };

  const getFormattedTime = (dateTime?: Date) => {
    if (dateTime) return format(dateTime, 'HH:mm');
    return '';
  };

  return (
    <HeaderContainer>
      <TimerContainer>
        <TimerBox>
          <Typography color="secondary" variant="h6">
            {startingLabel}
          </Typography>
          <p>
            <CalendarIcon />
            {getFormattedDate(startingDatetime)}
          </p>
          <p>
            <TimerIcon />
            {getFormattedTime(startingDatetime)}
          </p>
        </TimerBox>
        <TimerBox>
          <Typography color="secondary" variant="h6">
            {endingLabel}
          </Typography>
          <p>
            <CalendarIcon />
            {getFormattedDate(endingDatetime)}
          </p>
          <p>
            <TimerIcon />
            {getFormattedTime(endingDatetime)}
          </p>
        </TimerBox>
      </TimerContainer>
      {responsibleName && (
        <HeaderSubtitle>
          <Typography variant="h6">Responsável</Typography>
          <p>{responsibleName}</p>
        </HeaderSubtitle>
      )}
    </HeaderContainer>
  );
};
export default ShiftTimeContestationDateBlock;
