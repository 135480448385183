import { clientSideScheme } from '@cuidador/whitelabel';
import { IconButton, ListItemIcon } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ReactComponent as SignOutIcon } from '../../assets/sign-out-icon.svg';
import AppShortcut from './AppSchortcut';
import {
  AppIcon,
  DrawerBody,
  DrawerButton,
  DrawerFooter,
  DrawerFooterText,
  DrawerHeader,
  StyledCloseIcon,
  StyledDrawer,
  StyledHrefLink,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledLogoBlock,
} from './styles';
import { UserIcon } from './SideMenuIcons/userIcon';
import { FileIcon } from './SideMenuIcons/fileIcon';
import { CaseIcon } from './SideMenuIcons/caseIcon';
import { HandIcon } from './SideMenuIcons/handIcon';
import { ShareIcon } from './SideMenuIcons/shareIcon';
import { AuthContext } from '../../contexts/auth';
import LogoutDialog from '../LogoutDialog';
import useCanAccess from '../../hooks/useCanAccess';

interface SideMenuProps {
  handleToggleSideMenu: () => void;
  isSideMenuOpen: boolean;
}

export function SideMenu(props: SideMenuProps) {
  const { handleToggleSideMenu, isSideMenuOpen } = props;
  const { isAllowedToRead: isAllowedToReadSubscription } = useCanAccess(
    'user/subscription'
  );
  const { signOut, userInfo } = useContext(AuthContext);
  const { appCShortcutIconUrl, appCUrl } = clientSideScheme();

  const isCaregiverAppAccess = userInfo?.user?.role?.caregiverAppAccess;
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const handleToggleLogoutDialog = () => {
    setIsLogoutDialogOpen(!isLogoutDialogOpen);
  };

  return (
    <>
      <StyledDrawer
        open={isSideMenuOpen}
        onClose={() => {
          handleToggleSideMenu();
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              handleToggleSideMenu();
            }}
          >
            <StyledCloseIcon fontSize="large" />
          </IconButton>
          <StyledLogoBlock />
        </DrawerHeader>
        <DrawerBody>
          <StyledList>
            <StyledLink to={'/minha-conta/pessoais'} key={'Minha conta'}>
              <StyledListItem button>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <StyledListItemText>{'Minha conta'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            <StyledHrefLink
              href={'https://www.cuidadordeconfianca.com.br/termos-e-politicas'}
              key={'Termos de uso'}
            >
              <StyledListItem button>
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <StyledListItemText>{'Termos de uso'}</StyledListItemText>
              </StyledListItem>
            </StyledHrefLink>
            {isAllowedToReadSubscription && (
              <StyledLink to={'/plano'} key={'Plano'}>
                <StyledListItem button>
                  <ListItemIcon>
                    <CaseIcon />
                  </ListItemIcon>
                  <StyledListItemText>{'Plano'}</StyledListItemText>
                </StyledListItem>
              </StyledLink>
            )}
            <StyledLink
              to={'/em-construcao/servicos-de-saude'}
              key={'Serviços de saúde'}
            >
              <StyledListItem button>
                <ListItemIcon>
                  <HandIcon />
                </ListItemIcon>
                <StyledListItemText>{'Serviços de saúde'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            <StyledLink
              to={'/em-construcao/produtos-de-saude'}
              key={'Produtos de saúde'}
            >
              <StyledListItem button>
                <ListItemIcon>
                  <ShareIcon />
                </ListItemIcon>
                <StyledListItemText>{'Produtos de saúde'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            {Boolean(isCaregiverAppAccess) && (
              <StyledHrefLink href={appCUrl}>
                <AppShortcut
                  icon={<AppIcon src={appCShortcutIconUrl} />}
                  label={'Ir para o app de Cuidados'}
                />
              </StyledHrefLink>
            )}
          </StyledList>
        </DrawerBody>
        <DrawerFooter>
          <DrawerButton
            onClick={() => {
              handleToggleLogoutDialog();
            }}
          >
            <SignOutIcon />
            <DrawerFooterText>Sair da conta</DrawerFooterText>
          </DrawerButton>
        </DrawerFooter>
      </StyledDrawer>
      <LogoutDialog
        open={isLogoutDialogOpen}
        onConfirm={signOut}
        onClose={handleToggleLogoutDialog}
      />
    </>
  );
}

export default SideMenu;
