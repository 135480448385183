import React from 'react';
import { Container, MessageContainer } from './styles';

type PendingRegistrationWarningBannerProps = {
  onClick: () => void;
};

const PendingRegistrationWarningBanner: React.FC<PendingRegistrationWarningBannerProps> = ({
  onClick,
}) => {
  return (
    <Container
      onClick={onClick}
      data-testid="pending-registration-warning-banner"
    >
      <MessageContainer>
        Este usuário ainda não completou o cadastro. Clique aqui para reenviar o
        convite.
      </MessageContainer>
    </Container>
  );
};

export default PendingRegistrationWarningBanner;
