import { EventScheduleModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Fab from '../../components/Fab';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import ReportDownloadButton from '../../components/ReportDownloadButton';
import RoutineCard from '../../components/RoutineCard';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useRoutine from '../../hooks/useRoutine';
import { convertTimeToDate, isTimeAfter } from '../../utils/date';
import { Item } from '../../utils/store';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  StyledLink,
  StyledTypography,
  TypographyContainer,
} from './styles';
import { PscButton } from '../../components/PscButton'
import { PageTitle } from '../../components/PageTitle'
import { Header } from '../../components/Header'
import { BackButton } from '../../components/BackButton'

const RoutineCardItem: React.FC<{
  routine: Item<EventScheduleModel>;
}> = ({ routine }) => {
  return (
    <StyledLink to={`/agendar/rotina/${routine.id}`}>
      <RoutineCard {...routine} />
    </StyledLink>
  );
};

const Routines: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const {
    getAllByPatientId,
    byId,
    ids: eventsIds,
    loading,
    createReportByPatientId,
    getReportDownloadLinkByPatientId,
  } = useRoutine();
  const [downloadLink, setDownloadLink] = useState('');

  const { isAllowedToCreate } = useCanAccess('care/event/schedule');

  const { isAllowedToCreate: isAllowedToCreateRoutineReport } = useCanAccess(
    'report/routine'
  );
  const { isAllowedToRead: isAllowedToReadRoutineReport } = useCanAccess(
    'media/patient/report/routine'
  );

  const patientId = userInfo?.activePatientId;

  useEffect(() => {
    if (!patientId) return;
    getAllByPatientId(patientId);
  }, [patientId]);

  const handleCreateReportAndGetDownloadLink = async () => {
    createRoutineReport()
      .then(() => getRoutineReportDownloadUrl())
      .then((signedUrl) => setDownloadLink(signedUrl));
  };

  const createRoutineReport = async () => {
    try {
      await createReportByPatientId(Number(userInfo?.activePatientId));
      toast.success('Relátorio de rotinas gerado com sucesso');
    } catch (err) {
      toast.error('Erro ao gerar relatório de rotinas');
    }
  };

  const getRoutineReportDownloadUrl = async (): Promise<string> => {
    try {
      const { signedUrl } = await getReportDownloadLinkByPatientId(
        Number(userInfo?.activePatientId)
      );
      return signedUrl;
    } catch (err) {
      toast.error('Erro ao baixar relatório de rotinas');
      return '';
    }
  };

  const orderedRoutines = eventsIds
    .map(id => byId[id])
    .sort((a, b): number => {
      const eventAhappensAt = new Date(a.frequencyStartsAt || '')
      const eventBhappensAt = new Date(b.frequencyStartsAt || '')
      return isTimeAfter(eventAhappensAt, eventBhappensAt) ? 1 : -1
    })

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Rotinas' />
        }
        rightContent={
          <PscButton />
        }
      />
      <PendingRegistrationBanner />
      {loading ? (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      ) : (
        <Container>
          {eventsIds.length <= 0 ? (
            <TypographyContainer>
              <Typography variant="h5">Não há rotinas cadastradas.</Typography>
              <StyledTypography variant="subtitle1">
                Agende aqui tudo que não pode ser esquecido sobre as rotinas da
                pessoa sob cuidado.
              </StyledTypography>
            </TypographyContainer>
          ) : (
            <>
              <TypographyContainer>
                <Typography variant="h6">Lista de rotinas</Typography>
                {isAllowedToReadRoutineReport &&
                  isAllowedToCreateRoutineReport && (
                    <ReportDownloadButton
                      createTitle="Clique aqui para gerar o relatório de rotinas"
                      downloadtitle="Clique aqui para baixar o relatório de rotinas"
                      onGenerateReportClick={
                        handleCreateReportAndGetDownloadLink
                      }
                      downloadLink={downloadLink}
                    />
                  )}
              </TypographyContainer>
              {orderedRoutines.map((routine) => (
                <RoutineCardItem key={routine.id} routine={routine} />
              ))}
            </>
          )}
          {isAllowedToCreate && (
            <Fab to="/agendar/rotina/nova">
              <AddIcon />
            </Fab>
          )}
        </Container>
      )}
    </>
  );
};

export default Routines;
