import { SubscriptionDetailModel } from '@cuidador/database';

export enum IntervalOptions {
  day = 'Diária',
  week = 'Semanal',
  month = 'Mensal',
  year = 'Anual',
}

export enum SubscriptionStatusOptions {
  future = 'futura',
  active = 'ativa',
  canceled = 'cancelada',
  failed = 'com pagamento pendente',
}

export const calculateSubscriptionTotalValue = (
  subscription: SubscriptionDetailModel
) => {
  let totalInCentsValue = 0;
  subscription.items?.forEach(
    (item) => (totalInCentsValue += item.priceInCents || 0)
  );
  const fomatedTotalValueInReal = Number(totalInCentsValue / 100).toFixed(2);
  return fomatedTotalValueInReal;
};
