import { Typography, DialogActions } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;

  div {
    margin-top: ${({ theme }) => theme.spacing(-1)}px;
  }
`;

export const StyledTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  line-height: 1.3;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-size: ${({ theme }) => theme.spacing(2)}px;
  text-align: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;

  & > button {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }

  & > p {
    cursor: pointer;
    text-decoration: underline;
  }
`;
