import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const StyledIconButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.palette.error.dark};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  min-width: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.dark};
  }
`;
