import { ShiftModel } from '@cuidador/database';
import { addMinutes, format, subDays } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import * as Yup from 'yup';
import { FormValues } from '.';

export const durationOptions = Array.from({ length: 24 * 2 - 1 }, (_, k) => {
  const durationInMinutes = 60 + 30 * k;
  const hourValue = Math.ceil((k + 1) / 2);
  const minuteValue = k % 2 ? ' e 30 minutos' : '';

  return {
    durationInMinutes: durationInMinutes.toString(),
    label: `${hourValue} ${hourValue > 1 ? 'horas' : 'hora'}${minuteValue}`,
  };
});

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, insira um nome'),
  date: Yup.string().required('Por favor, insira a data'),
  time: Yup.string().required('Por favor, insira o horário'),
  duration: Yup.string().required('Por favor, insira a duração'),
});

export const initialDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
export const initialTime = format(new Date(), 'HH:mm');

export const formDataToAdditionalCoverageModel = (
  data: FormValues,
  patientId: number
) => {
  const dateTimePlannedToStart = new Date(data?.date?.replace(/-/g, '/') || '');
  dateTimePlannedToStart.setHours(Number(data.time?.split(':')[0]) || 0);
  dateTimePlannedToStart.setMinutes(Number(data.time?.split(':')[1]) || 0);

  const dateTimePlannedToEnd = addMinutes(
    dateTimePlannedToStart,
    Number(data.duration)
  );

  return {
    name: data.name,
    plannedToStartAt: dateTimePlannedToStart.toISOString(),
    plannedToEndAt: dateTimePlannedToEnd.toISOString(),
    patientId,
  };
};

export const additionalCoverageDataToFormData = (
  data: ShiftModel
): FormValues => {
  const plannedToStartAt = new Date(data.plannedToStartAt || '');
  const plannedToEndAt = new Date(data.plannedToEndAt || '');
  return {
    name: data.name || '',
    date: format(new Date(data.plannedToStartAt || ''), 'yyyy-MM-dd'),
    duration: differenceInMinutes(plannedToEndAt, plannedToStartAt).toString(),
    time: format(new Date(data.plannedToStartAt || ''), 'HH:mm'),
  };
};
