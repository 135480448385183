import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { ShoppingListModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';
import { AxiosResponse } from 'axios';

const endpoint = '/care/shopping-list';

const initialData: ReducerData<ShoppingListModel> = {
  byId: {} as Record<string, Item<ShoppingListModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useShoppingList = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShoppingListModel>(),
    initialData
  );

  const getOneByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/by-patient/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response as AxiosResponse<ShoppingListModel>);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const postBuyShoppingListById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}/${id}/buy`);
      dispatch({ type: 'CREATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getOneByPatientId,
    postBuyShoppingListById,
  };
};

export default useShoppingList;
