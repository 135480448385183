import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledTextField from '../../components/StyledTextField';

export const QuestionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
`;

export const QuestionContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  word-wrap: break-word;
`;

export const QuestionTitle = styled(Typography)`
  color: black;
  font-weight: bold;
`;

export const OptionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const OptionDescriptionContainer = styled.div``;

export const StyledOptionDescriptionTextField = styled(StyledTextField).attrs({
  placeholder: 'Descreva',
  color: 'secondary',
  fullWidth: true,
  multiline: true,
  inputProps: {
    maxLength: 200,
    'data-testid': 'interviewOptionText',
  },
})``;
