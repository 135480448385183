import { RoleModel, UserModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import qs from 'query-string';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormCardContainer,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../../../components/FormCardContainer';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import MedicBalloon from '../../../components/MedicBalloon';
import PendingRegistrationWarningBanner from '../../../components/PendingRegistrationWarningBanner';
import RoleApps from '../../../components/RoleApps';
import StyledButton from '../../../components/StyledButton';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useRoles from '../../../hooks/useRoles';
import useUser from '../../../hooks/useUser';
import { resolveErrorMessage } from '../../../utils/error';
import { cpfMask, numberMask, phoneMask } from '../../../utils/inputs';
import { Item } from '../../../utils/store';
import CollapsibleCaption from './CollapsibleCaption';
import RelateToPatientDialog from './RelateToPatientDialog';
import ShareActivationLinkDialog from './ShareActivationLinkDialog';
import {
  AvatarContainer,
  Container,
  ContentContainer,
  FooterContainer,
  RoleDescription,
  RoleLabel,
  RoleMenuItem,
  RoleSelect,
  StyledAvatar,
} from './styles';
import UnrelateFromOrganizationDialog from './UnrelateFromOrganizationDialog';
import {
  formDataToUserModel,
  FormValues,
  getShareMessage,
  initialValues,
  userModelToFormData,
  validationSchema,
} from './utils';
import { RemoveButton } from '../../../components/RemoveButton'
import { PscButton } from '../../../components/PscButton'
import { BackButton } from '../../../components/BackButton'
import { Header } from '../../../components/Header'
import { PageTitle } from '../../../components/PageTitle'

interface RouteParams {
  id: string;
}

const UserForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [usersFound, setUsersFound] = useState<UserModel[]>([]);
  const [showUnrelateDialog, setShowUnrelateDialog] = useState(false);
  const [completeSignupUrl, setCompleteSignupUrl] = useState('');
  const [shareActivationUrl, setShareActivationUrl] = useState<{
    message: string;
  } | null>(null);
  const [
    showShareActivationLinkDialog,
    setShowShareActivationLinkDialog,
  ] = useState(false);
  const [avatars, setAvatars] = useState<{ id: number; image: string }[]>([]);
  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState('');
  const [, setName] = useState('');
  const [userFormValues, setUserFormValues] = useState(initialValues);
  const params = useParams<RouteParams>();
  const {
    byId,
    getById,
    post,
    loading: loadingUser,
    findAvailableUsers,
    getProfilePicture: getUserProfilePicture,
    relateToPatient,
    relateToOrganization,
    unrelateFromOrganization,
    getPendingSignupToken,
  } = useUser();
  const {
    loading: loadingRoles,
    getAll: getAllRoles,
    byId: rolesById,
    ids: rolesIds,
  } = useRoles();
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const { search } = useLocation();

  const {
    isAllowedToCreate: isAllowedToCreateUser,
    isAllowedToUpdate: isAllowedToUpdateUser,
  } = useCanAccess('user');
  const { isAllowedToInvoke: isAllowedToUnrelateUser } = useCanAccess(
    'user/unrelate-from-organization'
  );
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );

  const id = parseInt(params.id);
  const isCreationPage = !id;
  const patientId = Number(userInfo?.activePatientId);
  const userName = byId[id]?.name;

  const shouldRelatePatient = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return Boolean(queryParams.get('relatePatient')?.toLowerCase() === 'true');
  }, [search]);

  const showShareDialog = useMemo(() => {
    const { show_share_dialog } = qs.parse(search);
    return show_share_dialog === 'true';
  }, [search]);

  const systemRoles = useMemo(() => {
    // If id is defined, is an update (or visualization)
    if (id) {
      const userRole = byId[id]?.role;
      const isUserRoleVisible = userRole?.isVisible;
      // If the user role is not visible, the only returned role are the user's role
      if (userRole && !isUserRoleVisible) {
        return [userRole as Item<RoleModel>];
      }
      // If the user role is visible, all roles are returned
    }
    // If id is not defined, is a user creation, all roles should be returned
    return rolesIds.map((roleId) => rolesById[roleId]);
  }, [id, byId, rolesById, rolesIds]);

  const isFormDisabled =
    (Boolean(id) && !isAllowedToUpdateUser) || !isAllowedToCreateUser;
  const isLoading = loadingUser || loading || loadingRoles;
  const isUserInfoFetched = Boolean(id)
    ? initialValues != userFormValues
    : true;

  useEffect(() => {
    if (!isCreationPage) {
      async () => {
        const userProfilePictureUrl = await handleGetUserProfilePicture(id);
        setUserProfilePictureUrl(userProfilePictureUrl);
      };
    }
  }, [id]);

  useEffect(() => {
    getAllRoles();
    // if has id is an update, else is an insert
    if (id) {
      getById(id).then(({ data }) => {
        const userData = userModelToFormData(data);
        setUserFormValues(userData);

        if (data.status === 'awaitingSignupCompletion') {
          // User still awaiting for Signup Completion
          // Let's open "Share Activation Dialog"
          handleShareActivationMessageOpen(userData.name!).then(() => {
            if (showShareDialog) setShowShareActivationLinkDialog(true);
          });
        }
      });
    }
  }, [id, getById, getAllRoles]);

  const handleGetUserProfilePicture = async (id: number) => {
    try {
      return await getUserProfilePicture(id).then(
        ({ data }) => data?.signedUrl
      );
    } catch (err) {
      return '';
    }
  };

  async function fetchUserProfilePicture(id: number) {
    const result = isAllowedToReadProfilePicture
      ? await handleGetUserProfilePicture(parseInt(`${id}`))
      : false;
    const newAvatar = avatars;
    if (result) {
      newAvatar.push({ id, image: result });
      return setAvatars(newAvatar);
    }
    newAvatar.push({ id, image: '' });
    return setAvatars(newAvatar);
  }

  const handleSubmit = async (values: FormValues) => {
    const userData = formDataToUserModel(
      values,
      shouldRelatePatient ? patientId : undefined
    );
    const { available, user: usersFound } = await findAvailableUsers(userData);
    if (available) {
      return await createUser(userData);
    } else {
      setName(userData.name || '');
      setUsersFound(usersFound);
      setUserFormValues(values);
      usersFound.forEach((user: UserModel) =>
        fetchUserProfilePicture(Number(user.id))
      );
    }
  };

  const handleCreateUserAndGenerateSignupUrl = () => {
    setUsersFound([]);
    const userData = formDataToUserModel(
      userFormValues,
      shouldRelatePatient ? patientId : undefined
    );
    return post(userData)
      .then(async ({ data }) => {
        setLoading(true);
        const {
          data: { completeSignupUrl },
        } = await getPendingSignupToken(data.id);
        setCompleteSignupUrl(completeSignupUrl);
        const message = getShareMessage(data.name, completeSignupUrl);
        setShareActivationUrl({ message });
        setShowShareActivationLinkDialog(true);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createUser = async (userData: UserModel) => {
    return post(userData)
      .then(async ({ data }) =>
        history.replace(`/usuarios/${data.id}?show_share_dialog=true`)
      )
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        const fieldError = err.response?.data.context?.key;
        if (fieldError) {
          if (Object.keys(initialValues).includes(fieldError)) {
            formikRef.current?.setFieldError(fieldError, displayMessage || '');
          }
        } else {
          toast.error(displayMessage);
        }
      });
  };

  const handleConfirmRelation = (id?: number) => {
    if (id) {
      setUsersFound([]);
      setAvatars([]);
      shouldRelatePatient
        ? relateUserToPatient(id)
        : relateUserToOrganization(id);
    }
  };

  const relateUserToPatient = (id: number) => {
    relateToPatient(id, patientId!)
      .then(() => history.replace('/cuidadores'))
      .catch((err) => {
        const responseDataMessage = err.response.data.message;
        if (
          responseDataMessage === 'User is already related to given patient'
        ) {
          return toast.error('Este usuário já está vinculado ao paciente');
        }
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const relateUserToOrganization = (id: number) => {
    relateToOrganization(id)
      .then(() => history.goBack())
      .catch((err) => {
        const responseDataMessage = err.response?.data?.message;
        if (responseDataMessage === 'User already belongs to an organization') {
          return toast.error('Este usuário já pertence a uma organização');
        }
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleConfirmUnrelate = () => {
    setShowUnrelateDialog(false);
    unrelateFromOrganization(id)
      .then(() => history.goBack())
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleShareActivationMessageOpen = async (name: string) => {
    try {
      setLoading(true);
      const {
        data: { completeSignupUrl },
      } = await getPendingSignupToken(id);
      setCompleteSignupUrl(completeSignupUrl);
      const message = getShareMessage(name, completeSignupUrl);
      setShareActivationUrl({ message });
    } catch (_) {
      toast.error('Erro ao gerar o link de ativação');
    } finally {
      setLoading(false);
    }
  };

  const handleShareActivationMessageClick = (phoneNumber: string) => {
    // Universal links format
    // https://faq.whatsapp.com/425247423114725
    const formattedPhoneNumber = numberMask(`55${phoneNumber}`);
    const url = `https://wa.me/${formattedPhoneNumber}?${qs.stringify({
      text: shareActivationUrl?.message,
    })}`;
    window.open(url);
    setShareActivationUrl(null);
    history.goBack();
  };

  const resolveUserFormTitle = () => {
    if (isCreationPage) {
      return 'Adicionar rede de cuidado';
    } else {
      return 'Informações do usuário';
    }
  };

  const resolveRightButtonType = () => {
    if (!isCreationPage && isAllowedToUnrelateUser) {
      return 'remove';
    } else {
      return 'pscList';
    }
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title={resolveUserFormTitle()} />
        }
        rightContent={
          resolveRightButtonType() === 'remove'
          ? <RemoveButton action={() => setShowUnrelateDialog(true)} />
          : <PscButton />
        }
      />
      <LoadingBackdrop loading={isLoading} />
      {Boolean(shareActivationUrl) && (
        <PendingRegistrationWarningBanner
          onClick={() => setShowShareActivationLinkDialog(true)}
        />
      )}
      {!loadingRoles && isUserInfoFetched && (
        <Container>
          <Formik
            innerRef={(ref) => (formikRef.current = ref)}
            initialValues={userFormValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  {isCreationPage && (
                    <MedicBalloon text="Insira os dados para convidar uma pessoa à rede de cuidados do paciente. Muita atenção à escolha do tipo de perfil, verificando as descrições. Ao clicar em Cadastrar, vamos gerar uma mensagem com link para você compartilhar pelo WhatsApp (padrão) ou outro canal de sua preferência" />
                  )}
                  {!isCreationPage && (
                    <AvatarContainer>
                      <StyledAvatar
                        src={userProfilePictureUrl}
                        alt={values.name}
                      />
                    </AvatarContainer>
                  )}
                  <ContentContainer>
                    <FormCardContainer>
                      <StyledBoldTitle variant="subtitle2">
                        Informações pessoais
                      </StyledBoldTitle>
                      <FormikTextField
                        variant="outlined"
                        placeholder="Nome"
                        label="Nome"
                        color="secondary"
                        inputProps={{ 'data-testid': 'name' }}
                        name="name"
                        margin="normal"
                        size="small"
                        disabled={Boolean(id) || isFormDisabled}
                      />
                      <FormikTextField
                        color="secondary"
                        inputProps={{ 'data-testid': 'phoneNumber' }}
                        placeholder="(99) 99999-9999"
                        variant="outlined"
                        label="Telefone"
                        name="phoneNumber"
                        margin="normal"
                        type="tel"
                        size="small"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'phoneNumber',
                            phoneMask(e.target.value)
                          );
                        }}
                        disabled={Boolean(id) || isFormDisabled}
                      />
                      <FormikTextField
                        color="secondary"
                        name="cpf"
                        label="CPF (opcional)"
                        variant="outlined"
                        placeholder="411.111.111-11"
                        type="tel" // numeric keyboard without parsing to number
                        margin="normal"
                        inputProps={{ 'data-testid': 'cpf' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('cpf', cpfMask(e.target.value));
                        }}
                        size="small"
                        disabled={Boolean(id) || isFormDisabled}
                      />
                      {!isCreationPage && (
                        <FormikTextField
                          color="secondary"
                          name="email"
                          label="E-mail"
                          variant="outlined"
                          placeholder="Email"
                          type="email"
                          margin="normal"
                          inputProps={{ 'data-testid': 'email' }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue('email', e.target.value);
                          }}
                          size="small"
                          disabled={Boolean(id) || isFormDisabled}
                        />
                      )}
                    </FormCardContainer>
                    <FormCardContainer>
                      <StyledBoldTitle variant="subtitle2">
                        Tipo de perfil
                      </StyledBoldTitle>
                      <RoleSelect
                        name="roleId"
                        SelectDisplayProps={{
                          'data-testid': 'roleId',
                        }}
                        disabled={Boolean(id) || isFormDisabled}
                        variant="outlined"
                        placeholder="Tipo de perfil"
                        color="secondary"
                        size="small"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('roleId', String(e.target.value))
                        }
                        value={values.roleId}
                      >
                        {systemRoles?.map((role) => (
                          <RoleMenuItem key={role.id} value={role.id}>
                            <RoleLabel>
                              {role.title}
                              <RoleApps
                                guardianAppAccess={role.guardianAppAccess}
                                caregiverAppAccess={role.caregiverAppAccess}
                                adminAppAccess={role.adminAppAccess}
                              />
                            </RoleLabel>
                            <RoleDescription>
                              {role.description}
                            </RoleDescription>
                          </RoleMenuItem>
                        ))}
                      </RoleSelect>
                      <CollapsibleCaption
                        roles={systemRoles}
                        values={values}
                        defaultVisibility={isCreationPage}
                      />
                    </FormCardContainer>
                    {Boolean(isCreationPage) && (
                      <FooterContainer>
                        <StyledButton
                          type="submit"
                          size="large"
                          color="inherit"
                        >
                          {isCreationPage ? 'Convidar' : 'Salvar'}
                        </StyledButton>
                      </FooterContainer>
                    )}
                  </ContentContainer>
                </Form>
              );
            }}
          </Formik>
        </Container>
      )}
      <RelateToPatientDialog
        open={Boolean(usersFound.length) && !showShareActivationLinkDialog}
        users={usersFound}
        newUserCpf={formikRef.current?.values.cpf}
        avatars={avatars}
        onClose={() => {
          setUsersFound([]);
        }}
        handleCreateUserAndGenerateSignupUrl={
          handleCreateUserAndGenerateSignupUrl
        }
        onConfirm={handleConfirmRelation}
      />
      <UnrelateFromOrganizationDialog
        open={showUnrelateDialog}
        onClose={() => {
          setShowUnrelateDialog(false);
        }}
        onConfirm={handleConfirmUnrelate}
      />
      <ShareActivationLinkDialog
        open={showShareActivationLinkDialog}
        onClose={() => {
          setShowShareActivationLinkDialog(false);
        }}
        onConfirm={() =>
          handleShareActivationMessageClick(
            formikRef.current?.values.phoneNumber ?? ''
          )
        }
        userName={isCreationPage ? formikRef.current?.values.name : userName}
        completeSignupUrl={completeSignupUrl}
      />
    </>
  );
};

export default UserForm;
