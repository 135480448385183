import { clientSideScheme } from '@cuidador/whitelabel';
import { FormControlLabel, Typography } from '@material-ui/core';
import styled from 'styled-components';
import PasswordTextField from '../../components/PasswordField';

export const Screen = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex: 1;
  flex-direction: column;
  button {
    align-self: center;
  }
`;

export const Logo = styled.img.attrs(() => {
  const { logoLightUrl } = clientSideScheme();
  return { src: logoLightUrl };
})`
  width: 100%;
  max-width: 500px;
  max-height: 45px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Header = styled.div`
  background-color: blue;
`;

export const Container = styled.div`
  flex-direction: inherit;
  flex: inherit;
  form {
    flex: 1;
    flex-direction: inherit;
    flex: inherit;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})`
  font-size: 16px;
  font-weight: 500;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'body2',
  align: 'center',
})`
  font-weight: bold;
`;

export const VisibilityContainer = styled.div<{ $active?: boolean }>`
  display: ${({ $active }) => ($active ? 'inherit' : 'none')};
  flex-direction: inherit;
  flex: inherit;
  form {
    flex: 1;
    display: ${({ $active }) => ($active ? 'inherit' : 'none')};
    flex-direction: inherit;
    flex: inherit;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.palette.secondary.main};
  & a,
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const PasswordInstructions = styled(Typography).attrs({
  variant: 'caption',
})`
  margin: 0;
  & ul {
    margin: 0;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    list-style-position: inside;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  button {
    margin: ${({ theme }) => theme.spacing(0, 1)};
  }
  margin: ${({ theme }) => theme.spacing(4)}px;
`;

export const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const SuccessContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    align-self: center;
  }
`;

export const StyledFormikTextPassword = styled(PasswordTextField)`
  & label:not(.Mui-error) {
    color: grey;
  }
  & input {
    color: black;
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
  & textarea {
    color: black;
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: black;
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: black;
      color: black;
    }
    &:hover fieldset {
      border-color: black;
      color: black;
    }
    &.Mui-focused fieldset {
      border-color: black;
      color: black;
    }
  }
`;

export const StyledError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1em;
`;

export const MessageContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
