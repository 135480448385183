import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CountType,
  IsCountingType,
  RegistrationDashboardContext,
} from '../../contexts/registrationDashboard';
import { ButtonContainer, Container, MessageContainer } from './styles';

export const MANDATORY_KEYS = Object.freeze([
  'shiftSchedule',
  'medication',
  'caregiver',
]);

export default function PendingRegistrationBanner() {
  const { count, isCounting, triggerRefresh } = useContext(
    RegistrationDashboardContext
  );
  const history = useHistory();

  useEffect(() => {
    triggerRefresh?.();
  }, []);

  const mandatoryIsCounting = MANDATORY_KEYS.map(
    (key) => isCounting[key as keyof IsCountingType]
  );
  const mandatoryCounts = MANDATORY_KEYS.map(
    (key) => count[key as keyof CountType]
  );
  if (
    mandatoryIsCounting.some((isCounting) => isCounting === true) ||
    mandatoryCounts.every((count) => count > 0)
  )
    return null;

  return (
    <Container>
      <MessageContainer>Ainda existem cadastros pendentes.</MessageContainer>
      <ButtonContainer
        onClick={() => {
          history.push('/cadastros');
        }}
      >
        Finalizar cadastros
        <NavigateNextIcon fontSize="large" />
      </ButtonContainer>
    </Container>
  );
}
