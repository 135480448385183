import styled, { css } from 'styled-components';
import { List, AccessTime } from '@material-ui/icons';
import { ReactComponent as UserExpel } from '../../../assets/Icon-user-expel.svg';

const baseIconStyles = css`
  height: 20px;
  width: 20px;
`;

export const ShiftDetailsIcon = styled(List)`
  ${baseIconStyles}
`;

export const TimeCorrectionIcon = styled(AccessTime)`
  ${baseIconStyles}
`;

export const EndExecutionIcon = styled(UserExpel)`
  ${baseIconStyles}
  margin-left: ${({ theme }) => theme.spacing(0.25)}px;
`;
