import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
