import { Radio, RadioGroup } from '@material-ui/core';
import { FormikErrors } from 'formik';
import React, { useEffect } from 'react';
import {
  FormCardContainer,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../../../FormCardContainer';
import FormikWeekDaySelect from '../../../Forms/FormikWeekDaySelect';
import StyledFormControlLabel from '../../../StyledFormControlLabel';
import StyledMenuItem from '../../../StyledMenuItem';
import ErrorText from '../ErrorText';
import { FormValues } from '../utils';

export enum FrequencyType {
  EveryDay = 'Todos os dias',
  MondayToFriday = 'Segunda a sexta',
  SomeDays = 'Alguns dias da semana',
  Custom = 'Intervalos fixos',
}

export const EVERYDAY = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];
export const MONDAY_TO_FRIDAY = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'];

export const frequencyTimeOptions: {
  value: FormValues['rangeInDays'];
  label: string;
}[] = [
  { value: '2d', label: 'A cada 2 dias' },
  { value: '3d', label: 'A cada 3 dias' },
  { value: '5d', label: 'A cada 5 dias' },
  { value: '7d', label: 'A cada 7 dias' },
  { value: '15d', label: 'A cada 15 dias' },
  { value: '30d', label: 'A cada 30 dias' },
  { value: 'custom', label: 'Incluir novo intervalo' },
];

interface DaysSelectorProps {
  values: FormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<FormValues>;
  disabled?: boolean;
}

const DaysSelector: React.FC<DaysSelectorProps> = ({
  values,
  setFieldValue,
  errors,
  disabled,
}) => {
  const handleSelectedCustomIntervalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (e.target.value as FrequencyType) {
      case FrequencyType.EveryDay:
        setFieldValue('dayOptions', FrequencyType.EveryDay);
        setFieldValue('days', EVERYDAY);
        break;
      case FrequencyType.MondayToFriday:
        setFieldValue('dayOptions', FrequencyType.MondayToFriday);
        setFieldValue('days', MONDAY_TO_FRIDAY);
        break;
      case FrequencyType.SomeDays:
        setFieldValue('dayOptions', FrequencyType.SomeDays);
        setFieldValue('days', []);
        break;
      case FrequencyType.Custom:
        setFieldValue('dayOptions', FrequencyType.Custom);
        setFieldValue('days', []);
        break;
      default:
        setFieldValue('dayOptions', FrequencyType.EveryDay);
        setFieldValue('days', EVERYDAY);
        break;
    }
  };

  const handleSelectedDaysChange = () => {
    if (!values.days) return;
    const isEveryDay =
      values.days.length === 7 &&
      values.days.every((day) => EVERYDAY.includes(day));

    const isMondayToFriday =
      values.days.length === 5 &&
      values.days.every((day) => MONDAY_TO_FRIDAY.includes(day));

    const isSomeDays = !isEveryDay && !isMondayToFriday;
    if (values.dayOptions !== FrequencyType.Custom) {
      if (isEveryDay) setFieldValue('dayOptions', FrequencyType.EveryDay);
      if (isMondayToFriday)
        setFieldValue('dayOptions', FrequencyType.MondayToFriday);
      if (isSomeDays) setFieldValue('dayOptions', FrequencyType.SomeDays);
    }
  };

  useEffect(() => {
    handleSelectedDaysChange();
  }, [values.days]);

  return (
    <FormCardContainer>
      <StyledBoldTitle variant="subtitle2">Dias</StyledBoldTitle>
      <RadioGroup
        aria-label="custom-interval"
        name="dayOptions"
        value={values.dayOptions}
        onChange={handleSelectedCustomIntervalChange}
      >
        {Object.values(FrequencyType).map((label, index) => (
          <StyledFormControlLabel
            key={index}
            value={label}
            control={<Radio size="small" />}
            label={label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {values.dayOptions === FrequencyType.Custom ? (
        <>
          <StyledSelect
            variant="outlined"
            placeholder="Incluir novo intervalo"
            label="Intervalo em dias"
            name="rangeInDays"
            value={values.rangeInDays}
            SelectDisplayProps={{
              'data-testid': 'rangeInDays',
            }}
            color="secondary"
            size="small"
            disabled={disabled}
          >
            {frequencyTimeOptions.map((frequencyOption, index) => (
              <StyledMenuItem
                key={index}
                value={frequencyOption.value}
                color="secondary"
              >
                {frequencyOption.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          {errors.rangeInDays && (
            <ErrorText errorMessage={errors.rangeInDays} />
          )}
          {values.rangeInDays === 'custom' && (
            <>
              <FormikTextField
                id="customRangeInDays"
                name="customRangeInDays"
                label="Intervalo em dias"
                placeholder="Intervalo em dias"
                inputProps={{ 'data-testid': 'customRangeInDays' }}
                variant="outlined"
                color="secondary"
                margin="normal"
                autoComplete="off"
                size="small"
                type="number"
                disabled={disabled}
              />
              {errors.customRangeInDays && (
                <ErrorText errorMessage={errors.customRangeInDays} />
              )}
            </>
          )}
        </>
      ) : (
        <FormikWeekDaySelect name="days" />
      )}
    </FormCardContainer>
  );
};

export default DaysSelector;
