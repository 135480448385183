import { SubscriptionDetailModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store';

const ENDPOINT = 'user/subscription';

const initialData: ReducerData<SubscriptionDetailModel> = {
  byId: {} as Record<string, Item<SubscriptionDetailModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useSubscription = () => {
  const [state, dispatch] = useReducer(
    createReducer<SubscriptionDetailModel>(),
    initialData
  );

  const getById = useCallback(async (id: string) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get<Item<SubscriptionDetailModel>>(
        `${ENDPOINT}/${id}`
      );
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data;
    } catch (error) {
      if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
      throw error;
    }
  }, []);

  return {
    ...state,
    getById,
  };
};

export default useSubscription;
