import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: 0;
`;

export const CardContent = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  word-break: break-word;

  span {
    font-size: 18px;
    font-weight: 500;
  }

  label {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const StyledImg = styled.img`
  width: 85px;
  height: 85px;
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledOrderlyDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLinkDiv = styled.div`
  display: flex;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;
