import { MedicationModel } from '@cuidador/database';
import { format } from 'date-fns';
import { handleDoses } from '../../components/MedicationCard/utils';
import { convertTimeToDate } from '../../utils/date';

/**
 * sort medications by dose times putting ifNecessary medications in last indexes
 */
export const sortMedicationsByDoseTime = (
  a: MedicationModel,
  b: MedicationModel
) => {
  if (a.ifNecessary) return 1;
  else if (b.ifNecessary) return -1;

  return getFirstDoseTimeFromMedication(a) < getFirstDoseTimeFromMedication(b)
    ? -1
    : 1;
};

const getFirstDoseTimeFromMedication = (medication: MedicationModel) => {
  if (medication.eventSchedule?.scheduleType === 'custom_frequency') {
    return getFirstDoseTimeFromCustomTimeMedication(medication);
  } else {
    return getFirstDoseTimeFromFixedTimeMedication(medication);
  }
};

const getFirstDoseTimeFromCustomTimeMedication = (
  medication: MedicationModel
) => {
  const sortedHappensAt = medication.eventSchedule?.customTimes
    ?.map(({ happensAt }) =>
      format(new Date(convertTimeToDate(happensAt || '')), 'HH:mm')
    )
    ?.sort((a, b) => (String(a) < String(b) ? -1 : 1));
  return sortedHappensAt ? String(sortedHappensAt[0]) : '';
};

const getFirstDoseTimeFromFixedTimeMedication = (
  medication: MedicationModel
) => {
  if (
    !medication.eventSchedule?.frequencyRule ||
    !medication.eventSchedule.frequencyStartsAt
  )
    return '';

  const startTime = format(
    new Date(medication.eventSchedule.frequencyStartsAt),
    'HH:mm'
  );

  const doseTimes = handleDoses(
    startTime,
    medication.eventSchedule.frequencyRule
  );
  return doseTimes[0];
};
