import {
  Backdrop as MaterialBackdrop,
  Checkbox,
  CircularProgress as MaterialCircularProgress,
  FormControlLabel,
  Avatar,
} from '@material-ui/core';
import styled from 'styled-components';
import { default as StyledSelect } from '../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../../components/StyledMenuItem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.main};
  flex-direction: column;
  form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;

  .MuiAvatar-fallback {
    width: 100%;
    height: 100%;
  }
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  height: 100%;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledCheckbox = styled(Checkbox).attrs({
  color: 'secondary',
})`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FormGroupContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
`;

export const AppContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.palette.extra.color.grey.dark};
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const RoleSelect = styled(StyledSelect)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.7)}px;

  & [role='button'] {
    div {
      color: inherit;
      font-weight: inherit;
    }
    span {
      display: none;
    }
  }
`;

export const RoleMenuItem = styled(StyledMenuItem)`
  color: ${({ theme }) => theme.palette.getContrastText('#FFF')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-block: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const RoleLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-inline: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  white-space: break-spaces;
`;

export const RoleDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-inline: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  width: 90%;
  text-align: justify;
  white-space: normal;
`;
