import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { StyledFormControl } from './styles';
import { ColorsType } from '../../styles/colorMapping';

export interface SelectProps {
  name?: string;
  label: string;
  value?: string;
  id: string;
  error?: boolean;
  disabled?: boolean;
  color?: ColorsType;
  size?: 'small';
  formControlVariant?: 'outlined' | 'filled' | 'standard';
}

const StyledSelectField: React.FC<SelectProps> = ({
  id,
  label,
  children,
  error,
  disabled,
  color = 'primary',
  size,
  formControlVariant = 'outlined',
  ...props
}) => {
  return (
    <StyledFormControl
      $color={color}
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
      size={size}
      variant={formControlVariant}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        label={label}
        labelId={id}
        disabled={disabled}
        {...props}
      >
        {children}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default StyledSelectField;
