import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserModel } from '@cuidador/database'
import qs from 'query-string'

import { AddRounded } from '@material-ui/icons'
import { toast } from 'react-toastify'
import useUser from '../../../hooks/useUser'
import useCanAccess from '../../../hooks/useCanAccess'
import { handleGetProfilePictureURL } from '../../Patient/PatientSelect/utils'
import FilterChips from '../../Patient/PatientSelect/OrganizationMembers/FilterChips'
import { Container, FormControl, MenuItem, Select, useTheme } from '@material-ui/core'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import OrganizationMemberSelectCard from '../../../components/SelectCard/Member'
import { SearchTextField } from '../../../components/SearchTextField'
import { FabStyled } from '../../../components/FabStyled'
import { Pagination } from '../../../components/Pagination'

export function OrganizationMembersList () {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>([])
  const [
    organiationMemberIdToProfilePictureURL,
    setOrganiationMemberToProfilePictureURL,
  ] = useState<Record<number, string>>({})
  const [status, setStatus] = useState<UserModel['status'][]>([
    'enabled',
    'awaitingSignupCompletion',
  ])

  const history = useHistory()
  const theme = useTheme()
  const {
    getOrganizationMembersPaginated,
    getProfilePicture,
    loading: loadingOrganizationMembers,
    total,
  } = useUser()
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess('media/profile-picture')
  const { isAllowedToRead: isAllowedToReadOrganizationMembers } = useCanAccess('user/user.by-organization')
  const { isAllowedToCreate: canCreateUser } = useCanAccess('user')

  const historyLocationSearch = history.location.search

  useEffect(() => {
    if (!isAllowedToReadOrganizationMembers) {
      toast.error('Você não tem permissão para visualizar essa página')
      history.push('/cadastros')
    }
  }, [])

  useEffect(() => {
    handleFetchOrganizationMembersPaginated()
  }, [page, pageSize, status, historyLocationSearch])

  const handleRedirectToPatientRelatedToUser = (userId: number) => {
    history.push(`/organizacao/usuario/${userId}`)
  }

  const handleFetchOrganizationMembersPaginated = () => {
    const params = qs.parse(historyLocationSearch)
    getOrganizationMembersPaginated({
      ...params,
      page,
      status,
      orderBy: 'name',
      limit: pageSize
    })
      .then((response) => {
        const loadedOrganizationMembers = response.data.results
        const organizationMembersToInsert = loadedOrganizationMembers
        for (const organizationMember of organizationMembersToInsert) {
          if (!organizationMember.id || !isAllowedToReadProfilePicture) return
          handleGetProfilePictureURL(organizationMember.id, getProfilePicture)
            .then((url) => {
              if (!url) return
              setOrganiationMemberToProfilePictureURL((previousMap) => {
                if (!organizationMember.id) return { ...previousMap }
                const newMap = { ...previousMap }
                newMap[organizationMember.id] = url
                return newMap
              })
            })
        }

        setOrganizationMembers(organizationMembersToInsert)
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0)
    }
  }

  if (loadingOrganizationMembers && organizationMembers.length === 0) {
    return <LoadingBackdrop loading={loadingOrganizationMembers} />
  }

  return (
    <>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <SearchTextField
          fieldName='user.name'
          onChangeDebounced={onChangeParams}
        />
      </div>
      <FilterChips setStatus={setStatus} status={status} />
      <Container>
        {organizationMembers.map(({ id, name, status, role }) => {
          if (!id) return null
          return (
            <OrganizationMemberSelectCard
              key={Number(id)}
              id={Number(id)}
              name={String(name)}
              profilePictureURL={
                organiationMemberIdToProfilePictureURL[Number(id)]
              }
              status={String(status)}
              roleTitle={String(role?.title)}
              onRelationClick={() => handleRedirectToPatientRelatedToUser(id)}
              onRowClick={() => history.push(`/pessoas/usuarios/${id}`)}
            />
          )
        })}
        {loadingOrganizationMembers && (
          <LoadingBackdrop loading={loadingOrganizationMembers} />
        )}
        <div
          style={{ display: 'flex', justifyContent: 'center', marginBlock: '16px' }}
        >
          <Pagination
            count={Math.ceil(total / pageSize)}
            page={page + 1}
            onChange={(p) => setPage(p - 1)}
          />
          <FormControl>
            <Select
              value={pageSize}
              onChange={ev => {
                setPageSize(Number(ev.target.value))
                onChangeParams()
              }}
            >
              <MenuItem value={10}>10 por página</MenuItem>
              <MenuItem value={20}>25 por página</MenuItem>
              <MenuItem value={30}>100 por página</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Container>
      {canCreateUser && (
        <FabStyled
          onClick={() => history.push('/usuarios/novo')}
          color={theme.palette.secondary.main}
          backgroundColor={theme.palette.background.default}
          borderColor={theme.palette.secondary.main}
        >
          <AddRounded />
        </FabStyled>
      )}
    </>
  )
}
