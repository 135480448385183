import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts/auth';
import StyledButton from '../../components/StyledButton';

import { Background, Title, ButtonContainer, SuccessContainer } from './styles';

const AwaitingReview: React.FC = () => {
  const { signOut, userInfo } = useContext(AuthContext);

  useEffect(function redirectIfGuardianIsNotAwaitingReview() {
    if (userInfo?.user?.status !== 'awaitingReview') {
      toast.success(
        'Você foi ativado no sistema. Faça login novamente para começar a usar.'
      );
      signOut?.();
    }
  }, []);

  if (userInfo?.user?.status !== 'awaitingReview') {
    return null;
  }

  return (
    <Background>
      <SuccessContainer>
        <Title>Pessoa sob cuidado cadastrada com sucesso!</Title>
        <Title>
          Agora, aguarde nosso contato para realização da entrevista e a
          liberação do seu acesso.
        </Title>

        <ButtonContainer>
          <StyledButton size="large" color="inherit" onClick={signOut}>
            Sair
          </StyledButton>
        </ButtonContainer>
      </SuccessContainer>
    </Background>
  );
};

export default AwaitingReview;
