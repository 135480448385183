import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const RuleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

interface RuleTextProps {
  isValid: boolean;
  submitError: boolean;
}

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const RuleText = styled(Typography)<RuleTextProps>`
  color: ${({ isValid, submitError, theme }) =>
    isValid
      ? theme.palette.extra.color.green
      : submitError
      ? theme.palette.error.dark
      : theme.palette.extra.color.grey.main};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  font-weight: bold;
`;
