import { ShiftReportModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store';
import useMedia from './useMedia';

const ENDPOINT = '/report/shift';

const initialData: ReducerData<ShiftReportModel> = {
  byId: {} as Record<string, Item<ShiftReportModel>>,
  ids: [] as Array<Id>,
  total: 0,
  loading: false,
  error: null,
};

const useShiftReport = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftReportModel>(),
    initialData
  );
  const { getMedia } = useMedia();

  const getAllFromPatient = useCallback(async (patientId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get<Item<ShiftReportModel>[]>(
        `${ENDPOINT}/all-by-patient/${patientId}`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data;
    } catch (error) {
      if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
      throw error;
    }
  }, []);

  //push production again.
  const getDownloadLink = useCallback(
    async (patientId: number, reportId: number) => {
      try {
        const { data } = await getMedia(
          `/media/patient/${patientId}/shift-report/${reportId}`
        );
        return data.signedUrl;
      } catch (error) {
        if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
        throw error;
      }
    },
    []
  );

  const requestReport = useCallback(
    async (patientId: number, startDate: string, endDate: string, guardianId: number) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post<Item<ShiftReportModel>>(ENDPOINT, {
          patientId,
          startDate,
          endDate,
          name: `Gerado por ${guardianId}`
        });
        dispatch({ type: 'CREATE', payload: response.data });
        return response.data;
      } catch (error) {
        if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
        throw error;
      }
    },
    []
  );

  return {
    ...state,
    getAllFromPatient,
    getDownloadLink,
    requestReport,
  };
};

export default useShiftReport;
