import styled from 'styled-components';
import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

export const StyledContainer = styled.div`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;
