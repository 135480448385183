import { Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(7)}px;
  border: none;
  background: none;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  align-items: center;
  flex-direction: row;
`;

export const LeftSide = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  word-break: break-word;
`;

export const RightSide = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const StyledIcon = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.spacing(4)}px;
`;
