import { ShiftModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import RightIcon from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import React from 'react';
import Card from './Card';
import {
  CardDiv,
  Container,
  StyledSection,
  HeaderContainer,
  Divider,
  SubTitleContainer,
  IconContainer,
} from './styles';
import { getCareCategoryCardStatusMessage } from './utils';

interface CareCategoryListProps {
  shift: ShiftModel;
}

const CareCategoryList: React.FC<CareCategoryListProps> = (
  props: CareCategoryListProps
) => {
  const questionsGroupedByCareCategory = _.groupBy(
    props.shift.shiftCareQuestions || [],
    'careQuestion.careLine.categoryId'
  );
  return (
    <StyledSection>
      <SubTitleContainer>
        <IconContainer>
          <RightIcon />
        </IconContainer>
        <Typography variant="h6">Cuidados</Typography>
      </SubTitleContainer>
      <Container>
        {Object.entries(questionsGroupedByCareCategory).map(
          ([careCategoryId, shiftCareQuestionList]) => {
            const currentCareCategory = shiftCareQuestionList
              .map((scq) => scq.careQuestion?.careLine?.careCategory)
              .find((cq) => String(cq?.id) === careCategoryId);

            const filteredCareQuestions = (
              props.shift.careQuestionAnswers || []
            ).filter((cqa) => {
              return shiftCareQuestionList
                .map((scq) => scq.careQuestionId)
                .includes(cqa.careQuestionId);
            });

            return (
              <CardDiv
                key={careCategoryId}
                color={currentCareCategory?.colorHexa}
              >
                <HeaderContainer>
                  <Typography variant="h6">
                    <b>{currentCareCategory?.name}</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {getCareCategoryCardStatusMessage(
                      filteredCareQuestions,
                      shiftCareQuestionList.length
                    )}
                  </Typography>
                </HeaderContainer>

                {shiftCareQuestionList.map((questions, index) => {
                  const currentCareQuestionAnswer = props.shift.careQuestionAnswers?.find(
                    (cqa) => cqa.careQuestionId === questions.careQuestionId
                  );
                  const lastOrFirstQuestionItemIndex = shiftCareQuestionList.indexOf(
                    shiftCareQuestionList[shiftCareQuestionList.length - 1]
                  );
                  return (
                    <>
                      <Typography variant="subtitle1">
                        <b>{questions.careQuestion?.careLine?.name}</b>
                      </Typography>
                      <Typography key={questions.shiftId}>
                        {questions?.careQuestion?.careQuestionText}
                      </Typography>
                      <Card
                        key={currentCareQuestionAnswer?.id}
                        careQuestionAnswer={currentCareQuestionAnswer}
                      />
                      {index !== lastOrFirstQuestionItemIndex && <Divider />}
                    </>
                  );
                })}
              </CardDiv>
            );
          }
        )}
      </Container>
    </StyledSection>
  );
};

export default CareCategoryList;
