import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import useCanAccess from '../../hooks/useCanAccess'

import { Header } from '../../components/Header'
import { MenuButton } from '../../components/MenuButton'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'
import { SideMenu } from '../../components/SideMenu'
import { TabsBar } from '../../components/TabsBar'

import { PatientsList } from './PatientsList'
import { OrganizationMembersList } from './OrganizationMembersList'

export type OperationalManagementRouteParams = {
  tab: 'pacientes' | 'usuarios'
}

export function OperationalManagement () {
  const { tab } = useParams<OperationalManagementRouteParams>()
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)

  const { isAllowedToRead: showOrganizationMembers } = useCanAccess('user/user.by-organization')

  return (
    <>
      <Header
        leftContent={
          <MenuButton action={() => setMenuOpen(true)} />
        }
        centerContent={
          <PageTitle title='Gestão Operacional' />
        }
        rightContent={
          <PscButton />
        }
      />
      <SideMenu
        isSideMenuOpen={isMenuOpen}
        handleToggleSideMenu={() => setMenuOpen(false)}
      />

      <TabsBar
        tabItems={[{
          path: '/gestao/pacientes',
          label: 'Pacientes',
          enabled: true,
        }, {
          path: '/gestao/usuarios',
          label: 'Usuários',
          enabled: showOrganizationMembers,
        }]}
        selectedTab={`/gestao/${tab}`}
        redirectionPath='/gestao/pacientes'
      />

      {tab === 'pacientes' && <PatientsList /> }
      {tab === 'usuarios' && <OrganizationMembersList />}
    </>
  )
}
