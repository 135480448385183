import React, { useState } from 'react';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { MultiselectModal, MultiselectModalProps } from './MultiselectModal';
import {
  CountMarker,
  FilterChipMultiselectContainer,
  MultiSelectButton,
  StyledFilterChip,
  Container,
} from './styles';
import { FilterChipProps } from '..';

export interface FilterChipMultiselectProps
  extends FilterChipProps,
    Omit<MultiselectModalProps, 'close' | 'isOpen'> {
  countMarkerAmount: number | undefined;
  testid: string;
}

export const FilterChipMultiselect: React.FC<FilterChipMultiselectProps> = ({
  onClick,
  options,
  toggleOption,
  title,
  backgroundColor,
  variant,
  icon,
  onFinishOptionsSelection,
  loading,
  countMarkerAmount,
  testid,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { active } = props;

  const closeModal = () => setIsModalOpen(false);

  return (
    <Container data-testid={testid}>
      {!!countMarkerAmount && (
        <CountMarker>
          <p data-testid={`${testid}-count-marker`}>{countMarkerAmount}</p>
        </CountMarker>
      )}
      <FilterChipMultiselectContainer {...props} variant={variant}>
        <StyledFilterChip {...props} onClick={onClick} icon={icon} />
        {!!active && (
          <MultiSelectButton
            $active={active}
            onClick={() => setIsModalOpen(true)}
            data-testid="multiselect-open-modal"
          >
            <ExpandMoreOutlinedIcon />
          </MultiSelectButton>
        )}
      </FilterChipMultiselectContainer>
      <MultiselectModal
        isOpen={isModalOpen}
        close={closeModal}
        options={options}
        toggleOption={toggleOption}
        title={title}
        backgroundColor={backgroundColor}
        variant={variant}
        icon={icon}
        onFinishOptionsSelection={onFinishOptionsSelection}
        loading={loading}
        data-testid="multiselect-modal"
      />
    </Container>
  );
};
