import { clientSideScheme } from '@cuidador/whitelabel';
import React from 'react';
import LogoPurple from '../../assets/logo-purple.png';
import { Container, LogoImage } from './styles';

interface RoleAppsProps {
  guardianAppAccess?: boolean;
  caregiverAppAccess?: boolean;
  adminAppAccess?: boolean;
}

const RoleApps: React.FC<RoleAppsProps> = ({
  guardianAppAccess,
  caregiverAppAccess,
  adminAppAccess,
}) => {
  const accessibleApps = [];
  const { appCShortcutIconUrl, appFShortcutIconUrl } = clientSideScheme();

  if (guardianAppAccess) {
    accessibleApps.push(appFShortcutIconUrl);
  }
  if (caregiverAppAccess) {
    accessibleApps.push(appCShortcutIconUrl);
  }
  if (adminAppAccess) {
    accessibleApps.push(LogoPurple);
  }

  return (
    <Container>
      {accessibleApps.map((apps) => (
        <>
          <LogoImage src={apps} />
        </>
      ))}
    </Container>
  );
};

export default RoleApps;
