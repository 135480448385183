import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

export const Toast = styled(ToastContainer)`
  .Toastify__toast--info {
    background: ${({ theme }) => theme.palette.info.main};
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.palette.primary.light};
    }
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.palette.success.main};
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.palette.warning.main};
    color: #000000;
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.palette.error.main};
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.palette.extra.color.red.main};
    }
  }
`;
