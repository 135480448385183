import { ShiftReportModel } from '@cuidador/database';
import { Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useCanAccess from '../../../../hooks/useCanAccess';
import useShiftReport from '../../../../hooks/useShiftReport';
import {
  CardContainer,
  ColoredAnchor,
  ColoredTypography,
  StyledPaper,
} from './styles';

const ShiftReportCard: React.FC<{ report: ShiftReportModel }> = ({
  report,
}) => {
  const [downloadLink, setDownloadLink] = useState('');
  const { getDownloadLink, error } = useShiftReport();
  const { isAllowedToRead: isAllowedToReadShiftReport } = useCanAccess(
    'media/patient/report/shift'
  );
  const theme = useTheme();
  useEffect(() => {
    if (!report.patientId || !report.id) return;
    let mounted = true;
    getDownloadLink(report.patientId, report.id)
      .then((link) => {
        if (mounted) setDownloadLink(link);
      })
      .catch(() => {
        if (mounted) toast.error('Erro ao solicitar o link de download.');
      });
    return () => {
      mounted = false;
    };
  }, [report.patientId, report.id]);

  const renderLink = () => {
    if (!isAllowedToReadShiftReport) return null;

    return error ? (
      <ColoredTypography align="right" textColor={theme.palette.error.main}>
        Erro
      </ColoredTypography>
    ) : downloadLink ? (
      <ColoredAnchor
        href={downloadLink}
        download
        underlineColor={theme.palette.success.main}
      >
        <ColoredTypography align="right" textColor={theme.palette.success.main}>
          Baixar PDF
        </ColoredTypography>
      </ColoredAnchor>
    ) : (
      <ColoredTypography align="right" textColor={theme.palette.secondary.main}>
        Processando
      </ColoredTypography>
    );
  };

  return (
    <CardContainer key={report.id}>
      <StyledPaper elevation={3}>
        <Typography>
          <strong>PSC:</strong> {report.patient?.name}
        </Typography>
        <Typography>
          <strong>Período:</strong>{' '}
          {`${format(
            new Date(String(report.startDate)),
            'dd/MM/yyyy'
          )} a ${format(new Date(String(report.endDate)), 'dd/MM/yyyy')}`}
        </Typography>
        <Typography>
          <strong>Solicitado em:</strong>{' '}
          {format(new Date(String(report.createdAt)), 'dd/MM/yyyy')}
        </Typography>
        {renderLink()}
      </StyledPaper>
    </CardContainer>
  );
};

export default ShiftReportCard;
