import styled from 'styled-components';
import { ExecutionTimelineSlot } from '..';

export const TimelineContainer = styled.div<{ hasTimestamps: boolean }>`
  padding: ${({ theme, hasTimestamps }) =>
    theme.spacing(0, hasTimestamps ? 5 : 2, 2)};
`;

export const BoundaryTimestamps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 2, 1)};
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  p {
    margin: 0;
  }
`;

export const ParentTimeline = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  height: 30px;
  width: 100%;
  overflow: hidden;
`;

export const ExecutionTimeSlotBlock = styled.div<{
  timeslot: ExecutionTimelineSlot;
}>`
  position: absolute;
  height: 100%;
  min-width: 2px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  top: 0;

  left: ${({ timeslot }) => timeslot.startsAtPercentage}%;
  width: ${({ timeslot }) => timeslot.durationPercentage}%;
`;

export const LegendContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: ${({ theme }) => theme.spacing(1.5)}px;
  column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const Legend = styled.div`
  display: flex;
`;

export const LegendLabel = styled.span``;

export const LegendColor = styled.div<{ color: string }>`
  width: 40px;
  height: 20px;
  background-color: ${({ color }) => color};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;
