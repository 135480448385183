import styled from 'styled-components';
import { CardBoxProps, StatusMessageIconContainerProps } from './utils';

export const CardBox = styled.div<CardBoxProps>`
  position: relative;
  background-color: ${({ color }) => color};
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  box-shadow: 0.1em 0.1em 0.2em gray;
  margin-bottom: 1em;
`;

export const TextContainer = styled.div`
  width: 85%;
  margin: 0.75em 0 0.75em 0.75em;
`;

export const ButtonContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  font-size: 3em;
  margin-right: 0.2em;
`;

export const TitleContainer = styled.div`
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0.25em;
`;

export const StatusMessageContainer = styled.div`
  font-size: smaller;
`;

export const StatusMessageIconContainer = styled.span<StatusMessageIconContainerProps>`
  border: 0.1em solid ${(props) => props.iconColor};
  color: ${(props) => props.iconColor};
  font-size: 1.25em;
  border-radius: 50%;
  display: inline-flex;
  vertical-align: middle;
  background-color: white;
  margin-right: 0.5em;
`;

export const AlertContainer = styled.span`
  position: absolute;
  top: -10%;
  right: -2.5%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.extra.color.red.main};
  width: 1.25em;
  height: 1.25em;
  line-height: 1.25em;
  text-align: center;
  font-weight: bold;
`;
