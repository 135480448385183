import React, { useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import Routes from '../../routes/Routes';
import Tabbar from '../Tabbar';
import { Container, Content } from './styles';

export interface ContentProps {
  withTabs: boolean;
}

const routesWithMenu = ['/compras', '/menu', '/acompanhar/:id', '/cadastros', '/contatos'];

const Layout: React.FC = () => {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch(routesWithMenu);

  const shouldRenderTabs = useMemo(() => {
    return Boolean(user) && Boolean(match?.isExact);
  }, [user, match]);

  return (
    <Container>
      <Content withTabs={shouldRenderTabs}>
        <Routes />
      </Content>
      {shouldRenderTabs && <Tabbar />}
    </Container>
  );
};

export default Layout;
