import { ShiftScheduleModel } from '@cuidador/database';
import React, { useContext, useEffect } from 'react';
import ShiftScheduleCard from '../../../components/ShiftScheduleCard';
import { AuthContext } from '../../../contexts/auth';
import useShiftSchedule from '../../../hooks/useShiftSchedule';
import { Item } from '../../../utils/store';
import {
  Backdrop,
  BackdropCircularProgress,
  StyledLink,
  StyledTypography,
  TypographyContainer,
} from '../styles';

const ShiftScheduleCardItem: React.FC<{
  shiftSchedule: Item<ShiftScheduleModel>;
}> = ({ shiftSchedule }) => {
  return (
    <StyledLink to={`/plantoes/item/${shiftSchedule.id}`}>
      <ShiftScheduleCard {...shiftSchedule} />
    </StyledLink>
  );
};

const NextShiftSchedule: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const {
    getAllByPatientId,
    byId,
    ids: shiftSchedulesIds,
    loading,
  } = useShiftSchedule();

  const patientId = userInfo?.activePatientId;

  useEffect(() => {
    if (!patientId) return;
    getAllByPatientId(patientId);
  }, [patientId]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      {shiftSchedulesIds.length <= 0 ? (
        <TypographyContainer>
          <StyledTypography variant="subtitle1">
            Planeje uma única vez e cadastre aqui os plantões do(a) cuidador(a)
            ou da equipe de cuidadores, clicando no &quot;+&quot; abaixo.
          </StyledTypography>
        </TypographyContainer>
      ) : (
        shiftSchedulesIds
          .map((id) => byId[id])
          .map((shiftSchedule) => (
            <ShiftScheduleCardItem
              key={shiftSchedule.id}
              shiftSchedule={shiftSchedule}
            />
          ))
      )}
    </>
  );
};

export default NextShiftSchedule;
