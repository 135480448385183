import React, { createContext } from 'react';
import useEventCareHistory from '../hooks/useEventCareHistory';

type FilterProps = {
  useEventCareHistory: ReturnType<typeof useEventCareHistory>;
};

export const CareHistoryFilterContext = createContext<FilterProps>(
  {} as FilterProps
);

export const CareHistoryFilterProvider: React.FC = ({ children }) => {
  const useEventCareHistoryHook = useEventCareHistory();

  return (
    <CareHistoryFilterContext.Provider
      value={{ useEventCareHistory: useEventCareHistoryHook }}
    >
      {children}
    </CareHistoryFilterContext.Provider>
  );
};
