import { RoleModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store/index';

const endpoint = '/auth/role';

const initialData: ReducerData<RoleModel> = {
  byId: {} as Record<string, Item<RoleModel>>,
  ids: [] as Array<Id>,
  total: 0,
  loading: false,
  error: null,
};

const useRoles = () => {
  const [state, dispatch] = useReducer(createReducer<RoleModel>(), initialData);

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}?orderBy=title`);
      dispatch({ type: 'GET_ALL', payload: response.data.results });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getById = useCallback(async (id: Id) => {
    try {
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getById,
    getAll,
  };
};

export default useRoles;
