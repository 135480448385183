import ControlPointIcon from '@material-ui/icons/ControlPoint';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { renderStatusMessage } from './renderStatusMessage';
import {
  AlertContainer,
  ButtonContainer,
  CardBox,
  TextContainer,
  TitleContainer,
} from './styles';
import { NounGender } from './utils';

type RegistrationDashboardCardProps = {
  color: string;
  title: string;
  statusTitleSingular?: string;
  statusTitlePlural?: string;
  itemQuantity: number;
  creationRoute: string;
  isAllowedToCreate: boolean;
  visualizationRoute: string;
  titleGender?: NounGender;
  mandatory?: boolean;
  statusMessage?: boolean;
  loading?: boolean
};

export default function RegistrationDashboardCard({
  color,
  title,
  statusTitleSingular,
  statusTitlePlural,
  itemQuantity,
  creationRoute,
  isAllowedToCreate,
  visualizationRoute,
  titleGender = 'male',
  mandatory = false,
  statusMessage = true,
  loading
}: RegistrationDashboardCardProps) {
  const history = useHistory();

  return (
    <CardBox
      data-testid="registration-dashboard-card"
      color={color}
      onClick={() => {
        if (itemQuantity > 0) {
          history.push(visualizationRoute);
        } else if (isAllowedToCreate) {
          history.push(creationRoute);
        } else {
          toast.error(
            'Você não possui autorização para realizar este cadastro.'
          );
        }
      }}
    >
      <TextContainer>
        <TitleContainer>{title}</TitleContainer>
        {statusMessage &&
          renderStatusMessage(
            itemQuantity,
            titleGender,
            statusTitleSingular,
            statusTitlePlural,
            loading
          )}
      </TextContainer>
      <ButtonContainer>
        {itemQuantity > 0 ? (
          <NavigateNextIcon fontSize="inherit" data-testid={'view-button'} />
        ) : (
          isAllowedToCreate && (
            <ControlPointIcon
              fontSize="inherit"
              data-testid={'create-button'}
            />
          )
        )}
      </ButtonContainer>
      {mandatory && itemQuantity === 0 && <AlertContainer>!</AlertContainer>}
    </CardBox>
  );
}
