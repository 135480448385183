import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import _ from 'lodash';
import { categoryIdMapper } from '../../utils';

type GroupedMeasurements = {
  scheduledMeasurements: EventModel[];
  subCategory: EventSubCategoryModel | undefined;
};

type FormattedEvent = GroupedMeasurements | EventModel;

export const formatEvents = (events: EventModel[]) => {
  const measurements = events.filter(
    (event) =>
      event.subCategory?.categoryId === categoryIdMapper['MEASUREMENT'] &&
      event.eventScheduleId
  );
  const filteredEvents = events.filter(
    (event) =>
      event.subCategory?.categoryId !== categoryIdMapper['MEASUREMENT'] ||
      !event.eventScheduleId
  );

  const groupedMeasurements = Object.values(
    _.groupBy(measurements, 'eventHappensAt')
  );

  const formattedMeasurements = groupedMeasurements.map((group) => {
    return {
      scheduledMeasurements: group,
      subCategory: group[0].subCategory,
    };
  });

  const formattedEvents: FormattedEvent[] = filteredEvents.concat(
    formattedMeasurements
  );

  const formattedSortedEvents = formattedEvents.sort((event1, event2) => {
    const dateEvent1 =
      'scheduledMeasurements' in event1
        ? event1.scheduledMeasurements[0].eventHappensAt
        : event1.eventHappensAt;

    const dateEvent2 =
      'scheduledMeasurements' in event2
        ? event2.scheduledMeasurements[0].eventHappensAt
        : event2.eventHappensAt;

    return new Date(dateEvent1 as string) > new Date(dateEvent2 as string)
      ? 1
      : -1;
  });

  return formattedSortedEvents;
};
