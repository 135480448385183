import React from 'react'
import { useEffect, useState } from 'react'
import useUser from '../../hooks/useUser'
import { Avatar } from '@material-ui/core'

export type UserAvatarProps = {
  userId: number
}

export function UserAvatar(props: UserAvatarProps) {
  const { userId } = props
  const [imageUrl, setImageUrl] = useState<string>('')
  const { getProfilePicture } = useUser()

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl)
    } catch (err) {
      console.log('error')
    }
  }
  async function fetchUserProfilePicture() {
    if (!userId) return
    const result = await handleGetProfilePicture(parseInt(`${userId}`))
    if (result) {
      setImageUrl(result)
    }
  }

  useEffect(() => {
    fetchUserProfilePicture()
  }, [])

  return <Avatar src={imageUrl} />
}
