import {
  AnswerType,
  CareCategoryModel,
  CareQuestionAnswerModel,
  PatientInterviewModel,
} from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { IconButton, Step, StepLabel, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import _, { Dictionary } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InterviewAnswerList from '../../components/InterviewAnswerList';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import MedicBalloon from '../../components/MedicBalloon';
import StyledButton from '../../components/StyledButton';
import StyledSimpleDialog from '../../components/StyledSimpleDialog';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useInterview from '../../hooks/useInterview';
import { resolveErrorMessage } from '../../utils/error';
import {
  ContentContainer,
  EditButton,
  NavigationButtonsContainer,
  StyledStepper,
  TextContainer,
} from './styles';
import {
  buildNewCareQuestionAnswerList,
  getCurrentCareQuestionAnswerList,
  getMedicBalloonText,
  getScrollValueFromRatio,
  handleInterviewCareQuestionsAnswers,
} from './utils';
import { PageTitle } from '../../components/PageTitle'
import { Header } from '../../components/Header'
import { BackButton } from '../../components/BackButton'

enum ActionType {
  readOnly = 'visualizar',
  edit = 'editar',
  answer = 'responder',
}

const Interview: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageIndexes, setPageIndexes] = useState<string[]>([]);
  const [loadingInterview, setLoadingInterview] = useState(false);
  const [popUpVisible, isPopUpVisible] = useState(false);
  const [
    patientInterview,
    setPatientInterview,
  ] = useState<PatientInterviewModel>({});
  const [
    answersGroupedByCareCategoryDictionary,
    setAnswersGroupedByCareCategoryDictionary,
  ] = useState<Dictionary<CareQuestionAnswerModel[]>>();
  const { isAllowedToInvoke: isAllowedToFinishInterview } = useCanAccess(
    'care/interview.finish'
  );
  const {
    loading,
    getById,
    getByPatientPaginated,
    patchInterviewCareQuestionAnswer,
    postFinishInterview,
    patch,
    deleteInterview,
  } = useInterview();
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { actionType } = useParams<{ actionType: string }>();
  const isReadOnly = actionType === ActionType.readOnly;
  const isEditing = actionType === ActionType.edit;
  const { isAllowedToUpdate: canEdit } = useCanAccess('care/interview')

  useEffect(() => {
    loadInterview();
  }, [isReadOnly, isEditing]);

  const loadInterview = async () => {
    try {
      setLoadingInterview(true);
      const patientInterview = await handleGetPatientInterview();

      const patientInterviewData = await getById(Number(patientInterview.id));
      setPatientInterview(patientInterviewData);

      const interviewCareQuestionAnswers = handleInterviewCareQuestionsAnswers(
        patientInterviewData,
        isReadOnly
      );

      const groupedAnswers = _.groupBy(
        interviewCareQuestionAnswers,
        'careQuestion.careCategoryId'
      );
      setAnswersGroupedByCareCategoryDictionary(groupedAnswers);

      const availableCareCategories = interviewCareQuestionAnswers?.map(
        (answer) => answer?.careQuestion?.careCategory as CareCategoryModel
      );
      const uniqueCareCategories = _.uniqBy(availableCareCategories, 'id');
      const orderedCareCategories = _.orderBy(uniqueCareCategories, 'order');

      const availableCareCategoryIds = orderedCareCategories.map(
        (careCategory) => String(careCategory.id)
      );
      setCurrentIndex(0);
      setPageIndexes(availableCareCategoryIds);
    } catch (error) {
      console.log(error);
      toast.error(resolveErrorMessage(error));
    } finally {
      setLoadingInterview(false);
    }
  };

  const handleGetPatientInterview = async (): Promise<PatientInterviewModel> => {
    if (isReadOnly) {
      return getLastPatientInterview();
    } else {
      return getLastDraftPatientInterview();
    }
  };

  const getLastPatientInterview = async (): Promise<PatientInterviewModel> => {
    const patientInterviews = await getByPatientPaginated(Number(patientId), {
      limit: 1,
      page: 0,
      orderBy: 'updatedAt',
      order: 'desc',
      isDraft: false,
    });
    const lastPatientInterview = patientInterviews.results[0];
    if (!lastPatientInterview) {
      history.goBack();
      toast.error('Entrevista não encontrada');
    }
    return lastPatientInterview;
  };

  const getLastDraftPatientInterview = async (): Promise<PatientInterviewModel> => {
    const allInterviews = await getByPatientPaginated(Number(patientId), {
      limit: 100,
      page: 0,
    });
    const draftInterview = allInterviews.results.find(
      (interview: PatientInterviewModel) => interview.isDraft
    );
    if (!draftInterview) {
      history.push('/cadastros');
      toast.error('Erro ao carregar entrevista. Tente novamente daqui a pouco');
    }
    return draftInterview;
  };

  const handleSubmitInterview = () => {
    postFinishInterview(Number(patientInterview.id))
      .then(() => {
        history.push('/cadastros');
        toast.info('Entrevista salva com sucesso');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handlePatchInterview = () => {
    patch(Number(patientInterview.id))
      .then(() => history.push('/entrevista/editar'))
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleLeavePatchInterview = () => {
    isPopUpVisible(false);
    deleteInterview(patientInterview.id);
    return history.replace('/entrevista/visualizar');
  };

  const getCurrentPageAnswers = (): CareQuestionAnswerModel[] => {
    const careCategoryId = pageIndexes[currentIndex];
    if (!answersGroupedByCareCategoryDictionary) return [];
    return answersGroupedByCareCategoryDictionary[careCategoryId];
  };

  /**
   * Resets the interview answers dictionary given an careQuestionId
   * and the new CareQuestionAnswer.questionAnswerData.
   */
  type BuildNewAnswerListParams = {
    careQuestionId: Id;
    newQuestionAnswerData: AnswerType;
  };
  const buildNewAnswerList = useCallback(
    (params: BuildNewAnswerListParams) => {
      const { careQuestionId, newQuestionAnswerData } = params;
      setAnswersGroupedByCareCategoryDictionary(
        (answersGroupedByCareCategoryDictionary) => {
          if (!answersGroupedByCareCategoryDictionary) return;

          const currentCareQuestionAnswerList = getCurrentCareQuestionAnswerList(
            {
              careQuestionAnswerDictionary: answersGroupedByCareCategoryDictionary,
              careQuestionIdToMatch: careQuestionId as number,
            }
          );
          if (!currentCareQuestionAnswerList) return;

          const careQuestionAnswerToChange = currentCareQuestionAnswerList.find(
            (answer) => answer.careQuestion?.id === careQuestionId
          );
          if (!careQuestionAnswerToChange) return;

          const newCareQuestionAnswerList = buildNewCareQuestionAnswerList({
            careQuestionAnswerToChange,
            newQuestionAnswerData,
            careQuestionAnswerList: currentCareQuestionAnswerList,
          });

          const careCategoryId = Number(
            careQuestionAnswerToChange?.careQuestion?.careCategoryId
          );
          const newCareQuestionAnswerDictionary = {
            ...answersGroupedByCareCategoryDictionary,
            [`${careCategoryId}`]: newCareQuestionAnswerList,
          };
          return newCareQuestionAnswerDictionary;
        }
      );
    },
    [setAnswersGroupedByCareCategoryDictionary]
  );

  const handleAnswerOnChange = useCallback(
    (careQuestionId: Id, newQuestionAnswerData: AnswerType) => {
      buildNewAnswerList({ careQuestionId, newQuestionAnswerData });
    },
    [buildNewAnswerList]
  );

  const handleAnswerOnChangeDebounce = (
    careQuestionId: number,
    newQuestionAnswerData: AnswerType
  ) => {
    patchInterviewCareQuestionAnswer(
      Number(patientInterview.id),
      careQuestionId,
      newQuestionAnswerData
    ).catch((err) => {
      Sentry.captureException(err);
      return toast.error(
        'Erro ao salvar resposta. Tente novamente daqui a pouco'
      );
    });
  };

  const stepperReference = useRef<Element>(null);

  const RenderNavigationButtons = () => {
    const isFirstPage = currentIndex === 0;
    const isLastPage = currentIndex === pageIndexes.length - 1;

    return (
      <NavigationButtonsContainer>
        {!isFirstPage && (
          <StyledButton
            color="secondary"
            onClick={() => {
              window.scrollTo(0, 0);
              stepperReference.current?.scroll(
                getScrollValueFromRatio(
                  stepperReference,
                  (currentIndex - 3) / pageIndexes.length
                ),
                0
              );
              setCurrentIndex(currentIndex - 1);
            }}
          >
            Voltar
          </StyledButton>
        )}

        {!isLastPage && (
          <StyledButton
            color="secondary"
            onClick={() => {
              window.scrollTo(0, 0);
              stepperReference.current?.scroll(
                getScrollValueFromRatio(
                  stepperReference,
                  (currentIndex - 1) / pageIndexes.length
                ),
                0
              );
              setCurrentIndex(currentIndex + 1);
            }}
          >
            Continuar
          </StyledButton>
        )}

        {isLastPage && !isReadOnly && (
          <StyledButton
            color="secondary"
            onClick={handleSubmitInterview}
            disabled={isReadOnly || !isAllowedToFinishInterview}
          >
            Finalizar
          </StyledButton>
        )}
      </NavigationButtonsContainer>
    );
  };

  const interviewDate = patientInterview?.updatedAt
    ? format(new Date(patientInterview.updatedAt), 'dd/MM/yyyy')
    : '';

  return (
    <>
      <LoadingBackdrop loading={loading || loadingInterview} />
      <Header
        leftContent={
          (isReadOnly || isEditing) && (
            <BackButton
              action={
                isEditing
                ? () => isPopUpVisible(true)
                : undefined}
            />
          )
        }
        centerContent={
          <PageTitle title='Perfil de saúde' />
        }
        rightContent={
          !isEditing && canEdit && (
            <IconButton
              style={{
                margin: 0,
                padding: 0
              }}
              onClick={handlePatchInterview}
            >
              <EditButton>Editar</EditButton>
            </IconButton>
          )
        }
      />
      <ContentContainer>
        <StyledStepper ref={stepperReference} activeStep={currentIndex}>
          {pageIndexes.map((_, index) => (
            <Step key={index} data-testid={`step-${index + 1}`}>
              <StepLabel />
            </Step>
          ))}
        </StyledStepper>
        {!(isReadOnly || isEditing) && (
          <MedicBalloon
            text={getMedicBalloonText(currentIndex, pageIndexes.length)}
          />
        )}
        {isReadOnly && (
          <TextContainer>
            <Typography variant="body2">
              Atualizado em {interviewDate} por {patientInterview.user?.name}
            </Typography>
          </TextContainer>
        )}
        <InterviewAnswerList
          readOnly={isReadOnly}
          careQuestionAnswerList={getCurrentPageAnswers()}
          handleAnswerOnChange={handleAnswerOnChange}
          debounceAnswerOnChange={handleAnswerOnChangeDebounce}
        />
        <RenderNavigationButtons />
        <StyledSimpleDialog
          open={popUpVisible}
          handleNo={() => isPopUpVisible(false)}
          handleYes={handleLeavePatchInterview}
          title="Abandonar edição"
          subTitle="As alterações não foram salvas. Tem certeza que deseja sair?"
        />
      </ContentContainer>
    </>
  );
};

export default Interview;
