import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.red.main};
  color: white;
  width: 100%;
  height: ${({ theme }) => theme.spacing(8)}px;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const MessageContainer = styled.div`
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
`;
