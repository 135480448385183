import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';
import StyledFormControlLabel from '../../StyledFormControlLabel';

export const SelectMeasurementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const AddButtonContainer = styled.div`
  width: 10%;
`;

export const SaveControlOptionsButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 0;
  padding-block: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  bottom: 0px;

  &:hover {
    color: ${({ theme }) => theme.palette.extra.color.grey.light};
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const OpenModalButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  font-weight: 600;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-inline: auto;
  max-width: 350px;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.palette.extra.color.grey.light};
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledBackIcon = styled(ChevronLeftRounded)`
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  font-size: 40px;
  cursor: pointer;
`;

export const StyledAddButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  min-width: 0px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: 0;
`;

export const ControlOptionsModalHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
`;

export const ControlOptionsModalBody = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
`;

export const ControlOptionsModalText = styled.span`
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  font-weight: 400;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const ControlOptionsModal = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  @media (max-width: 500px) {
    height: 100%;
  }
`;

export const FormControlList = styled(StyledFormControlLabel)`
  padding-inline: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
`;

export const MeasurementListContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

export const MeasurementRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({ theme }) => theme.spacing(5)}px;
`;

export const InstructionRow = styled.div``;

export const RemoveButtonContainer = styled.div``;

export const StyledRemoveButton = styled(StyledButton)`
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  min-width: 0px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: 0;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  background-color: ${({ theme }) => theme.palette.grey[400]};
`;
