import React from 'react';
import {
  CardBox,
  TypographyContainer,
  IconContainer,
  TimerContainer,
  TitleContainer,
} from './styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CalendarIcon } from '../../assets/Icon-coloredCalendar.svg';
import { format } from 'date-fns';
import { EventSubCategoryModel } from '@cuidador/database';

interface CardProps {
  id: number;
  subCategory?: EventSubCategoryModel;
  name?: string;
  eventHappensAt?: string;
  description?: string;
}

const AppointmentCard: React.FC<CardProps> = ({
  id,
  subCategory,
  name,
  description,
  eventHappensAt,
}) => {
  const formattedDate = format(new Date(`${eventHappensAt}`), 'dd/MM/yyyy');
  const formattedHour = format(new Date(`${eventHappensAt}`), 'HH:mm');

  return (
    <CardBox data-testid={`appointmentCard-${id}`}>
      <IconContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedHour}</Typography>
        </TimerContainer>
        <CalendarIcon />
      </IconContainer>
      <TypographyContainer>
        <TitleContainer>
          <Typography variant="h6">{subCategory?.name}</Typography>
        </TitleContainer>
        <Typography variant="subtitle1">{name?.trim()}</Typography>
        <Typography variant="h6">{formattedDate}</Typography>
        <Typography variant="subtitle2">{description}</Typography>
      </TypographyContainer>
    </CardBox>
  );
};

export default AppointmentCard;
