import * as Yup from 'yup';
import { numberMask, phoneMask } from '../../utils/inputs';

export interface FormValues {
  name?: string;
  phoneNumber?: string;
  callingCondition?: string;
  relevantInformation?: string;
  link?: string;
  isWhatsapp?: boolean;
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, insira um nome do contato.'),
  phoneNumber: Yup.string(),
  callingCondition: Yup.string(),
  link: Yup.string()
    .max(255, 'O link não pode ter mais de 255 caracteres.')
    .nullable(),
  isWhatsapp: Yup.boolean(),
});

export const formDataToEmergencyContactsModel = (
  values: FormValues,
  patientId: Id
) => {
  return {
    name: values.name,
    phoneNumber: handleNumberMask(values.phoneNumber),
    callingCondition: values.callingCondition,
    relevantInformation: values.relevantInformation,
    patientId: patientId,
    link: values.link || '',
    isWhatsapp: values.phoneNumber ? !!values.isWhatsapp : false,
  } as FormValues;
};

export const handleNumberMask = (phoneNumber?: string) => {
  // Numbers with the format 0800 ddd dddd
  if (phoneNumber && phoneNumber.startsWith('08'))
    return numberMask(phoneNumber);
  if (phoneNumber) return numberMask(`55${phoneNumber}`);
  return '';
};

export const emergencyContactsModelToformData = (values: FormValues) => {
  return {
    name: values.name,
    phoneNumber: phoneMask(`${values.phoneNumber}`),
    callingCondition: values.callingCondition,
    relevantInformation: values.relevantInformation,
    link: values.link,
    isWhatsapp: values.isWhatsapp,
  } as FormValues;
};

export const urlMask = (url?: string) => {
  if (!url) return '';
  if (url === 'https://') return;
  const linkWithoutHttp = url.replace(
    /^(?:https?:\/\/)?(?:http?:\/\/)?(?:www\.)?/i,
    ''
  );
  const urlStartsWithHttp =
    url.startsWith('http://') || url.startsWith('https://');
  return urlStartsWithHttp ? url : `https://${linkWithoutHttp}`;
};
