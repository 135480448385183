import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContactsUpsertForm from '../../../components/ContactsUpsertForm';
import {
  emergencyContactsModelToformData,
  formDataToEmergencyContactsModel,
  FormValues,
} from '../../../components/ContactsUpsertForm/utils';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useEmergencyContacts from '../../../hooks/useEmergencyContacts';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress, Container } from './styles';
import { Header } from '../../../components/Header'
import { PageTitle } from '../../../components/PageTitle'
import { BackButton } from '../../../components/BackButton'
import { RemoveButton } from '../../../components/RemoveButton'

const ContactsUpdate: React.FC = () => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const params = useParams<{ id: string }>();
  const { getById, byId, loading, patch, remove } = useEmergencyContacts();
  const history = useHistory();
  const { isAllowedToDelete, isAllowedToUpdate } = useCanAccess(
    'care/emergency-contact'
  );
  const { userInfo } = useContext(AuthContext);

  const id = parseInt(params.id);

  useEffect(
    function fetchInitialValues() {
      if (id) {
        getById(id);
      }
    },
    [id]
  );

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const contacts = byId[id];

  if (!contacts) {
    return null;
  }

  const patientId = userInfo?.activePatientId;

  const handleSubmit = (values: FormValues) => {
    return patch(id!, formDataToEmergencyContactsModel(values, patientId!))
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Contatos de Emergência' />
        }
        rightContent={
          isAllowedToDelete && (
            <RemoveButton
              action={() => setDialogStatus(true)}
            />
          )
        }
      />
      <Container>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          <ContactsUpsertForm
            initialValues={emergencyContactsModelToformData(contacts)}
            onSubmit={handleSubmit}
          />
        </StyledFieldset>
        <StyledSimpleDialog
          open={dialogStatus}
          handleNo={() => setDialogStatus(false)}
          handleYes={() => handleDelete(id!)}
          title="Excluir contato de emergência"
          subTitle="Tem certeza que deseja excluir o contato de emergência?"
        />
      </Container>
    </>
  );
};

export default ContactsUpdate;
