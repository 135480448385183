import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiRadio-root:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  & .MuiCheckbox-root:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  & .Mui-disabled {
    opacity: 65%;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export default StyledFormControlLabel;
