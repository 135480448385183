import styled from 'styled-components';

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  margin: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
`;

export const TimerContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  align-self: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
`;

export const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StatusContainer = styled.div`
  flex-shrink: 0;
  margin-left: auto;

  & img {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
`;

export const CardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
