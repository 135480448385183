import React from 'react';
import ExpelUserIcon from '../../assets/Icon-user-expel.svg';
import { StyledIconButton } from './styles';

interface ExpelCaregiverButtonProps {
  onClick: () => void;
}

const ExpelCaregiverButton: React.FC<ExpelCaregiverButtonProps> = (props) => {
  return (
    <StyledIconButton variant="contained" {...props}>
      <img src={ExpelUserIcon} />
    </StyledIconButton>
  );
};

export default ExpelCaregiverButton;
