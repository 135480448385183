import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { StatusColor } from '../../utils';

export const ExpandedContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const ShortcutsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Shortcut = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.primary.dark)};
  min-width: 100px;
  width: fit-content;
  max-width: 120px;
  min-height: 100%;
  padding: ${({ theme }) => theme.spacing(1.5, 1, 1)};
  align-items: inherit;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  & > .MuiButton-label {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`;

export const ShortcutIcon = styled.div`
  & > * {
    width: 18px;
    height: 18px;
  }
`;

export const ShortcutLabelContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const ShortcutLabel = styled(Typography)`
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  word-wrap: break-word;
  hyphens: auto;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const CardSectionContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export const CardSectionHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const CardSectionTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.primary.dark)};
`;

export const CardSectionContent = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const CardSectionFooter = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  border-top: solid 1px ${({ theme }) => theme.palette.primary.dark}55;
`;

export const StatusIndicator = styled(Typography)<{ badgeColor: StatusColor }>`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.primary.dark)};
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;

    background-color: ${({ badgeColor }) => {
      switch (badgeColor) {
        case 'red':
          return '#f54141';
        case 'yellow':
          return '#fdb62e';
        case 'green':
          return '#1cbc86';
        case 'gray':
          return '#999999';
      }
    }};
  }
`;
