import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppointmentsUpsertForm, {
  formDataToAppointmentModel,
  FormValues,
} from '../../../components/AppointmentsUpsertForm';
import { AuthContext } from '../../../contexts/auth';
import useAppointment from '../../../hooks/useAppointment';
import useCanAccess from '../../../hooks/useCanAccess';
import { resolveErrorMessage } from '../../../utils/error';
import { Container } from './styles';
import { Header } from '../../../components/Header'
import { PageTitle } from '../../../components/PageTitle'
import { BackButton } from '../../../components/BackButton'
import { PscButton } from '../../../components/PscButton'

const startDate = format(new Date(), 'yyyy-MM-dd');

const formInitialValue = {
  name: '',
  description: '',
  subCategoryId: undefined,
  date: '',
  time: '',
  startDate: startDate,
};

const AppointmentsInsert: React.FC = () => {
  const { isAllowedToCreate } = useCanAccess('care/event/appointment');
  const history = useHistory();

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { post } = useAppointment();

  const handleSubmit = (values: FormValues) => {
    return post(formDataToAppointmentModel(values, patientId!))
      .then(() => {
        history.replace('/agendar/compromisso');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  useEffect(() => {
    if (!isAllowedToCreate) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.goBack();
    }
  }, []);

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Adicionar Compromisso' />
        }
        rightContent={
          <PscButton />
        }
      />
      <Container>
        <AppointmentsUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default AppointmentsInsert;
