import * as Yup from 'yup';
import { FrequencyType } from './MedicationSecondStep/DaysSelector';
import { PeriodType } from './MedicationSecondStep/PeriodSelector';

export const validationSchema = Yup.object().shape({
  dosageFormat: Yup.string().required('Insira um formato de dose.'),
  dosageQuantity: Yup.number().required('Insira a quantidade.'),
  administeredBy: Yup.string().required('Insira uma via de aplicação.'),
  medicalNotes: Yup.string().max(
    2000,
    'Deve conter no máximo 2000 caracteres.'
  ),
  timeSchedule: Yup.string().when('ifNecessary', {
    is: true,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Por favor, insira uma frequência.'),
  }),
  time: Yup.string().when(['timeSchedule', 'ifNecessary'], {
    is: (timeSchedule, ifNecessary) => timeSchedule === 'custom' || ifNecessary,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Por favor, insira um horário.'),
  }),
  customTimes: Yup.array().when(['timeSchedule', 'ifNecessary'], {
    is: (timeSchedule, ifNecessary) =>
      timeSchedule === 'custom' && !ifNecessary,
    then: Yup.array().min(1, 'Por favor, insira pelo menos um horário.'),
  }),

  customRangeInDays: Yup.string().when(['rangeInDays', 'ifNecessary'], {
    is: (rangeInDays, ifNecessary) => rangeInDays === 'custom' && !ifNecessary,
    then: Yup.string().required('Por favor, insira um intervalo.'),
  }),

  rangeInDays: Yup.string().when(['dayOptions', 'ifNecessary'], {
    is: (dayOptions, ifNecessary) =>
      dayOptions === FrequencyType.Custom && !ifNecessary,
    then: Yup.string().required('Por favor, insira um intervalo.'),
  }),

  frequencyStartsAt: Yup.string().when('ifNecessary', {
    is: true,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Por favor, insira uma data de início.'),
  }),

  frequencyEndsAt: Yup.string().when('periodType', {
    is: PeriodType.DeterminedTime,
    then: Yup.string().required('Por favor, insira uma data de término.'),
  }),

  days: Yup.array().when(['dayOptions', 'ifNecessary'], {
    is: (dayOptions, ifNecessary) =>
      dayOptions === FrequencyType.SomeDays && !ifNecessary,
    then: Yup.array().min(1, 'Por favor, insira um intervalo.'),
  }),
});
