import { subHours } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import addMinutes from 'date-fns/addMinutes';
import { mod } from '../math';

/**
 * Receives a time e.g 10:00 and converts to Date
 * with the hour and minute provided: 2020-01-01T10:00:12.000Z
 */
export const convertTimeToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':');
  const now = new Date();
  now.setUTCHours(Number(hours));
  now.setUTCMinutes(Number(minutes));
  return now;
};

/**
 * Receives a time e.g 10:00 and converts to UTC Date
 * with the hour and minute provided: 2020-01-01T07:00:12.000Z
 */
export const convertTimeToUTCDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':');
  const now = new Date();
  now.setHours(Number(hours));
  now.setMinutes(Number(minutes));
  return now;
};

/**
 * Receives a local browser time and converts it to UTC.
 * Example: 10:00 --> 13:00
 */
export const convertLocalTimeToUTC = (time: string): string => {
  const [hours, minutes] = time.split(':');
  const now = new Date();
  now.setHours(Number(hours));
  now.setMinutes(Number(minutes));
  return format(utcToZonedTime(now, 'UTC'), 'HH:mm');
};

/**
 * Receives a ISODateTime, and format to a time
 * with America/Sao_Paulo timezone (GMT -3),
 * e.g, 2022-06-06T13:00:00.000Z returns 10:00
 */
export const formatTimeToAmericaSaoPauloTimezone = (date: string): string => {
  /**
   * It removes 3 hours because the database holds the datetime as America/Sao_Paulo timezone.
   */
  const utcDatePtBr = subHours(new Date(date), 3);
  const formattedDate = format(
    // Removes timezone of the local machine that was introduced in Date instance above.
    timezoneUnawareDate(utcDatePtBr),
    'HH:mm'
  );
  return formattedDate;
};

/**
 * Receives a ISODateTime, and format to a Date
 * with America/Sao_Paulo timezone (GMT -3),
 * e.g, 2022-06-06T12:24:45.255Z returns 06/06/2022
 */
export const formatShortDateToAmericaSaoPauloTimezone = (
  date: string
): string => {
  /**
   * It removes 3 hours because the database holds the datetime as America/Sao_Paulo timezone.
   */
  const utcDatePtBr = subHours(new Date(date), 3);
  const formattedDate = format(
    // Removes timezone of the local machine that was introduced in Date instance above.
    timezoneUnawareDate(utcDatePtBr),
    'dd/MM/yy'
  );
  return formattedDate;
};

/**
 * Checks wheter date is after than dateToCompare only comparing its HH:mm
 */
export const isTimeAfter = (date: Date, dateToCompare: Date): boolean => {
  if (date.getHours() > dateToCompare.getHours()) return true;
  if (
    date.getHours() === dateToCompare.getHours() &&
    date.getMinutes() > dateToCompare.getMinutes()
  ) {
    return true;
  }
  return false;
};

/**
 * This method will ignore time and timezone from a date, an use only the date
 * part, no matter the current local timezone for the machine running the code
 * Useful for "fixed" dates, like birthdates
 */
export const timezoneUnawareDate = (date: number | string | Date) =>
  addMinutes(new Date(date), new Date(date).getTimezoneOffset());

export const weekdayMap = Object.freeze({
  mon: 'Seg',
  tue: 'Ter',
  wed: 'Qua',
  thu: 'Qui',
  fri: 'Sex',
  sat: 'Sab',
  sun: 'Dom',
});

export function getUTCWeeklySchedule(
  localSchedule: {
    mon?: boolean;
    tue?: boolean;
    wed?: boolean;
    thu?: boolean;
    fri?: boolean;
    sat?: boolean;
    sun?: boolean;
  },
  localTime: string
) {
  const date = convertTimeToUTCDate(localTime);
  const utcWeekDay = date.getUTCDay();
  const localWeekDay = date.getDay();

  const daysDifference = utcWeekDay - localWeekDay;
  const modularDaysDifference = mod(daysDifference, 7);

  const utcSchedule = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  const activeDays = (Object.keys(localSchedule) as Array<
    keyof typeof localSchedule
  >).filter((key) => localSchedule[key]);

  activeDays.forEach((day) => {
    if (day === 'sun') {
      if (modularDaysDifference > 0) {
        utcSchedule.mon = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.sat = true;
      } else {
        utcSchedule.sun = true;
      }
    }
    if (day === 'mon') {
      if (modularDaysDifference > 0) {
        utcSchedule.tue = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.sun = true;
      } else {
        utcSchedule.mon = true;
      }
    }
    if (day === 'tue') {
      if (modularDaysDifference > 0) {
        utcSchedule.wed = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.mon = true;
      } else {
        utcSchedule.tue = true;
      }
    }
    if (day === 'wed') {
      if (modularDaysDifference > 0) {
        utcSchedule.thu = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.tue = true;
      } else {
        utcSchedule.wed = true;
      }
    }
    if (day === 'thu') {
      if (modularDaysDifference > 0) {
        utcSchedule.fri = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.wed = true;
      } else {
        utcSchedule.thu = true;
      }
    }
    if (day === 'fri') {
      if (modularDaysDifference > 0) {
        utcSchedule.sat = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.thu = true;
      } else {
        utcSchedule.fri = true;
      }
    }
    if (day === 'sat') {
      if (modularDaysDifference > 0) {
        utcSchedule.sun = true;
      } else if (modularDaysDifference < 0) {
        utcSchedule.fri = true;
      } else {
        utcSchedule.sat = true;
      }
    }
  });

  return utcSchedule;
}

export function getLocalWeeklySchedule(
  utcSchedule: {
    mon?: boolean;
    tue?: boolean;
    wed?: boolean;
    thu?: boolean;
    fri?: boolean;
    sat?: boolean;
    sun?: boolean;
  },
  utcTime: string
) {
  const date = convertTimeToDate(utcTime);
  const utcWeekDay = date.getUTCDay();
  const localWeekDay = date.getDay();

  const daysDifference = utcWeekDay - localWeekDay;
  const modularDaysDifference = mod(daysDifference, 7);

  const localSchedule = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  const activeDays = (Object.keys(utcSchedule) as Array<
    keyof typeof utcSchedule
  >).filter((elem) => utcSchedule[elem]);

  activeDays.forEach((day) => {
    if (day === 'sun') {
      if (modularDaysDifference < 0) {
        localSchedule.mon = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.sat = true;
      } else {
        localSchedule.sun = true;
      }
    }
    if (day === 'mon') {
      if (modularDaysDifference < 0) {
        localSchedule.tue = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.sun = true;
      } else {
        localSchedule.mon = true;
      }
    }
    if (day === 'tue') {
      if (modularDaysDifference < 0) {
        localSchedule.wed = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.mon = true;
      } else {
        localSchedule.tue = true;
      }
    }
    if (day === 'wed') {
      if (modularDaysDifference < 0) {
        localSchedule.thu = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.tue = true;
      } else {
        localSchedule.wed = true;
      }
    }
    if (day === 'thu') {
      if (modularDaysDifference < 0) {
        localSchedule.fri = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.wed = true;
      } else {
        localSchedule.thu = true;
      }
    }
    if (day === 'fri') {
      if (modularDaysDifference < 0) {
        localSchedule.sat = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.thu = true;
      } else {
        localSchedule.fri = true;
      }
    }
    if (day === 'sat') {
      if (modularDaysDifference < 0) {
        localSchedule.sun = true;
      } else if (modularDaysDifference > 0) {
        localSchedule.fri = true;
      } else {
        localSchedule.sat = true;
      }
    }
  });

  return localSchedule;
}
