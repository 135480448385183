import styled from 'styled-components';
import StyledButton from '../../StyledButton';

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
`;

export const Button = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;
