import { GuardianModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store/index';

const endpoint = '/user/guardian';

const initialData: ReducerData<GuardianModel> = {
  byId: {} as Record<string, Item<GuardianModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useGuardian = () => {
  const [state, dispatch] = useReducer(
    createReducer<GuardianModel>(),
    initialData
  );
  const post = useCallback(async (data: GuardianModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(async (data: Partial<GuardianModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/me`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    post,
    patch,
  };
};

export default useGuardian;
