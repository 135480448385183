import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

export type StyledChipProps = { $active?: boolean };

export const StyledChip = styled(Button)<StyledChipProps>`
  border-radius: 4px;
  height: 25px;
  padding: ${({ theme }) => theme.spacing(0.25, 0.5)};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.background.paper};

  ${({ $active }) =>
    !$active &&
    css`
      filter: grayscale(0.45) opacity(0.9);
    `};
`;

export const StyledChipContent = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  align-items: center;
  justify-content: center;
`;

export const ChipIcon = styled.img`
  width: 16px;
  height: 16px;
`;
