import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import { ReactComponent as LogoInline } from '../../assets/logo-cuidador-inline.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
  left: 0;
  width: 100%;
`;

export const StyledFavorite = styled(Favorite)`
  margin-inline: 5px;
  height: 15px;
  width: 15px;
`;

export const StyledLogoInline = styled(LogoInline)`
  margin-top: 2.5px;
  margin-inline: 5px;
  height: 15px;
  width: auto;
`;
