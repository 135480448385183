import { CaregiverModel } from '@cuidador/database';
import React, { useEffect, useState } from 'react';
import user from '../../assets/user.png';
import useCanAccess from '../../hooks/useCanAccess';
import useUser from '../../hooks/useUser';
import { isGuardian, isCareManager } from '../../contexts/permission';
import {
  CardBox,
  CardContent,
  StyledImg,
  StyledLink,
  StyledLinkDiv,
} from './styles';

interface Props {
  caregiver?: CaregiverModel;
  id?: Id;
}

const CardItem: React.FC<Props> = ({ caregiver, id }: Props) => {
  const [imageUrl, setImageUrl] = useState('');
  const { getProfilePicture } = useUser();
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );

  const resolveCaregiverStatusLabel = (status: string): string => {
    switch (status) {
      case 'disabled':
        return 'Desabilitado';
      default:
        return 'Pendente';
    }
  };

  const handleGetCaregiverProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      return '';
    }
  };

  useEffect(() => {
    async function fetchCaregiverProfilePicture() {
      const result = isAllowedToReadProfilePicture
        ? await handleGetCaregiverProfilePicture(parseInt(`${id}`))
        : false;
      if (result) {
        setImageUrl(result);
      } else {
        setImageUrl(user);
      }
    }
    fetchCaregiverProfilePicture();
  }, []);

  if (isCareManager(caregiver?.user) || isGuardian(caregiver?.user)) {
    return null;
  }

  return (
    <CardBox data-testid={`cardItem-${id}`}>
      <StyledImg src={imageUrl} />
      <CardContent>
        <span>{caregiver?.user?.name}</span>
        <label>{caregiver?.user?.role?.title}</label>
        {caregiver?.user?.status !== 'enabled' && (
          <>
            <StyledLinkDiv>
              <StyledLink to={`usuarios/${id}?show_share_dialog=true`}>
                <label>
                  {resolveCaregiverStatusLabel(String(caregiver?.user?.status))}
                  {' - Reenviar convite'}
                </label>
              </StyledLink>
            </StyledLinkDiv>
          </>
        )}
      </CardContent>
    </CardBox>
  );
};

export default CardItem;
