export type CardBoxProps = {
  color: string;
};

export type StatusMessageIconContainerProps = {
  iconColor: string;
};

export type NounGender = 'male' | 'female';

export function getStatusMessage(
  amount: number,
  singularNoun?: string,
  pluralNoun?: string,
  nounGender?: NounGender
) {
  if (!singularNoun || !pluralNoun || !nounGender) {
    return '';
  } else if (amount > 1) {
    return `${amount} ${pluralNoun} cadastrad${
      nounGender === 'male' ? 'o' : 'a'
    }s`;
  } else if (amount > 0) {
    return `${amount} ${singularNoun} cadastrad${
      nounGender === 'male' ? 'o' : 'a'
    }`;
  } else {
    return `sem ${pluralNoun} cadastrad${nounGender === 'male' ? 'o' : 'a'}s`;
  }
}
