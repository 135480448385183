import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { ShiftExecution, ShiftModel } from '@cuidador/database';
import useShift from '../../hooks/useShift';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import OriginalSchedules from './OriginalSchedules';
import AnnulledCard from './AnnulledCard';
import TimeContestationCard from './TimeContestationCard';
import {
  HeaderContainer,
  CardContainer,
  CardItem,
  StyledTitle,
  Divider,
  NoRegistrationsText,
  StyledTypography,
} from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { BackButton } from '../../components/BackButton'
import { PscButton } from '../../components/PscButton'

interface RouteParams {
  shiftId: string;
  executionId: string;
}

const ExecutionHistoryContestation: React.FC = () => {
  const [shift, setShift] = useState<ShiftModel>();

  const { shiftId, executionId } = useParams<RouteParams>();
  const { getById, loading } = useShift();

  useEffect(() => {
    getById(shiftId).then((shift) => {
      setShift(shift as ShiftModel);
    });
  }, [shiftId]);

  const findExecutionByExecutionId = (execution: ShiftExecution) => {
    return execution.id === +executionId;
  };

  // eslint-disable-next-line
  const orderByCreatedAt = (collection?: any[]) => {
    return sortBy(collection, ({ createdAt }) => createdAt);
  };

  const shiftExecution = shift?.executions?.find(findExecutionByExecutionId);

  const executionNumber = shift?.executions?.findIndex(
    findExecutionByExecutionId
  );

  const timeContestations = orderByCreatedAt(shiftExecution?.timeContestations);

  const annullations = orderByCreatedAt(shiftExecution?.annullations);

  const hasTimeContestations = !!timeContestations.length;

  const hasAnnullations = !!annullations.length;

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title={`Execução ${Number(executionNumber) + 1}`} />
        }
        rightContent={<PscButton />}
      />
      {!hasTimeContestations && !hasAnnullations && (
        <NoRegistrationsText>
          Ainda não foi cadastrada nenhuma correção de horários
        </NoRegistrationsText>
      )}
      {(hasTimeContestations || hasAnnullations) && (
        <>
          <HeaderContainer>
            <CardItem>
              <StyledTypography color="primary" align="center">
                Aqui você pode registrar a correção dos horários do início e
                final do plantão. Para inclusão, clique no botão (+).
              </StyledTypography>
            </CardItem>
            <CardItem>
              <StyledTitle>Registro original</StyledTitle>
            </CardItem>
          </HeaderContainer>
          <OriginalSchedules
            shiftExecution={shiftExecution as ShiftExecution}
          />
          <StyledTitle>Histórico de correção de horários</StyledTitle>
        </>
      )}
      <CardContainer>
        {hasAnnullations && (
          <>
            {annullations
              .map((annullation, index) => (
                <>
                  <AnnulledCard annullation={annullation} />
                  {(index !== 0 || hasTimeContestations) && <Divider />}
                </>
              ))
              .reverse()}
          </>
        )}
        {hasTimeContestations && (
          <>
            {timeContestations
              .map((timeContestation, index) => (
                <>
                  <TimeContestationCard
                    timeContestation={timeContestation}
                    contestationNumber={index + 1}
                  />
                  {index !== 0 && <Divider />}
                </>
              ))
              .reverse()}
          </>
        )}
      </CardContainer>
    </>
  );
};

export default ExecutionHistoryContestation;
