import { Button, CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { CardSectionContainer, CardSectionHeader, CardSectionTitle, CardSectionContent, CardSectionFooter } from './styles'
import { Shift } from '@cuidador/database'
import { useAsync } from 'react-async-hook'
import axios from '../../../../config/axios'
import { EvolutionList } from './EvolutionList'
import { endOfDay, startOfDay } from 'date-fns'

type EvolutionContentProps = {
  id: number
  active: boolean | undefined
  shifts: Shift[] | undefined
  handleChangeActivePatient: (patientId: number, pageRedirectRoute: string, isPatientActive?: boolean) => Promise<void>
  fromDate?: Date
  toDate?: Date
  isLoading?: boolean
  onOccurrencesUpdate?: (occurrencesOfPatient?: number[]) => void
  onLoadChange?: (isLoading: boolean) => void
}

export function EvolutionContent (props: EvolutionContentProps) {
  const { id, active, shifts, handleChangeActivePatient, fromDate, toDate, isLoading, onOccurrencesUpdate, onLoadChange } = props

  const fromDateString = fromDate
    ? 'updatedTimeHappensAt[min]=' + startOfDay(fromDate)?.toISOString()
    : ''
  const toDateString = toDate
    ? 'updatedTimeHappensAt[max]=' + endOfDay(toDate)?.toISOString()
    : ''

  const getEvents = useAsync(async () => {
    return await axios.get(
      `${process.env.REACT_APP_GATEWAY_URL}/api/v1/report/event/history/${id}?${fromDateString}&${toDateString}`
    )
  }, [fromDate, toDate])

  useEffect(() => {
    onLoadChange?.(getEvents.loading)
  }, [getEvents.loading])

  return (
    <div style={{ marginTop: '8px' }}>
      <CardSectionContainer>
        <CardSectionHeader>
          <CardSectionTitle>Painel de ocorrências</CardSectionTitle>
        </CardSectionHeader>
        <CardSectionContent style={{ overflowY: 'auto', maxHeight: '400px' }}>
          {(getEvents.result?.data.length > 0 && !getEvents.loading) && fromDate && toDate &&
            <EvolutionList
              eventData={getEvents.result?.data}
              onOccurrencesUpdate={onOccurrencesUpdate}
              fromDate={fromDate}
              toDate={toDate}
            />
          }
          {getEvents.result?.data.length === 0 && <Typography>Sem eventos para o período selecionado</Typography>}
          {(getEvents.loading) && <CircularProgress />}
          {getEvents.error && <Typography>Não foi possível carregar os dados</Typography>}
        </CardSectionContent>
        <CardSectionFooter>
          <Button onClick={() => handleChangeActivePatient(id, '/acompanhar/evolucao', active)}>
            Ver evolução completa
          </Button>
        </CardSectionFooter>
      </CardSectionContainer>
    </div>
  )
}
