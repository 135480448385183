import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FilterChipMultiselect,
  FilterChipMultiselectProps,
} from '../../../../components/FilterChip/FilterChipMultiselect';
import IconComplication from '../../../../assets/Icon-flat-complication.svg';
import { CareHistoryFilterContext } from '../../../../contexts/CareHistoryFilter';
import { AuthContext } from '../../../../contexts/auth';
import { formatGenericSubCategoriesIntoOptions } from './utils';

type ComplicationFilterChipMultiselectProps = Pick<
  FilterChipMultiselectProps,
  'active' | 'onClick'
>;

export const ComplicationFilterChipMultiselect = ({
  ...props
}: ComplicationFilterChipMultiselectProps) => {
  const [complicationOptions, setComplicationOptions] = useState<
    {
      id: number;
      label: string;
      checked: boolean;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const { useEventCareHistory } = useContext(CareHistoryFilterContext);
  const {
    params,
    setParams,
    getEventSubCategoriesRelatedToPatientCategory,
  } = useEventCareHistory;

  const { userInfo } = useContext(AuthContext);

  const patientId = userInfo?.activePatientId as number;

  useEffect(() => {
    setLoading(true);
    getEventSubCategoriesRelatedToPatientCategory(patientId, 'complication')
      .then((data) => {
        if (!data) return;
        const formattedOptions = formatGenericSubCategoriesIntoOptions(data);

        const { complicationSubCategoryIds } = params;

        setComplicationOptions(
          formattedOptions.map((complication) => ({
            ...complication,
            checked: complicationSubCategoryIds.includes(
              String(complication.id)
            ),
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [setComplicationOptions]);

  const appySelectionToQuery = useCallback(() => {
    const selectedIds = complicationOptions
      .filter((option) => !!option.checked)
      .map((option) => String(option.id));

    setParams('complicationSubCategoryIds', selectedIds);
  }, [complicationOptions]);

  /**
   * Updates the whole state but now the option which id was informed
   *  has a toggled `checked` property.
   */
  const toggleOption: FilterChipMultiselectProps['toggleOption'] = (id) => {
    setComplicationOptions((measurementOptions) =>
      measurementOptions.map(({ checked, ...option }) => ({
        ...option,
        checked: option.id === id ? !checked : checked,
      }))
    );
  };

  return (
    <FilterChipMultiselect
      {...props}
      label="Intercorrências"
      icon={IconComplication}
      variant="red"
      options={complicationOptions}
      toggleOption={toggleOption}
      title="Intercorrências"
      onFinishOptionsSelection={appySelectionToQuery}
      loading={loading}
      countMarkerAmount={params.complicationSubCategoryIds?.length}
      testid="filter-chip-multiselect-complications"
    />
  );
};
