import styled from 'styled-components';
import { CircularProgress as MaterialCircularProgress } from '@material-ui/core';

export const SimpleCircularProgressContainer = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(15)}px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const SimpleCircularProgress = styled(MaterialCircularProgress)`
  color: ${({ theme }) => theme.palette.primary.light};
  margin-top: 20px;
`;
