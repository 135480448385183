import {
  AnswerType,
  CareQuestionAnswerModel,
  CareQuestionModel,
  PatientInterviewModel,
} from '@cuidador/database';
import { Dictionary } from 'lodash';
import { RefObject } from 'react';

export const generateAnswerDictionaryFromQuestionDictionary = (
  questionDictionary: Dictionary<CareQuestionModel[]>
): Dictionary<CareQuestionAnswerModel[]> => {
  const answerDictionary: Dictionary<CareQuestionAnswerModel[]> = {};

  Object.keys(questionDictionary).map((key) => {
    const questions = questionDictionary[key];
    const answers = questions.map(
      (question) =>
        ({
          careQuestion: question,
          questionAnswerData: question.answerConfig,
        } as CareQuestionAnswerModel)
    );
    answerDictionary[key] = answers;
  });
  return answerDictionary;
};

type GetCurrentCareQuestionAnswerParams = {
  careQuestionAnswerDictionary: Dictionary<CareQuestionAnswerModel[]>;
  careQuestionIdToMatch: number;
};
export const getCurrentCareQuestionAnswerList = (
  params: GetCurrentCareQuestionAnswerParams
) => {
  const { careQuestionAnswerDictionary, careQuestionIdToMatch } = params;
  const allCareQuestionAnswerList = Object.entries(
    careQuestionAnswerDictionary
  ).map((careQuestionAnswersMap) => {
    const careQuestionAnswerList = careQuestionAnswersMap[1];
    return careQuestionAnswerList;
  });
  const currentCareQuestionAnswerList = allCareQuestionAnswerList.find(
    (careQuestionAnswerList) =>
      careQuestionAnswerList.find(
        (answer) => answer.careQuestion?.id === careQuestionIdToMatch
      )
  );

  return currentCareQuestionAnswerList;
};

type BuildNewCareQuestionAnswerListParams = {
  careQuestionAnswerToChange: CareQuestionAnswerModel;
  newQuestionAnswerData: AnswerType;
  careQuestionAnswerList: CareQuestionAnswerModel[];
};
export const buildNewCareQuestionAnswerList = (
  params: BuildNewCareQuestionAnswerListParams
) => {
  const {
    careQuestionAnswerToChange,
    newQuestionAnswerData,
    careQuestionAnswerList,
  } = params;
  const newCareQuestionAnswer: CareQuestionAnswerModel = {
    ...careQuestionAnswerToChange,
    questionAnswerData: newQuestionAnswerData,
  };
  const indexToReplace = careQuestionAnswerList.indexOf(
    careQuestionAnswerToChange
  );
  const newCareQuestionAnswerList = careQuestionAnswerList.map(
    (answer, index) => {
      if (index !== indexToReplace) return answer;
      return newCareQuestionAnswer;
    }
  );
  return newCareQuestionAnswerList;
};

type BuildInterviewDataParams = {
  patientId: Id;
  interviewName: string;
  interviewDate: string;
  careQuestionAnswerDictionary: Dictionary<CareQuestionAnswerModel[]>;
};
export const buildInterviewAnswerData = (
  params: BuildInterviewDataParams
): PatientInterviewModel => {
  const {
    patientId,
    interviewName,
    interviewDate,
    careQuestionAnswerDictionary,
  } = params;

  const careQuestionAnswerList: CareQuestionAnswerModel[] = [];

  Object.entries(careQuestionAnswerDictionary)
    .map((careQuestionAnswersMap) => {
      const careQuestionAnswerList = careQuestionAnswersMap[1];
      return careQuestionAnswerList;
    })
    .forEach((list) => careQuestionAnswerList.push(...list));

  /**
   * Get only careQuestionId and questionAnswerData
   */
  const mappedCareQuestionAnswerList = careQuestionAnswerList.map(
    (careQuestionAnswer) =>
      ({
        careQuestionId: careQuestionAnswer.careQuestion?.id,
        questionAnswerData: careQuestionAnswer.questionAnswerData,
      } as CareQuestionAnswerModel)
  );

  const interviewAnswerData: PatientInterviewModel = {
    patientId: patientId as number,
    name: interviewName,
    interviewedAt: interviewDate,
    careQuestionAnswers: mappedCareQuestionAnswerList,
  };
  return interviewAnswerData;
};

export const handleInterviewCareQuestionsAnswers = (
  interview: PatientInterviewModel,
  readOnly: boolean
) => {
  if (readOnly) {
    return filterAnsweredOptionCareQuestions(
      interview.careQuestionAnswers || []
    );
  }
  return interview.careQuestionAnswers;
};

/**
 * Filter answered CareQuestionsAnswer with only checked Option.
 */
export function filterAnsweredOptionCareQuestions(
  careQuestionAnswers: CareQuestionAnswerModel[]
) {
  return careQuestionAnswers
    .filter((careQuestionAnswer) => {
      const answer = careQuestionAnswer.questionAnswerData as AnswerType;
      const answered = answer.options.some((option) =>
        Boolean(option.isChecked)
      );
      return answered;
    })
    .map((careQuestionAnswer) => {
      const answer = careQuestionAnswer.questionAnswerData as AnswerType;
      return {
        ...careQuestionAnswer,
        questionAnswerData: {
          ...answer,
          options: answer.options.filter((option) => Boolean(option.isChecked)),
        },
      };
    });
}

export function getScrollValueFromRatio(
  element: RefObject<Element>,
  ratio: number
) {
  const scrollWidth = element.current?.scrollWidth || 0;

  return scrollWidth * ratio;
}

export function getEveryPageQuestionAnswered(
  questions: CareQuestionAnswerModel[]
) {
  function isAnswered(question: CareQuestionAnswerModel) {
    const options = (question.questionAnswerData as AnswerType).options;
    return options.some((option) => option.isChecked === true);
  }

  return questions.every(isAnswered);
}

export const MEDIC_BALLOON_TEXTS = [
  'As perguntas estão divididas por temas e você consegue acompanhar a evolução pela barra acima. Se precisar continuar mais tarde, não finalize... as informações ficarão salvas.',
  'Conhecer os dados de saúde vai nos ajudar a antecipar riscos, atuar preventivamente e evitar agravamento de condições e problemas de saúde.',
  'Ótimo, chegamos ao final do cadastro da pessoa sob cuidado. Agora falta pouco para começarmos a nossa jornada!',
];

export function getMedicBalloonText(currentPage: number, totalPages: number) {
  if (currentPage === 0) return MEDIC_BALLOON_TEXTS[0];
  else if (currentPage === totalPages - 1) return MEDIC_BALLOON_TEXTS[2];
  else return MEDIC_BALLOON_TEXTS[1];
}
