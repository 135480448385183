import styled from 'styled-components';

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const StatusLabelContainer = styled.div`
  font-size: 14px;
  /* Medium screen  */
  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  img {
    width: 1.5rem;
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.extra.color.grey.light};

    &:hover {
      opacity: 0.8;
    }
  }

  /* Medium screen  */
  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const StatusDescriptionContainer = styled.div`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: 12px;

  /* Medium screen  */
  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;
