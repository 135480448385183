import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;

  div + div {
    margin-left: ${({ theme }) => theme.spacing(0.75)}px;
  }
`;

export const AutoRelateContainer = styled.div<{ isChecked?: boolean }>`
  background-color: ${({ theme, isChecked }) =>
    isChecked ? theme.palette.primary.main : theme.palette.common.white};

  padding-left: ${({ theme }) => theme.spacing(1)}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  height: 100%;

  label {
    span {
      color: ${({ theme, isChecked }) =>
        isChecked ? theme.palette.common.white : theme.palette.primary.main};
      font-weight: bold;
      font-size: ${({ theme }) => theme.spacing(1.6)}px;
    }

    .Mui-disabled {
      color: ${({ theme, isChecked }) =>
        isChecked ? theme.palette.common.white : theme.palette.primary.main};
    }
  }
`;

export const RemoveRelationsContainer = styled.div`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.extra.color.red.dark};

  button {
    border: none;
    height: 100%;
    color: ${({ theme }) => theme.palette.common.white};

    .MuiButton-label {
      svg {
        margin-right: ${({ theme }) => theme.spacing(1.5)}px;
      }
      span {
        line-height: normal;
        font-size: ${({ theme }) => theme.spacing(1.6)}px;
      }
    }
  }
`;
