import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPaper = styled(Paper)`
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const ColoredTypography = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;

export const ColoredAnchor = styled.a<{ underlineColor: string }>`
  text-decoration: underline;
  text-decoration-color: ${(props) => props.underlineColor};
`;
