import { EventSubCategoryModel } from '@cuidador/database';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  'updatedTimeHappensAt[min]': Yup.date()
    .required('Por favor, insira uma data.')
    .typeError('Por favor, insira uma data válida'),
  'updatedTimeHappensAt[max]': Yup.date()
    .required('Por favor, insira uma data.')
    .typeError('Por favor, insira uma data válida'),
});

export const formatMedicationSubCategoriesIntoOptions = (
  subCategories: EventSubCategoryModel[]
) =>
  subCategories.map((item) => ({
    id: Number(item.id),
    label: String(item.medicationCatalogItem?.name ?? item.name),
    checked: false,
  }));

export const formatGenericSubCategoriesIntoOptions = (
  subCategories: EventSubCategoryModel[]
) =>
  subCategories.map((item) => ({
    id: Number(item.id),
    label: String(item.name ?? '-'),
    checked: false,
  }));
