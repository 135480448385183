import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.success.main};
  padding: 0;
`;

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  word-wrap: break-word;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
`;

export const TimerContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  align-self: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(-0.8)}px;
`;
