import React from 'react';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import StyledSelectField from '../StyledSelectField';
import { SelectProps } from '@material-ui/core';

interface MySelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

const FormikSelect = ({
  name,
  required = false,
  formControlVariant,
  ...props
}: FieldAttributes<any> & // eslint-disable-line @typescript-eslint/no-explicit-any
  SelectProps & {
    name: string;
    required?: boolean;
    SelectDisplayProps?: MySelectDisplayProps;
    formControlVariant?: 'standard' | 'outlined' | 'filled';
  }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched } = useFormikContext<any>();

  const hasError = touched[name] && errors[name];

  return (
    <Field
      type="select"
      id={name}
      required={required}
      as={StyledSelectField}
      name={name}
      error={hasError}
      formControlVariant={formControlVariant}
      {...props}
    />
  );
};

export default FormikSelect;
