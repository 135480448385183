import styled from 'styled-components';

export const IndicatorLabel = styled.span`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-weight: ${({ theme }) => theme.typography.weightBold};
  margin: ${({ theme }) => theme.spacing(0, 1)};
`;

export const IndicatorCircle = styled.div<{ color: string }>`
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: ${({ color }) => color};
  border-radius: 100vw;
  transform: 'translateY(-100%)';
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
