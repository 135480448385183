import { IconButton, useTheme } from '@material-ui/core'
import React from 'react'

export type StyledIconButtonProps = {
  icon: React.ReactNode
  action?: () => void
  backgroundColor?: string
}

export function StyledIconButton (props: StyledIconButtonProps) {
  const { icon, action, backgroundColor } = props
  const theme = useTheme()

  return (
    <IconButton
      style={{
        width: '40px',
        height: '40px',
        fontSize: '40px',
        color: theme.palette.primary.light,
        backgroundColor: backgroundColor,
        borderRadius: '16px',
        padding: '8px',
      }}
      onClick={action}
    >
      {icon}
    </IconButton>
  )
}
