import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FilterChipMultiselect,
  FilterChipMultiselectProps,
} from '../../../../components/FilterChip/FilterChipMultiselect';
import IconRoutine from '../../../../assets/Icon-flat-routine.svg';
import { CareHistoryFilterContext } from '../../../../contexts/CareHistoryFilter';
import { AuthContext } from '../../../../contexts/auth';
import { formatGenericSubCategoriesIntoOptions } from './utils';

type RoutineFilterChipMultiselectProps = Pick<
  FilterChipMultiselectProps,
  'active' | 'onClick'
>;

export const RoutineFilterChipMultiselect = ({
  ...props
}: RoutineFilterChipMultiselectProps) => {
  const [routineOptions, setRoutineOptions] = useState<
    {
      id: number;
      label: string;
      checked: boolean;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const { useEventCareHistory } = useContext(CareHistoryFilterContext);
  const {
    params,
    setParams,
    getEventSubCategoriesRelatedToPatientCategory,
  } = useEventCareHistory;

  const { userInfo } = useContext(AuthContext);

  const patientId = userInfo?.activePatientId as number;

  useEffect(() => {
    setLoading(true);
    getEventSubCategoriesRelatedToPatientCategory(patientId, 'routine')
      .then((data) => {
        if (!data) return;
        const formattedOptions = formatGenericSubCategoriesIntoOptions(data);

        const { routineSubCategoryIds } = params;

        setRoutineOptions(
          formattedOptions.map((routine) => ({
            ...routine,
            checked: routineSubCategoryIds.includes(String(routine.id)),
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [setRoutineOptions]);

  const appySelectionToQuery = useCallback(() => {
    const selectedIds = routineOptions
      .filter((option) => !!option.checked)
      .map((option) => String(option.id));

    setParams('routineSubCategoryIds', selectedIds);
  }, [routineOptions]);

  /**
   * Updates the whole state but now the option which id was informed
   *  has a toggled `checked` property.
   */
  const toggleOption: FilterChipMultiselectProps['toggleOption'] = (id) => {
    setRoutineOptions((routineOptions) =>
      routineOptions.map(({ checked, ...option }) => ({
        ...option,
        checked: option.id === id ? !checked : checked,
      }))
    );
  };

  return (
    <FilterChipMultiselect
      {...props}
      label="Rotinas"
      icon={IconRoutine}
      variant="lightblue"
      options={routineOptions}
      toggleOption={toggleOption}
      title="Rotinas"
      onFinishOptionsSelection={appySelectionToQuery}
      loading={loading}
      countMarkerAmount={params.routineSubCategoryIds?.length}
      testid="filter-chip-multiselect-routines"
    />
  );
};
