import { Form as FormikForm, Formik } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';
import useCanAccess from '../../hooks/useCanAccess';
import DaysSelector, { FrequencyType } from './DaysSelector';
import InformativeText from './InformativeText';
import MeasurementsSelector from './MeasurementsSelector';
import MedicBalloon from './MedicBalloon';
import PeriodSelector, { PeriodType } from './PeriodSelector';
import { validationSchema } from './schema';
import { ContentContainer } from './styles';
import SubmitButton from './SubmitButton';
import TimeSelector from './TimeSelector';

export interface Measurement {
  id: string;
  instruction: string;
}

export interface FormValues {
  measurements?: Measurement[];
  dayOptions?: FrequencyType;
  days?: string[];
  rangeInDays?: '2d' | '3d' | '5d' | '7d' | '15d' | '30d' | 'custom';
  customRangeInDays?: number;
  timeSchedule?: string;
  time?: string;
  customTimes?: string[];
  frequencyStartsAt?: string;
  frequencyEndsAt?: string;
  periodType?: PeriodType;
}

interface MeasurementScheduleForm {
  id?: number;
  initialValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}

const MeasurementScheduleForm: React.FC<MeasurementScheduleForm> = ({
  id,
  initialValues,
  onSubmit,
}) => {
  const { isAllowedToUpdate } = useCanAccess('care/event/schedule');
  return (
    <>
      <MedicBalloon />
      <InformativeText />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
      >
        {({ values, setFieldValue, isSubmitting, errors, touched }) => {
          return (
            <FormikForm>
              <ContentContainer>
                <TimeSelector
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  disabled={!!id && !isAllowedToUpdate}
                />
                <MeasurementsSelector
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  disabled={!!id && !isAllowedToUpdate}
                />
                <DaysSelector
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  disabled={!!id && !isAllowedToUpdate}
                />
                <PeriodSelector
                  values={values}
                  setFieldValue={setFieldValue}
                  id={id}
                  disabled={!!id && !isAllowedToUpdate}
                />
                <SubmitButton
                  disabled={isSubmitting || isEqual(initialValues, values)}
                />
              </ContentContainer>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default MeasurementScheduleForm;
