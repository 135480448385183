import { Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledBoldTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 40%;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
  & .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.black};
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
`;

export const StyledOptions = styled.div`
  width: 80%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledOptionsTypography = styled.div`
  width: 80%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPicker = styled(Typography)`
  width: 40%;
`;

export const Button = styled(StyledButton)`
  width: 49%;
`;

export const StyledOkStep = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledError = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.red.main};
  font-size: 1em;
`;

export const StyledParagraph = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
