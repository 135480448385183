import { ReportModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

const endpoint = '/report';

const initialData: ReducerData<ReportModel> = {
  byId: {} as Record<string, Item<ReportModel>>,
  ids: [] as Array<Id>,
  total: 0,
  loading: false,
  error: null,
};

const useReport = () => {
  const [state, dispatch] = useReducer(
    createReducer<ReportModel>(),
    initialData
  );
  const { getMedia } = useMedia();

  const getByPatientPaginated = useCallback(
    async (patientId: Id, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(
          `${endpoint}/by-patient/${patientId}?isDraft=0`,
          {
            params: { ...params },
          }
        );
        dispatch({ type: 'PAGINATION', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const getReportUrl = useCallback(
    (patientId: Id, reportId: Id) =>
      getMedia(`/media/patient/${patientId}/report/${reportId}`),
    []
  );

  return {
    ...state,
    getByPatientPaginated,
    getReportUrl,
  };
};

export default useReport;
