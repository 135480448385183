import { EventModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppointmentCard from '../../components/AppointmentCard';
import Fab from '../../components/Fab';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import ReportDownloadButton from '../../components/ReportDownloadButton';
import { AuthContext } from '../../contexts/auth';
import useAppointment from '../../hooks/useAppointment';
import useCanAccess from '../../hooks/useCanAccess';
import { Item } from '../../utils/store';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  StyledLink,
  StyledTypography,
  TypographyContainer,
} from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { BackButton } from '../../components/BackButton'
import { PscButton } from '../../components/PscButton'

const AppointmentCardItem: React.FC<{
  appointment: Item<EventModel>;
}> = ({ appointment }) => {
  return (
    <StyledLink to={`/agendar/compromisso/${appointment.id}`}>
      <AppointmentCard {...appointment} />
    </StyledLink>
  );
};

const Appointments: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const {
    getAllByPatientId,
    byId,
    ids: eventsIds,
    loading,
    createReportByPatientId,
    getReportDownloadLinkByPatientId,
  } = useAppointment();
  const {
    isAllowedToRead: isAllowedToReadAppointment,
    isAllowedToCreate: isAllowedToCreateAppointment,
  } = useCanAccess('care/event/appointment');

  const {
    isAllowedToCreate: isAllowedToCreateAppointmentReport,
  } = useCanAccess('report/appointment');

  const { isAllowedToRead: isAllowedToReadAppointmentReport } = useCanAccess(
    'media/patient/report/appointment'
  );

  const history = useHistory();

  const [downloadLink, setDownloadLink] = useState('');
  const patientId = userInfo?.activePatientId;

  useEffect(() => {
    if (!isAllowedToReadAppointment) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.goBack();
      return;
    }

    if (!patientId) return;
    getAllByPatientId(patientId);
  }, [patientId]);

  const handleCreateReportAndGetDownloadLink = async () => {
    createAppointmentReport()
      .then(() => getAppointReportDownloadUrl())
      .then((signedUrl) => setDownloadLink(signedUrl));
  };

  const createAppointmentReport = async () => {
    try {
      await createReportByPatientId(Number(userInfo?.activePatientId));
      toast.success('Relátorio de compromissos gerado com sucesso');
    } catch (err) {
      toast.error('Erro ao gerar relatório de compromissos');
    }
  };

  const getAppointReportDownloadUrl = async (): Promise<string> => {
    try {
      const { signedUrl } = await getReportDownloadLinkByPatientId(
        Number(userInfo?.activePatientId)
      );
      return signedUrl;
    } catch (err) {
      toast.error('Erro ao baixar relatório de compromissos');
      return '';
    }
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Compromissos'/>
        }
        rightContent={<PscButton />}
      />
      <PendingRegistrationBanner />
      {loading ? (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      ) : (
        <Container>
          {eventsIds.length <= 0 ? (
            <TypographyContainer>
              <Typography variant="h5">
                Não há compromissos cadastrados.
              </Typography>
              <StyledTypography variant="subtitle1">
                Agende aqui os compromissos da pessoa sob cuidado.
              </StyledTypography>
            </TypographyContainer>
          ) : (
            <>
              <TypographyContainer>
                <Typography variant="h6">Lista de compromissos</Typography>
              </TypographyContainer>
              {isAllowedToReadAppointmentReport &&
                isAllowedToCreateAppointmentReport && (
                  <ReportDownloadButton
                    createTitle="Clique aqui para gerar o relatório de compromissos"
                    downloadtitle="Clique aqui para baixar o relatório de compromissos"
                    onGenerateReportClick={handleCreateReportAndGetDownloadLink}
                    downloadLink={downloadLink}
                  />
                )}

              {eventsIds
                .map((id) => byId[id])
                .map((appointment) => (
                  <AppointmentCardItem
                    key={appointment.id}
                    appointment={appointment}
                  />
                ))}
            </>
          )}
          {isAllowedToCreateAppointment && (
            <Fab to="/agendar/compromisso/novo">
              <AddIcon />
            </Fab>
          )}
        </Container>
      )}
    </>
  );
};

export default Appointments;
