import { EventModel } from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { xor } from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import SvgFilter from '../../../../assets/Icon-filter.svg';
import IconAccomplished from '../../../../assets/icon-accomplished-negative.svg';
import IconAwaiting from '../../../../assets/icon-awaiting-negative.svg';
import IconNotAccomplished from '../../../../assets/icon-not-accomplished-negative.svg';
import IconAlert from '../../../../assets/icon-flat-accomplished-warning-white.svg';
import { CareHistoryFilterContext } from '../../../../contexts/CareHistoryFilter';
import {
  CategoryType,
  isCategoryType,
  SubCategoryType,
} from '../../../../hooks/useEventCareHistory';
import {
  AccomplishedFilter,
  AlertFilter,
  AwaitingFilter,
  ClockIcon,
  CommentIcon,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderContent,
  DrawerSection,
  HeaderTitle,
  NotAccomplishedFilter,
  ResetFiltersButton,
  Row,
  SectionContent,
  SectionHeader,
  StyledBackIcon,
  StyledButton,
  StyledDatePicker,
  StyledDrawer,
  StyledFilterIcon,
} from './styles';
import { MedicationFilterChipMultiselect } from './MedicationFilterChipMultiselect';
import { MeasurementFilterChipMultiselect } from './MeasurementFilterChipMultiselect';
import { ComplicationFilterChipMultiselect } from './ComplicationFilterChipMultiselect';
import { AppointmentFilterChipMultiselect } from './AppointmentFilterChipMultiselect';
import { RoutineFilterChipMultiselect } from './RoutineFilterChipMultiselect';

interface DrawerProps {
  handleToggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const FilterDrawer: React.FC<DrawerProps> = ({
  isDrawerOpen,
  handleToggleDrawer,
}) => {
  const { useEventCareHistory } = useContext(CareHistoryFilterContext);
  const {
    params,
    setParams,
    resetParams,
    generateDefaultParams,
  } = useEventCareHistory;

  const onChangeDate = useCallback(
    (
      paramName: 'updatedTimeHappensAt[min]' | 'updatedTimeHappensAt[max]',
      date: MaterialUiPickersDate
    ) => {
      if (!isValid(date)) return;

      setParams(paramName, (date as Date).toISOString());
    },
    []
  );

  const handleToggleStatus = (status: EventModel['status']) => {
    if (!status) return;

    const toggledStatus = xor(params.status, [status]);

    setParams('status', toggledStatus);
  };

  const handleCategory = (category: CategoryType) => {
    const toggledCategory = xor(params.category, [category]);
    setParams('category', toggledCategory);

    if (isCategoryType(category) && !toggledCategory.includes(category))
      setParams(`${category}SubCategoryIds` as SubCategoryType, []);
  };

  useEffect(() => {
    const initialDate = params['updatedTimeHappensAt[min]'];
    const finalDate = params['updatedTimeHappensAt[max]'];
    if (initialDate > finalDate) {
      toast.dismiss();
      toast.error('Data de início deve ser anterior ou igual a da data de fim');
      const defaultParams = generateDefaultParams();
      setParams(
        'updatedTimeHappensAt[max]',
        defaultParams['updatedTimeHappensAt[max]']
      );
      setParams(
        'updatedTimeHappensAt[min]',
        defaultParams['updatedTimeHappensAt[min]']
      );
    }
  }, [
    params['updatedTimeHappensAt[min]'],
    params['updatedTimeHappensAt[max]'],
  ]);

  return (
    <StyledDrawer
      open={isDrawerOpen}
      onClose={handleToggleDrawer}
      anchor="right"
      data-testid="filter-drawer"
    >
      <DrawerHeader>
        <IconButton
          onClick={handleToggleDrawer}
          data-testid="filter-drawer-close"
        >
          <StyledBackIcon fontSize="large" />
        </IconButton>
        <DrawerHeaderContent>
          <StyledFilterIcon src={SvgFilter} />
          <HeaderTitle>Filtros</HeaderTitle>
        </DrawerHeaderContent>
      </DrawerHeader>
      <DrawerBody>
        <Row>
          <ResetFiltersButton
            startIcon={<RefreshIcon />}
            size="small"
            onClick={resetParams}
          >
            Limpar filtros{' '}
          </ResetFiltersButton>
        </Row>
        <DrawerSection>
          <SectionHeader>Período</SectionHeader>
          <SectionContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <StyledDatePicker
                format="dd/MM/yyyy"
                inputProps={{
                  'data-testid': 'input-startDate',
                }}
                name="updatedTimeHappensAt[min]"
                margin="normal"
                label="Início"
                inputVariant="outlined"
                value={params['updatedTimeHappensAt[min]']}
                maxDate={params['updatedTimeHappensAt[max]']}
                clearable
                onChange={(date) =>
                  onChangeDate('updatedTimeHappensAt[min]', date)
                }
                size="small"
              />
              <StyledDatePicker
                format="dd/MM/yyyy"
                inputProps={{
                  'data-testid': 'input-endDate',
                }}
                name="updatedTimeHappensAt[max]"
                margin="normal"
                label="Fim"
                inputVariant="outlined"
                value={params['updatedTimeHappensAt[max]']}
                minDate={params['updatedTimeHappensAt[min]']}
                clearable
                onChange={(date) =>
                  onChangeDate('updatedTimeHappensAt[max]', date)
                }
                size="small"
              />
            </MuiPickersUtilsProvider>
          </SectionContent>
        </DrawerSection>
        <DrawerSection>
          <SectionHeader>Resposta</SectionHeader>
          <SectionContent>
            <Row>
              <AlertFilter
                label="Alertas"
                onClick={() => handleToggleStatus('alert')}
                active={params.status.includes('alert')}
                icon={IconAlert}
              />
              <AccomplishedFilter
                label="Feito"
                onClick={() => handleToggleStatus('accomplished')}
                icon={IconAccomplished}
                active={params.status.includes('accomplished')}
              />
              <NotAccomplishedFilter
                label="Não feito"
                onClick={() => handleToggleStatus('not_accomplished')}
                icon={IconNotAccomplished}
                active={params.status.includes('not_accomplished')}
              />
              <AwaitingFilter
                label="Sem resposta"
                onClick={() => handleToggleStatus('awaiting')}
                icon={IconAwaiting}
                active={params.status.includes('awaiting')}
              />
            </Row>
          </SectionContent>
        </DrawerSection>
        <DrawerSection>
          <SectionHeader>Tipo de evento</SectionHeader>
          <SectionContent>
            <Row>
              <MeasurementFilterChipMultiselect
                onClick={() => handleCategory('measurement')}
                active={params.category?.includes('measurement')}
              />
              <ComplicationFilterChipMultiselect
                onClick={() => handleCategory('complication')}
                active={params.category?.includes('complication')}
              />
              <MedicationFilterChipMultiselect
                onClick={() => handleCategory('medication')}
                active={params.category?.includes('medication')}
              />
              <AppointmentFilterChipMultiselect
                onClick={() => handleCategory('appointment')}
                active={params.category?.includes('appointment')}
              />
              <RoutineFilterChipMultiselect
                onClick={() => handleCategory('routine')}
                active={params.category?.includes('routine')}
              />
            </Row>
          </SectionContent>
        </DrawerSection>
        <DrawerSection>
          <SectionHeader>Extras</SectionHeader>
          <SectionContent>
            <StyledButton
              onClick={() => setParams('hasUpdated', !params.hasUpdated)}
              isActive={params.hasUpdated}
            >
              <ClockIcon /> Horário alterado
            </StyledButton>

            <StyledButton
              onClick={() => setParams('hasComment', !params.hasComment)}
              isActive={params.hasComment}
            >
              <CommentIcon /> Tem observação
            </StyledButton>
          </SectionContent>
        </DrawerSection>
      </DrawerBody>
    </StyledDrawer>
  );
};

export default FilterDrawer;
