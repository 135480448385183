import styled from 'styled-components';
import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
`;

export const StyledTabItem = styled(Tab)`
  width: ${({ theme }) => theme.spacing(15)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.spacing(2)}px;
  font-weight: 400;
  border-radius: 5px 5px 0 0;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
