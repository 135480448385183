import styled from 'styled-components';
import MuiFab from '@material-ui/core/Fab';
import AvatarEditor from 'react-avatar-editor';

import { hexToRGBArray } from '../../styles/colorMapping';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: center;
  flex-direction: column;
  margin-bottom: 30px;

  img {
    height: 82px;
    width: 82px;
    border-radius: 50%;
    border: solid 1px ${({ theme }) => theme.palette.common.white};
  }

  label {
    text-decoration: underline;
  }
`;

export const Fab = styled(MuiFab)`
  margin-left: 10px;
  margin-bottom: 2px;
  width: 25px;
  height: 25px;
  min-height: auto;
  border: solid 1px ${({ theme }) => theme.palette.common.white};
  svg {
    font-size: 15px;
  }
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledEditor = styled(AvatarEditor).attrs((props) => ({
  width: 230,
  height: 230,
  borderRadius: 115, // totally round
  color: [...hexToRGBArray(props.theme.palette.primary.main), 0.8],
}))``;
