import { EventScheduleModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Item } from '../../utils/store';
import {
  BoldTypography,
  CardBox,
  DetailsBox,
  MeasurementBox,
  StyledLink,
  StyledUl,
  TitleBox,
} from './styles';
import { getDaysText, getIntervalText, getTimes } from './utils';

const MeasurementCard: React.FC<{
  eventSchedule: Item<EventScheduleModel>;
}> = ({ eventSchedule }) => {
  return (
    <CardBox>
      <StyledLink to={`/controles/${eventSchedule.id}`}>
        <TitleBox>
          <BoldTypography variant="h6">
            Controles e Sinais Vitais
          </BoldTypography>
          <EditIcon />
        </TitleBox>
        <MeasurementBox>
          <BoldTypography variant="subtitle1">
            Medição(ões) agendada(s):
          </BoldTypography>
          <StyledUl>
            {eventSchedule?.subCategory?.map((subCategory) => (
              <li key={subCategory.id}>{subCategory.name}</li>
            ))}
          </StyledUl>
        </MeasurementBox>
        <DetailsBox>
          <BoldTypography variant="subtitle1">
            Detalhes do agendamento:
          </BoldTypography>
          <Typography variant="body2">{getDaysText(eventSchedule)}</Typography>
          <Typography variant="body2">
            {getIntervalText(eventSchedule)}
          </Typography>
          {getTimes(eventSchedule)}
        </DetailsBox>
      </StyledLink>
    </CardBox>
  );
};

export default MeasurementCard;
