import React from 'react';
import { useFormikContext } from 'formik';
import { StyledField, StyledRow, StyledSpan } from '../StyledWeekDaySelect';
import { weekdayMap } from '../../utils/date';
import { StyledFormHelperText } from './styles';

type FormikWeekDaySelectProps = {
  name: string;
};

export default function FormikWeekDaySelect({
  name,
}: FormikWeekDaySelectProps) {
  //   eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, values, touched } = useFormikContext<any>();

  return (
    <>
      <StyledRow>
        {Object.entries(weekdayMap).map(([key, ptBrWeekDayName]) => (
          <label key={key}>
            <StyledField
              type="checkbox"
              name={name}
              value={ptBrWeekDayName}
              data-testid={`${name}-${key}`}
            />
            <StyledSpan checked={values?.[name]?.includes(ptBrWeekDayName)}>
              {ptBrWeekDayName[0]}
            </StyledSpan>
          </label>
        ))}
      </StyledRow>
      {touched[name] && errors[name] && (
        <StyledFormHelperText error={true}>{errors[name]}</StyledFormHelperText>
      )}
    </>
  );
}
