import { GuardianModel } from '@cuidador/database';
import * as yup from 'yup';
import {
  numberMask,
  validateCpf,
  validatePasswordStrength,
} from '../../utils/inputs';

export type FormValues = {
  name: string;
  cpf: string;
  phoneNumber: string;
  email: string;
  howMetUs: string;
  relationToPatient: string;
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;
};

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  cpf: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      validateCpf(value || '')
    ),
  phoneNumber: yup
    .string()
    .required('Por favor, insira um telefone')
    .min(14, 'Por favor, insira um telefone válido')
    .max(15, 'Por favor, insira um telefone válido'),
  email: yup
    .string()
    .required('Por favor, insira um email')
    .email('Por favor, insira um e-mail válido'),
  howMetUs: yup.string(),
  relationToPatient: yup.string(),
  password: yup
    .string()
    .required('Por favor, insira a senha')
    .test(
      'isValidPassword',
      'Por favor, insira uma senha que atenda aos critérios listados abaixo',
      (value) => validatePasswordStrength(value || '')
    ),
  passwordConfirmation: yup
    .string()
    .required('Por favor, insira a confirmação da senha')
    .oneOf([yup.ref('password'), ''], 'As senhas devem ser iguais'),
  termsAccepted: yup
    .boolean()
    .equals(
      [true],
      'Por favor, leia e aceite nossos termos e políticas de privacidade'
    ),
});

export const initialValues = {
  name: '',
  cpf: '',
  phoneNumber: '',
  email: '',
  howMetUs: '',
  relationToPatient: '',
  password: '',
  passwordConfirmation: '',
  termsAccepted: false,
};

export const howMetUsOptions = [
  { value: 'friends', label: 'Indicação de amigos' },
  { value: 'whatsapp', label: 'Whatsapp' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'nestle', label: 'Parceria Nestlé' },
  { value: 'torrent', label: 'Parceria Torrent' },
  { value: 'professionalIndication', label: 'Indicação Profissional' },
  { value: 'google', label: 'Google' },
  { value: 'others', label: 'Outros' },
];

export const relationToPatientOptions = [
  { value: 'son', label: 'Filho(a)' },
  { value: 'grandson', label: 'Neto(a)' },
  { value: 'sibling', label: 'Irmão(ã)' },
  { value: 'spouse', label: 'Cônjuge (Marido/Esposa)' },
  { value: 'friend', label: 'Amigo(a)' },
  { value: 'care-manager', label: 'Gestor de Cuidados' },
  { value: 'other', label: 'Outra' },
];

export const formDataToGuardianModel = (values: FormValues) =>
  ({
    relationToPatient: values.relationToPatient || undefined,
    user: {
      name: values.name,
      cpf: numberMask(values.cpf),
      phoneNumber: numberMask(`55${values.phoneNumber}`),
      email: values.email || undefined,
      howMetUs: values.howMetUs || undefined,
      password: values.password,
    },
  } as GuardianModel);

export const passwordInitialValues = {
  password: '',
  passwordConfirmation: '',
  termsAccepted: false,
};

export type PasswordFormValues = {
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;
};
