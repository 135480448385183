import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import DoneIcon from '../../../../assets/Icon-done.svg';
import NotDoneIcon from '../../../../assets/Icon-notdone.svg';
import TimeColorIcon from '../../../../assets/Icon-time-color.svg';
import ClockIcon from '../../../../assets/Icon-watch.svg';
import {
  CaregiverTypography,
  CardFooter,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox, TitleContainer } from './styles';

interface CardProps {
  id?: Id;
  name?: string;
  subCategory?: EventSubCategoryModel;
  eventHappensAt?: string;
  description?: string;
  status?: string;
  comment?: string;
  updatedTimeHappensAt?: string | null;
  caregiverName?: string;
}

const RoutineCard: React.FC<CardProps> = ({
  id,
  name,
  subCategory,
  description,
  eventHappensAt,
  status,
  comment,
  updatedTimeHappensAt,
  caregiverName,
}) => {
  const eventDate = updatedTimeHappensAt || eventHappensAt;
  const formattedDate = format(new Date(`${eventDate}`), 'HH:mm');

  return (
    <CardBox data-testid={`routineCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={ClockIcon} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <TitleContainer>
            <Typography variant="h6">{subCategory?.name}</Typography>
          </TitleContainer>
          <Typography variant="subtitle1">{name?.trim()}</Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          <StatusContainer>
            {updatedTimeHappensAt && status === 'accomplished' && (
              <img src={TimeColorIcon} data-testid={`delayedIcon-${id}`} />
            )}
            {status === 'awaiting' && <></>}
            {status === 'accomplished' && <img src={DoneIcon} />}
            {status === 'not_accomplished' && <img src={NotDoneIcon} />}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  );
};

export default RoutineCard;
