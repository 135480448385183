import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { toast } from 'react-toastify';
import {
  FormCardContainer,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../../FormCardContainer';
import MedicBalloon from '../../MedicBalloon';
import StyledButton from '../../StyledButton';
import StyledMenuItem from '../../StyledMenuItem';
import {
  Centralizer,
  ContentContainer,
  DosageFormikTextField,
  Form,
  StyledRow,
} from '../styles';

import { validationSchema } from '../schema';
import DaysSelector from './DaysSelector';
import PeriodSelector from './PeriodSelector';
import TimeSelector from './TimeSelector';
import { administeredNames, dosesNames, FormValues } from './utils';

const MedicationSecondStep: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
  id?: number;
  disabled?: boolean;
}> = ({ initialValues, innerRef, onSubmit, id, disabled }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        if (
          values.frequencyStartsAt &&
          values.frequencyEndsAt &&
          values.frequencyEndsAt < values.frequencyStartsAt
        ) {
          toast.error('Data de início não pode ser após a data de fim');
          formikHelpers.setSubmitting(false);
        } else return onSubmit(values, formikHelpers);
      }}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <>
              <MedicBalloon text="Coloque aqui a forma de uso, conforme receita médica, em quais situações tomar e a quantidade." />
              <ContentContainer>
                <PeriodSelector
                  values={values}
                  setFieldValue={setFieldValue}
                  id={id}
                  startDate={initialValues.frequencyStartsAt}
                />
                {!values.ifNecessary && (
                  <>
                    <TimeSelector
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                    />
                    <DaysSelector
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      disabled={disabled}
                    />
                  </>
                )}

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Qual quantidade deve usar?
                  </StyledBoldTitle>
                  <StyledRow style={{ alignItems: 'flex-start' }}>
                    <DosageFormikTextField
                      value={values.dosageQuantity}
                      color="secondary"
                      id="dosageQuantity"
                      inputProps={{ 'data-testid': 'dosageQuantity' }}
                      name="dosageQuantity"
                      autoComplete="off"
                      type="number"
                      size="small"
                    />
                    <StyledSelect
                      name="dosageFormat"
                      value={values?.dosageFormat || ''}
                      SelectDisplayProps={{
                        'data-testid': 'dosageFormat',
                      }}
                      color="secondary"
                      size="small"
                      disabled={disabled}
                    >
                      {dosesNames.map((item) => (
                        <StyledMenuItem
                          key={item.id}
                          value={item.value}
                          color="secondary"
                        >
                          {item.name}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                  </StyledRow>
                </FormCardContainer>

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Qual a via de aplicação?
                  </StyledBoldTitle>
                  <StyledSelect
                    variant="outlined"
                    label="Via de aplicação"
                    placeholder="Via de aplicação"
                    name="administeredBy"
                    value={values?.administeredBy || ''}
                    SelectDisplayProps={{
                      'data-testid': 'administeredBy',
                    }}
                    color="secondary"
                    size="small"
                    disabled={disabled}
                  >
                    {administeredNames.map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.value}
                        color="secondary"
                      >
                        {item.name}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormCardContainer>

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Recomendações médicas (opcional)
                  </StyledBoldTitle>
                  <FormikTextField
                    variant="outlined"
                    label="Recomendações"
                    placeholder="Recomendações"
                    value={values?.medicalNotes || ''}
                    color="secondary"
                    id="medicalNotes"
                    inputProps={{ 'data-testid': 'medicalNotes' }}
                    name="medicalNotes"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                  />
                </FormCardContainer>
                <Centralizer>
                  <StyledButton
                    data-testid="submit-medication"
                    type="submit"
                    size="large"
                    color="inherit"
                    disabled={
                      isSubmitting ||
                      (!!initialValues.subCategoryId &&
                        isEqual(initialValues, values))
                    }
                  >
                    Salvar
                  </StyledButton>
                </Centralizer>
              </ContentContainer>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicationSecondStep;
