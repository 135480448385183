import React, { useState } from 'react';
import { Menu, Button, withStyles } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';
import MenuItem from './MenuItem';
import { ButtonContainer } from './styles';

export type MenuItemProps = {
  label: string;
  icon?: React.ReactNode;
  handleClickItem: (() => Promise<void>) | (() => void);
};

interface DropdownMenuProps {
  menuId?: number;
  menuItems: MenuItemProps[];
}

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    backgroundColor: 'transparent',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const DropdownMenu: React.FC<DropdownMenuProps> = ({ menuId, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  return (
    <ButtonContainer
      $isExpanded={isExpanded}
      data-testid={`menu-button-${menuId}`}
    >
      <Button
        aria-controls="button-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <MenuIcon fontSize="large" />
      </Button>
      <StyledMenu
        id="button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(({ label, icon, handleClickItem }, index) => (
          <MenuItem
            key={index}
            label={label}
            icon={icon}
            handleClickItem={handleClickItem}
          />
        ))}
      </StyledMenu>
    </ButtonContainer>
  );
};

export default DropdownMenu;
