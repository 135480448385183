import React from 'react';
import { format } from 'date-fns';
import { ShiftExecution, ShiftModel } from '@cuidador/database';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { TypographyWithIcon } from '../../styles';
import { shiftHasNotStarted, isShiftWithContestation } from '../../utils';

interface ShiftTimeMessageProps {
  shift: ShiftModel;
  execution: ShiftExecution;
}

const ShiftTimeMessage: React.FC<ShiftTimeMessageProps> = ({
  shift,
  execution,
}) => {
  if (shiftHasNotStarted(shift) || !shift.startedAt) return null;

  const handleFormatShiftExecutionTime = (execution: ShiftExecution) => {
    const start = format(new Date(String(execution.startedAt)), 'HH:mm');
    let dateStringMessage = `${start}`;

    if (execution.endedAt) {
      const end = format(new Date(execution.endedAt), 'HH:mm');
      dateStringMessage += ` até ${end}`;
    }

    return dateStringMessage;
  };

  return (
    <TypographyWithIcon>
      {!!shift.isAnnulled && <s>{handleFormatShiftExecutionTime(execution)}</s>}
      {!shift.isAnnulled && <b>{handleFormatShiftExecutionTime(execution)}</b>}
      {!!isShiftWithContestation(shift) && <AccessTimeIcon />}
    </TypographyWithIcon>
  );
};

export default ShiftTimeMessage;
