import { EventSubCategoryModel } from '@cuidador/database';
import { parse } from 'date-fns';
import { FormValues, Measurement } from '..';
import { MeasurementScheduleModelDTO } from '../../../hooks/useMeasurement';
import {
  convertLocalTimeToUTC,
  getUTCWeeklySchedule,
  weekdayMap,
} from '../../../utils/date/index';
import { FrequencyType } from '../DaysSelector';
import { PeriodType } from '../PeriodSelector';
import { getMeasurementTimes } from '../TimeSelector';

export const formDataToEventScheduleModel = (
  formData: FormValues,
  patientId: number
) => {
  const frequencyStartsAt = parse(
    `${formData.frequencyStartsAt} ${
      (formData.timeSchedule === 'custom'
        ? formData.customTimes && formData.customTimes[0]
        : formData.time) || '00:00'
    }`,
    'yyyy-MM-dd HH:mm',
    new Date()
  ).toISOString();

  const frequencyEndsAt =
    formData.periodType === PeriodType.DeterminedTime
      ? parse(
          `${formData.frequencyEndsAt}`,
          'yyyy-MM-dd',
          new Date()
        ).toISOString()
      : null;

  const utcSchedule = getUtcSchedule(formData.days!, formData.time!);

  const frequencyRule = getFrequencyRule(formData);

  const { subCategory, eventDescription } = parseMeasurements(
    formData.measurements!
  );

  const times = getTimes(formData);

  const customTimes = times.map((time) => ({
    happensAt: convertLocalTimeToUTC(time),
  }));

  const data: MeasurementScheduleModelDTO = {
    name: 'Controles e sinais vitais',
    scheduleType: 'custom_frequency',
    customTimes,
    frequencyStartsAt,
    frequencyEndsAt,
    ...utcSchedule,
    frequencyRule,
    subCategory,
    eventDescription,
    patientId,
  };

  return data;
};

const getTimes = (formData: FormValues) => {
  let times;
  if (formData.timeSchedule === 'custom') {
    times = formData.customTimes;
  } else {
    times = getMeasurementTimes(formData.time!, formData.timeSchedule!);
  }
  return times || [];
};

const getFrequencyRule = (formData: FormValues) => {
  if (formData.dayOptions !== FrequencyType.Custom) return '1d';
  if (formData.rangeInDays !== 'custom') return formData.rangeInDays;
  return `${formData.customRangeInDays}d`;
};

const parseMeasurements = (measurements: Measurement[]) => {
  const subCategory = measurements.map((measurement) => {
    const subCategoryFormatted: EventSubCategoryModel = {
      id: Number(measurement.id),
    };
    return subCategoryFormatted;
  });

  const eventDescription = measurements.map((measurement) => ({
    subCategoryId: Number(measurement.id),
    description: measurement.instruction || '',
  }));

  return {
    subCategory,
    eventDescription,
  };
};

const getUtcSchedule = (days: string[], time: string) => {
  const localSchedule = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };

  if (days && days.length > 0) {
    localSchedule.mon = days.includes(weekdayMap.mon);
    localSchedule.tue = days.includes(weekdayMap.tue);
    localSchedule.wed = days.includes(weekdayMap.wed);
    localSchedule.thu = days.includes(weekdayMap.thu);
    localSchedule.fri = days.includes(weekdayMap.fri);
    localSchedule.sat = days.includes(weekdayMap.sat);
    localSchedule.sun = days.includes(weekdayMap.sun);
  }

  const utcSchedule = getUTCWeeklySchedule(localSchedule, time);
  return utcSchedule;
};
