import { PatientInterviewModel } from '@cuidador/database';
import { clientSideScheme } from '@cuidador/whitelabel';
import { Radio, RadioGroup } from '@material-ui/core';
import { format } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useCEP from '../../hooks/useCEP';
import useInterview from '../../hooks/useInterview';
import { cepMask, cpfMask, phoneMask } from '../../utils/inputs';
import {
  FormCardContainer,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../FormCardContainer';
import MedicBalloon from '../MedicBalloon';
import ProfilePicture from '../ProfilePicture';
import RegistrationDashboardCard from '../RegistrationDashboardCard';
import StyledButton from '../StyledButton';
import StyledFormControlLabel from '../StyledFormControlLabel';
import StyledKeyboardDatePicker from '../StyledKeyboardDatePicker';
import StyledMenuItem from '../StyledMenuItem';
import PatientStatus from './PatientStatus';
import {
  Centralizer,
  ContentContainer,
  Form,
  InterviewCardContainer,
  PostalCodeContainer,
} from './styles';
import {
  CustomLabel,
  FormValues,
  genderOptions,
  provincesOptions,
  validationSchema,
} from './utils';

const PatientUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  // eslint-disable-next-line
  formikRef?: React.MutableRefObject<FormikProps<any> | null | undefined>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
  initialPictureUrl?: string;
  editingPatient?: boolean;
  disabled?: boolean;
}> = ({
  initialValues,
  onSubmit,
  innerRef,
  formikRef,
  initialPictureUrl,
  editingPatient,
  disabled,
}) => {
  const [selectedCustomLabel, setSelectedCustomLabel] = useState<CustomLabel>(
    initialValues.labelPreference || CustomLabel.Name
  );
  const { isAllowedToUpdate } = useCanAccess('user/patient');
  const [isAllowedToCreate, setIsAllowedToCreate] = useState(false);
  const { appFPalette } = clientSideScheme();
  const { loading: loadingAddressData, getAddress } = useCEP();
  const { getByPatientPaginated } = useInterview();
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  useEffect(() => {
    let isMounted = true;
    getByPatientPaginated(Number(patientId), {
      limit: 1,
      page: 0,
      orderBy: 'updatedAt',
      order: 'desc',
      isDraft: false,
    }).then((patientInterviews) => {
      if (isMounted) setIsAllowedToCreate(patientInterviews?.total > 0);
    });

    return () => (isMounted = false);
  }, []);

  const finalDate = format(new Date(), 'yyyy-MM-dd');

  const fetchAddress = (cep: string) => {
    if (!formikRef || !formikRef.current) return;
    getAddress(cep)
      .then((data) => {
        const { erro, uf, localidade, bairro, logradouro } = data;

        if (!erro && formikRef.current) {
          formikRef.current.setFieldValue('province', uf);
          formikRef.current.setFieldValue('city', localidade);
          formikRef.current.setFieldValue('district', bairro);
          formikRef.current.setFieldValue('streetName', logradouro);
        }
      })
      .catch(() => {
        toast.error('CEP inválido');
      });
  };

  // eslint-disable-next-line
  const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
    if (!formikRef || !formikRef.current) return;
    formikRef.current.setFieldValue('postalCode', e.target.value);
    if (e.target.value.length === 9) {
      fetchAddress(e.target.value);
    }
  };

  return (
    <Formik
      innerRef={innerRef}
      onSubmit={onSubmit}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, isSubmitting }) => {
        const handleSelectedCustomLabelChange = (
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          setFieldValue('labelPreference', e.target.value as CustomLabel);
          setSelectedCustomLabel(e.target.value as CustomLabel);
        };
        return (
          <Form noValidate={true}>
            <MedicBalloon text="Coloque nos campos abaixo as informações da pessoa sob cuidado." />
            <ContentContainer>
              <ProfilePicture
                onChange={(file) => setFieldValue('picture', file)}
                initialImageUrl={initialPictureUrl}
              />
              <InterviewCardContainer>
                <RegistrationDashboardCard
                  color={appFPalette.secondary.main}
                  itemQuantity={Number(isAllowedToCreate)}
                  title="Perfil de saúde"
                  visualizationRoute="/entrevista/visualizar"
                  creationRoute="/entrevista/responder"
                  isAllowedToCreate={true}
                  statusMessage={false}
                />
              </InterviewCardContainer>
              {!!editingPatient && <PatientStatus />}
              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Dados de identificação
                </StyledBoldTitle>
                <FormikTextField
                  color="secondary"
                  id="name"
                  inputProps={{ 'data-testid': 'name' }}
                  name="name"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Nome do idoso"
                />
                <StyledSelect
                  label="Gênero"
                  name="gender"
                  SelectDisplayProps={{
                    'data-testid': 'gender',
                  }}
                  color="secondary"
                  size="small"
                  disabled={disabled}
                >
                  {genderOptions.map((item) => (
                    <StyledMenuItem
                      key={item.value}
                      value={item.value}
                      color="secondary"
                    >
                      {item.label}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledKeyboardDatePicker
                  name="dateOfBirth"
                  label="Data de nascimento"
                  value={values.dateOfBirth}
                  onChange={(date: Date | null) => {
                    setFieldValue('dateOfBirth', date, true);
                  }}
                  placeholder="dd/mm/aaaa"
                  color="secondary"
                  margin="none"
                  inputProps={{
                    'data-testid': 'dateOfBirth',
                  }}
                  InputLabelProps={{ shrink: true }}
                  maxDate={finalDate}
                  borderType="flat"
                />
                <FormikTextField
                  color="secondary"
                  id="cpf"
                  inputProps={{ 'data-testid': 'cpf' }}
                  name="cpf"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="CPF"
                  type="tel" // numeric keyboard without parsing to number
                  value={cpfMask(values.cpf)}
                />
                <FormikTextField
                  color="secondary"
                  id="phoneNumber"
                  inputProps={{ 'data-testid': 'phoneNumber' }}
                  name="phoneNumber"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Telefone"
                  type="tel" // numeric keyboard without parsing to number
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('phoneNumber', phoneMask(e.target.value));
                  }}
                />
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Endereço (opcional)
                </StyledBoldTitle>
                <PostalCodeContainer>
                  <FormikTextField
                    color="secondary"
                    id="postalCode"
                    inputProps={{ 'data-testid': 'postalCode' }}
                    name="postalCode"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    label="CEP"
                    value={values.postalCode && cepMask(values.postalCode)}
                    onChange={handlePostalCodeChange}
                  />
                  <StyledSelect
                    color="secondary"
                    id="province"
                    SelectDisplayProps={{
                      'data-testid': 'province',
                    }}
                    name="province"
                    label="UF"
                    size="small"
                    disabled={loadingAddressData || disabled}
                  >
                    {provincesOptions.map((province) => (
                      <StyledMenuItem
                        key={province}
                        value={province}
                        color="secondary"
                      >
                        {province}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </PostalCodeContainer>
                <FormikTextField
                  color="secondary"
                  id="city"
                  inputProps={{ 'data-testid': 'city' }}
                  name="city"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Cidade"
                  disabled={loadingAddressData}
                />
                <FormikTextField
                  color="secondary"
                  id="district"
                  inputProps={{ 'data-testid': 'district' }}
                  name="district"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Bairro"
                  disabled={loadingAddressData}
                />
                <FormikTextField
                  color="secondary"
                  id="streetName"
                  inputProps={{ 'data-testid': 'streetName' }}
                  name="streetName"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Rua"
                  disabled={loadingAddressData}
                />
                <FormikTextField
                  color="secondary"
                  id="streetNumber"
                  inputProps={{ 'data-testid': 'streetNumber' }}
                  name="streetNumber"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Número"
                />
                <FormikTextField
                  color="secondary"
                  id="streetComplement"
                  inputProps={{ 'data-testid': 'streetComplement' }}
                  name="streetComplement"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  label="Complemento"
                />
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Como gosta de ser chamado?
                </StyledBoldTitle>
                <RadioGroup
                  aria-label="labelPreference"
                  name="labelPreference"
                  onChange={handleSelectedCustomLabelChange}
                  value={selectedCustomLabel}
                >
                  {Object.values(CustomLabel).map((label, index) => (
                    <StyledFormControlLabel
                      key={index}
                      value={label}
                      control={<Radio size="small" />}
                      label={label}
                    />
                  ))}
                </RadioGroup>
                {selectedCustomLabel === CustomLabel.Nickname && (
                  <FormikTextField
                    color="secondary"
                    id="nickname"
                    inputProps={{ 'data-testid': 'nickname' }}
                    name="nickname"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    label="Apelido"
                  />
                )}
              </FormCardContainer>

              <Centralizer>
                <StyledButton
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={isSubmitting || !isAllowedToUpdate}
                >
                  Salvar
                </StyledButton>
              </Centralizer>
            </ContentContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PatientUpsertForm;
