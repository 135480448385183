import React from 'react';
import MedicBalloon from '../../components/MedicBalloon';
import RegistrationOnboardingCard from '../../components/RegistrationOnboardingCard';
import { Container } from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'

const Onboarding: React.FC = () => {
  return (
    <>
      <Header
        centerContent={
          <PageTitle title='Cadastro' />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <MedicBalloon
          text={
            'Olá, eu sou a Augusta! Vou auxiliar você durante o seu cadastro inicial no Cuidador de Confiança.\n\nPara começar, cadastre as informações da pessoa sob cuidado.'
          }
        />
        <RegistrationOnboardingCard
          color="#725B53"
          title="Pessoa sob Cuidado"
          subTitle="Cadastre as informações da pessoa sob cuidado."
          registrationPSCRoute="/cadastro-psc"
        />
      </Container>
    </>
  );
};

export default Onboarding;
