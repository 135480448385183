import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FilterChipMultiselect,
  FilterChipMultiselectProps,
} from '../../../../components/FilterChip/FilterChipMultiselect';
import IconMedication from '../../../../assets/Icon-flat-medication.svg';
import { CareHistoryFilterContext } from '../../../../contexts/CareHistoryFilter';
import { AuthContext } from '../../../../contexts/auth';
import { formatMedicationSubCategoriesIntoOptions } from './utils';

type MedicationFilterChipMultiselectProps = Pick<
  FilterChipMultiselectProps,
  'active' | 'onClick'
>;

export const MedicationFilterChipMultiselect = ({
  ...props
}: MedicationFilterChipMultiselectProps) => {
  const [medicationOptions, setMedicationOptions] = useState<
    {
      id: number;
      label: string;
      checked: boolean;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const { useEventCareHistory } = useContext(CareHistoryFilterContext);
  const {
    params,
    setParams,
    getEventSubCategoriesRelatedToPatientCategory,
  } = useEventCareHistory;

  const { userInfo } = useContext(AuthContext);

  const patientId = userInfo?.activePatientId as number;

  useEffect(() => {
    setLoading(true);
    getEventSubCategoriesRelatedToPatientCategory(patientId, 'medication')
      .then((data) => {
        if (!data) return;
        const formattedOptions = formatMedicationSubCategoriesIntoOptions(data);

        const { medicationSubCategoryIds } = params;

        setMedicationOptions(
          formattedOptions.map((medication) => ({
            ...medication,
            checked: medicationSubCategoryIds.includes(String(medication.id)),
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [setMedicationOptions]);

  const appySelectionToQuery = useCallback(() => {
    const selectedIds = medicationOptions
      .filter((option) => !!option.checked)
      .map((option) => String(option.id));

    setParams('medicationSubCategoryIds', selectedIds);
  }, [medicationOptions]);

  /**
   * Updates the whole state but now the option which id was informed
   *  has a toggled `checked` property.
   */
  const toggleOption: FilterChipMultiselectProps['toggleOption'] = (id) => {
    setMedicationOptions((medicationOptions) =>
      medicationOptions.map(({ checked, ...option }) => ({
        ...option,
        checked: option.id === id ? !checked : checked,
      }))
    );
  };

  return (
    <FilterChipMultiselect
      {...props}
      label="Medicamentos"
      icon={IconMedication}
      variant="blue"
      options={medicationOptions}
      toggleOption={toggleOption}
      title="Medicamentos"
      onFinishOptionsSelection={appySelectionToQuery}
      loading={loading}
      countMarkerAmount={params.medicationSubCategoryIds?.length}
      testid="filter-chip-multiselect-medications"
    />
  );
};
