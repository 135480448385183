import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FilterChipMultiselect,
  FilterChipMultiselectProps,
} from '../../../../components/FilterChip/FilterChipMultiselect';
import IconAppointment from '../../../../assets/Icon-flat-appointment.svg';
import { CareHistoryFilterContext } from '../../../../contexts/CareHistoryFilter';
import { AuthContext } from '../../../../contexts/auth';
import { formatGenericSubCategoriesIntoOptions } from './utils';

type AppointmentFilterChipMultiselectProps = Pick<
  FilterChipMultiselectProps,
  'active' | 'onClick'
>;

export const AppointmentFilterChipMultiselect = ({
  ...props
}: AppointmentFilterChipMultiselectProps) => {
  const [appointmentOptions, setAppointmentOptions] = useState<
    {
      id: number;
      label: string;
      checked: boolean;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const { useEventCareHistory } = useContext(CareHistoryFilterContext);
  const {
    params,
    setParams,
    getEventSubCategoriesRelatedToPatientCategory,
  } = useEventCareHistory;

  const { userInfo } = useContext(AuthContext);

  const patientId = userInfo?.activePatientId as number;

  useEffect(() => {
    setLoading(true);
    getEventSubCategoriesRelatedToPatientCategory(patientId, 'appointment')
      .then((data) => {
        if (!data) return;
        const formattedOptions = formatGenericSubCategoriesIntoOptions(data);

        const { appointmentSubCategoryIds } = params;

        setAppointmentOptions(
          formattedOptions.map((appointment) => ({
            ...appointment,
            checked: appointmentSubCategoryIds.includes(String(appointment.id)),
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [setAppointmentOptions]);

  const appySelectionToQuery = useCallback(() => {
    const selectedIds = appointmentOptions
      .filter((option) => !!option.checked)
      .map((option) => String(option.id));

    setParams('appointmentSubCategoryIds', selectedIds);
  }, [appointmentOptions]);

  /**
   * Updates the whole state but now the option which id was informed
   *  has a toggled `checked` property.
   */
  const toggleOption: FilterChipMultiselectProps['toggleOption'] = (id) => {
    setAppointmentOptions((appointmentOptions) =>
      appointmentOptions.map(({ checked, ...option }) => ({
        ...option,
        checked: option.id === id ? !checked : checked,
      }))
    );
  };

  return (
    <FilterChipMultiselect
      {...props}
      label="Compromissos"
      icon={IconAppointment}
      variant="green"
      options={appointmentOptions}
      toggleOption={toggleOption}
      title="Compromissos"
      onFinishOptionsSelection={appySelectionToQuery}
      loading={loading}
      countMarkerAmount={params.appointmentSubCategoryIds?.length}
      testid="filter-chip-multiselect-appointments"
    />
  );
};
