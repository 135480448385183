import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MedicationUpsertForm from '../../../components/MedicationUpsertForm';
import {
  formDataToMedicationModel,
  FormValues,
  medicationModelToFormValues,
} from '../../../components/MedicationUpsertForm/utils';
import { AuthContext } from '../../../contexts/auth';
import useMedication from '../../../hooks/useMedication';
import { resolveErrorMessage } from '../../../utils/error';
import { Container } from './styles';
import { Header } from '../../../components/Header'
import { BackButton } from '../../../components/BackButton'
import { PageTitle } from '../../../components/PageTitle'
import { PscButton } from '../../../components/PscButton'

const formInitialValue = {
  dosageFormat: undefined,
  dosageQuantity: undefined,
  administeredBy: undefined,
  subCategoryId: undefined,
  eventSchedule: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
    frequencyRule: '',
    frequencyStartsAt: '',
  },
};

const MedicationInsert: React.FC = () => {
  const [page, setPage] = useState(1);
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { post } = useMedication();
  const history = useHistory();

  const handleSubmit = (values: FormValues) => {
    return post(formDataToMedicationModel(values, patientId!))
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handlePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton
            action={
              page !== 1
              ? () => handlePage(1)
              : undefined
            }
          />
        }
        centerContent={
          <PageTitle title='Adicionar Medicamento' />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <MedicationUpsertForm
          initialValues={medicationModelToFormValues(formInitialValue)}
          onSubmit={handleSubmit}
          page={page}
          handlePage={handlePage}
        />
      </Container>
    </>
  );
};

export default MedicationInsert;
