import { Button as TextButton, DialogContent } from '@material-ui/core';
import styled from 'styled-components';
import StyledButton from '../StyledButton';

export const CenterLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTextButton = styled(TextButton)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledWhiteButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.primary.main};
    opacity: 65%;
    transition: 0.3s;
  }
`;
