import { Tabs } from '@material-ui/core';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  & .PrivateTabIndicator-colorSecondary-11 {
    width: 120px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  color: ${({ theme }) => theme.palette.common.black};

  & .Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default StyledTabs;
