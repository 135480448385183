import { Chip } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const DefaultStyledChip = styled(Chip)`
  margin-inline: ${({ theme }) => theme.spacing(0.5)}px;
  margin-block: ${({ theme }) => theme.spacing(0.5)}px;
  cursor: pointer;
`;

export const StyledBlueChip = styled(DefaultStyledChip)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.dark};

  & svg path {
    stroke: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledWhiteChip = styled(DefaultStyledChip)`
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
`;
