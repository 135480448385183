import { Radio, RadioGroup } from '@material-ui/core';
import { format, parse } from 'date-fns';
import React from 'react';
import {
  FormCardContainer,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../../../FormCardContainer';
import StyledFormControlLabel from '../../../StyledFormControlLabel';
import { FormValues } from '../utils';
import { StyledRow } from './styles';

interface PeriodSelectorProps {
  id?: number;
  values: FormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void;
  startDate?: string;
  disabled?: boolean;
}

export enum PeriodType {
  Continuously = 'Continuamente',
  DeterminedTime = 'Por tempo determinado',
  AsNeeded = 'Apenas se necessário',
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
  id,
  values,
  setFieldValue,
  startDate: initialStartDate,
  disabled,
}) => {
  const initialDate = format(new Date(), 'yyyy-MM-dd');
  const finalDate = format(new Date('12/31/9999'), 'yyyy-MM-dd');

  const isEdit = !!id;
  const today = new Date();
  const startDate = parse(
    `${initialStartDate} 00:00`,
    'yyyy-MM-dd HH:mm',
    new Date()
  );

  const isDateDisabled = isEdit && today >= startDate;

  return (
    <FormCardContainer>
      <StyledBoldTitle variant="subtitle2">Período</StyledBoldTitle>
      <RadioGroup
        aria-label="custom-interval"
        name="periodOptions"
        value={values.periodType}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('periodType', e.target.value);

          setFieldValue('ifNecessary', e.target.value === PeriodType.AsNeeded);
        }}
      >
        {Object.values(PeriodType).map((label, index) => (
          <StyledFormControlLabel
            key={index}
            value={label}
            control={<Radio size="small" />}
            label={label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {values.periodType !== PeriodType?.AsNeeded && (
        <StyledRow>
          <StyledBoldTitle variant="subtitle2">Data de início</StyledBoldTitle>
          <FormikTextField
            disabled={isDateDisabled || disabled}
            value={values?.frequencyStartsAt}
            color="secondary"
            id="frequencyStartsAt"
            inputProps={{
              'data-testid': 'frequencyStartsAt',
              min: initialDate,
              max: finalDate,
            }}
            name="frequencyStartsAt"
            autoComplete="off"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </StyledRow>
      )}
      {values.periodType === PeriodType?.DeterminedTime && (
        <StyledRow>
          <StyledBoldTitle variant="subtitle2">Data de fim</StyledBoldTitle>
          <FormikTextField
            value={values?.frequencyEndsAt}
            color="secondary"
            id="frequencyEndsAt"
            inputProps={{
              'data-testid': 'frequencyEndsAt',
              min: initialDate,
              max: finalDate,
            }}
            name="frequencyEndsAt"
            autoComplete="off"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            disabled={disabled}
          />
        </StyledRow>
      )}
    </FormCardContainer>
  );
};

export default PeriodSelector;
