import { Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-right: auto;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: 1.25em;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledIconButton = styled(IconButton)`
  position: fixed;
  right: 0;
  top: 0;
`;

export const LargeCloseIcon = styled(CloseIcon)`
  font-size: 2em;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;
