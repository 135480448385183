import { ShiftAnnullationModel, ShiftExecutionModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resolveErrorMessage } from '../../utils/error';
import { StyledFormikTextField } from '../FormCardContainer';
import StyledButton from '../StyledButton';
import StyledSimpleModal from '../StyledSimpleModal';
import { ButtonContainer, Centralizer, Form } from './styles';

type ModalProps = {
  onClose: () => void;
  opened: boolean;
  execution: ShiftExecutionModel;
  onSubmit: (
    executionId: number,
    data: Partial<ShiftAnnullationModel>
  ) => Promise<void>;
};

const RestoreAnnulledShift: React.FC<ModalProps> = ({
  onClose,
  opened,
  execution,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submit = async () => {
    setIsSubmitting(true);
    return onSubmit(Number(execution.id), {
      isAnnulled: false,
      description: '',
    })
      .then(() => {
        toast.success('Execução restaurada com sucesso.');
        onClose();
      })
      .catch((err: AxiosError<APIError>) => {
        toast.error(resolveErrorMessage(err));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <StyledSimpleModal open={opened}>
      <Centralizer>
        <WarningIcon fontSize="large" />
        <Typography variant="subtitle1" align="center">
          Tem certeza que deseja restaurar <b>esta execução</b>?
        </Typography>
        <ButtonContainer>
          <StyledButton size="medium" color="inherit" onClick={onClose}>
            Voltar
          </StyledButton>
          <StyledButton
            size="medium"
            color="inherit"
            onClick={submit}
            disabled={isSubmitting}
          >
            Confirmar
          </StyledButton>
        </ButtonContainer>
      </Centralizer>
    </StyledSimpleModal>
  );
};

const AnnullableShift: React.FC<ModalProps> = ({
  onClose,
  opened,
  execution,
  onSubmit,
}) => {
  interface FormValues {
    description?: string;
  }

  const formInitialValues: FormValues = {
    description: '',
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .required('Por favor, insira uma justificativa.')
      .max(4000, 'A justificativa não pode ter mais de 4000 caracteres.'),
  });

  const submit = async (values: FormValues) => {
    return onSubmit(Number(execution.id), {
      ...values,
      isAnnulled: true,
    })
      .then(() => {
        toast.success('Execução anulada com sucesso.');
        onClose();
      })
      .catch(() => {
        toast.error('Erro ao anular a execução.');
      });
  };

  return (
    <StyledSimpleModal open={opened}>
      <Centralizer>
        <WarningIcon fontSize="large" />
        <Typography variant="subtitle1" align="center">
          Tem certeza que deseja anular <b>esta execução</b>?
        </Typography>
        <Typography variant="subtitle1" align="center">
          Para confirmar, digite a justificativa e clique em <b>Confirmar</b>
        </Typography>
        <Formik
          initialValues={formInitialValues}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ isSubmitting, values }) => (
            <Form noValidate={true}>
              <StyledFormikTextField
                placeholder="Digite aqui sua justificativa"
                color="secondary"
                id="description"
                inputProps={{
                  'data-testid': 'description',
                  maxLength: 4000,
                }}
                name="description"
                margin="normal"
                autoComplete="off"
                multiline
                rows={4}
              />
              <ButtonContainer>
                <StyledButton size="medium" color="inherit" onClick={onClose}>
                  Voltar
                </StyledButton>
                <StyledButton
                  size="medium"
                  color="inherit"
                  type="submit"
                  disabled={isSubmitting || isEqual(formInitialValues, values)}
                  data-testid="submit"
                >
                  Confirmar
                </StyledButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Centralizer>
    </StyledSimpleModal>
  );
};

const AnnullableShiftExecutionModal: React.FC<ModalProps> = ({
  opened,
  onClose,
  execution,
  onSubmit,
}) => {
  return execution?.isAnnulled ? (
    <RestoreAnnulledShift
      onClose={onClose}
      opened={opened}
      execution={execution}
      onSubmit={onSubmit}
    />
  ) : (
    <AnnullableShift
      onClose={onClose}
      opened={opened}
      execution={execution}
      onSubmit={onSubmit}
    />
  );
};

export default AnnullableShiftExecutionModal;
