import React from 'react'
import PscIcon from '../Icons/PscIcon'
import { useHistory } from 'react-router-dom'
import { StyledIconButton } from '../StyledIconButton'

export function PscButton () {
  const history = useHistory()

  return (
    <StyledIconButton
      icon={<PscIcon />}
      action={() => history.push('/gestao/pacientes')}
      backgroundColor='white'
    />
  )
}
