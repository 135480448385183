import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MeasurementScheduleForm, {
  FormValues,
} from '../../../components/MeasurementScheduleForm';
import { formDataToEventScheduleModel } from '../../../components/MeasurementScheduleForm/utils/parseFormDataToModel';
import { eventScheduleModelToFormData } from '../../../components/MeasurementScheduleForm/utils/parseModelToFormData';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useMeasurement from '../../../hooks/useMeasurement';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress } from './styles';
import { Header } from '../../../components/Header'
import { BackButton } from '../../../components/BackButton'
import { PageTitle } from '../../../components/PageTitle'
import { RemoveButton } from '../../../components/RemoveButton'

const UpdateMeasurementSchedule: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;
  const params = useParams<{ id: string }>();
  const measurementScheduleId = parseInt(params.id);
  const history = useHistory();
  const { loading, patch, getById, byId, remove } = useMeasurement();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { isAllowedToDelete, isAllowedToUpdate } = useCanAccess(
    'care/event/schedule'
  );

  const measurementScheduleData =
    measurementScheduleId != null ? byId[measurementScheduleId] : null;

  const handleSubmit = async (values: FormValues) => {
    if (!patientId) return;
    const defaultValues = handleFormValuesWithDefaultStartDate(values);
    const formattedData = formDataToEventScheduleModel(
      defaultValues,
      patientId
    );
    return patch(measurementScheduleId, formattedData)
      .then(() => {
        toast.success('Medição editada com sucesso!');
        history.push('/controles');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleFormValuesWithDefaultStartDate = (
    data: FormValues
  ): FormValues => {
    return { ...data, frequencyStartsAt: format(new Date(), 'yyyy-MM-dd') };
  };

  const handleDelete = (id: Id) => {
    setIsDeleteDialogOpen(false);
    remove(id)
      .then(() => {
        toast.success('Agendamento de controle excluída com sucesso!');
        history.push('/controles');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const fetchMeasurementSchedule = async () => {
    if (measurementScheduleId) {
      getById(measurementScheduleId);
    }
  };

  useEffect(() => {
    fetchMeasurementSchedule();
  }, []);

  return (
    <>
      <Header
        leftContent={
          <BackButton action={() => history.push('/controles')} />
        }
        centerContent={
          <PageTitle title='Editar Controle' />
        }
        rightContent={
          params.id && isAllowedToDelete && (
            <RemoveButton action={() => setIsDeleteDialogOpen(true)} />
          )
        }
      />
      {loading ? (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      ) : (
        measurementScheduleData && (
          <>
            <StyledFieldset disabled={!isAllowedToUpdate}>
              <MeasurementScheduleForm
                initialValues={eventScheduleModelToFormData(
                  measurementScheduleData
                )}
                onSubmit={handleSubmit}
                id={measurementScheduleId}
              />
            </StyledFieldset>
            <StyledSimpleDialog
              open={isDeleteDialogOpen}
              handleNo={() => setIsDeleteDialogOpen(false)}
              handleYes={() => handleDelete(params.id!)}
              title="Remover agendamento de controle"
              subTitle="Tem certeza que deseja remover este controle?"
            />
          </>
        )
      )}
    </>
  );
};

export default UpdateMeasurementSchedule;
