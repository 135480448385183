import { PatientModel } from '@cuidador/database';
import * as yup from 'yup';
import {
  cepMask,
  numberMask,
  phoneMask,
  validateCpf,
} from '../../utils/inputs';
import { format } from 'date-fns';

export enum CustomLabel {
  Name = 'Pelo próprio nome',
  Nickname = 'Pelo apelido',
}

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  cpf: yup
    .string()
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      validateCpf(value || '')
    ),
  gender: yup
    .string()
    .required('Por favor, insira o sexo')
    .oneOf(['male', 'female', 'other']),
  phoneNumber: yup
    .string()
    .min(14, 'Por favor, insira um telefone válido')
    .max(15, 'Por favor, insira um telefone válido'),
  dateOfBirth: yup
    .date()
    .required('Por favor, insira a data de nascimento')
    .max(new Date(), 'Por favor, insira uma data anterior a hoje')
    .nullable()
    .typeError('Por favor, insira uma data válida'),
  nickname: yup.string().when('labelPreference', {
    is: CustomLabel.Nickname,
    then: yup.string().max(124).required('Por favor, insira um apelido'),
  }),
  postalCode: yup.string(),
  province: yup.string(),
  city: yup.string(),
  district: yup.string(),
  streetName: yup.string(),
  streetNumber: yup.string(),
  streetComplement: yup.string(),
});

export type FormValues = {
  picture: File | null;
  name: string;
  cpf: string;
  gender: '' | 'male' | 'female' | 'other';
  phoneNumber: string;
  dateOfBirth: string;
  nickname: string;
  labelPreference: CustomLabel;
  initialImageUrl?: string;
  postalCode?: string;
  province?: string;
  city?: string;
  district?: string;
  streetName?: string;
  streetNumber?: string;
  streetComplement?: string;
};

export const provincesOptions = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const genderOptions = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' },
  { value: 'other', label: 'N/A' },
];

export const initialValues: FormValues = {
  picture: null,
  name: '',
  cpf: '',
  gender: '',
  phoneNumber: '',
  dateOfBirth: '',
  nickname: '',
  labelPreference: CustomLabel.Name,
  postalCode: '',
  province: '',
  city: '',
  district: '',
  streetName: '',
  streetNumber: '',
  streetComplement: '',
};

export const formDataToPatientModel = (values: FormValues) =>
  ({
    name: values.name,
    cpf: numberMask(values.cpf),
    gender: values.gender,
    phoneNumber: values.phoneNumber
      ? numberMask(`55${values.phoneNumber}`)
      : undefined,
    dateOfBirth: format(new Date(values.dateOfBirth), 'yyyy-MM-dd'),
    nickname:
      values.labelPreference === CustomLabel.Nickname ? values.nickname : '',
    postalCode: values.postalCode ? cepMask(values.postalCode) : undefined,
    province: values.province,
    city: values.city,
    district: values.district,
    streetName: values.streetName,
    streetNumber: values.streetNumber,
    streetComplement: values.streetComplement,
  } as PatientModel);

export const patientModelToFormData = (patient: PatientModel): FormValues => {
  return {
    picture: null,
    name: patient.name || '',
    cpf: patient.cpf || '',
    gender: patient.gender || '',
    phoneNumber: phoneMask(patient?.phoneNumber?.slice(2) || ''),
    dateOfBirth: patient.dateOfBirth || '',
    nickname: patient.nickname || '',
    labelPreference: patient.nickname ? CustomLabel.Nickname : CustomLabel.Name,
    postalCode: patient.postalCode || '',
    province: patient.province || '',
    city: patient.city || '',
    district: patient.district || '',
    streetName: patient.streetName || '',
    streetNumber: patient.streetNumber || '',
    streetComplement: patient.streetComplement || '',
  };
};
