import { useContext } from 'react'
import { AuthContext } from '../contexts/auth'
import { MedicationModel } from '@cuidador/database'
import { toast } from 'react-toastify'
import { resolveDosage } from '../components/MedicationCard/utils'
import { format, formatISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export function useSafepill () {
  const { userInfo } = useContext(AuthContext)

  const baseWhatsappUrl = 'https://api.whatsapp.com/send'
  const safepillPhone = '5511966467873'
  const patientName = userInfo?.activePatient?.name

  const createRequest = (medications: MedicationModel[]) => {
    try {
      if (!userInfo?.user?.name) {
        toast.error('Não foi possível solicitar o orçamento dos medicamentos, tente mais tarde.')
        throw new Error('Error on safepill request handler - User without name')
      }

      if (!medications || medications?.length === 0) {
        toast.error('É necessário ao menos um medicamento em uso para solicitar o orçamento.')
        throw new Error('Error on safepill request handler - empty medication list')
      }

      const medicationsList = medications.map((medication, index) => {
        const medicationName = medication.subCategory?.name
        const medicationDosage = resolveDosage(medication.dosageQuantity, medication.dosageFormat).slice(0, -1)
        const medicationDailyDose = medication.eventSchedule?.customTimes?.length

        const startDate = medication.eventSchedule?.frequencyStartsAt
        const endDate = medication.eventSchedule?.frequencyEndsAt

        const formattedPeriod = endDate
          ? `de ${format(new Date(startDate!), 'P', { locale: ptBR })} até ${format(new Date(endDate), 'P', { locale: ptBR })}.`
          : 'todos os dias.'

        const doseAndPeriod = medication.ifNecessary
          ? 'apenas se necessário.'
          : `${medicationDailyDose} vezes por dia, ${formattedPeriod}`

        return `${index + 1}) ${medicationName} - ${medicationDosage} - ${doseAndPeriod}`
      })
      .join('\n')

      const textToSend = encodeURI(
        `Olá, gostaria de comprar a medicação em uso para ${patientName}. Agradeço o envio do orçamento e condição de entrega!\n${medicationsList}`
      )

      const url = `${baseWhatsappUrl}?phone=${safepillPhone}&text=${textToSend}`

      window.open(url, '_blank', 'noreferrer')
      window.focus()

      toast.success('Orçamento solicitado.')
    } catch (err: any) {
      if (err instanceof Error && err.message === 'Network Error') {
        toast.error('Você está sem internet no momento, tente mais tarde.')
      } else {
        toast.error('Não foi possível solicitar o orçamento dos medicamentos, tente mais tarde.')
      }

      throw err;
    }
  }

  return {
    createRequest
  }
}
