import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import React from 'react';

import { ReactComponent as IconCheck } from '../../assets/Icon-check-circle.svg';
import useCanAccess from '../../hooks/useCanAccess';
import MenuItem from './MenuItem';
import { Container, StyledPhoneIcon } from './styles';

const Tabbar: React.FC = () => {
  const { isAllowedToRead: isAllowedToReadShoppingList } = useCanAccess(
    'care/shopping-list'
  );
  const { isAllowedToRead: isAllowedToReadEmergencyContacts } = useCanAccess(
    'care/emergency-contact'
  );
  return (
    <Container>
      <MenuItem to="/acompanhar">
        <IconCheck />
        Cuidados
      </MenuItem>
      <MenuItem to="/cadastros">
        <WatchLaterIcon /> Cadastros
      </MenuItem>
      {isAllowedToReadShoppingList && (
        <MenuItem to="/compras">
          <ShoppingCartIcon /> Compras
        </MenuItem>
      )}
      {isAllowedToReadEmergencyContacts && (
        <MenuItem to="/contatos">
          <StyledPhoneIcon /> Me ajuda
        </MenuItem>
      )}
    </Container>
  );
};

export default Tabbar;
