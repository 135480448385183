import React, { useMemo } from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import {
  ButtonContainer,
  Container,
  PreviousButton,
  ShiftDataContainer,
} from './styles';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';

interface PreviousOrNextShiftProps {
  plannedToStartAt?: string;
  shiftIds: number[];
}

const PreviousOrNextShift: React.FC<PreviousOrNextShiftProps> = ({
  plannedToStartAt,
  shiftIds,
}) => {
  const params = useParams<{ id: string }>();
  const currentShiftId = parseInt(params.id);
  const history = useHistory();
  const getShiftPlannedStartDate = () => {
    const shiftPlannedStartDate = plannedToStartAt
      ? format(new Date(plannedToStartAt), 'dd/MM/yyyy')
      : '--/--/----';
    return shiftPlannedStartDate;
  };

  const goToPreviousShift = () => {
    const previousShiftId = shiftIds[shiftIds.indexOf(currentShiftId) + 1];
    history.push(`/historico/plantao/${previousShiftId}`);
  };

  const goToNextShift = () => {
    const nextShiftId = shiftIds[shiftIds.indexOf(currentShiftId) - 1];
    history.push(`/historico/plantao/${nextShiftId}`);
  };

  const isPreviousShiftButtonDisabled = useMemo(() => {
    const previousShiftId = shiftIds[shiftIds.indexOf(currentShiftId) + 1];
    if (!previousShiftId) {
      return true;
    }
    return false;
  }, [shiftIds, currentShiftId]);

  const isNextShiftButtonDisabled = useMemo(() => {
    const nextShiftId = shiftIds[shiftIds.indexOf(currentShiftId) - 1];
    if (!nextShiftId) {
      return true;
    }
    return false;
  }, [shiftIds, currentShiftId]);

  return (
    <Container>
      <ButtonContainer>
        <PreviousButton
          onClick={goToPreviousShift}
          disabled={isPreviousShiftButtonDisabled}
          data-testid="previous-shift-button"
        >
          <ChevronLeft />
        </PreviousButton>
      </ButtonContainer>

      <ShiftDataContainer>
        <span>{getShiftPlannedStartDate()}</span>
      </ShiftDataContainer>

      <ButtonContainer>
        <PreviousButton
          onClick={goToNextShift}
          disabled={isNextShiftButtonDisabled}
        >
          <ChevronRight />
        </PreviousButton>
      </ButtonContainer>
    </Container>
  );
};

export default PreviousOrNextShift;
