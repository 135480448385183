import React from 'react';
import { ShiftExecution } from '@cuidador/database';
import { handleFormatDate, handleFormatTime } from '../utils';
import {
  OriginalSchedulesContainer,
  CardContainer,
  CardItemJustify,
  CardItem,
  CardTimeText,
  StyledSubtitle,
  StyledCreatedBy,
  StyledCalendarIcon,
  StyledClockIcon,
} from '../styles';

interface OriginalSchedulesProps {
  shiftExecution: ShiftExecution;
}

const OriginalSchedules: React.FC<OriginalSchedulesProps> = ({
  shiftExecution,
}) => {
  const dateStartedAt = handleFormatDate(shiftExecution.registeredStartedAt!);
  const timeStartedAt = handleFormatTime(shiftExecution.registeredStartedAt!);

  const dateEndedAt = handleFormatDate(shiftExecution.registeredEndedAt!);
  const timeEndedAt = handleFormatTime(shiftExecution.registeredEndedAt!);

  return (
    <OriginalSchedulesContainer>
      <CardContainer>
        <CardItemJustify>
          <CardItem>
            <StyledSubtitle>Início registrado</StyledSubtitle>
            <CardTimeText data-testid="registered-date-started-at">
              <StyledCalendarIcon /> {dateStartedAt}
            </CardTimeText>
            <CardTimeText data-testid="registered-time-started-at">
              <StyledClockIcon /> {timeStartedAt}
            </CardTimeText>
          </CardItem>
          <CardItem>
            <StyledSubtitle>Fim registrado</StyledSubtitle>
            <CardTimeText data-testid="registered-date-ended-at">
              <StyledCalendarIcon /> {dateEndedAt}
            </CardTimeText>
            <CardTimeText data-testid="registered-time-ended-at">
              <StyledClockIcon /> {timeEndedAt}
            </CardTimeText>
          </CardItem>
        </CardItemJustify>
        <CardItem>
          <StyledSubtitle>Responsável</StyledSubtitle>
          <StyledCreatedBy>
            {shiftExecution?.caregiver?.user?.name}
          </StyledCreatedBy>
        </CardItem>
      </CardContainer>
    </OriginalSchedulesContainer>
  );
};

export default OriginalSchedules;
