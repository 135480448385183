import { ShiftModel } from '@cuidador/database';
import format from 'date-fns/format';
import qs from 'query-string';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import ShiftsHistory from '../../../components/ShiftsHistory';
import StyledButton from '../../../components/StyledButton';
import { AuthContext } from '../../../contexts/auth';
import useShift from '../../../hooks/useShift';
import { Container } from './styles';

const History: React.FC = () => {
  const { getByPatientIdPaginated, total, loading, byId, ids } = useShift();
  const history = useHistory();

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;
  const [page, setPage] = useState(1);

  const fetchData = () => {
    const params = qs.parse(history.location.search);
    if (patientId) {
      getByPatientIdPaginated(patientId, {
        ...params,
        page: 0,
        limit: 50 * page,
        orderBy: 'plannedToStartAt', // nao seria por outra chave?
        order: 'DESC',
      }).catch((e) => {
        toast.error(e);
      });
    }
  };

  const groupShiftsByDate = (shifts: ShiftModel[]) => {
    /**
     * groupBy year + month, then group by day
     * Map<'YYYYMM', Map<'DD', ShiftModel>>
     */
    const map = new Map<string, Map<string, ShiftModel[]>>();
    for (const shift of shifts) {
      const mapKeyYYYYMM = format(
        new Date(String(shift.plannedToStartAt)),
        'yyyyMM'
      );
      const mapKeyDD = format(new Date(String(shift.plannedToStartAt)), 'dd');

      if (map.has(mapKeyYYYYMM)) {
        const innerMap = map.get(mapKeyYYYYMM)!;
        if (innerMap.has(mapKeyDD)) {
          const shifts = innerMap.get(mapKeyDD)!;
          if (!shifts.find((s) => s.id === shift.id)) {
            innerMap.set(mapKeyDD, [...shifts, shift]);
          }
        } else {
          innerMap.set(mapKeyDD, [shift]);
        }
      } else {
        map.set(
          mapKeyYYYYMM,
          new Map<string, ShiftModel[]>([[mapKeyDD, [shift]]])
        );
      }
    }
    return map;
  };

  const shifts = ids.map((id) => byId[id]);
  const groupedShifts = useMemo(() => groupShiftsByDate(shifts), [shifts]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const pageIncrement = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Container>
        <ShiftsHistory shifts={groupedShifts} onExpelCaregiver={fetchData} />
        {loading && <LoadingBackdrop loading />}
        {shifts.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="secondary"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
    </>
  );
};

export default History;
