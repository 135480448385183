import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardBox = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

export const AnswerLineTypography = styled(Typography)`
  line-height: 1.3;
`;

export const MultiAnswerUl = styled.ul`
  margin: 0;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
`;

export const MultiAnswerLi = styled(AnswerLineTypography)`
  list-style-type: disc;
  display: list-item;
`;
