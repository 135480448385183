import { addMinutes, isBefore } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import {
  FormCardContainer,
  HelperContainer,
  HelperText,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField,
} from '../FormCardContainer';
import MedicBalloon from '../MedicBalloon';
import { Centralizer } from '../ShiftScheduleUpsertForm/styles';
import StyledButton from '../StyledButton';
import StyledMenuItem from '../StyledMenuItem';
import { ContentContainer, Form } from './styles';
import {
  durationOptions,
  initialDate,
  initialTime,
  validationSchema,
} from './utils';

export interface FormValues {
  name?: string;
  date?: string;
  time?: string;
  duration?: string;
}

const AdditionalCoverageForm: React.FC<{
  isDisabled?: boolean;
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, innerRef, onSubmit, isDisabled }) => {
  const validateAndSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const date = new Date(values.date?.replace(/-/g, '/') || '');
    date.setHours(Number(values.time?.split(':')[0]) || 0);
    date.setMinutes(Number(values.time?.split(':')[1]) || 0);
    const datePlusDuration = addMinutes(date, Number(values.duration));
    const isInThePast = isBefore(datePlusDuration, new Date());

    if (isInThePast) {
      toast.dismiss();
      toast.error('O plantão deve terminar em um horário futuro.');
      formikHelpers.setSubmitting(false);
    } else {
      return await onSubmit(values, formikHelpers);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={validateAndSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {({ values, isSubmitting, errors, touched }) => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <MedicBalloon text="Em caso de hora-extra ou cobertura pontual de plantão que se faça necessária para realizar os cuidados, é aqui que deve ser cadastrada. Os cuidadores acessarão essa cobertura adicional da mesma maneira que entram em um plantão recorrente." />

            <ContentContainer>
              <FormCardContainer>
                <StyledBoldTitle>
                  Nome da hora-extra / cobertura
                </StyledBoldTitle>

                <StyledFormikTextField
                  color="secondary"
                  id="name"
                  inputProps={{ 'data-testid': 'name' }}
                  label="Nome"
                  variant="outlined"
                  placeholder="Nome"
                  name="name"
                  margin="normal"
                  autoComplete="off"
                  helperText={
                    <HelperContainer>
                      <HelperText>
                        Exemplo: Hora-extra sexta-feira, etc
                      </HelperText>
                      {touched.name && errors.name}
                    </HelperContainer>
                  }
                  size="small"
                />
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle>Data e horário de início</StyledBoldTitle>

                <StyledFormikTextField
                  variant="outlined"
                  label="Data"
                  color="secondary"
                  id="date"
                  inputProps={{
                    'data-testid': 'date',
                    min: initialDate,
                  }}
                  name="date"
                  autoComplete="off"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />

                <StyledFormikTextField
                  variant="outlined"
                  label="Horário"
                  color="secondary"
                  id="time"
                  inputProps={{ 'data-testid': 'time', min: initialTime }}
                  name="time"
                  margin="dense"
                  autoComplete="off"
                  type="time"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle>Duração</StyledBoldTitle>
                <StyledSelect
                  name="duration"
                  label="Duração"
                  SelectDisplayProps={{
                    'data-testid': 'duration-input',
                  }}
                  color="secondary"
                  size="small"
                >
                  {durationOptions.slice().map((item, index) => (
                    <StyledMenuItem
                      color="secondary"
                      key={index}
                      value={item.durationInMinutes}
                    >
                      {item.label}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </FormCardContainer>

              <Centralizer>
                <StyledButton
                  data-testid="submit-additional-coverage"
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={
                    isSubmitting || isEqual(initialValues, values) || isDisabled
                  }
                >
                  Salvar
                </StyledButton>
              </Centralizer>
            </ContentContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdditionalCoverageForm;
