import { EventModel } from '@cuidador/database';
import { format, parse } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import useSubCategory from '../../hooks/useSubCategory';
import {
  FormCardContainer,
  HelperContainer,
  HelperText,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../FormCardContainer';
import MedicBalloon from '../MedicBalloon';
import StyledButton from '../StyledButton';
import StyledMenuItem from '../StyledMenuItem';
import {
  Backdrop,
  BackdropCircularProgress,
  Centralizer,
  ContentContainer,
  Form,
} from './styles';

export interface FormValues {
  name?: string;
  description?: string;
  subCategoryId?: number;
  date?: string;
  time?: string;
  startDate?: string;
}

export const appointmentModelToFormData = (data: EventModel) => {
  let formattedDate;
  let formattedTime;

  if (data.eventHappensAt) {
    formattedDate = format(new Date(data.eventHappensAt), 'yyyy-MM-dd');
    formattedTime = format(new Date(data.eventHappensAt), 'HH:mm');
  }

  const startDate = format(new Date(), 'yyyy-MM-dd');

  return {
    name: data.name ? data.name.trim() : '',
    description: data.description || '',
    subCategoryId: data.subCategoryId || '',
    time: formattedTime || '',
    date: formattedDate || '',
    startDate: startDate || '',
  } as FormValues;
};

export const formDataToAppointmentModel = (
  data: FormValues,
  patientId: number
) => {
  const isoDate = parse(
    `${data.date} ${data.time}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  ).toISOString();

  return {
    name: data.name ? data.name.trim() : '',
    description: data.description || '',
    subCategoryId: data.subCategoryId || '',
    eventHappensAt: isoDate || '',
    patientId: patientId || '',
  } as FormValues;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, insira um nome.'),
  subCategoryId: Yup.string().required(
    'Por favor, insira o tipo do compromisso.'
  ),
  date: Yup.string().required('Por favor, insira a data.'),
  time: Yup.string().required('Por favor, insira a hora.'),
  description: Yup.string().max(2000, 'Deve conter no máximo 2000 caracteres.'),
});

const AppointmentsUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
  disabled?: boolean;
}> = ({ initialValues, innerRef, onSubmit, disabled }) => {
  const { getOnlyForAppointments, ids, byId, loading } = useSubCategory();

  useEffect(() => {
    getOnlyForAppointments();
  }, []);

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const finalDate = format(new Date('12/31/9999'), 'yyyy-MM-dd');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {({ values, isSubmitting, errors, touched }) => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <MedicBalloon text="Reserve a agenda para compromissos diversos como consultas de saúde, atividades pessoais, de lazer, aniversários, etc. Isso ajuda a organização do roteiro diário da pessoa sob cuidado." />
            <ContentContainer>
              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Selecione o tipo do compromisso e dê um nome para ele
                </StyledBoldTitle>

                <StyledSelect
                  variant="outlined"
                  label="Tipo de compromisso"
                  name="subCategoryId"
                  SelectDisplayProps={{
                    'data-testid': 'subcategory',
                  }}
                  color="secondary"
                  size="small"
                  value={values.subCategoryId || ''}
                  disabled={disabled}
                >
                  {ids
                    .map((id) => byId[id])
                    .map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.id}
                        color="secondary"
                      >
                        {item.name}
                      </StyledMenuItem>
                    ))}
                </StyledSelect>

                <FormikTextField
                  variant="outlined"
                  label="Nome do compromisso"
                  color="secondary"
                  id="name"
                  inputProps={{ 'data-testid': 'name' }}
                  placeholder="Nome do compromisso"
                  name="name"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                  helperText={
                    <HelperContainer>
                      <HelperText>
                        Exemplo: Dentista, Fisioterapeuta, etc.
                      </HelperText>
                      {touched.name && errors.name}
                    </HelperContainer>
                  }
                />
              </FormCardContainer>
              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Coloque a data e hora
                </StyledBoldTitle>
                <FormikTextField
                  variant="outlined"
                  placeholder="dd/mm/aaaa"
                  label="Data"
                  color="secondary"
                  id="date"
                  inputProps={{
                    'data-testid': 'eventHappensAt',
                    min: values.startDate,
                    max: finalDate,
                  }}
                  name="date"
                  margin="normal"
                  autoComplete="off"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
                <FormikTextField
                  variant="outlined"
                  label="Hora"
                  color="secondary"
                  id="time"
                  inputProps={{ 'data-testid': 'time' }}
                  name="time"
                  margin="normal"
                  autoComplete="off"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </FormCardContainer>
              <FormCardContainer>
                <StyledBoldTitle variant="subtitle2">
                  Instruções (Opcional)
                </StyledBoldTitle>

                <FormikTextField
                  variant="outlined"
                  label="Instruções"
                  placeholder="Opcional"
                  color="secondary"
                  id="description"
                  inputProps={{ 'data-testid': 'description' }}
                  name="description"
                  margin="normal"
                  autoComplete="off"
                  size="small"
                />
              </FormCardContainer>

              <Centralizer>
                <StyledButton
                  data-testid="submit-appointment"
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={isSubmitting || isEqual(initialValues, values)}
                >
                  Salvar
                </StyledButton>
              </Centralizer>
            </ContentContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AppointmentsUpsertForm;
