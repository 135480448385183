import { PatientModel } from '@cuidador/database';
import React, { useContext, useEffect, useState } from 'react';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import StyledButton from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import useReport from '../../hooks/useReport';
import ReportListItem from './ReportListItem';
import { Container, Title } from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { BackButton } from '../../components/BackButton'
import { PscButton } from '../../components/PscButton'

const ReportList: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { getByPatientPaginated, loading, total, ids, byId } = useReport();
  const [patient, setPatient] = useState<PatientModel>();
  const [page, setPage] = useState(0);

  const patientId = userInfo?.activePatientId;

  const onChangeParams = () => {
    if (!patientId) return;
    getByPatientPaginated(patientId, {
      page,
      limit: 10,
      orderBy: 'startDate',
      order: 'DESC',
    });
  };

  const handlePatientName = () => {
    const guardianPatientes = userInfo?.user?.patients;
    const patient = guardianPatientes?.find(
      (patient) => patient.id === patientId
    );
    setPatient(patient);
  };

  useEffect(() => {
    onChangeParams();
    handlePatientName();
  }, []);

  const pageIncrement = () => {
    setPage(page + 1);
  };

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title={`Relatórios ${patient?.name}`} />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <Title>
          Veja aqui o consolidado do mês. Você pode encaminhar para familiares e
          para médicos.
        </Title>
        {ids.length <= 0 ? (
          <Title>Não há nenhum relatório disponível.</Title>
        ) : (
          ids.map((id) => {
            const report = byId[id];
            return (
              <ReportListItem
                key={id}
                {...report}
                patientId={Number(patientId)}
              />
            );
          })
        )}
        {ids.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="default"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
    </>
  );
};

export default ReportList;
