import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { PatientEventFilter } from '../../hooks/usePatient'

export type MedicationStaturCardProps = {
  icon: string
  title?: string
  color: string
  value?: number
  isSmall?: boolean
  isLoading?: boolean
  onFilter?: (value?: string) => void
  filterType?: PatientEventFilter
  selected?: boolean
}

export default function MedicationStatusCard(props: MedicationStaturCardProps) {
  const { icon, title, color, value, isSmall, isLoading, onFilter, filterType, selected } = props

  if (isSmall) {
    const smallBoxCssStyle = {
      display: 'flex',
      width: '56px',
      height: '24px',
      backgroundColor: color,
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontFamily: 'Work Sans',
      borderRadius: 'var(--sm, 8px)',
    }

    return (
      <div style={smallBoxCssStyle}>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img style={{ width: '16px', height: '16px' }} src={icon} />
            <div
              style={{
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              }}
            >
              {isLoading && <CircularProgress color='inherit' size={14} />}
              {!isLoading && (value !== undefined ? value : '-')}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    const boxCssStyle = {
      display: 'flex',
      width: '88px',
      minWidth: '88px',
      height: '76px',
      padding: '8px',
      backgroundColor: color,
      gap: '8px',
      color: 'white',
      fontFamily: 'Work Sans',
      borderRadius: 'var(--sm, 8px)',
      cursor: isLoading ? 'wait' : 'pointer',
    }

    return (
      <div style={boxCssStyle} onClick={() => onFilter?.(String(filterType))}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              paddingTop: '2px',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ display: 'flex' }}>
              <img style={{ width: '24px', height: '24px' }} src={icon} />
              <div
                style={{
                  paddingLeft: '6px',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                {isLoading && <CircularProgress color='inherit' size={18} />}
                {!isLoading && (value !== undefined ? value : '-')}
              </div>
            </div>
            {selected && <img style={{ width: '24px', height: '24px' }} src='/images/Occurrences/times-svgrepo-com.svg' />}
          </div>
          <div
            style={{
              padding: '12px 0px 8px 0px',
              fontWeight: 300,
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            {title}
          </div>
        </div>
      </div>
    )
  }
}
