import { PlanModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib/error/error';
import PlanIcon from '@material-ui/icons/FavoriteBorder';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, FormikProps } from 'formik';
import { StyledFormikTextField } from '../../components/FormCardContainer';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import MedicBalloon from '../../components/MedicBalloon';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import usePlan from '../../hooks/usePlan';
import { initialValues, makeValidationSchema, FormValues } from './utils';
import { resolveErrorMessage } from '../../utils/error';
import {
  BoldTypography,
  CardBody,
  CardContent,
  CardHeader,
  ContentContainer,
  LeftAlignedTypography,
  RightAlignedTypography,
  StyledForm,
  StyledSubmitButton,
  InputLabel,
  InputRequired,
} from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'

const PlanCard: React.FC<{ plan: PlanModel }> = ({ plan }) => {
  return (
    <CardBody>
      <CardHeader>
        <PlanIcon />
        <BoldTypography>{plan.planCardTitle}</BoldTypography>
        {plan.isPromotional && (
          <RightAlignedTypography color="primary">
            Grátis
          </RightAlignedTypography>
        )}
      </CardHeader>
      <CardContent>
        <ul>
          <li>Limite de uma Pessoa Sob Cuidado cadastrada</li>
          <li>Acesso ilimitado a todas funcionalidades</li>
          <li>Cadastro ilimitado de cuidadores</li>
          <li>
            <strong>
              Válido por: {Math.round(Number(plan.trialPeriodDays) / 30)} meses
            </strong>
          </li>
        </ul>
      </CardContent>
    </CardBody>
  );
};

const SubscriptionActivation: React.FC = () => {
  const [plan, setPlan] = useState<PlanModel>();
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const { getById, signPromotionalPlan, loading } = usePlan();
  const { refreshUserInfo, promotionPlanId } = useContext(AuthContext);
  const history = useHistory();
  const { isAllowedToInvoke } = useCanAccess('user/plan/promotional.subscribe');

  const handleStartWithPlan = (values: FormValues) => {
    if (!promotionPlanId) return;
    signPromotionalPlan(promotionPlanId, {
      promotionalCode: values.promotionalCode,
    })
      .then(refreshUserInfo)
      .then(() => {
        toast.success('Plano assinado!');
        history.push('/onboarding');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  useEffect(() => {
    if (!promotionPlanId) {
      toast.error('Não há ID do plano');
      return;
    }

    getById(promotionPlanId)
      .then((data) => {
        setPlan(data);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  }, []);

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <>
      <Header
        centerContent={
          <PageTitle title='Ativação' />
        }
        rightContent={<PscButton />}
      />
      {plan && (
        <>
          <ContentContainer>
            <MedicBalloon
              text={[
                `Parabéns, você acaba de obter gratuitamente o ${plan.planCardTitle}.`,
                `Após essa data, você pode contratar o plano de sua preferência aqui mesmo, pelo aplicativo.`,
                `Esperamos que você aproveite ao máximo toda a praticidade e tranquilidade que o Cuidador de Confiança pode proporcionar.`,
              ]}
            />
            <LeftAlignedTypography>Informações do Plano</LeftAlignedTypography>
            <PlanCard plan={plan} />
            <Formik
              innerRef={(ref) => (formikRef.current = ref)}
              initialValues={initialValues}
              validationSchema={makeValidationSchema(
                Boolean(plan?.requirePromotionalCode)
              )}
              onSubmit={handleStartWithPlan}
            >
              {(formik) => (
                <StyledForm noValidate>
                  <InputLabel>
                    Cupom promocional
                    {plan.requirePromotionalCode && (
                      <InputRequired>(obrigatório)</InputRequired>
                    )}
                  </InputLabel>
                  <StyledFormikTextField
                    color="secondary"
                    name="promotionalCode"
                    margin="normal"
                    inputProps={{ 'data-testid': 'promotionalCode' }}
                    value={formik.values.promotionalCode}
                    onChange={formik.handleChange}
                  />
                  <StyledSubmitButton
                    size="large"
                    color="secondary"
                    disabled={!isAllowedToInvoke}
                    type="submit"
                    data-testid="plan-submit"
                  >
                    Começar Agora
                  </StyledSubmitButton>
                </StyledForm>
              )}
            </Formik>
          </ContentContainer>
        </>
      )}
    </>
  );
};

export default SubscriptionActivation;
