import { Shift } from '@cuidador/database'
import React, { useEffect, useState } from 'react'
import {
  AvatarContainer,
  BorderedAvatar,
  CardBox,
  PatientOverview,
  StatusIndicator,
  PatientName,
  UserInfoContainer,
  ClampedText,
} from '../styles'
import {
  resolvePatientStatusColor,
  resolvePatientStatusLabel,
  resolveShiftStatusColor,
  resolveShiftStatusLabel,
} from '../utils'
import ExpandedContent from './ExpandedContent'
import { ExpandButton, Row, StyledArrow } from './styles'
import MedicationStatusCard from '../../MedicationStatus'
import Occurrences from '../../../assets/Occurrences.json'

export type PatientSelectCardProps = {
  id: number
  name: string
  age: number
  profilePictureURL?: string
  status: string
  isNew: number
  shifts?: Shift[]
  active?: boolean
  handleClick?: (id: number) => void
  loadingOccurrences?: boolean
  fromDate?: Date
  toDate?: Date
  onOccurrencesUpdate?: (occurrencesOfPatient: number[]) => void
  onLoadChange?: (isLoading: boolean) => void
}

export function PatientSelectCard (props: PatientSelectCardProps) {
  const { id, name, age, profilePictureURL, status, isNew, active, shifts, handleClick, loadingOccurrences, fromDate, toDate, onOccurrencesUpdate, onLoadChange } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const [occurrencesOfPatient, setOccurrencesOfPatient] = useState<number[]>([0, 0, 0, 0, 0])

  const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsExpanded((state) => !state)
  }

  const patientStatusLabel = resolvePatientStatusLabel(status, isNew)
  const shiftStatusLabel = resolveShiftStatusLabel(shifts)

  const handleOccurrences = (occurrences?: number[] | undefined) => {
    if (occurrences) {
      setOccurrencesOfPatient(occurrences)
      onOccurrencesUpdate?.(occurrences)
    }
  }

  return (
    <CardBox open={isExpanded}>
      <Row data-testid={`patient-select-card-${id}`}>
        <PatientOverview
          onClick={() => {
            if (handleClick) handleClick(id)
          }}
        >
          <AvatarContainer>
            <BorderedAvatar
              src={profilePictureURL}
              alt={name}
              data-testid='avatar'
              borderColor={resolvePatientStatusColor(patientStatusLabel)}
            />
            <ClampedText>{age} anos</ClampedText>
          </AvatarContainer>
          <UserInfoContainer>
            <PatientName
              statusLabel={patientStatusLabel}
              data-testid='patient-name'
            >
              {name}
            </PatientName>

            {patientStatusLabel !== 'INATIVA' && (
              <StatusIndicator
                data-testid='status-indicator'
                badgeColor={resolveShiftStatusColor(shiftStatusLabel)}
              >
                {shiftStatusLabel}
              </StatusIndicator>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '8px',
                alignSelf: 'stretch',
                minHeight: '24px',
              }}
            >
              {Occurrences.map((item, index) =>
              (index != 3 && Boolean(occurrencesOfPatient?.[index] || loadingOccurrences) && <MedicationStatusCard
                key={index}
                icon={item.icon}
                color={item.backgroundColor}
                value={occurrencesOfPatient?.[index]}
                isSmall={true}
                isLoading={loadingOccurrences}
              />),
              )}
            </div>
          </UserInfoContainer>
        </PatientOverview>
        <ExpandButton
          onClick={handleExpand}
          data-testid='patient-expand-button'
        >
          <StyledArrow isExpanded={isExpanded} />
        </ExpandButton>
      </Row>
      <ExpandedContent
        id={id}
        active={active}
        shifts={shifts}
        fromDate={fromDate}
        toDate={toDate}
        onOccurrencesUpdate={handleOccurrences}
        isLoading={loadingOccurrences}
        onLoadChange={onLoadChange}
      />
    </CardBox>
  )
}
