import { ExecutionMessage, ShiftModel } from '@cuidador/database';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RightIcon from '@material-ui/icons/ChevronRight';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CareCategoryList from '../../../../components/CareCategoriesList';
import ExecutionMessageCard from '../../../../components/ExecutionMessageCard';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import ShiftEventList from '../../../../components/ShiftEventList';
import { formatEvents } from '../../../../components/ShiftEventList/LastShiftCards/ScheduledMeasurementCard/utils';
import { separateComplicationsFromActivities } from '../../../../components/ShiftEventList/utils';
import {
  getShiftExecutionRealTime,
  isAnnulledShiftExecution,
  isShiftExecutionWithContestation,
} from '../../../../components/ShiftsHistory/utils';
import { AuthContext } from '../../../../contexts/auth';
import usePatient from '../../../../hooks/usePatient';
import useShift from '../../../../hooks/useShift';
import PreviousOrNextShift from './PreviousOrNextShift';
import {
  Container,
  ExecutionMessagesContainer,
  IconContainer,
  ShiftPatientContainer,
  StyledTypographyAdditionalCoverage,
  StyledTypographyAvatar,
  StyledTypographyTitle,
  SubTitleContainer,
  TitleContainer,
  TypographyWithIcon,
} from './styles';
import { Header } from '../../../../components/Header'
import { PageTitle } from '../../../../components/PageTitle'
import { BackButton } from '../../../../components/BackButton'
import { PscButton } from '../../../../components/PscButton'

const FollowShift: React.FC = () => {
  const [patientPictureURL, setPatientPictureURL] = useState('');
  const [shiftIds, setShiftIds] = useState<number[]>([]);
  const [shift, setShift] = useState<ShiftModel>();
  const { getById, getByPatientIdPaginated, loading } = useShift();
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const { getProfilePicture } = usePatient();

  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  useEffect(() => {
    if (id && userInfo?.activePatientId) {
      if (shiftIds.length === 0)
        fetchShiftList(userInfo.activePatientId)
          .then(() => getById(id))
          .then((shift) => setShift(shift as ShiftModel));
      else getById(id).then((shift) => setShift(shift as ShiftModel));
    }
  }, [id, userInfo?.activePatientId]);

  useEffect(() => {
    if (shift) {
      handleGetProfilePictureURL(Number(shift?.patient?.id)).then((url) => {
        if (!url) return;
        setPatientPictureURL(url);
      });
    }
  }, [shift]);

  const handleGetProfilePictureURL = async (id: number) => {
    try {
      return await getProfilePicture(id).then(
        ({ data: { signedUrl } }) => signedUrl
      );
    } catch (err) {
      // ignore patient without profile picture error
      if (!String(err).includes('404')) toast.error(err);
    }
  };

  const fetchShiftList = async (patientId: number) => {
    getByPatientIdPaginated(patientId, {
      page: 0,
      limit: 100,
      orderBy: 'plannedToStartAt',
      order: 'DESC',
    }).then((response) => {
      const shifts = response.data.results;
      const startedShifts = shifts.filter((shift) => shift.startedAt);
      const shiftIds = startedShifts.map((shift) => shift.id);
      setShiftIds(shiftIds);
    });
  };

  const sortedExecutions = useMemo(() => {
    if (!shift) return [];

    return sortBy(shift.executions, (item) => item.startedAt ?? item.createdAt);
  }, [shift?.executions]);

  const executionMessages = useMemo(
    () =>
      sortedExecutions
        .map((execution) => execution.message)
        .filter(Boolean) as ExecutionMessage[],
    [sortedExecutions]
  );

  const {
    complicationEvents,
    eventsWithoutComplications,
  } = separateComplicationsFromActivities(shift?.events);

  const formattedEventsWithoutComplications = useMemo(
    () => formatEvents(eventsWithoutComplications),
    [shift?.events]
  );

  if (loading) {
    return <LoadingBackdrop loading />;
  }

  if (!shift) {
    return null;
  }

  return (
    <>
      <Header
        leftContent={
          <BackButton
            action={() => history.push('/acompanhar/historico')}
          />
        }
        centerContent={
          <PageTitle title='Histórico do plantão' />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <TitleContainer>
          <StyledTypographyTitle variant="h6">
            {format(new Date(shift.plannedToStartAt || ''), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(shift.plannedToStartAt || ''), 'EEEE', {
              locale: ptBR,
            })}
          </StyledTypographyTitle>
          <StyledTypographyTitle variant="h6">
            {shift.name || shift?.shiftSchedule?.name}
          </StyledTypographyTitle>
          <ShiftPatientContainer>
            <Avatar
              src={patientPictureURL}
              alt={shift.patient?.name}
              data-testid="avatar"
            />
            <StyledTypographyAvatar variant="subtitle1">
              {shift.patient?.name}
            </StyledTypographyAvatar>
          </ShiftPatientContainer>
          <>
            {/* It's necessary to sort it in here because executions
              could be loaded by backend in any order.
              Also, the order by query string is already filled. */}
            {sortedExecutions.map((execution, index) => (
              <TypographyWithIcon
                variant="subtitle1"
                key={`execution-caregiver-${index}`}
                data-testid={`execution-caregiver-${index}`}
              >
                {isAnnulledShiftExecution(execution) ? (
                  <>
                    <del>
                      <b>{getShiftExecutionRealTime(execution)}</b>
                    </del>
                    {!!isShiftExecutionWithContestation(execution) && (
                      <AccessTimeIcon
                        data-testid={`test-execution-time-icon-${index}`}
                      />
                    )}
                    <div>|</div>
                    <del>{execution.caregiver?.user?.name}</del>
                  </>
                ) : (
                  <>
                    <b>{getShiftExecutionRealTime(execution)}</b>
                    {!!isShiftExecutionWithContestation(execution) && (
                      <AccessTimeIcon
                        data-testid={`test-execution-time-icon-${index}`}
                      />
                    )}
                    <div>|</div>
                    {execution.caregiver?.user?.name}
                  </>
                )}
              </TypographyWithIcon>
            ))}
          </>
          {!!shift.isAdditionalCoverage && (
            <StyledTypographyAdditionalCoverage>
              *Essa é uma hora-extra / cobertura.
            </StyledTypographyAdditionalCoverage>
          )}
        </TitleContainer>

        {!!complicationEvents && complicationEvents.length > 0 && (
          <ShiftEventList
            events={complicationEvents || []}
            title="Intercorrências"
          />
        )}

        {executionMessages && executionMessages.length > 0 && (
          <ExecutionMessagesContainer>
            <SubTitleContainer>
              <IconContainer>
                <RightIcon />
              </IconContainer>
              <Typography variant="h6">
                Anotações e evolução do plantão
              </Typography>
            </SubTitleContainer>
            {executionMessages.map((message, index) => (
              <>
                {index !== 0 && <p />}
                <ExecutionMessageCard
                  executionMessage={message}
                  key={`execution-message-card-${index}`}
                  data-testid={`execution-message-card-${index}`}
                />
              </>
            ))}
          </ExecutionMessagesContainer>
        )}

        {!!formattedEventsWithoutComplications &&
        formattedEventsWithoutComplications.length > 0 ? (
          <ShiftEventList
            events={formattedEventsWithoutComplications || []}
            title="Atividades"
          />
        ) : (
          <TitleContainer>
            <Typography variant="h6">
              Nenhum evento registrado no plantão.
            </Typography>
          </TitleContainer>
        )}

        {shift.careQuestionAnswers && shift.careQuestionAnswers.length > 0 ? (
          <CareCategoryList shift={shift} />
        ) : (
          <TitleContainer>
            <Typography variant="h6">
              Nenhuma pergunta de cuidado relacionada ao plantão.
            </Typography>
          </TitleContainer>
        )}
      </Container>
      <PreviousOrNextShift
        plannedToStartAt={shift.plannedToStartAt}
        shiftIds={shiftIds}
      />
    </>
  );
};

export default FollowShift;
