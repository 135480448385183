import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const ContainerShifts = styled.div`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const ContainerDayShifts = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  margin: ${({ theme }) => theme.spacing(1.5, -2, 0)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const ShiftCardContainer = styled.div<{ backgroundColor: string }>`
  border: 2px solid ${({ theme }) => theme.palette.extra.color.grey.light};
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const ShiftCardTitle = styled.div`
  border-bottom: 2px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ShiftCardTitleName = styled(Typography)`
  font-weight: bold;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledYYYYMM = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  text-transform: capitalize;
`;

export const StyledDD = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
`;

export const StyledName = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
`;

export const ShiftBodyDetails = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ShiftBodyText = styled.div<{ isAnnulled?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-block: ${({ theme }) => theme.spacing(0.5)}px;
  text-decoration: ${({ isAnnulled }) => (isAnnulled ? 'line-through' : '')};
`;

export const TypographyWithIcon = styled(Typography)`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledButton = styled(Button)`
  width: 30%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ theme }) => theme.spacing(0, 2)};
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
