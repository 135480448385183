import * as Yup from 'yup';
import { FrequencyType } from './DaysSelector';
import { PeriodType } from './PeriodSelector';

export const validationSchema = Yup.object().shape({
  measurements: Yup.array().min(
    1,
    'Por favor, insira pelo menos um tipo de controle.'
  ),
  timeSchedule: Yup.string().required('Por favor, insira uma frequência.'),
  time: Yup.string().when('timeSchedule', {
    is: 'custom',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Por favor, insira um horário.'),
  }),
  customTimes: Yup.array().when('timeSchedule', {
    is: 'custom',
    then: Yup.array().min(1, 'Por favor, insira pelo menos um horário.'),
  }),
  rangeInDays: Yup.string().when('dayOptions', {
    is: FrequencyType.Custom,
    then: Yup.string().required('Por favor, insira um intervalo.'),
  }),
  customRangeInDays: Yup.number().when('rangeInDays', {
    is: 'custom',
    then: Yup.number().required('Por favor, insira um intervalo.'),
  }),
  frequencyStartsAt: Yup.string().required(
    'Por favor, insira uma data de início.'
  ),
  frequencyEndsAt: Yup.string().when('periodType', {
    is: PeriodType.DeterminedTime,
    then: Yup.string().required('Por favor, insira uma data de término.'),
  }),
  days: Yup.array().when('dayOptions', {
    is: FrequencyType.SomeDays,
    then: Yup.array().min(1, 'Por favor, insira pelo menos um dia.'),
  }),
});
