import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';

import StyledTextField, { StyledTextFieldProps } from '../StyledTextField';
import { CircularProgress } from '@material-ui/core';

export type Option = {
  value: number;
  text: string;
};

export type Value = Option | null;

type AutocompleteFieldProps = {
  initialValue?: Value;
  onChangeDebounced: (inputText: string) => Promise<Option[]>;
  onValueChange: (listValue: Value) => void;
  label?: string;
  testId?: string;
  getOptionLabel?: (option: Option) => string;
  getOptionSelected?: (option: Option, value: Value) => boolean;
} & StyledTextFieldProps;

const AutocompleteTextField: React.FC<AutocompleteFieldProps> = ({
  getOptionLabel = (option: Option) => option.text,
  getOptionSelected = (option: Option, value: Value) =>
    option?.value === value?.value,
  onChangeDebounced,
  initialValue,
  testId,
  color,
  label,
  onValueChange,
  margin,
}) => {
  const [data, setData] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  const debounced = useDebouncedCallback(async (inputText) => {
    setLoading(true);
    const newListValues = await onChangeDebounced(inputText);
    setData(newListValues || []);
    setLoading(false);
  }, 1000);

  return (
    <Autocomplete
      options={data}
      defaultValue={initialValue}
      getOptionLabel={getOptionLabel}
      onInputChange={(_, textValue) => debounced.callback(textValue)}
      onChange={(e, value) => onValueChange(value)}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          margin={margin}
          color={color}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            'data-testid': testId,
          }}
        />
      )}
    />
  );
};

export default AutocompleteTextField;
