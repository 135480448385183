import { AnswerType, CareQuestionAnswerModel } from '@cuidador/database';
import { orderBy } from 'lodash';
import React from 'react';
import InterviewAnswer from './InterviewAnswer';
import { QuestionsWrapper } from './styles';

type FormProps = {
  readOnly?: boolean;
  careQuestionAnswerList: CareQuestionAnswerModel[];
  handleAnswerOnChange: (
    careQuestionId: Id,
    questionAnswerData: AnswerType
  ) => void;
  debounceAnswerOnChange: (
    careQuestionId: number,
    questionAnswerData: AnswerType
  ) => void;
};

const InterviewAnswerList: React.FC<FormProps> = ({
  readOnly,
  careQuestionAnswerList,
  handleAnswerOnChange,
  debounceAnswerOnChange,
}: FormProps) => {
  const orderedList = orderBy(
    careQuestionAnswerList,
    'careQuestion.orderNumber',
    'asc'
  );
  return (
    <QuestionsWrapper id="question-wrapper">
      {orderedList?.map((careQuestionAnswer, index) => {
        const careQuestion = careQuestionAnswer.careQuestion;
        return (
          <InterviewAnswer
            readOnly={readOnly}
            key={index}
            questionAnswerData={
              careQuestionAnswer.questionAnswerData as AnswerType
            }
            careQuestionText={careQuestion?.careQuestionText}
            careQuestionId={careQuestion?.id}
            name={careQuestion?.name}
            onAnswerChange={handleAnswerOnChange}
            onAnswerChangeDebounce={debounceAnswerOnChange}
          />
        );
      })}
    </QuestionsWrapper>
  );
};

InterviewAnswerList.displayName = 'InterviewAnswerList';

export default InterviewAnswerList;
