import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdditionalCoverageForm, {
  FormValues,
} from '../../../../components/AdditionalCoverageForm';
import { formDataToAdditionalCoverageModel } from '../../../../components/AdditionalCoverageForm/utils';
import { AuthContext } from '../../../../contexts/auth';
import useAdditionalCoverage from '../../../../hooks/useAdditionalCoverage';
import { resolveErrorMessage } from '../../../../utils/error';
import { Container } from './styles';
import { Header } from '../../../../components/Header'
import { BackButton } from '../../../../components/BackButton'
import { PageTitle } from '../../../../components/PageTitle'

const formInitialValues = {
  name: '',
  date: '',
  time: '',
  duration: '',
};

const AdditionalCoverageInsert: React.FC = () => {
  const { post } = useAdditionalCoverage();
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const history = useHistory();

  const handleSubmit = (values: FormValues) => {
    if (!patientId) return;
    return post(patientId, formDataToAdditionalCoverageModel(values, patientId))
      .then(() => {
        toast.success('A hora-extra / cobertura foi adicionada com sucesso!');
        history.replace('/plantoes/cobertura-adicional');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Adicionar hora-extra / cobertura' />
        }
      />
      <Container>
        <AdditionalCoverageForm
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default AdditionalCoverageInsert;
