import {
  PtBrWeekdayEnum,
  ShiftScheduleModel,
  WeekdayEnum,
} from '@cuidador/database';
import { format } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import * as Yup from 'yup';
import { convertLocalTimeToUTC, convertTimeToDate } from '../../utils/date';
import {
  FormCardContainer,
  HelperContainer,
  HelperText,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField,
} from '../FormCardContainer';
import FormikWeekDaySelect from '../Forms/FormikWeekDaySelect';
import MedicBalloon from '../MedicBalloon';
import StyledButton from '../StyledButton';
import StyledMenuItem from '../StyledMenuItem';
import { Centralizer, ContentContainer, Form } from './styles';
import {
  formatWeekDaysFromFormValues,
  formatWeekDaysFromShiftSchedule,
} from './utils';

export interface FormValues {
  name: string;
  startingTime: string;
  duration: string;
  days: PtBrWeekdayEnum[];
}

export const durationOptions = Array.from({ length: 24 * 2 - 1 }, (_, k) => {
  const durationInMinutes = 60 + 30 * k;
  const hourValue = Math.ceil((k + 1) / 2);
  const minuteValue = k % 2 ? ' e 30 minutos' : '';

  return {
    durationInMinutes: durationInMinutes.toString(),
    label: `${hourValue} ${hourValue > 1 ? 'horas' : 'hora'}${minuteValue}`,
  };
});

export const days: {
  id: WeekdayEnum;
  name: PtBrWeekdayEnum;
}[] = [
  { id: 'mon', name: 'Seg' },
  { id: 'tue', name: 'Ter' },
  { id: 'wed', name: 'Qua' },
  { id: 'thu', name: 'Qui' },
  { id: 'fri', name: 'Sex' },
  { id: 'sat', name: 'Sab' },
  { id: 'sun', name: 'Dom' },
];

export const formDataToShiftScheduleModel = (data: FormValues) => {
  // convert days to check UTC date and time
  const formattedWeekDays = formatWeekDaysFromFormValues(data);

  const duration = parseInt(data!.duration!);
  const shiftScheduleData = {
    name: data.name,
    // converts to UTC before send to backend
    startingTime: convertLocalTimeToUTC(data.startingTime),
    duration: duration,
    ...formattedWeekDays,
  };
  return shiftScheduleData;
};

export const shiftScheduleModelToFormData = (data: ShiftScheduleModel) => {
  const days = formatWeekDaysFromShiftSchedule(data);

  // format UTC to local time for correct rendering
  const formattedTime = data?.startingTime
    ? format(convertTimeToDate(data.startingTime), 'HH:mm')
    : '';
  return {
    name: data.name || '',
    startingTime: formattedTime,
    duration: String(data.duration) || '',
    days,
  } as FormValues;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, insira um nome'),
  startingTime: Yup.string().required('Por favor, insira o horário'),
  duration: Yup.string().required('Por favor, insira a duração'),
  days: Yup.array().min(1, 'Por favor, escolha pelo menos 1 dia'),
});

const ShiftScheduleUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
  isAllowedToUpdate?: boolean;
}> = ({ initialValues, innerRef, onSubmit, isAllowedToUpdate = true }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <MedicBalloon text="O roteiro diário ou plantão é o período em que o cuidado será realizado, pelo cuidador, pessoa idosa em autocuidado ou outro profissional de saúde. Em caso de autocuidado, orientamos que o período seja de 24 horas." />

            <ContentContainer>
              <FormCardContainer>
                <StyledBoldTitle>Nome do plantão</StyledBoldTitle>

                <StyledFormikTextField
                  color="secondary"
                  id="name"
                  inputProps={{ 'data-testid': 'name' }}
                  label="Nome do plantão"
                  variant="outlined"
                  placeholder="Nome do plantão"
                  name="name"
                  margin="normal"
                  autoComplete="off"
                  helperText={
                    <HelperContainer>
                      <HelperText>
                        Exemplo: Plantão Diurno, Plantão Noturno, etc
                      </HelperText>
                    </HelperContainer>
                  }
                  size="small"
                />
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle>Horário de início e duração</StyledBoldTitle>

                <StyledFormikTextField
                  color="secondary"
                  id="startingTime"
                  inputProps={{ 'data-testid': 'startingTime-input' }}
                  name="startingTime"
                  type="time"
                  margin="normal"
                  autoComplete="off"
                  label="Horário"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />

                <StyledSelect
                  name="duration"
                  label="Duração"
                  SelectDisplayProps={{
                    'data-testid': 'duration-input',
                  }}
                  color="secondary"
                  size="small"
                  disabled={!isAllowedToUpdate}
                >
                  {durationOptions
                    .slice()
                    .reverse()
                    .map((item, index) => (
                      <StyledMenuItem
                        color="secondary"
                        key={index}
                        value={item.durationInMinutes}
                      >
                        {item.label}
                      </StyledMenuItem>
                    ))}
                </StyledSelect>
              </FormCardContainer>

              <FormCardContainer>
                <StyledBoldTitle>Em quais dias da semana?</StyledBoldTitle>
                <FormikWeekDaySelect name="days" />
              </FormCardContainer>

              <Centralizer>
                <StyledButton
                  data-testid="submit-shift"
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={
                    isSubmitting ||
                    isEqual(initialValues, values) ||
                    !isAllowedToUpdate
                  }
                >
                  Salvar
                </StyledButton>
              </Centralizer>
            </ContentContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ShiftScheduleUpsertForm;
