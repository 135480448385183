import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

export function getAdditionalCoverageCardContent(
  plannedToStartAt?: string,
  plannedToEndAt?: string
) {
  if (!plannedToStartAt || !plannedToEndAt) return;

  const start = new Date(plannedToStartAt);
  const end = new Date(plannedToEndAt);

  const weekday = format(start, 'EEE', { locale: ptBrLocale });
  const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
  const startTime = format(start, 'HH:mm', { locale: ptBrLocale });
  const endTime = format(end, 'HH:mm', { locale: ptBrLocale });

  return `${capitalizedWeekday} - ${startTime} às ${endTime}`;
}
