import React from 'react';
import {
  SimpleCircularProgress,
  SimpleCircularProgressContainer,
} from './styles';

const LoadingSimpleCircular: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <SimpleCircularProgressContainer>
      {loading && (
        <SimpleCircularProgress data-testid="loading-simple-circular" />
      )}
    </SimpleCircularProgressContainer>
  );
};

export default LoadingSimpleCircular;
