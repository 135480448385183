import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../contexts/auth';
import { Container, FooterContainer } from './styles';
import { Backdrop, BackdropCircularProgress } from '../styles';
import { Formik, Form } from 'formik';
import {
  validationSchema,
  FormValues,
  userModelToFormData,
  formDataToGuardianModel,
} from './utils';
import FormikTextField from '../../../components/Forms/FormikTextField';
import { cpfMask, phoneMask } from '../../../utils/inputs';
import StyledButton from '../../../components/StyledButton';
import useGuardian from '../../../hooks/useGuardian';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { resolveErrorMessage } from '../../../utils/error';
import { isEqual } from 'lodash';

const Personal: React.FC = () => {
  const { userInfo, loading, refreshUserInfo } = useContext(AuthContext);
  const { patch } = useGuardian();

  useEffect(() => {
    refreshUserInfo();
  }, []);

  const userData = userInfo?.user;
  const formInitialValue = userModelToFormData(userData!);

  const handleSubmit = (values: FormValues) => {
    patch(formDataToGuardianModel(values))
      .then(() => {
        toast.success('Dados alterados com sucesso!');
        refreshUserInfo();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {(formik) => (
            <Form noValidate={true}>
              <FormikTextField
                color="secondary"
                name="name"
                label="Nome"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'name' }}
              />
              <FormikTextField
                color="secondary"
                name="cpf"
                label="CPF"
                type="tel" // numeric keyboard without parsing to number
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'cpf' }}
                value={cpfMask(formik.values.cpf)}
                disabled
              />
              <FormikTextField
                color="secondary"
                name="phoneNumber"
                label="Celular"
                type="tel" // numeric keyboard without parsing to number
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'phoneNumber' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'phoneNumber',
                    phoneMask(e.target.value)
                  );
                }}
              />
              <FormikTextField
                color="secondary"
                name="email"
                label="E-mail"
                disabled
                type="email"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'email' }}
              />
              <FooterContainer>
                <StyledButton
                  disabled={
                    formik.isSubmitting ||
                    isEqual(formInitialValue, formik.values)
                  }
                  type="submit"
                  size="large"
                  color="inherit"
                >
                  Salvar
                </StyledButton>
              </FooterContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Personal;
