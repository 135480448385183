import { Button } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CaptionButton = styled(Button)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-weight: 600;
  display: flex;
  align-items: center;
  align-self: flex-end;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  width: fit-content;

  & svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  }

  &:hover {
    background-color: transparent;
  }
`;

export const StyledArrowUp = styled(KeyboardArrowUp)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledArrowDown = styled(KeyboardArrowDown)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;
