import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Fab from '../../components/Fab';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-color: #fafafa;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const ListContainer = styled.div`
  width: 100%;
`;

export const ListContainerWithBorder = styled(ListContainer)`
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  background-color: inherit;

  > .MuiAccordionSummary-root {
    padding: 0px;
  }
`;

export const StyledFab = styled(Fab)`
  bottom: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: ${({ theme }) => theme.spacing(1, 0)};
  font-size: 18px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export const StyledBuyButton = styled(StyledButton)`
  margin: ${({ theme }) => theme.spacing(2)}px;
`;
