import styled, { css } from 'styled-components';
import { Button, Checkbox, Input, Modal, Typography } from '@material-ui/core';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import { FilterChipMultiselectProps } from '.';
import FilterChip from '..';
import { StyledChipProps } from '../styles';

type MultiselectContainerProps = Pick<
  FilterChipMultiselectProps,
  'backgroundColor' | 'variant'
> & { active?: boolean };

const makeBackgroundColorWithHover = (color: string) => css`
  background-color: ${color};
  &:hover {
    background-color: ${color};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const CountMarker = styled.span`
  position: relative;
  width: 12px;
  height: 12px;
  margin: -4px -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.error.dark};
  border-radius: 100%;
  & > p {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 10px;
    color: ${({ theme }) => theme.palette.background.paper};
    margin: 0;
  }
`;

export const FilterChipMultiselectContainer = styled.div<MultiselectContainerProps>`
  border: 0;
  border-radius: 4px;
  display: flex;

  ${({ active }) =>
    !active &&
    css`
      filter: grayscale(0.45);
    `};

  ${({ theme, backgroundColor, variant }) => {
    if (!!backgroundColor) return makeBackgroundColorWithHover(backgroundColor);

    switch (variant) {
      case 'blue':
        return makeBackgroundColorWithHover(theme.palette.info.main);
      case 'orange':
        return makeBackgroundColorWithHover(theme.palette.extra.color.yellow);
      case 'red':
        return makeBackgroundColorWithHover(theme.palette.extra.color.red.dark);
      case 'green':
        return makeBackgroundColorWithHover(theme.palette.success.main);
      case 'lightblue':
        return makeBackgroundColorWithHover(theme.palette.extra.color.ocean);
      default:
        return makeBackgroundColorWithHover(theme.palette.primary.dark);
    }
  }};
`;

export const MultiSelectButton = styled(Button)<StyledChipProps>`
  width: 30px;
  min-width: auto;
  height: 100%;
  padding: 0;
  outline: none;
  border: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.background.paper};
`;

export const StyledFilterChip = styled(FilterChip)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MultiselectContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 10px;
`;

const makeBackgroundAndColor = (background: string, color: string) => css`
  background-color: ${background};
  color: ${color};
`;

export const Header = styled.div<MultiselectContainerProps>`
  display: flex;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 9px;

  ${({ theme, backgroundColor, variant }) => {
    if (!!backgroundColor)
      return makeBackgroundAndColor(
        backgroundColor,
        theme.palette.text.primary
      );

    switch (variant) {
      case 'blue':
        return makeBackgroundAndColor(
          theme.palette.info.main,
          theme.palette.background.paper
        );
      case 'orange':
        return makeBackgroundAndColor(
          theme.palette.extra.color.yellow,
          theme.palette.background.paper
        );
      case 'red':
        return makeBackgroundAndColor(
          theme.palette.extra.color.red.dark,
          theme.palette.background.paper
        );
      case 'green':
        return makeBackgroundAndColor(
          theme.palette.success.main,
          theme.palette.background.paper
        );
      case 'lightblue':
        return makeBackgroundAndColor(
          theme.palette.extra.color.ocean,
          theme.palette.background.paper
        );
      default:
        return makeBackgroundAndColor(
          theme.palette.primary.dark,
          theme.palette.background.paper
        );
    }
  }};
`;

export const HeaderLeftIcon = styled.div`
  display: flex;
  width: 60px;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 36px;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  width: 100%;
  margin-right: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const SearchInput = styled(Input)`
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.spacing(0.25)}px
    ${({ theme }) => theme.spacing(0.5)}px
    ${({ theme }) => theme.spacing(0.15)}px;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  width: 85%;
`;

export const StyledSearchOutlined = styled(SearchOutlined)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 85%;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  text-overflow: clip;
  overflow-x: hidden;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

export const FooterButton = styled(Button)`
  width: 100%;
  ${({ theme }) => makeBackgroundColorWithHover(theme.palette.primary.dark)};
  color: ${({ theme }) => theme.palette.background.paper};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;
