import { EventModel, EventSubCategoryModel } from '@cuidador/database';

export const categoryIdMapper = {
  GENERAL: 1,
  MEDICATION: 2,
  COMPLICATION: 3,
  MEASUREMENT: 4,
};

export const subCategoryIdMapper = {
  BLOOD_PRESSURE: 35231,
  BODY_TEMPERATURE: 35237,
  BLOOD_GLYCEMIA: 35230,
  BREATH_RATE: 35236,
  HEART_RATE: 35235,
  OXIMETRY_RATE: 35232,
  WEIGHT_MEASURMENT: 35233,
};

export const separateComplicationsFromActivities = (
  shiftEvents: EventModel[] | undefined
) => {
  if (!shiftEvents || shiftEvents.length === 0)
    return {
      complicationEvents: [],
      eventsWithoutComplications: [],
    };

  const complicationEvents = shiftEvents.filter((event) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    return subCategory.categoryId === categoryIdMapper['COMPLICATION'];
  });
  const eventsWithoutComplications = shiftEvents.filter((event) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    return subCategory.categoryId !== categoryIdMapper['COMPLICATION'];
  });

  return { complicationEvents, eventsWithoutComplications };
};

export const getMeasurementUnit = (subCategoryId?: number): string => {
  switch (subCategoryId) {
    case subCategoryIdMapper.BLOOD_GLYCEMIA:
      return 'mg/dL';
    case subCategoryIdMapper.BODY_TEMPERATURE:
      return '°C';
    case subCategoryIdMapper.BREATH_RATE:
      return 'mrm';
    case subCategoryIdMapper.HEART_RATE:
      return 'bpm';
    case subCategoryIdMapper.OXIMETRY_RATE:
      return '%SaO2';
    case subCategoryIdMapper.BLOOD_PRESSURE:
      return 'mmHg';
    case subCategoryIdMapper.WEIGHT_MEASURMENT:
      return 'Kg';
    default:
      return '';
  }
};
