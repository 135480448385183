import React, { useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  MultiselectContainer,
  OptionContainer,
  SearchInput,
  StyledModal,
  StyledSearchOutlined,
  StyledCheckbox,
  StyledTypography,
  OptionsSection,
  HeaderTitle,
  Header,
  HeaderLeftIcon,
  FooterButton,
} from './styles';
import LoadingBackdrop from '../../LoadingBackdrop';

type MultiselectModalOptionsType = {
  id: number | string;
  label: string;
  checked: boolean;
};

export interface MultiselectModalProps {
  isOpen: boolean;
  close: () => void;
  options: MultiselectModalOptionsType[];
  toggleOption: (id: MultiselectModalOptionsType['id']) => void;
  title: string;
  backgroundColor?: string;
  variant?: 'blue' | 'orange' | 'red' | 'green' | 'lightblue';
  icon?: string;
  onFinishOptionsSelection: () => void;
  loading: boolean;
}

interface OptionProps extends MultiselectModalOptionsType {
  check?: (id: MultiselectModalOptionsType['id']) => void;
}

const Option: React.FC<OptionProps & { testid: string }> = ({
  id,
  label,
  checked,
  check,
  testid,
}) => {
  return (
    <OptionContainer onClick={() => !!check && check(id)} data-testid={testid}>
      <StyledCheckbox checked={checked} />
      <StyledTypography variant="body2">{label}</StyledTypography>
    </OptionContainer>
  );
};

export const MultiselectModal: React.FC<MultiselectModalProps> = ({
  isOpen,
  close,
  options,
  title,
  toggleOption,
  variant,
  backgroundColor,
  icon,
  onFinishOptionsSelection,
  loading,
}: MultiselectModalProps) => {
  const [searchInput, setSearchInput] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(options, {
        keys: ['label'],
        threshold: 0.4,
      }),
    [options]
  );

  const searchedOptions = useMemo(() => {
    if (searchInput.length < 2) return undefined;

    return fuse.search(searchInput).map((searchResult) => searchResult.item);
  }, [searchInput, options]);

  return (
    <>
      {loading && isOpen && <LoadingBackdrop loading={loading} />}
      <StyledModal open={isOpen} onBackdropClick={close}>
        <MultiselectContainer>
          <Header variant={variant} backgroundColor={backgroundColor}>
            <HeaderLeftIcon onClick={() => close()}>
              <ChevronLeft />
            </HeaderLeftIcon>
            <HeaderTitle>
              {!!icon && <img src={icon} />}
              <Typography variant="h6">{title}</Typography>
            </HeaderTitle>
          </Header>
          <br />
          <SearchInput
            placeholder="Pesquisar..."
            disableUnderline
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.currentTarget.value)
            }
            startAdornment={<StyledSearchOutlined />}
            data-testid="multiselect-search-input"
          />
          <br />
          <OptionsSection data-testid="multiselect-options-section">
            {(searchedOptions || options).map((option, index) => (
              <Option
                {...option}
                check={() => toggleOption(option.id)}
                key={`multiselect-option-${index}`}
                testid={`multiselect-option-${index}`}
              />
            ))}
          </OptionsSection>
          <FooterButton
            onClick={() => {
              onFinishOptionsSelection();
              close();
            }}
          >
            Selecionar
          </FooterButton>
        </MultiselectContainer>
      </StyledModal>
    </>
  );
};
