import Card from '@material-ui/core/Card';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: ${({ theme }) => theme.spacing(2)}px;
  box-sizing: border-box;
`;

export const StyledCard = styled(Card).attrs({ elevation: 3 })`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  & .MuiCardHeader-avatar {
    color: ${({ theme }) => theme.palette.info.main};
    width: 20px;
  }
  & .MuiCardHeader-action {
    margin: 0;
    align-self: center;
  }
`;
