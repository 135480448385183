import { UserModel } from '@cuidador/database';
import { DialogContent, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import StyledButton from '../../../../components/StyledButton';
import StyledDialog from '../../../../components/StyledDialog';
import UserList from './UserList';
import { AuthContext } from '../../../../contexts/auth';
import useCanAccess from '../../../../hooks/useCanAccess';
import { phoneMask, cpfMask } from '../../../../utils/inputs';
import { ButtonContainer, StyledTitle, StyledDialogActions } from './styles';

type RelateToPatientDialogProps = {
  onClose: () => void;
  onConfirm: (id?: number) => void;
  handleCreateUserAndGenerateSignupUrl: () => void;
  users: (UserModel & { roleTitle?: string })[];
  newUserCpf?: string;
  avatars?: { id: number; image: string }[];
  open: boolean;
};

const RelateToPatientDialog: React.FC<RelateToPatientDialogProps> = ({
  onClose,
  onConfirm,
  handleCreateUserAndGenerateSignupUrl,
  users,
  newUserCpf,
  avatars,
  open,
}) => {
  const { userInfo } = useContext(AuthContext);
  const guardianOrganizationId = userInfo?.user?.organizationId;
  const { isAllowedToCreate: isAllowedToCreateUser } = useCanAccess('user');
  const { isAllowedToInvoke: isAllowedToRelateUser } = useCanAccess(
    'user/relate-to-patient'
  );

  const isUserFoundByCpf =
    !!newUserCpf &&
    users.some(({ cpf }) => cpfMask(String(cpf)) === newUserCpf);

  const findAndReturnAvatarImage = (id?: number) => {
    if (avatars) {
      const found = avatars.find((avatar) => avatar.id === id)?.image;
      if (found) {
        return found;
      }
    }
    return '';
  };

  const allowedToRelateUserPatient = (userOrganizationId?: number | null) => {
    const userHasNoOrganization = !userOrganizationId;
    const userIsInGuardianOrganization = !!(
      userOrganizationId === guardianOrganizationId
    );
    return userHasNoOrganization || userIsInGuardianOrganization;
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        {!!users.length && (
          <DialogContent>
            {isAllowedToRelateUser && (
              <>
                {isUserFoundByCpf && (
                  <StyledTitle>
                    Encontramos este usuário com o CPF{' '}
                    {cpfMask(String(users[0].cpf))}
                  </StyledTitle>
                )}
                {!isUserFoundByCpf && (
                  <>
                    <StyledTitle>
                      Encontramos {users?.length} usuário(s) com o número{' '}
                      {!!users[0].phoneNumber &&
                        phoneMask(users[0].phoneNumber)}
                    </StyledTitle>
                    <StyledTitle>Qual deles você está buscando?</StyledTitle>
                  </>
                )}
                <UserList
                  users={users}
                  findAndReturnAvatarImage={findAndReturnAvatarImage}
                  allowedToRelateUserPatient={allowedToRelateUserPatient}
                  onConfirm={onConfirm}
                />
              </>
            )}
            <ButtonContainer>
              <StyledDialogActions>
                <StyledButton
                  onClick={onClose}
                  color="inherit"
                  variant="outlined"
                >
                  Voltar
                </StyledButton>
                {isAllowedToRelateUser && isAllowedToCreateUser && (
                  <Typography
                    onClick={handleCreateUserAndGenerateSignupUrl}
                    color="inherit"
                    align="center"
                  >
                    Não encontrei quem procuro
                  </Typography>
                )}
              </StyledDialogActions>
            </ButtonContainer>
          </DialogContent>
        )}
      </StyledDialog>
    </>
  );
};

export default RelateToPatientDialog;
