import { RoleModel } from '@cuidador/database';
import { clientSideScheme } from '@cuidador/whitelabel';
import React, { useState } from 'react';
import { StyledBoldTitle } from '../../../../components/FormCardContainer';
import { LogoImage } from '../../../../components/RoleApps/styles';
import { AppContainer } from '../styles';
import { FormValues } from '../utils';
import {
  CaptionButton,
  Container,
  Description,
  StyledArrowDown,
  StyledArrowUp,
} from './styles';

interface CollapsibleCaptionProps {
  roles: RoleModel[];
  values: FormValues;
  defaultVisibility: boolean;
}

const { appCShortcutIconUrl, appFShortcutIconUrl } = clientSideScheme();

const apps = [
  { label: 'Acessa o app de Gestão de cuidados', icon: appFShortcutIconUrl },
  { label: 'Acessa o app de Cuidados', icon: appCShortcutIconUrl },
];

const CollapsibleCaption: React.FC<CollapsibleCaptionProps> = ({
  roles,
  values,
  defaultVisibility,
}) => {
  const [
    isVisibleCollapsibleCaption,
    setIsVisibleCollapsibleCaption,
  ] = useState(defaultVisibility);

  const handleToggleCollapsibleCaption = () => {
    setIsVisibleCollapsibleCaption(!isVisibleCollapsibleCaption);
  };

  return (
    <Container>
      <CaptionButton
        onClick={() => {
          handleToggleCollapsibleCaption();
        }}
      >
        Legenda
        {isVisibleCollapsibleCaption ? <StyledArrowUp /> : <StyledArrowDown />}
      </CaptionButton>
      {isVisibleCollapsibleCaption && (
        <>
          <StyledBoldTitle variant="subtitle2">
            Descrição do tipo de perfil
          </StyledBoldTitle>
          <Description data-testid="description">
            {roles?.find((role) => role.id == values.roleId)?.description}
          </Description>
          {apps.map(({ label, icon }, index) => (
            <AppContainer key={index}>
              <LogoImage src={icon} />
              <span>{label}</span>
            </AppContainer>
          ))}
        </>
      )}
    </Container>
  );
};

export default CollapsibleCaption;
