import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';

export type ShiftReportListDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
