import React from 'react';
import { ShiftExecution } from '@cuidador/database';
import { AccessTime } from '@material-ui/icons';
import { isExecutionWithContestation } from '../utils';
import { StyledExecutionTitle } from '../styles';

interface ExecutionTitleProps {
  executionNumber: number;
  timeContestations: ShiftExecution['timeContestations'];
}

const ExecutionTitle: React.FC<ExecutionTitleProps> = ({
  executionNumber,
  timeContestations,
}) => {
  return (
    <StyledExecutionTitle>
      Execução {executionNumber}
      {!!isExecutionWithContestation(timeContestations) && <AccessTime />}
    </StyledExecutionTitle>
  );
};

export default ExecutionTitle;
