import React from 'react';
import { ShiftExecution } from '@cuidador/database';
import { handleFormatShiftExecutionTime } from '../utils';

interface ExecutionTimeProps {
  execution: ShiftExecution;
}

const ExecutionTime: React.FC<ExecutionTimeProps> = ({ execution }) => {
  return <b>{handleFormatShiftExecutionTime(execution)} </b>;
};

export default ExecutionTime;
