import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const StyledButton = styled(Button).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))`
  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 65%;
  }
`;

export default StyledButton;
