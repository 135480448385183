import { CaregiverModel, SupporterModel, UserModel } from '@cuidador/database';
import { TokenProfile } from '@cuidador/lib';
import { AxiosResponse } from 'axios';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

export type CompleteSignupBody = {
  userData: UserModel;
  caregiverData?: Omit<CaregiverModel, 'user' | 'supporter'>;
  supporterData?: SupporterModel;
};

export interface GetOrganizationMembersPaginatedRequestParams
  extends PaginatedRequestParams {
  status?: UserModel['status'][];
  withRelatedPatientId?: number;
}

const endpoint = '/user';

const initialData: ReducerData<UserModel> = {
  byId: {} as Record<string, Item<UserModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useUser = () => {
  const { uploadMedia, getMedia } = useMedia();
  const [state, dispatch] = useReducer(createReducer<UserModel>(), initialData);

  const post = useCallback(async (data: UserModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patchAutoPatientRelate = useCallback(
    async (
      id: Id,
      isAutoPatientRelateEnabled: UserModel['isAutoPatientRelateEnabled']
    ) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(
          `${endpoint}/${id}/auto-patient-relate`,
          { isAutoPatientRelateEnabled }
        );
        dispatch({ type: 'UPDATE', payload: response.data });
        return Promise.resolve(response as AxiosResponse<UserModel>);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response as AxiosResponse<UserModel>);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const findAvailableUsers = useCallback(async (values: UserModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}/available`, values);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const completeSignup = useCallback(
    async (data: CompleteSignupBody, completeSignupToken: string) => {
      try {
        const response = await axios.post(`${endpoint}/complete-signup`, data, {
          headers: {
            Authorization: `Bearer ${completeSignupToken}`,
          },
        });
        return Promise.resolve(response.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const uploadProfilePicture = useCallback(
    async (file: File, userId: number) => {
      try {
        const response = await uploadMedia(
          `/media/profile-picture/user/${userId}`,
          file
        );
        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/user/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const getOrganizationMembersPaginated = useCallback(
    async (params: GetOrganizationMembersPaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<UserModel>[];
          total: number;
        }>(`${endpoint}/by-organization`, { params });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const relateToPatient = useCallback(
    async (userId: number, patientId: number) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post(
          `${endpoint}/${userId}/relate-to-patient`,
          { patientToRelateId: patientId }
        );
        dispatch({ type: 'GET_BY_ID', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const relateToOrganization = useCallback(async (userId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(
        `${endpoint}/${userId}/relate-to-organization`
      );
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const unrelateFromPatient = useCallback(
    async (userId: number, patientId: number) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post(
          `${endpoint}/${userId}/unrelate-from-patient`,
          { patientToUnrelateId: patientId }
        );
        dispatch({ type: 'GET_BY_ID', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const unrelateAllPatients = useCallback(async (userId: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(
        `${endpoint}/${userId}/unrelate-from-patient/all`
      );
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const unrelateFromOrganization = useCallback(async (userId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(
        `${endpoint}/${userId}/unrelate-from-organization`
      );
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getPendingSignupToken = useCallback(async (id: Id) => {
    try {
      const response = await axios.post(`/auth/pending-signup-token`, {
        userId: id,
      });
      return Promise.resolve(
        response as AxiosResponse<{
          id: UserModel['id'];
          userProfile: TokenProfile;
          /**
           * @deprecated will be removed. completeSignupUrl should be used
           */
          token: string;
          completeSignupUrl: string;
        }>
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const checkSignupStatus = useCallback(async (id: Id) => {
    try {
      const response = await axios.get(`${endpoint}/check-signup-status/${id}`);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    post,
    patchAutoPatientRelate,
    getById,
    findAvailableUsers,
    completeSignup,
    uploadProfilePicture,
    getProfilePicture,
    getOrganizationMembersPaginated,
    relateToPatient,
    relateToOrganization,
    unrelateFromPatient,
    unrelateAllPatients,
    unrelateFromOrganization,
    getPendingSignupToken,
    checkSignupStatus,
  };
};

export default useUser;
