import React from 'react';
import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import {
  OptionsWrapper,
  OptionDescriptionContainer,
  StyledOptionDescriptionTextField,
} from '../../styles';
import { AnswerType, QuestionAnswerOption } from '@cuidador/database';
import StyledFormControlLabel from '../../../StyledFormControlLabel';

type FormProps = {
  readOnly?: boolean;
  type: AnswerType['type'];
  options: AnswerType['options'];
  onOptionsChange: (option: QuestionAnswerOption[]) => void;
};

const InterviewAnswerOption: React.FC<FormProps> = React.memo(
  ({ type, options, readOnly, onOptionsChange }: FormProps) => {
    const buildChangeOptions = (
      optionId: string,
      changedData: Partial<QuestionAnswerOption>
    ): QuestionAnswerOption[] => {
      const optionToChange = options.find((op) => op.id === optionId);
      if (!optionToChange) return options;

      return options.map((option) => {
        if (option.id !== optionId) {
          /**
           * if changed option was single and checked,
           * the other options must have isChecked false
           * and must not have description.
           */
          if (type === 'single' && changedData?.isChecked) {
            return {
              ...option,
              isChecked: false,
              description: undefined,
            };
          }
          return option;
        }
        return {
          ...optionToChange,
          ...changedData,
          // if option to change is not checked, remove description
          description: optionToChange.isChecked
            ? changedData.description
            : undefined,
        };
      });
    };
    return (
      <OptionsWrapper>
        {type === 'multi' &&
          options.map((option) => {
            return (
              <div key={option.id}>
                <StyledFormControlLabel
                  label={option.value}
                  control={
                    <Checkbox
                      disabled={readOnly}
                      key={`${option.id}-multi`}
                      checked={option.isChecked || false}
                      onChange={(e) =>
                        onOptionsChange(
                          buildChangeOptions(option.id, {
                            isChecked: e.target.checked,
                          })
                        )
                      }
                    />
                  }
                />
                {option.isChecked && (
                  <OptionDescriptionContainer>
                    <StyledOptionDescriptionTextField
                      disabled={readOnly}
                      value={option.description}
                      onChange={(e) =>
                        onOptionsChange(
                          buildChangeOptions(option.id, {
                            description: e.target.value,
                          })
                        )
                      }
                    />
                  </OptionDescriptionContainer>
                )}
              </div>
            );
          })}
        {type === 'single' && (
          <RadioGroup
            onChange={(e) =>
              onOptionsChange(
                buildChangeOptions(
                  options.find((op) => op.id === e.target.value)!.id,
                  {
                    isChecked: true,
                  }
                )
              )
            }
          >
            {options.map((option) => (
              <div key={`${option.id}-single`}>
                <StyledFormControlLabel
                  value={option.id}
                  control={<Radio color="primary" />}
                  label={option.value}
                  checked={option.isChecked}
                  disabled={readOnly}
                />
                {option.isChecked && (
                  <OptionDescriptionContainer>
                    <StyledOptionDescriptionTextField
                      disabled={readOnly}
                      value={option.description}
                      onChange={(e) =>
                        onOptionsChange(
                          buildChangeOptions(option.id, {
                            description: e.target.value,
                          })
                        )
                      }
                    />
                  </OptionDescriptionContainer>
                )}
              </div>
            ))}
          </RadioGroup>
        )}
      </OptionsWrapper>
    );
  }
);
InterviewAnswerOption.displayName = 'InterviewAnswerOption';

export default InterviewAnswerOption;
