import { EventSchedule } from '@cuidador/database';
import { parse } from 'date-fns';
import {
  convertLocalTimeToUTC,
  getUTCWeeklySchedule,
  weekdayMap,
} from '../../../../utils/date/index';
import { FrequencyType } from '../DaysSelector';
import { PeriodType } from '../PeriodSelector';
import { getMedicationTimes } from '../TimeSelector';
import { FormValues } from '../utils';

export const needScheduleData = (values: FormValues): boolean => {
  return values.periodType !== PeriodType.AsNeeded;
};

export const needScheduleEndData = (values: FormValues): boolean => {
  return values.periodType === PeriodType.DeterminedTime;
};

export const formDataToMedicationModel = (
  formData: FormValues,
  patientId: number,
  subCategoryId?: number
) => {
  const frequencyStartsAt = !formData.ifNecessary
    ? parse(
        `${formData.frequencyStartsAt} ${
          (formData.timeSchedule === 'custom'
            ? formData.customTimes && formData.customTimes[0]
            : formData.time) || '00:00'
        }`,
        'yyyy-MM-dd HH:mm',
        new Date()
      ).toISOString()
    : undefined;

  const frequencyEndsAt =
    formData.periodType === PeriodType.DeterminedTime
      ? parse(
          `${formData.frequencyEndsAt} 23:59`,
          'yyyy-MM-dd HH:mm',
          new Date()
        ).toISOString()
      : undefined;

  const utcSchedule = !formData.ifNecessary
    ? getUtcSchedule(formData.days!, formData.time!)
    : {};

  const frequencyRule = !formData.ifNecessary
    ? getFrequencyRule(formData)
    : undefined;

  const times = !formData.ifNecessary ? getTimes(formData) : undefined;

  const customTimes = times?.map((time) => ({
    happensAt: convertLocalTimeToUTC(time),
  }));

  const data = {
    dosageFormat: formData?.dosageFormat || undefined,
    dosageQuantity: formData.dosageQuantity,
    administeredBy: formData?.administeredBy || undefined,
    subCategoryId,
    patientId: (patientId as number) || undefined,
    medicalNotes: formData?.medicalNotes || undefined,
    ifNecessary: Boolean(formData?.ifNecessary),
    eventSchedule: needScheduleData(formData)
      ? {
          id: (formData?.eventScheduleId as number) || undefined,
          frequencyRule: frequencyRule,
          frequencyStartsAt,
          frequencyEndsAt,
          ...utcSchedule,
          scheduleType: 'custom_frequency' as EventSchedule['scheduleType'],
          customTimes,
        }
      : undefined,
  };

  return data;
};

const getTimes = (formData: FormValues) => {
  let times;
  if (formData.timeSchedule === 'custom') {
    times = formData.customTimes;
  } else {
    times = getMedicationTimes(formData.time!, formData.timeSchedule!);
  }
  return times || [];
};

const getFrequencyRule = (formData: FormValues) => {
  if (formData.dayOptions !== FrequencyType.Custom) return '1d';
  if (formData.rangeInDays !== 'custom') return formData.rangeInDays;
  return `${formData.customRangeInDays}d`;
};

const getUtcSchedule = (days: string[], time: string) => {
  const localSchedule = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };

  if (days && days.length > 0) {
    localSchedule.mon = days.includes(weekdayMap.mon);
    localSchedule.tue = days.includes(weekdayMap.tue);
    localSchedule.wed = days.includes(weekdayMap.wed);
    localSchedule.thu = days.includes(weekdayMap.thu);
    localSchedule.fri = days.includes(weekdayMap.fri);
    localSchedule.sat = days.includes(weekdayMap.sat);
    localSchedule.sun = days.includes(weekdayMap.sun);
  }

  const utcSchedule = getUTCWeeklySchedule(localSchedule, time);
  return utcSchedule;
};
