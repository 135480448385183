import React from 'react';
import MedicationFirstStepCard from '../../MedicationFirstStepCard';
import MedicationSearchBar from '../../MedicationSearchBar';
import MedicBalloon from '../../MedicBalloon';
import { MedicationItem } from '../MedicationSecondStep/utils';
import { CardContainer, SearchContainer } from '../styles';

interface Props {
  handleChangeMedicationText?: (textValue: string) => void;
  handleSelectMedication?: (medication: MedicationItem) => void;
  allMedication?: MedicationItem[];
}

const MedicationFirstStep: React.FC<Props> = ({
  handleChangeMedicationText,
  allMedication,
  handleSelectMedication,
}) => {
  return (
    <>
      <MedicBalloon text="Digite o nome do medicamento ou do princípio ativo no campo de busca abaixo. Caso não encontre, tente usar diferentes dados que estão na receita." />
      <SearchContainer>
        <MedicationSearchBar
          fieldName="q"
          onChangeDebounced={handleChangeMedicationText}
        />
      </SearchContainer>
      <CardContainer>
        {allMedication &&
          allMedication.length > 0 &&
          allMedication?.map((item) => {
            return (
              <MedicationFirstStepCard
                title={item.name}
                subTitle={item.description}
                key={item.id}
                id={item.id}
                onClick={() => handleSelectMedication?.(item)}
              />
            );
          })}
      </CardContainer>
    </>
  );
};

export default MedicationFirstStep;
