import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MenuItemProps } from './';

export const StyledLink = styled(Link)<MenuItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  font-size: 10px;
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(1, 0)};
  background-color: ${({ $active, theme }) =>
    $active ? theme.palette.secondary.main : theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  height: 100%;

  svg {
    height: 24px;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  }

  &:first-child {
    border-top-left-radius: 24px;
  }

  &:last-child {
    border-top-right-radius: 24px;
  }
`;
