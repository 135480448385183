import { CareQuestionAnswer } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import React from 'react';
import { CardBox } from './styles';
import { getAnswerContent } from './utils';

type CardProps = {
  careQuestionAnswer?: CareQuestionAnswer;
};

const Card: React.FC<CardProps> = ({ careQuestionAnswer }) => {
  return (
    <CardBox>
      {careQuestionAnswer ? (
        <Typography>
          {getAnswerContent(careQuestionAnswer.questionAnswerData)}
        </Typography>
      ) : (
        <Typography>Não respondida.</Typography>
      )}
    </CardBox>
  );
};

export default Card;
