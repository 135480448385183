import { CardActionArea, CardHeader } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import { Container, StyledCard } from './styles';

const reportsUrl = '/relatorio/paciente';
const professionalReportsUrl = '/avaliacao-multiprofissional/paciente';

type CardProps = {
  title: string;
  subtitle?: string;
  icon: JSX.Element;
  onClick?: () => void;
  'data-testid'?: string;
  disabled: boolean;
};

const renderCard = (data: CardProps) => {
  if (data.disabled) return null;

  return (
    <StyledCard data-testid={`card-${data['data-testid']}`} key={data.title}>
      <CardActionArea
        data-testid={`card-action-${data['data-testid']}`}
        onClick={data.onClick}
      >
        <CardHeader
          titleTypographyProps={{
            variant: 'body1',
          }}
          subheaderTypographyProps={{
            variant: 'caption',
            color: 'inherit',
          }}
          avatar={data.icon}
          action={<ChevronRightRoundedIcon />}
          title={data.title}
          subheader={data.subtitle}
        />
      </CardActionArea>
    </StyledCard>
  );
};

const Reports: React.FC = () => {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;
  const { isAllowedToRead: isAllowedToReadMonthlyReports } = useCanAccess(
    'report/patient'
  );
  const { isAllowedToRead: isAllowedToReadProfessionalReports } = useCanAccess(
    'report/professional'
  );

  const menuList: CardProps[] = [
    {
      title: 'Relatórios mensais',
      subtitle: 'Relatórios mensais de acompanhamento de cuidados e saúde',
      icon: <CollectionsBookmarkIcon />,
      'data-testid': 'reports',
      onClick: () => history.push(`${reportsUrl}/${patientId}`),
      disabled: !isAllowedToReadMonthlyReports,
    },
    {
      title: 'Avaliações multiprofissionais',
      subtitle: 'Avaliações feitas por profissionais de saúde',
      icon: <LibraryBooksIcon />,
      'data-testid': 'professional-reports',
      onClick: () => history.push(`${professionalReportsUrl}/${patientId}`),
      disabled: !isAllowedToReadProfessionalReports,
    },
  ];

  return (
    <>
      <Container>{menuList.map((item) => renderCard(item))}</Container>
    </>
  );
};

export default Reports;
