import { Backdrop, CircularProgress, Fab } from '@material-ui/core';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import PictureAsPdfOutlined from '@material-ui/icons/PictureAsPdfOutlined';
import styled from 'styled-components';

interface RoundButtonProps {
  background: 'dark' | 'light' | 'green';
}

export const RoundButton = styled(Fab)<RoundButtonProps>`
  position: fixed;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  bottom: ${({ theme }) => theme.spacing(10)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.spacing(1.125)}px;
  height: ${({ theme }) => theme.spacing(5)}px;
  background-color: ${({ theme, background }) => {
    if (background === 'green') {
      return theme.palette.success.main;
    } else {
      return theme.palette.secondary.main;
    }
  }} !important;
  & > span > a {
    color: inherit;
    text-decoration: inherit;
  }
  & span {
    text-transform: none;
  }
`;

export const LoadingCircular = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.background.paper};
  height: 17px !important;
  width: 17px !important;
  margin-right: 5px !important;
`;

export const LoadingBackdrop = styled(Backdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const CloudDownload = styled(CloudDownloadOutlined)`
  margin-right: 5px;
  width: 17px;
  height: 17px;
`;

export const PictureAsPdf = styled(PictureAsPdfOutlined)`
  margin-right: 5px;
  width: 17px;
  height: 17px;
`;
