import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import StyledPaper from '../../components/StyledPaper';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledRightTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: right;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const Container = styled.div`
  width: 92%;
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const Card = styled(StyledPaper)`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.secondary.main};

  button {
    align-self: flex-end;
    color: ${({ theme }) => theme.palette.extra.color.green};
    text-decoration: underline;
    font-weight: normal;
    margin-top: ${({ theme }) => theme.spacing(1)}px;

    /* button is aligned to the right, keeping the shape during click effect */
    margin-right: ${({ theme }) => -theme.spacing(1)}px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
`;

export const TitleContainer = styled.div`
  width: 70%;
  word-wrap: break-word;
`;

export const Title = styled(Typography).attrs({
  variant: 'subtitle1',
})`
  text-align: center;
  font-weight: 500;
`;

export const ReportText = styled(Typography).attrs({
  variant: 'subtitle1',
})`
  font-weight: bold;
  line-height: normal;
`;
