import {
  Backdrop as MaterialBackdrop,
  Checkbox,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.palette.secondary.main};
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Button = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;
