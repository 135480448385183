import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
`;

export const CategoryTitle = styled(Typography)`
  margin: 1.5em auto 0.5em 0;
  font-weight: bold;
`;
