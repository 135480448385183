import styled from 'styled-components';
import {
  Avatar,
  Button,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;

  span {
    font-size: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;

  span {
    margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  align-self: center;
  height: ${({ theme }) => theme.spacing(4)}px;
  width: ${({ theme }) => theme.spacing(4)}px;
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
`;

export const StyledTypography = styled(Typography)`
  line-height: 1;
  font-size: ${({ theme }) => theme.spacing(2)}px;

  b {
    max-width: 50vw;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;

export const StyledSelectButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(0.75, 1.25)};
  min-width: unset;
`;

export const DisabledSelectButton = styled(StyledSelectButton)`
  opacity: 65%;
  cursor: default;
`;

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    borderRadius: '5px',
    maxWidth: '90px',
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);
