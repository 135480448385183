import React from 'react';
import { UserModel } from '@cuidador/database';
import ToggleableChip from '../../../../../components/ToggleableChip';
import { Container } from '../../../../../components/ToggleableChip/styles';
import { ReactComponent as ActiveIcon } from '../../../../../assets/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../../assets/disable-icon.svg';
import { ReactComponent as PendingIcon } from '../../../../../assets/pending-icon.svg';

export const chipItens: Array<{
  label: string;
  value: UserModel['status'];
  icon?: JSX.Element;
}> = [
  { label: 'Ativos', value: 'enabled', icon: <ActiveIcon /> },
  {
    label: 'Pendentes',
    value: 'awaitingSignupCompletion',
    icon: <PendingIcon />,
  },
  { label: 'Inativos', value: 'disabled', icon: <InactiveIcon /> },
];

interface FilterChipsProps {
  setStatus: (status: UserModel['status'][]) => void;
  status: UserModel['status'][];
}

const FilterChips: React.FC<FilterChipsProps> = ({ setStatus, status }) => {
  const handleToggleStatus = (
    value: UserModel['status'],
    isActive: boolean
  ) => {
    if (isActive) {
      setStatus(status.filter((status) => status !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  return (
    <Container>
      {chipItens.map(({ label, value, icon }) => {
        const isActive = status.includes(value);
        return (
          <ToggleableChip
            key={value}
            value={value}
            label={label}
            isActive={isActive}
            onClick={() => {
              handleToggleStatus(value, isActive);
            }}
            icon={icon}
          />
        );
      })}
    </Container>
  );
};

export default FilterChips;
