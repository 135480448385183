import React, { useContext } from 'react'
import { ReactComponent as IconCheck } from '../../../../assets/Icon-check-circle.svg'
import { ReactComponent as IconPill } from '../../../../assets/icon-pill-outline.svg'
import { ReactComponent as IconSwitch } from '../../../../assets/icon-switch.svg'
import { ReactComponent as IconGraph } from '../../../../assets/icon_graph.svg'
import { ReactComponent as IconLink } from '../../../../assets/link-icon.svg'
import { ReactComponent as IconClock } from '../../../../assets/icon-time-filled.svg'
import usePatient from '../../../../hooks/usePatient'
import { AuthContext } from '../../../../contexts/auth'
import {
  ShortcutLabelContainer,
  ExpandedContentContainer,
  Shortcut,
  ShortcutIcon,
  ShortcutLabel,
  ShortcutsContainer,
  CardSectionContainer,
  CardSectionTitle,
  CardSectionHeader,
  CardSectionContent,
  StatusIndicator,
  CardSectionFooter,
} from './styles'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { AssignmentOutlined } from '@material-ui/icons'
import { clientSideScheme } from '@cuidador/whitelabel'
import { Shift } from '@cuidador/database'
import {
  getShiftExecutions,
  resolveShiftStatus,
  resolveShiftStatusColor,
  resolveShiftStatusLabel,
} from '../../utils'
import { getShiftExecutionRealTime } from '../../../ShiftsHistory/utils'
import { sortBy } from 'lodash'
import { Button, Typography } from '@material-ui/core'
import { StyledHrefLink } from '../../../SideMenu/styles'
import { EvolutionContent } from './EvolutionContent'

interface ShortcutProps {
  label: string
  icon: JSX.Element
  handleClickItem: () => void
}

interface ExpandedContentProps {
  id: number
  active: boolean | undefined
  shifts: Shift[] | undefined
  fromDate?: Date
  toDate?: Date
  isLoading?: boolean
  onOccurrencesUpdate?: (occurrencesOfPatient?: number[]) => void
  onLoadChange?: (isLoading: boolean) => void
}

const ExpandedContent: React.FC<ExpandedContentProps> = ({
  id,
  active,
  shifts,
  fromDate,
  toDate,
  isLoading,
  onOccurrencesUpdate,
  onLoadChange
}: ExpandedContentProps) => {
  const { setActive } = usePatient()
  const { userInfo, refreshUserInfo } = useContext(AuthContext)
  const history = useHistory()
  const { appCUrl } = clientSideScheme()
  const hasCaregiverAppAccess = userInfo?.user?.role?.caregiverAppAccess

  const shiftStatusLabel = resolveShiftStatusLabel(shifts)
  const shiftStatus = resolveShiftStatus(shifts)
  const shiftExecutions = getShiftExecutions(shifts)

  const shortcuts: ShortcutProps[] = [
    {
      label: 'Cuidados',
      icon: <IconCheck />,
      handleClickItem: () => handleChangeActivePatient(id, '/acompanhar', active),
    },

    {
      label: 'Cadastros',
      icon: <IconClock />,
      handleClickItem: () => handleChangeActivePatient(id, '/cadastros', active),
    },
    {
      label: 'Medica-mentos',
      icon: <IconPill />,
      handleClickItem: () => handleChangeActivePatient(id, '/medicamentos', active),
    },
    {
      label: 'Evolução',
      icon: <IconGraph />,
      handleClickItem: () => handleChangeActivePatient(id, '/acompanhar/evolucao', active),
    },
    {
      label: 'Vincular',
      icon: <IconLink />,
      handleClickItem: () => handleChangeActivePatient(id, `/organizacao/paciente/${id}`, active),
    },
    {
      label: 'Relatórios',
      icon: <AssignmentOutlined />,
      handleClickItem: () => handleChangeActivePatient(id, '/acompanhar/relatorios', active),
    },
  ]

  const handleChangeActivePatient = async (
    patientId: number,
    pageRedirectRoute: string,
    isPatientActive?: boolean,
  ) => {
    try {
      if (!isPatientActive) {
        await setActive(patientId)
        await refreshUserInfo()
      }
      history.push(pageRedirectRoute)
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <ExpandedContentContainer data-testid={`patient-${id}-expanded-content`}>
      <ShortcutsContainer>
        {shortcuts.map((shortcut) => (
          <Shortcut key={shortcut.label} onClick={shortcut.handleClickItem}>
            <ShortcutIcon>{shortcut.icon}</ShortcutIcon>
            <ShortcutLabelContainer>
              <ShortcutLabel>{shortcut.label}</ShortcutLabel>
            </ShortcutLabelContainer>
          </Shortcut>
        ))}
        {Boolean(hasCaregiverAppAccess) && (
          <StyledHrefLink href={appCUrl}>
            <Shortcut>
              <ShortcutIcon>
                <IconSwitch />
              </ShortcutIcon>
              <ShortcutLabelContainer>
                <ShortcutLabel>Trocar de app</ShortcutLabel>
              </ShortcutLabelContainer>
            </Shortcut>
          </StyledHrefLink>
        )}
      </ShortcutsContainer>
      <CardSectionContainer>
        <CardSectionHeader>
          <CardSectionTitle>Plantão</CardSectionTitle>
          <StatusIndicator
            data-testid='status-indicator'
            badgeColor={resolveShiftStatusColor(shiftStatusLabel)}
          >
            {shiftStatus}
          </StatusIndicator>
        </CardSectionHeader>
        <CardSectionContent>
          {!!shiftExecutions && shiftExecutions.length > 0 ? (
            sortBy(
              shiftExecutions,
              (item) => item.startedAt ?? item.createdAt,
            ).map((execution) => (
              <Typography key={execution.id}>
                {getShiftExecutionRealTime(execution)}
                {' | '}
                {execution.caregiver?.user?.name}
              </Typography>
            ))
          ) : (
            <Typography>
              {shiftStatus === 'Atrasado' &&
                'Este plantão ainda não foi iniciado'}
              {shiftStatus === 'Sem plantão' &&
                'Não há nenhum plantão cadastrado para este horário'}
            </Typography>
          )}
        </CardSectionContent>
        <CardSectionFooter>
          {['Sem plantão', 'Atrasado'].includes(shiftStatus) ? (
            <Button onClick={() => handleChangeActivePatient(id, '/acompanhar/historico', active)}>
              Ver histórico
            </Button>
          ) : (
            <Button onClick={() => handleChangeActivePatient(id, '/acompanhar/plantao', active)}>
              Ver cuidados
            </Button>
          )}
        </CardSectionFooter>
      </CardSectionContainer>
      <EvolutionContent
        id={id}
        active={active}
        handleChangeActivePatient={handleChangeActivePatient}
        shifts={shifts}
        fromDate={fromDate}
        toDate={toDate}
        isLoading={isLoading}
        onOccurrencesUpdate={onOccurrencesUpdate}
        onLoadChange={onLoadChange}
      />
    </ExpandedContentContainer>
  )
}

export default ExpandedContent
