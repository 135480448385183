import React from 'react';
import {
  ButtonContainer,
  CardBox,
  TextContainer,
  TitleContainer,
} from './styles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useHistory } from 'react-router-dom';

type RegistrationOnboardingCardProps = {
  color: string;
  title: string;
  subTitle: string;
  registrationPSCRoute: string;
};

export default function RegistrationOnboardingCard({
  color,
  title,
  subTitle,
  registrationPSCRoute,
}: RegistrationOnboardingCardProps) {
  const history = useHistory();

  return (
    <CardBox
      data-testid="registration-onboarding-card"
      color={color}
      onClick={() => {
        history.push(registrationPSCRoute);
      }}
    >
      <TextContainer>
        <TitleContainer>{title}</TitleContainer>
        {subTitle}
      </TextContainer>
      <ButtonContainer>
        <ControlPointIcon fontSize="inherit" data-testid={'create-button'} />
      </ButtonContainer>
    </CardBox>
  );
}
