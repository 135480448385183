import * as Sentry from '@sentry/react';
import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import EditIconSvg from '../../../assets/EditIconBlack.svg';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import usePatient from '../../../hooks/usePatient';
import { FormCardContainer } from '../../FormCardContainer';
import {
  ButtonContainer,
  StatusContainer,
  StatusDescriptionContainer,
  StatusLabelContainer,
} from './styles';
import UpdateStatusModal, { FormValues } from './UpdateStatusModal';
import {
  getPatientStatus,
  getPatientStatusLabelAfterReset,
  getStatusDescription,
  PatientStatusType,
  resolveStatusLabel,
} from './utils';

const EditIcon = () => <img src={EditIconSvg} />;

const StatusLabel: React.FC<{ status: PatientStatusType }> = ({ status }) => {
  const styledTheme = useTheme();
  let textColor;
  if (status === 'inactive') {
    textColor = styledTheme.palette.extra.color.grey.main;
  }
  if (status === 'pending') {
    textColor = styledTheme.palette.extra.color.yellow;
  }
  if (status === 'active') {
    textColor = styledTheme.palette.extra.color.green;
  }
  return (
    <span style={{ color: textColor, fontWeight: 'bold' }}>
      {resolveStatusLabel(status)}
    </span>
  );
};

const PatientStatus: React.FC = () => {
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const { patch } = usePatient();
  const patient = userInfo?.activePatient;
  const patientId = Number(patient?.id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { isAllowedToUpdate } = useCanAccess('user/patient');

  const patientStatus = useMemo(() => {
    return getPatientStatus(patient?.status, Boolean(patient?.isNew));
  }, [patient?.status, patient?.isNew]);

  const onPatchStatus = async (values: FormValues) => {
    try {
      await patch(patientId, {
        ...values,
        status: 'disabled',
        statusUpdatedAt: new Date().toISOString(),
      });
      toast.success('Status do paciente modificado');
      setIsDeleteModalOpen(false);
      await refreshUserInfo();
    } catch (err) {
      Sentry.captureException(err);
      toast.error('Erro ao modificar status do paciente');
    }
  };

  const onResetStatus = async () => {
    try {
      await patch(patientId, {
        status: 'enabled',
        statusUpdatedAt: new Date().toISOString(),
      });
      setIsDeleteModalOpen(false);
      toast.success('Status do paciente restaurado');
      await refreshUserInfo();
    } catch (err) {
      Sentry.captureException(err);
      toast.error('Erro ao restaurar status do paciente');
    }
  };

  return (
    <>
      <FormCardContainer>
        <StatusContainer>
          <StatusLabelContainer>
            <b>Status atual: </b>
            <StatusLabel status={patientStatus} />
          </StatusLabelContainer>
          <ButtonContainer>
            <button
              disabled={!isAllowedToUpdate}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setIsDeleteModalOpen(true);
              }}
            >
              <EditIcon data-testid="edit-patient-status" />
              {patientStatus === 'inactive' ? 'Restaurar Status' : 'Inativar'}
            </button>
          </ButtonContainer>
        </StatusContainer>

        <StatusDescriptionContainer>
          <p>{getStatusDescription(patientStatus)}</p>
        </StatusDescriptionContainer>
      </FormCardContainer>
      {isDeleteModalOpen && (
        <UpdateStatusModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onPatchStatus={onPatchStatus}
          onResetStatus={onResetStatus}
          patientStatus={patient?.status}
          patientStatusLabelAfterReset={getPatientStatusLabelAfterReset(
            Boolean(patient?.isNew)
          )}
          patientStatusDescription={getStatusDescription(
            getPatientStatus('enabled', Boolean(patient?.isNew))
          )}
        />
      )}
    </>
  );
};

export default PatientStatus;
