import React, { useState } from 'react';
import MedicationFirstStep from './MedicationFirstStep';
import MedicationSecondStep from './MedicationSecondStep';
import {
  FormValues,
  MedicationInsertFormStep,
  MedicationItem,
} from './MedicationSecondStep/utils';
import { Container } from './styles';
import StyledSimpleDialog from '../StyledSimpleDialog'
import { FormikHelpers } from 'formik'

interface Props {
  step: MedicationInsertFormStep;
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => void;
  // optional values, because this form is also used to update medication, and that does not require these parameters
  allMedication?: MedicationItem[];
  handleChangeMedicationText?: (textValue: string) => void;
  handleSelectMedication?: (medication: MedicationItem) => void;
  handleStepChange?: (step: MedicationInsertFormStep) => void;
  id?: number;
  disabled?: boolean;
}

const MedicationInsertForm: React.FC<Props> = ({
  step,
  initialValues,
  handleSubmit,
  allMedication,
  handleChangeMedicationText,
  handleSelectMedication,
  id,
  disabled,
}) => {
  const [confirmPatch, setConfirmPatch] = useState(false)
  const [patchValues, setPatchValues] = useState<FormValues>()
  const [disableButton, setDisableButton] = useState<boolean>()

  function handlePatchData (values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    setConfirmPatch(true)
    setPatchValues(values)
  }

  function handleYes () {
    if (patchValues) {
      setDisableButton(true)
      handleSubmit(patchValues)
    }
  }

  const renderStep = (step: MedicationInsertFormStep) => {
    switch (step) {
      case MedicationInsertFormStep.SelectMedication:
        return (
          <MedicationFirstStep
            allMedication={allMedication || undefined}
            handleChangeMedicationText={handleChangeMedicationText || undefined}
            handleSelectMedication={handleSelectMedication || undefined}
          />
        );
      case MedicationInsertFormStep.FillDetails:
        return (
          <>
            <StyledSimpleDialog
              open={confirmPatch}
              handleNo={() => setConfirmPatch(false)}
              handleYes={handleYes}
              title="Salvar medicamento?"
              subTitle="ATENÇÃO: se um plantão já estiver em andamento, as alterações no medicamento surtirão efeito apenas no próximo plantão"
              disabled={disableButton}
            />
            <MedicationSecondStep
              initialValues={initialValues}
              onSubmit={handlePatchData}
              id={id}
              disabled={disabled}
            />
          </>
        );
      default:
        return null;
    }
  };

  return <Container>{renderStep(step)}</Container>;
};

export default MedicationInsertForm;
