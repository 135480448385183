import { Event, EventSubCategoryModel } from '@cuidador/database';
import { subDays } from 'date-fns';
import { capitalize, isEqual, omit } from 'lodash';
import qs from 'query-string';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../../config/axios';
import { AuthContext } from '../../contexts/auth';
import { resolveErrorMessage } from '../../utils/error';
import useMedia from '../useMedia';

const endpoint = '/report/event/history';

const categories = [
  'medication',
  'measurement',
  'appointment',
  'routine',
  'complication',
] as const;

const subCategories = [
  'medicationSubCategoryIds',
  'measurementSubCategoryIds',
  'appointmentSubCategoryIds',
  'routineSubCategoryIds',
  'complicationSubCategoryIds',
] as const;

export type CategoryType = typeof categories[number];
export type SubCategoryType = typeof subCategories[number];
export const isCategoryType = (category: string): category is CategoryType => {
  return categories.includes(category as CategoryType);
};

interface ParamsType {
  'updatedTimeHappensAt[min]': string;
  'updatedTimeHappensAt[max]': string;
  orderBy: string;
  order: 'asc' | 'desc';
  timezone: string;
  status: string[];
  hasComment: boolean;
  hasUpdated: boolean;
  category: CategoryType[];
  medicationSubCategoryIds: string[];
  measurementSubCategoryIds: string[];
  complicationSubCategoryIds: string[];
  appointmentSubCategoryIds: string[];
  routineSubCategoryIds: string[];
  patientId?: string;
}

const generateDefaultParams = (
  patientId?: ParamsType['patientId']
): ParamsType => {
  const todayMax = new Date();
  const todayMin = subDays(todayMax, 7);
  todayMax.setHours(23, 59, 59, 999);
  todayMin.setHours(0, 0, 0, 0);

  return {
    'updatedTimeHappensAt[min]': todayMin.toISOString(),
    'updatedTimeHappensAt[max]': todayMax.toISOString(),
    orderBy: 'updatedTimeHappensAt',
    order: 'desc',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    status: [],
    hasComment: false,
    hasUpdated: false,
    category: [],
    medicationSubCategoryIds: [],
    measurementSubCategoryIds: [],
    complicationSubCategoryIds: [],
    appointmentSubCategoryIds: [],
    routineSubCategoryIds: [],
    patientId,
  };
};

const useEventCareHistory = () => {
  const history = useHistory();
  const { getMedia } = useMedia();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);
  const [eventHistory, setEventHistory] = useState<
    { date: string; events: Event[] }[] | undefined
  >(undefined);
  const { userInfo } = useContext(AuthContext);
  const [params, setAllParams] = useState({
    ...generateDefaultParams(),
    ...omit(
      qs.parse(history.location.search, {
        parseBooleans: true,
        arrayFormat: 'bracket',
      }),
      'patientId'
    ),
  });

  const getAll = useCallback(
    async (patientId: Id) => {
      try {
        setLoading(true);
        const queryString = qs.stringify(params, {
          skipNull: true,
          skipEmptyString: true,
          arrayFormat: 'bracket',
        });
        const response = await axios.get(
          `${endpoint}/${patientId}?${queryString}`
        );
        setEventHistory(response.data);
      } catch (err) {
        const message = resolveErrorMessage(err);
        toast.error(message);
      } finally {
        setLoading(false);
      }
    },
    [params, history]
  );

  const createCareHistoryReport = useCallback(
    async (patientId: Id) => {
      try {
        const { data } = await axios.post(
          `${endpoint}/${patientId}${history.location.search}`
        );
        return data as { reportId?: number };
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    [params, history]
  );

  const getCareHistoryReportDownloadUrl = useCallback(
    async (patientId: Id, reportId: Id) => {
      try {
        const { data } = await getMedia(
          `/media/patient/${patientId}/care-history-report/${reportId}`
        );
        return data as { signedUrl: string };
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    [params, history]
  );

  const getEventSubCategoriesRelatedToPatientCategory = async (
    patientId: Id,
    category: CategoryType
  ) => {
    try {
      const { data } = await axios.get(
        `/care/event/category/sub/patient/${patientId}?onlyPatientRelated${capitalize(
          category
        )}=true`
      );
      return data as EventSubCategoryModel[];
    } catch (err) {
      const message = resolveErrorMessage(err);
      toast.error(message);
    }
  };

  const setParams = (
    paramName: keyof ParamsType,
    paramValue: string | string[] | boolean
  ) => setAllParams((state) => ({ ...state, [paramName]: paramValue }));

  const resetParams = useCallback(() => {
    setAllParams(generateDefaultParams(String(userInfo?.activePatientId)));
  }, [setAllParams, userInfo?.activePatientId]);

  useEffect(() => {
    if (userInfo?.activePatientId) {
      const urlPatientId = qs.parse(history.location.search, {
        parseBooleans: true,
        arrayFormat: 'bracket',
      })?.patientId;

      if (
        userInfo.activePatientId &&
        userInfo?.activePatientId !== Number(urlPatientId)
      ) {
        resetParams();
      }

      setParams('patientId', String(userInfo.activePatientId));
    }
  }, [userInfo?.activePatientId, resetParams]);

  useEffect(() => {
    const defaultParams = generateDefaultParams();
    setHasActiveFilters(
      !isEqual(omit(defaultParams, 'patientId'), omit(params, 'patientId'))
    );

    const newUrl = qs.stringifyUrl(
      {
        url: history.location.pathname,
        query: params,
      },
      { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' }
    );
    history.push(newUrl);
  }, [params]);

  return {
    loading,
    getAll,
    createCareHistoryReport,
    getCareHistoryReportDownloadUrl,
    eventHistory,
    params,
    setParams,
    resetParams,
    hasActiveFilters,
    generateDefaultParams,
    getEventSubCategoriesRelatedToPatientCategory,
  };
};

export default useEventCareHistory;
