import { MedicationModel } from '@cuidador/database';
import { AccordionActions, AccordionSummary, Divider } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledExpandMoreIcon,
  StyledMedicationIcon,
  StyledTitle,
  StyledTypography,
} from './styles';
import {
  getMedicationIntervalText,
  renderDoseTimes,
  renderAdministration,
} from './utils';

type MedicationCardProps = {
  medication: MedicationModel;
  route?: string;
};

export default function MedicationCard({
  medication,
  route,
}: MedicationCardProps) {
  const history = useHistory();
  return (
    <StyledAccordion data-testid={`medicationCard-${medication.id}`}>
      <AccordionSummary expandIcon={<StyledExpandMoreIcon />}>
        <StyledMedicationIcon />
        <StyledTitle>
          {medication.subCategory?.medicationCatalogItem?.name ||
            medication.subCategory?.name}
        </StyledTitle>
      </AccordionSummary>

      <Divider />

      <StyledAccordionDetails>
        {medication.subCategory?.medicationCatalogItem?.description && (
          <StyledTypography>
            {medication.subCategory?.medicationCatalogItem?.description}
          </StyledTypography>
        )}
        {getMedicationIntervalText(medication)}
        {renderDoseTimes(medication)}
        <StyledTypography data-testid={`administration-field-${medication.id}`}>
          <strong>Administração: </strong> {renderAdministration(medication)}
        </StyledTypography>
        {medication.medicalNotes && medication.medicalNotes.length > 0 && (
          <StyledTypography>
            <strong>Recomendações: </strong>
            {medication.medicalNotes}
          </StyledTypography>
        )}
      </StyledAccordionDetails>

      <AccordionActions>
        {route && (
          <EditIcon
            onClick={() => {
              history.push(`${route}/${medication.id}`);
            }}
          />
        )}
      </AccordionActions>
    </StyledAccordion>
  );
}
