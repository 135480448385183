import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const FloatingButton = styled(Button)`
  position: sticky;
  z-index: 1;
  top: calc(72px + ${({ theme }) => theme.spacing(2)}px);
`;

export const FloatingButtonContainer = styled.div`
  position: absolute;
  height: 100%;
  right: ${({ theme }) => theme.spacing(2)}px;
  top: ${({ theme }) => theme.spacing(3)}px;
`;

export const DayHistoryContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const DayHistoryHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-left: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const DayHistoryBody = styled.div`
  position: relative;
  z-index: 0;
  margin: ${({ theme }) => theme.spacing(0, 1.5)};
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const PureParagraph = styled.p`
  margin: 0px;
  padding: 0px;
`;

export const TimelineVerticalStroke = styled.div`
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0px;
  background-color: ${({ theme }) => theme.palette.grey.A100};
  left: calc(${({ theme }) => theme.spacing(6)}px + 30px);
  transform: translateX(-50%);
  z-index: 0;
`;

export const EventListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1.5, 0)};
  list-style: none;

  &:last-child {
    margin: 0px;
  }
`;

export const EventTime = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  min-width: calc(${({ theme }) => theme.spacing(5.5)}px);
  width: min-content;
`;

export const EventTitle = styled(PureParagraph)`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Icon = styled.img<{ isOddDayHistory: number }>`
  border-radius: 100vw;
  user-select: none;

  ${({ theme, isOddDayHistory }) =>
    isOddDayHistory
      ? css`
          border: 4px solid ${theme.palette.grey[200]};
          background-color: ${theme.palette.grey[200]};
        `
      : css`
          border: 4px solid #fafafa;
          background-color: #fafafa;
        `};
`;

export const EventCategoryIcon = styled(Icon)`
  width: 30px;
  height: 30px;
`;

export const EventStatusIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

export const DayMessage = styled(EventTitle)`
  margin-left: calc(${({ theme }) => theme.spacing(8)}px + 60px);
  font-style: italic;
`;

export const CenteredLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const EventCardPopUp = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  > div {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ActiveFilterIndicator = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  transform: translate(40%, -40%);
  width: 12px;
  height: 12px;
  border-radius: 100vw;
  background-color: ${({ theme }) => theme.palette.extra.color.red.light};
`;
