import { useCallback, useState } from 'react';

export type AddressData = {
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro?: boolean;
};

const useCEP = () => {
  const [loading, setLoading] = useState(false);
  const getAddress = useCallback(async (cep: string) => {
    try {
      setLoading(true);
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json`);
      const data: AddressData = await response.json();
      if (data.erro) throw new Error('CEP Inválido');
      return data;
    } catch (_) {
      return Promise.reject(new Error('CEP Inválido'));
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    getAddress,
  };
};

export default useCEP;
