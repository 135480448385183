import {
  RadioGroup,
  Radio,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import styled from 'styled-components';

export const CenterLayout = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledRadio = styled(Radio)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiRadio-root:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiCheckbox-root:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .Mui-disabled {
    opacity: 65%;
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  color: ${({ theme }) => theme.palette.common.white};
`;
