import { AppFPalette, clientSideScheme } from '@cuidador/whitelabel';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';

type StyledTheme = Theme & {
  palette: AppFPalette;
};

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {
    palette: Palette & AppFPalette;
  }
}

export const resolveTheme = () => {
  const scheme = clientSideScheme();

  const muiTheme = createMuiTheme({
    palette: scheme.appFPalette,
    shape: {
      borderRadius: 12,
    },
    typography: {
      fontFamily: 'Work Sans',
      fontSize: 14,
    },
    spacing: 8,
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '9px',
          textTransform: 'unset',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'unset',
        },
      },
    },
  });

  const styledTheme: StyledTheme = {
    ...muiTheme,
    palette: {
      ...muiTheme.palette,
      extra: scheme.appFPalette.extra,
    },
  };

  return {
    muiTheme,
    styledTheme,
  };
};
