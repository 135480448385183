import { APIError } from '@cuidador/lib';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FormikTextField from '../../../components/Forms/FormikTextField';
import StyledButton from '../../../components/StyledButton';
import PasswordRules from '../../../components/PasswordRules';
import { changePassword } from '../../../contexts/auth';
import { resolveErrorMessage } from '../../../utils/error';
import { Container, FooterContainer } from './styles';
import { formInitialValue, FormValues, validationSchema } from './utils';
import { isEqual } from 'lodash';

const ChangePassword: React.FC = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);

  const handleSubmit = (
    values: FormValues,
    { setFieldValue }: FormikHelpers<FormValues>
  ) => {
    changePassword(values.currentPassword, values.newPassword)
      .then(() => {
        toast.success('Senha alterada com sucesso');
        setFieldValue('currentPassword', '');
        setFieldValue('newPassword', '');
        setFieldValue('retypePassword', '');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };
  const [validationError, setValidationError] = useState(false);

  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleShowRetypePassword = () =>
    setShowRetypePassword(!showRetypePassword);

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {(formik) => (
            <Form noValidate={true}>
              <FormikTextField
                color="secondary"
                name="currentPassword"
                label="Senha antiga"
                type="password"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'currentPassword' }}
              />
              <FormikTextField
                color="secondary"
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                label="Nova senha"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'newPassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormikTextField
                color="secondary"
                name="retypePassword"
                type={showRetypePassword ? 'text' : 'password'}
                label="Confirmar nova senha"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'retypePassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowRetypePassword}
                      >
                        {showRetypePassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordRules
                password={formik.values.newPassword}
                validationError={validationError}
              />
              <FooterContainer>
                <StyledButton
                  disabled={
                    formik.isSubmitting ||
                    isEqual(formInitialValue, formik.values)
                  }
                  type="submit"
                  size="large"
                  color="inherit"
                  onClick={() => setValidationError(true)}
                >
                  Salvar
                </StyledButton>
              </FooterContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ChangePassword;
