import { Add, Clear } from '@material-ui/icons';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useState } from 'react';
import {
  FormCardContainer,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../../../FormCardContainer';
import StyledMenuItem from '../../../StyledMenuItem';
import StyledSimpleDialog from '../../../StyledSimpleDialog';
import ErrorText from '../ErrorText';
import { FormValues } from '../utils';
import {
  AddButtonContainer,
  CustomTimeInputContainer,
  MedicationTextRow,
  StyledAddButton,
  StyledRemoveButton,
  StyledText,
} from './styles';

const scheduleOptions = [
  { label: '2h/2h', value: '2h' },
  { label: '4h/4h', value: '4h' },
  { label: '6h/6h', value: '6h' },
  { label: '8h/8h', value: '8h' },
  { label: '12h/12h', value: '12h' },
  { label: '24h/24h', value: '24h' },
  { label: 'Intervalos irregulares', value: 'custom' },
];

interface TimeSelectorProps {
  values: FormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  disabled?: boolean;
}

export const getMedicationTimes = (startTime: string, frequency: string) => {
  const formattedFrequency = Number(frequency.replace('h', ''));
  const [hour, minute] = startTime.split(':');
  const formattedHour = Number(hour);
  const doses = [];
  const firstMedicationTime = formattedHour % formattedFrequency;
  for (
    let medicationTime = firstMedicationTime;
    medicationTime <= 23;
    medicationTime += formattedFrequency
  ) {
    doses.push(medicationTime);
  }

  const formattedDoses = doses.map((item) => {
    return item.toString().padStart(2, '0') + ':' + minute;
  });

  return formattedDoses;
};

const TimeSelector: React.FC<TimeSelectorProps> = ({
  values,
  setFieldValue,
  errors,
  touched,
  disabled,
}) => {
  const [customTime, setCustomTime] = useState<string>('');
  const [customTimeToDelete, setcustomTimeToDelete] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleAddCustomTime = () => {
    const customTimes = [...(values.customTimes || [])];
    if (values.customTimes?.includes(customTime)) return;
    setFieldValue('customTimes', [...customTimes, customTime]);
    setCustomTime('');
  };

  const handleRemoveCustomTime = (time: string) => {
    setIsDeleteDialogOpen(false);
    const customTimes = [...(values.customTimes || [])];
    const filteredCustomTimes = customTimes.filter(
      (customTime) => customTime !== time
    );
    setFieldValue('customTimes', filteredCustomTimes);
  };

  const orderedCustomTimes = values.customTimes?.sort((a, b) => {
    const fistHour = Number(a.split(':').join(''));
    const secondHour = Number(b.split(':').join(''));
    return fistHour - secondHour;
  });

  return (
    <>
      <FormCardContainer>
        <StyledBoldTitle variant="subtitle2">Horários</StyledBoldTitle>
        <StyledSelect
          id="timeSchedule"
          name="timeSchedule"
          variant="outlined"
          placeholder="Frequência"
          label="Frequência"
          value={values.timeSchedule}
          SelectDisplayProps={{
            'data-testid': 'timeSchedule',
          }}
          color="secondary"
          size="small"
          error={false}
          disabled={disabled}
        >
          {scheduleOptions.map((option) => (
            <StyledMenuItem
              key={option.value}
              value={option.value}
              color="secondary"
            >
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        {errors.timeSchedule && touched.timeSchedule && (
          <ErrorText errorMessage={errors.timeSchedule} />
        )}
        {values.timeSchedule && values.timeSchedule !== 'custom' ? (
          <>
            <FormikTextField
              id="time"
              name="time"
              value={values?.time}
              variant="outlined"
              label="Horário"
              color="secondary"
              inputProps={{ 'data-testid': 'time' }}
              margin="normal"
              autoComplete="off"
              type="time"
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled={disabled}
            />
            {values.time &&
              getMedicationTimes(values.time, values.timeSchedule).map(
                (time, index) => (
                  <MedicationTextRow key={index}>
                    <StyledBoldTitle variant="subtitle2">
                      {index + 1}ª medicação
                    </StyledBoldTitle>
                    <StyledText variant="subtitle2">{time}</StyledText>
                  </MedicationTextRow>
                )
              )}
          </>
        ) : (
          values.timeSchedule && (
            <>
              <CustomTimeInputContainer>
                <FormikTextField
                  id="customTime"
                  name="customTime"
                  value={customTime}
                  variant="outlined"
                  label="Horário"
                  color="secondary"
                  inputProps={{ 'data-testid': 'customTime' }}
                  margin="normal"
                  autoComplete="off"
                  type="time"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCustomTime(e.target.value)
                  }
                  style={{ width: '90%' }}
                  disabled={disabled}
                />
                <AddButtonContainer>
                  <StyledAddButton
                    onClick={handleAddCustomTime}
                    data-testid="add-customTime-button"
                    disabled={!customTime || disabled}
                  >
                    <Add />
                  </StyledAddButton>
                </AddButtonContainer>
              </CustomTimeInputContainer>
              {errors.customTimes && touched.customTimes && (
                <ErrorText errorMessage={errors.customTimes} />
              )}
              {orderedCustomTimes?.map((time, index) => (
                <MedicationTextRow key={index}>
                  <StyledBoldTitle variant="subtitle2">
                    {index + 1}ª medicação
                  </StyledBoldTitle>
                  <StyledText variant="subtitle2">{time}</StyledText>
                  <AddButtonContainer>
                    <StyledRemoveButton
                      disabled={disabled}
                      onClick={() => {
                        setcustomTimeToDelete(time);
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      <Clear fontSize="small" />
                    </StyledRemoveButton>
                  </AddButtonContainer>
                </MedicationTextRow>
              ))}
            </>
          )
        )}
      </FormCardContainer>
      <StyledSimpleDialog
        open={isDeleteDialogOpen}
        handleNo={() => setIsDeleteDialogOpen(false)}
        handleYes={() => handleRemoveCustomTime(customTimeToDelete)}
        title="Remover horário"
        subTitle="Tem certeza de que deseja remover o horário?"
      />
    </>
  );
};

export default TimeSelector;
