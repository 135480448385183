export interface TabOptions {
  key: 'proximos' | 'cobertura-adicional';
  label: 'Plantões' | 'Hora-extra / Cobertura';
  permission: 'isAllowedToReadShift' | 'isAllowedToReadAdditionalShift';
}

export const tabOptions: TabOptions[] = [
  { key: 'proximos', label: 'Plantões', permission: 'isAllowedToReadShift' },
  {
    key: 'cobertura-adicional',
    label: 'Hora-extra / Cobertura',
    permission: 'isAllowedToReadAdditionalShift',
  },
];

export type RouteParams = {
  tab?: 'cobertura-adicional' | 'proximos';
};
