import React from 'react';
import { Centralizer, Button } from './styles';

interface SubmitButtonProps {
  disabled?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ disabled }) => {
  return (
    <Centralizer>
      <Button
        data-testid="submit-measurement-schedule"
        type="submit"
        size="large"
        color="inherit"
        disabled={disabled}
      >
        Salvar
      </Button>
    </Centralizer>
  );
};

export default SubmitButton;
