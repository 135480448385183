import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ReportButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ theme }) => theme.spacing(1.5, 2)};
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const DownloadLink = styled.a`
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  margin: ${({ theme }) => theme.spacing(0, 2)};
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  :hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`;
