import { clientSideScheme } from '@cuidador/whitelabel';
import { FormControlLabel, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex: 1;
  flex-direction: column;
  button {
    align-self: center;
  }
`;

export const Logo = styled.img.attrs(() => {
  const { logoLightUrl } = clientSideScheme();
  return { src: logoLightUrl };
})`
  width: 100%;
  max-width: 500px;
  max-height: 45px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled(Typography).attrs({
  align: 'center',
})`
  margin: ${({ theme }) => theme.spacing(2, 0, 0, 0)}px;
`;

export const TitleBold = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})`
  text-decoration: underline;
  cursor: pointer;
`;

export const VisibilityContainer = styled.div<{ $active?: boolean }>`
  display: ${({ $active }) => ($active ? 'inherit' : 'none')};
  flex-direction: inherit;
  flex: inherit;
  form {
    flex: 1;
    display: ${({ $active }) => ($active ? 'inherit' : 'none')};
    flex-direction: inherit;
    flex: inherit;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.palette.secondary.main};
  & a,
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const PasswordInstructions = styled(Typography).attrs({
  variant: 'caption',
})`
  margin: ${({ theme }) => theme.spacing(1)}px;
`;

export const Line = styled.div`
  align-self: center;
  width: 90%;
  height: 0px;
  border-bottom: 1px solid black;
  margin: ${({ theme }) => theme.spacing(5, 0)};
`;

export const TermsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 5, 5, 5)};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  button {
    margin: ${({ theme }) => theme.spacing(0, 1)};
  }
`;

export const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SuccessContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
