import React, { useState } from 'react';
import { UserModel } from '@cuidador/database';
import { Zoom } from '@material-ui/core';
import {
  Container,
  InnerContainer,
  StyledAvatar,
  StyledTypography,
  StyledSelectButton,
  DisabledSelectButton,
  StyledTooltip,
} from './styles';

interface UserListProps {
  users: (UserModel & { roleTitle?: string })[];
  findAndReturnAvatarImage: (id?: number) => string;
  allowedToRelateUserPatient: (userOrganizationId?: number | null) => boolean;
  onConfirm: (id?: number) => void;
}

const UserList: React.FC<UserListProps> = ({
  users,
  findAndReturnAvatarImage,
  allowedToRelateUserPatient,
  onConfirm,
}) => {
  const initialStateTooltipsStatus = Array.from(
    { length: users.length },
    () => {
      return { isTooltipOpen: false };
    }
  );

  const [tooltipsStatus, setTooltipsStatus] = useState(
    initialStateTooltipsStatus
  );

  const handleToggleTooltipsStatus = (userIndex: number) => {
    const newStateTooltipsStatus = tooltipsStatus.map((tooltip, index) => {
      if (index === userIndex) {
        return { ...tooltip, isTooltipOpen: !tooltip.isTooltipOpen };
      }

      return tooltip;
    });

    setTooltipsStatus(newStateTooltipsStatus);
  };

  return (
    <>
      {users?.map((user, index) => (
        <Container key={user.id}>
          <StyledAvatar src={findAndReturnAvatarImage(user.id)} />
          <InnerContainer>
            <StyledTypography variant="subtitle1" color="inherit">
              <b>{user.name}</b>
            </StyledTypography>
            <StyledTypography variant="caption">
              {user.roleTitle}
            </StyledTypography>
          </InnerContainer>
          {allowedToRelateUserPatient(user.organizationId) ? (
            <StyledSelectButton
              onClick={() => onConfirm(user.id)}
              variant="contained"
            >
              Vincular
            </StyledSelectButton>
          ) : (
            <StyledTooltip
              open={tooltipsStatus[index].isTooltipOpen}
              onClose={() => setTooltipsStatus(initialStateTooltipsStatus)}
              title="Vinculado a outra organização. Contate o suporte"
              TransitionComponent={Zoom}
              placement="left"
              disableHoverListener
              arrow
            >
              <DisabledSelectButton
                onClick={() => handleToggleTooltipsStatus(index)}
                variant="contained"
              >
                Vincular
              </DisabledSelectButton>
            </StyledTooltip>
          )}
        </Container>
      ))}
    </>
  );
};

export default UserList;
