import { EventScheduleModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store';

const endpoint = '/care/event/schedule';
const reportEndpoint = '/report/measurement/patient';
const makeDownloadReportEndpoint = (patientId: Id) =>
  `/media/patient/${patientId}/measurement-report`;

const initialData: ReducerData<EventScheduleModel> = {
  byId: {} as Record<string, Item<EventScheduleModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useMeasurementSchedule = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventScheduleModel>(),
    initialData
  );

  const getAllByPatientId = useCallback(async (patientId: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/measurement/by-patient/${patientId}`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const createReportByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      await axios.post(`${reportEndpoint}/${id}`);
      dispatch({ type: 'SUCCESS' });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getReportDownloadLinkByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(makeDownloadReportEndpoint(id));
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    remove,
    getAllByPatientId,
    getReportDownloadLinkByPatientId,
    createReportByPatientId,
  };
};

export default useMeasurementSchedule;
