import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const StyledTypographyTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-weight: bold;
  font-family: Work Sans;
`;

export const StyledTypographyAdditionalCoverage = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.yellow};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  box-sizing: border-box;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const TitleContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  text-align: left;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
`;

export const TitleHeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const ExecutionMessageContainer = styled(TitleContainer)`
  div {
    margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;
