import React from 'react';

interface ExecutionUserNameProps {
  executionUserName: string;
}

const ExecutionUserName: React.FC<ExecutionUserNameProps> = ({
  executionUserName,
}) => {
  return <>| {executionUserName}</>;
};

export default ExecutionUserName;
