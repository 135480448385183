import { MedicationModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';

interface GetPatientMedicationParams extends PaginatedRequestParams {
  minFrequencyEndsAt?: string;
  [key: string]: string | number | undefined;
}

const endpoint = '/care/medication';
const reportEndpoint = '/report/medication';
const makeDownloadReportEndpoint = (patientId: Id) =>
  `/media/patient/${patientId}/medication-report`;

const initialData: ReducerData<MedicationModel> = {
  byId: {} as Record<string, Item<MedicationModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useMedication = () => {
  const [state, dispatch] = useReducer(
    createReducer<MedicationModel>(),
    initialData
  );

  const getAllByPatientId = useCallback(
    async (id: Id, params?: GetPatientMedicationParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(`${endpoint}/by-patient/${id}`, {
          params,
        });
        dispatch({ type: 'GET_ALL', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const getScheduledMedicationsByPatientId = useCallback(
    async (id: Id, params?: GetPatientMedicationParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(
          `${endpoint}/scheduled/by-patient/${id}`,
          { params }
        );
        dispatch({ type: 'SUCCESS' });
        return response.data;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patch = useCallback(async (id: Id, data: Partial<MedicationModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const post = useCallback(async (data: Partial<MedicationModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const createReportByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${reportEndpoint}/${id}`);
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getReportDownloadLinkByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(makeDownloadReportEndpoint(id));
      dispatch({ type: 'SUCCESS' });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getAllByPatientId,
    getById,
    patch,
    post,
    remove,
    createReportByPatientId,
    getReportDownloadLinkByPatientId,
    getScheduledMedicationsByPatientId,
  };
};

export default useMedication;
