import React from 'react';
import { Avatar } from '@material-ui/core';
import PatientRelationButton from '../PatientRelationButton';
import { resolveMemberStatusLabel, resolveMemberStatusIcon } from '../utils';
import {
  CardBox,
  ClickableArea,
  UserInfoContainer,
  UserInfo,
  RoleTitle,
  RoleTitleContainer,
} from './styles';

interface OrganizationMemberSelectCardProps {
  id: number;
  name: string;
  age?: number;
  profilePictureURL?: string;
  status: string;
  roleTitle?: string;
  isPatientRelatedToUser?: boolean;
  onRowClick?: () => void;
  onRelationClick?: () => void;
}

const OrganizationMemberSelectCard: React.FC<OrganizationMemberSelectCardProps> = ({
  id,
  name,
  age,
  profilePictureURL,
  status,
  roleTitle,
  isPatientRelatedToUser = true,
  onRowClick,
  onRelationClick,
}) => {
  const buttonStyle = isPatientRelatedToUser ? 'dark' : 'light';

  return (
    <CardBox>
      <ClickableArea
        data-testid={`member-select-card-${id}`}
        onClick={() => {
          if (onRowClick) {
            onRowClick();
          }
        }}
      >
        <Avatar src={profilePictureURL} alt={name} data-testid="avatar" />
        <UserInfoContainer>
          <UserInfo statusLabel={resolveMemberStatusLabel(status)}>
            {name}
          </UserInfo>
          {age != null && (
            <UserInfo statusLabel={resolveMemberStatusLabel(status)}>
              {age} anos {resolveMemberStatusIcon(status)}
            </UserInfo>
          )}
          {roleTitle && (
            <RoleTitleContainer>
              <RoleTitle statusLabel={resolveMemberStatusLabel(status)}>
                {roleTitle}
              </RoleTitle>
              {resolveMemberStatusIcon(status)}
            </RoleTitleContainer>
          )}
        </UserInfoContainer>
      </ClickableArea>
      <PatientRelationButton
        buttonStyle={buttonStyle}
        onClick={onRelationClick}
      />
    </CardBox>
  );
};

export default OrganizationMemberSelectCard;
