import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PostalCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0;
  width: 100%;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const InterviewCardContainer = styled.div`
  width: 80%;

  div {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    margin-left: ${({ theme }) => theme.spacing(-2)}px;
  }
`;
