import styled from 'styled-components';
import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledTabItem = styled(Tab)`
  max-width: 205px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: 400;
  border-radius: 5px 5px 0 0;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;
