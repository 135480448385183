import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../../assets/phone-icon.svg';

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: ${({ theme }) => theme.spacing(7)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const LogoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(8)}px
    ${({ theme }) => theme.spacing(4)}px;
  img {
    width: 100%;
  }
`;

export const LogoutContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
