import { CardDataType, PlanModel } from '@cuidador/database';
import { CompanyDataType } from '@cuidador/database/src/types';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store/index';

const ENDPOINT = '/user/plan';

type PromotionalCode = {
  promotionalCode: string;
};

const initialData: ReducerData<PlanModel> = {
  byId: {} as Record<string, Item<PlanModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const usePlan = () => {
  const [state, dispatch] = useReducer(createReducer<PlanModel>(), initialData);

  const getAvailablePlans = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get<Item<PlanModel>[]>(
        `${ENDPOINT}/available`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: 'ERROR', payload: error });
      }
      throw error;
    }
  }, []);

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get<Item<PlanModel>>(`${ENDPOINT}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data;
    } catch (error) {
      if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
      throw error;
    }
  }, []);

  const signAvailablePlan = useCallback(
    async (planId: string, body: CardDataType) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post(`${ENDPOINT}/${planId}/paid`, body);
        dispatch({ type: 'CREATE', payload: response.data });
        return response;
      } catch (error) {
        if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
        throw error;
      }
    },
    []
  );

  const signCompanyPlan = useCallback(
    async (planId: string, body: CompanyDataType) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post(
          `${ENDPOINT}/${planId}/company`,
          body
        );
        dispatch({ type: 'CREATE', payload: response.data });
        return response;
      } catch (error) {
        if (error instanceof Error) dispatch({ type: 'ERROR', payload: error });
        throw error;
      }
    },
    []
  );

  const signPromotionalPlan = useCallback(
    async (planId: Id, body?: PromotionalCode) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post<Item<PlanModel>>(
          `${ENDPOINT}/${planId}/promotional`,
          body
        );
        dispatch({ type: 'CREATE', payload: response.data });
        return response;
      } catch (err) {
        if (err instanceof Error) {
          dispatch({ type: 'ERROR', payload: err });
        }
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    getAvailablePlans,
    getById,
    signAvailablePlan,
    signPromotionalPlan,
    signCompanyPlan,
  };
};

export default usePlan;
