import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledSimpleDialog from '../../../../components/StyledSimpleDialog';
import useShiftSchedule from '../../../../hooks/useShiftSchedule';
import { resolveErrorMessage } from '../../../../utils/error';
import { Backdrop, BackdropCircularProgress, Container } from './styles';

import { PtBrWeekdayEnum } from '@cuidador/database';
import ShiftScheduleUpsertForm, {
  formDataToShiftScheduleModel,
  FormValues,
  shiftScheduleModelToFormData,
} from '../../../../components/ShiftScheduleUpsertForm';
import { StyledFieldset } from '../../../../components/StyledFieldset';
import useCanAccess from '../../../../hooks/useCanAccess';
import { RemoveButton } from '../../../../components/RemoveButton'
import { Header } from '../../../../components/Header'
import { BackButton } from '../../../../components/BackButton'
import { PageTitle } from '../../../../components/PageTitle'

const formInitialValue = {
  name: '',
  startingTime: '',
  duration: '',
  days: [] as PtBrWeekdayEnum[],
};

const ShiftScheduleUpdate: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { patch, remove, getById, byId, loading } = useShiftSchedule();
  const [dialogStatus, setDialogStatus] = useState(false);
  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const history = useHistory();

  const { isAllowedToUpdate, isAllowedToDelete } = useCanAccess(
    'care/shiftschedule'
  );

  const id = parseInt(params.id);
  const shiftSchedule = id != null ? byId[id] : null;

  useEffect(
    function fetchInitialValues() {
      // If 'id' exists, it's an Update form.
      // Else, it's a creation
      if (id) {
        getById(id);
      }
    },
    [id]
  );

  useEffect(
    function setInitialValues() {
      if (shiftSchedule) {
        formikRef.current?.setValues(
          shiftScheduleModelToFormData(shiftSchedule)
        );
      }
    },
    [shiftSchedule]
  );

  const handleSubmit = (values: FormValues) => {
    return patch(id!, formDataToShiftScheduleModel(values))
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        formikRef.current?.setValues(values);
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Editar plantão' />
        }
        rightContent={
          <RemoveButton action={() => setDialogStatus(true)} />
        }
      />
      <Container>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          <ShiftScheduleUpsertForm
            innerRef={(ref) => (formikRef.current = ref)}
            initialValues={formInitialValue}
            onSubmit={handleSubmit}
            isAllowedToUpdate={isAllowedToUpdate}
          />
        </StyledFieldset>
        <StyledSimpleDialog
          open={dialogStatus}
          handleNo={() => setDialogStatus(false)}
          handleYes={() => handleDelete(id!)}
          title="Excluir Plantão?"
          subTitle="Tem certeza que deseja excluir o plantão?"
        />
      </Container>
    </>
  );
};

export default ShiftScheduleUpdate;
