import { ShiftModel } from '@cuidador/database';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AdditionalCoverageCard from '../../../components/AdditionalCoverageCard';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import MedicBalloon from '../../../components/MedicBalloon';
import { AuthContext } from '../../../contexts/auth';
import useAdditionalCoverage from '../../../hooks/useAdditionalCoverage';
import { Container, StyledLink } from './styles';
import { getAdditionalCoverageCardContent } from './utils';

const AdditionalCoverage: React.FC = () => {
  const [additionalCoverages, setAdditionalCoverages] = useState<ShiftModel[]>(
    []
  );

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { getAllAvailable, loading } = useAdditionalCoverage();

  useEffect(() => {
    if (!patientId) return;

    getAllAvailable(patientId)
      .then(({ data }) => {
        setAdditionalCoverages([...data]);
      })
      .catch(() => {
        toast.error('Erro ao solicitar as coberturas adicionais.');
      });
  }, [patientId]);

  return (
    <Container>
      <MedicBalloon text="Em caso de hora-extra ou cobertura pontual de plantão, é aqui que você deve cadastrar. A forma de acesso pelos cuidadores é a mesma que em um plantão recorrente." />
      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        additionalCoverages.map((shift) => (
          <StyledLink to={`/cobertura-adicional/${shift.id}`} key={shift.id}>
            <AdditionalCoverageCard
              id={shift.id}
              name={shift.name}
              content={getAdditionalCoverageCardContent(
                shift.plannedToStartAt,
                shift.plannedToEndAt
              )}
              key={shift.id}
            />
          </StyledLink>
        ))
      )}
    </Container>
  );
};

export default AdditionalCoverage;
