import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdditionalCoverageForm, {
  FormValues,
} from '../../../../components/AdditionalCoverageForm';
import { additionalCoverageDataToFormData } from '../../../../components/AdditionalCoverageForm/utils';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import { StyledFieldset } from '../../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../../components/StyledSimpleDialog';
import useAdditionalCoverage from '../../../../hooks/useAdditionalCoverage';
import useCanAccess from '../../../../hooks/useCanAccess';
import { resolveErrorMessage } from '../../../../utils/error';
import { Container } from './styles';
import { Header } from '../../../../components/Header'
import { RemoveButton } from '../../../../components/RemoveButton'
import { BackButton } from '../../../../components/BackButton'
import { PageTitle } from '../../../../components/PageTitle'

const AdditionalCoverageUpdate: React.FC = () => {
  const initialValues = {
    name: '',
    date: '',
    time: '',
    duration: '',
  };

  const [dialogStatus, setDialogStatus] = useState(false);
  const { remove, getById, loading } = useAdditionalCoverage();

  const { isAllowedToUpdate, isAllowedToDelete } = useCanAccess(
    'care/shift.additional-coverage'
  );
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  useEffect(() => {
    if (id) {
      getById(id)
        .then((data) => {
          if (data)
            formikRef.current?.setValues(
              additionalCoverageDataToFormData(data)
            );
        })
        .catch((err: AxiosError<APIError>) => {
          history.replace('/plantoes/cobertura-adicional');
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        });
    }
  }, [id]);

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.replace('/plantoes/cobertura-adicional');
        toast.success('Hora-extra / cobertura removida');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Visualizar hora-extra / cobertura' />
        }
        rightContent={
          isAllowedToDelete && (<RemoveButton action={() => setDialogStatus(true)} />)
        }
      />
      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        <Container>
          <StyledFieldset disabled={!isAllowedToUpdate}>
            <AdditionalCoverageForm
              innerRef={(ref) => (formikRef.current = ref)}
              initialValues={initialValues}
              onSubmit={() => {
                return;
              }}
              isDisabled
            />
          </StyledFieldset>
          <StyledSimpleDialog
            open={dialogStatus}
            handleNo={() => setDialogStatus(false)}
            handleYes={() => handleDelete(id!)}
            title="Excluir hora-extra / cobertura?"
            subTitle="Tem certeza que deseja excluir a hora-extra / cobertura?"
          />
        </Container>
      )}
    </>
  );
};

export default AdditionalCoverageUpdate;
