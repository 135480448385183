import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addDays } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth';
import useShiftReport from '../../../hooks/useShiftReport';
import StyledSimpleModal from '../../StyledSimpleModal';
import {
  Button,
  Centralizer,
  Form,
  StyledDatePicker,
  StyledError,
  StyledOkStep,
  StyledOptions,
  StyledOptionsTypography,
  StyledParagraph,
  StyledPicker,
} from './styles';
import {
  FormValues,
  initialValues,
  ModalProps,
  validationSchema,
} from './utils';

const ExportModal: React.FC<ModalProps> = ({ onClose, opened }) => {
  const [okStep, setOkStep] = useState(false);
  const { requestReport } = useShiftReport();
  const { userInfo } = useContext(AuthContext);

  const resetModal = () => {
    onClose();
    setOkStep(false);
  };

  const submitReportRequest = (values: FormValues) => {
    if (!userInfo?.activePatientId) return;

    const startDate = new Date(values.dateFrom.toISOString());
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(values.dateUntil.toISOString());
    const now = new Date();
    if (
      values.dateUntil.getFullYear() !== now.getFullYear() ||
      values.dateUntil.getMonth() !== now.getMonth() ||
      values.dateUntil.getDate() !== now.getDate()
    ) {
      endDate = addDays(endDate, 1);
      endDate.setHours(0, 0, 0, 0);
    }

    setOkStep(true);
    requestReport(
      userInfo.activePatientId,
      startDate.toISOString(),
      endDate.toISOString(),
      userInfo.id!
    )
      .catch(() => {
        toast.error('Erro ao solicitar relatório');
      })
      .then(() => {
        toast.success('Relatório disponível para download');
      });
  };

  return (
    <StyledSimpleModal open={opened} title={'Gerar Relatório'}>
      {!okStep && (
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitReportRequest}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
              <Form noValidate={true}>
                <Typography>Selecione o periodo desejado:</Typography>
                <StyledOptionsTypography>
                  <StyledPicker>De:</StyledPicker>
                  <StyledPicker>Até:</StyledPicker>
                </StyledOptionsTypography>
                <StyledOptions>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                    <StyledDatePicker
                      data-testid={'data-from-input'}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      name="dateFrom"
                      maxDate={new Date()}
                      value={values.dateFrom}
                      cancelLabel="Cancelar"
                      helperText={<StyledError>{errors.dateFrom}</StyledError>}
                      onChange={(date: Date | null) => {
                        setFieldValue('dateFrom', date, true);
                      }}
                    />

                    <StyledDatePicker
                      data-testid={'data-until-input'}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      name="dateUntil"
                      helperText={<StyledError>{errors.dateUntil}</StyledError>}
                      value={values.dateUntil}
                      cancelLabel="Cancelar"
                      onChange={(date: Date | null) => {
                        setFieldValue('dateUntil', date, true);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </StyledOptions>
                <StyledOptions>
                  <Button size="medium" color="inherit" onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    color="inherit"
                    disabled={isSubmitting}
                  >
                    Exportar PDF
                  </Button>
                </StyledOptions>
              </Form>
            );
          }}
        </Formik>
      )}

      {okStep && (
        <>
          <StyledOkStep>
            <StyledParagraph>
              O seu arquivo já está sendo gerado.
            </StyledParagraph>
            <StyledParagraph>
              Em instantes, você poderá acessá-lo através do botão [Consultar
              relatórios].
            </StyledParagraph>
          </StyledOkStep>
          <Centralizer>
            <Button size="medium" color="inherit" onClick={resetModal}>
              Ok
            </Button>
          </Centralizer>
        </>
      )}
    </StyledSimpleModal>
  );
};

export default ExportModal;
