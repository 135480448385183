import * as Sentry from '@sentry/react';
import { FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PatientUpsertForm from '../../../components/PatientUpsertForm';
import MaxPatientModal from '../../../components/PatientUpsertForm/MaxPatientModal';
import {
  formDataToPatientModel,
  FormValues,
  initialValues,
} from '../../../components/PatientUpsertForm/utils';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useInterview from '../../../hooks/useInterview';
import usePatient from '../../../hooks/usePatient';
import { resolveErrorMessage } from '../../../utils/error';
import { Container } from '../styles';
import { Header } from '../../../components/Header'
import { BackButton } from '../../../components/BackButton'
import { PageTitle } from '../../../components/PageTitle'
import { PscButton } from '../../../components/PscButton'

const PatientRegister: React.FC = () => {
  const history = useHistory();
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const { post, uploadProfilePicture } = usePatient();
  const { post: createInterview } = useInterview();
  const { setActive } = usePatient();
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const [visibleModal, setVisibleModal] = useState(true);

  const {
    isAllowedToRead: isAllowedToReadInterview,
    isAllowedToCreate: isAllowedToCreateInterview,
  } = useCanAccess('care/interview');

  const { isAllowedToUpdate: isAllowedToAnswerInterview } = useCanAccess(
    'care/interview/answer'
  );

  useEffect(() => {
    const patients = userInfo?.user?.patients?.length || 0;
    const maxAllowedPatients =
      userInfo?.user?.organization?.subscription?.maxAllowedPatients || 0;
    // patients related to guardian user (of the organization) must be less than max Allowed Patients in the organization
    if (patients < maxAllowedPatients) {
      setVisibleModal(false);
    }
  }, []);

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await post(formDataToPatientModel(values));

      if (isAllowedToCreateInterview) {
        await createInterview({
          interviewedAt: new Date().toISOString(),
          name: 'Primeira Entrevista',
          patientId: response.data.id,
        });
      }
      await setActive(response.data.id);
      await refreshUserInfo();

      if (values.picture) {
        try {
          await uploadProfilePicture(values.picture, response.data.id);
        } catch (err) {
          toast.error(
            'Houve um erro durante o envio de sua imagem, tente novamente acessando seu perfil'
          );
          Sentry.captureException(err);
        }
      }
      toast.success('Criado com sucesso!');

      if (
        isAllowedToReadInterview &&
        isAllowedToCreateInterview &&
        isAllowedToAnswerInterview
      ) {
        history.push('/pre-entrevista');
      } else {
        toast.error('Você não possui permissão para preencher a entrevista.');
        history.push('/cadastros');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const displayMessage = resolveErrorMessage(err);
      const fieldError = err.response?.data.context?.key;
      if (fieldError && Object.keys(initialValues).includes(fieldError)) {
        formikRef.current?.setFieldError(fieldError, displayMessage || '');
      } else {
        toast.error(displayMessage);
      }
    }
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title='Adicionar pessoa sob cuidado' />
        }
        rightContent={
          <PscButton />
        }
      />
      {visibleModal && (
        <MaxPatientModal
          onClose={() => history.goBack()}
          opened={true}
          maxPatients={
            userInfo?.user?.organization?.subscription?.maxAllowedPatients || 0
          }
        />
      )}

      <Container>
        <PatientUpsertForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          innerRef={(ref) => (formikRef.current = ref)}
          formikRef={formikRef}
        />
      </Container>
    </>
  );
};

export default PatientRegister;
