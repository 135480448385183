export type ColorsType = 'primary' | 'secondary' | 'white' | 'text' | 'black';
import { resolveTheme } from './theme';

type ColorMappingType = Record<ColorsType, string>;

const { muiTheme, styledTheme } = resolveTheme();
export const colorMapping: ColorMappingType = {
  primary: muiTheme.palette.primary.main,
  secondary: muiTheme.palette.secondary.main,
  white: muiTheme.palette.common.white,
  text: muiTheme.palette.secondary.main,
  black: styledTheme.palette.extra.color.grey.dark,
};

export const hexToRGBArray = (hex: string): number[] => {
  const h = '0123456789ABCDEF';
  const r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2]);
  const g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4]);
  const b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6]);
  return [r, g, b];
};
