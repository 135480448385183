import React from 'react';
import { StyledBlueChip, StyledWhiteChip } from './styles';

const ToggleableChip: React.FC<{
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
  value?: string;
  isActive: boolean;
}> = ({ label, onClick, icon, value, isActive }) => {
  const StyledChip = isActive ? StyledBlueChip : StyledWhiteChip;

  return (
    <StyledChip
      label={label}
      onClick={onClick}
      icon={icon}
      data-testid={`${value}-chip`}
    />
  );
};

export default ToggleableChip;
