import React from 'react';
import { ChipIcon, StyledChip, StyledChipContent } from './styles';

export interface FilterChipProps {
  icon?: string;
  label: string;
  onClick: () => void;
  active?: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({
  icon,
  label,
  active,
  ...rest
}) => {
  return (
    <StyledChip
      {...rest}
      $active={active}
      variant="contained"
      disableElevation
      size="small"
    >
      <StyledChipContent>
        {!!icon && <ChipIcon src={icon} />}
        <span>{label}</span>
      </StyledChipContent>
    </StyledChip>
  );
};

export default FilterChip;
