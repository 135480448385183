import { ShiftReportModel } from '@cuidador/database';
import { Dialog } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth';
import useShiftReport from '../../../hooks/useShiftReport';
import LoadingBackdrop from '../../LoadingBackdrop';
import ShiftReportCard from './ShiftReportCard';
import {
  Container,
  LargeCloseIcon,
  StyledDivider,
  StyledIconButton,
  Title,
} from './styles';
import { ShiftReportListDialogProps, Transition } from './utils';

const ShiftReportListDialog: React.FC<ShiftReportListDialogProps> = ({
  open,
  onClose,
}) => {
  const [shiftReports, setShiftReports] = useState<ShiftReportModel[]>([]);
  const { getAllFromPatient, loading } = useShiftReport();
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const fetchData = async (id: number) => {
    try {
      const reports = await getAllFromPatient(id);
      setShiftReports(reports);
    } catch (error) {
      toast.error('Erro ao buscar relatórios');
    }
  };

  useEffect(() => {
    if (!patientId) return;
    let mounted = true;
    if (mounted) fetchData(patientId);
    return () => {
      mounted = false;
    };
  }, [patientId]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Container>
        <StyledIconButton onClick={onClose}>
          <LargeCloseIcon />
        </StyledIconButton>
        <Title align="left">Download de arquivos</Title>
        <StyledDivider />
        {loading ? (
          <LoadingBackdrop loading={loading} />
        ) : (
          shiftReports.map((report) => (
            <ShiftReportCard key={report.id} report={report} />
          ))
        )}
      </Container>
    </Dialog>
  );
};

export default ShiftReportListDialog;
