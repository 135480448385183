import { CompanyDataType } from '@cuidador/database/src/types';
import * as yup from 'yup';
import { validateCnpj } from '../../utils/inputs';

export const initialCompanyValues = {
  cnpj: '',
  companyName: '',
  tradingName: '',
};

export const initialAddressValues = {
  postalCode: '',
  country: '',
  province: '',
  city: '',
  address: '',
  addressNumber: '',
  additional: '',
};

export type FormCompanyValues = {
  cnpj: string;
  companyName: string;
  tradingName: string;
};

export type FormAddressValues = {
  postalCode: string;
  country: string;
  province: string;
  city: string;
  address: string;
  addressNumber: string;
  additional: string;
};

export const validationCompanySchema = yup.object().shape({
  companyName: yup
    .string()
    .required('Por favor, insira o nome')
    .min(3, 'O nome deve conter ao menos 3 caracteres'),
  tradingName: yup
    .string()
    .required('Por favor, insira o nome')
    .min(3, 'O nome deve conter ao menos 3 caracteres'),
  cnpj: yup
    .string()
    .required('Por favor, insira o CNPJ')
    .test('isCnpjValid', 'Por favor, insira um CNPJ válido', (value) =>
      validateCnpj(value || '')
    ),
});

export const validationAddressSchema = yup.object().shape({
  postalCode: yup
    .string()
    .required('Por favor, insira o CEP')
    .min(9, 'Por favor, insira um CEP válido')
    .max(10, 'Por favor, insira um CEP válido'),
  country: yup.string().required('Por favor, insira o país'),
  province: yup
    .string()
    .required('Por favor, insira a UF')
    .min(2, 'Por favor, insira uma UF válida')
    .max(3, 'Por favor, insira uma UF válida'),
  city: yup.string().required('Por favor, insira a cidade'),
  address: yup
    .string()
    .required('Por favor, insira o endereço')
    .min(3, 'Por favor, insira um endereço válido'),
  addressNumber: yup.string().required('Por favor, insira o número'),
});

export const formValuesToCompanySubscriptionRequest = (
  companyValues: FormCompanyValues,
  addressValues: FormAddressValues,
  district: string
) => {
  const postalCode = addressValues.postalCode.replace('-', '');
  const cnpj = unformattedCnpj(companyValues.cnpj);

  const body: CompanyDataType = {
    cnpj,
    companyName: companyValues.companyName,
    tradingName: companyValues.tradingName,
    address: {
      line1: `${addressValues.addressNumber}, ${addressValues.address}, ${district}`,
      zipCode: postalCode,
      city: addressValues.city,
      state: addressValues.province,
      country: 'BR',
    },
  };
  if (addressValues.additional) body.address.line2 = addressValues.additional;
  return body;
};

const unformattedCnpj = (cnpj: string) =>
  cnpj.replace('-', '').replace('/', '').split('.').join('');
