import { Accordion, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { StyledFormikTextField } from '../../components/FormCardContainer';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fafafa;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const StyledIcon = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  vertical-align: sub;
`;

export const StyledPrice = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTitle = styled(StyledTypography)`
  font-weight: 550;
`;

export const StyledPlan = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledPlanDescription = styled.div`
  margin-left: ${({ theme }) => theme.spacing(-2)}px;
  & ul {
    color: ${({ theme }) => theme.palette.extra.color.grey.main};
  }
`;

export const StyledLineSimple = styled.hr`
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
  width: 90%;
  margin: ${({ theme }) => theme.spacing(1.5, 0)};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledInfo = styled.div`
  width: 90%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  text-decoration: none;
  vertical-align: text-bottom;
  & h6 ul {
    color: ${({ theme }) => theme.palette.extra.color.grey.main};
    margin-left: ${({ theme }) => theme.spacing(-3)}px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const StyledFirstInRow = styled(StyledFormikTextField)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPayButton = styled(StyledButton)`
  width: 50%;
  align-self: center;
`;

export const StyledInfoPrice = styled.span`
  float: right;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
`;
