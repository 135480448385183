import styled from 'styled-components';
import { DialogTitle, Typography, InputAdornment } from '@material-ui/core';

export const StytledDialogTitle = styled(DialogTitle)`
  & > h2 {
    font-size: 1rem;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 0.95rem;
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  }

  & > div > span {
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 0.65rem;
  }
`;
