import { Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { ReactComponent as MedicationIcon } from '../../assets/Icon-pills-noBackground.svg';

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.info.main};
  color: white;
  border-radius: 5px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  fill: white;
`;

export const StyledTitle = styled(Typography)`
  font-size: 1.1em;
  font-weight: bold;
  margin-left: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledMedicationIcon = styled(MedicationIcon)`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
`;

export const StyledTypography = styled(Typography)`
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const DoseTimesContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
