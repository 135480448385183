import { ShiftExecutionModel, ShiftModel } from '@cuidador/database';
import { format, parseISO } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

export function getShiftCardBackgroundColor(shift: ShiftModel) {
  if (shift.status === 'not_done') return '#C0C0C0';
  if (shift.isAdditionalCoverage) return '#EFC1401A';
  return 'white';
}

export function getDayGroup(date: Date) {
  const weekday = format(date, 'EEE', { locale: ptBrLocale });
  const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
  const day = format(date, 'dd', { locale: ptBrLocale });
  const month = format(date, 'MMMM', { locale: ptBrLocale });
  return `${capitalizedWeekday} ${day} de ${month}`;
}

export function getShiftRealTime(shift: ShiftModel) {
  if (
    (shift.status !== 'done' && shift.status !== 'done_automatically') ||
    !shift.startedAt
  )
    return;
  const start = format(new Date(shift.startedAt), 'HH:mm');
  if (!shift.endedAt) return `Horário: ${start}`;
  const end = format(new Date(shift.endedAt), 'HH:mm');
  return `${start} às ${end}`;
}

export const shiftHasNotStarted = (shift: ShiftModel) => {
  return (
    shift.status !== 'done' &&
    shift.status !== 'done_automatically' &&
    shift.status !== 'in_progress'
  );
};

export const isShiftMenuVisible = (shift: ShiftModel) => {
  return shift.status === 'done' || shift.status === 'done_automatically';
};

export const isShiftExpelCaregiverButtonVisible = (
  shift: ShiftModel,
  isAllowedToExpel: boolean
) => {
  return shift.status === 'in_progress' && isAllowedToExpel;
};

export const isShiftWithContestation = (shift: ShiftModel) => {
  return shift.timeContestations && shift.timeContestations.length > 0;
};

export const shiftWasNotContestedYet = (shift: ShiftModel) => {
  return shift.timeContestations && shift.timeContestations.length === 0;
};

export const isAnnullableShiftExecution = (execution: ShiftExecutionModel) => {
  return execution.status === 'done' && !isAnnulledShiftExecution(execution);
};

export const isAnnulledShiftExecution = (execution: ShiftExecutionModel) => {
  return execution.isAnnulled;
};

export function getShiftExecutionRealTime(
  execution: ShiftExecutionModel
): string {
  let formattedString = '';

  if (!execution.startedAt) return formattedString;

  const start = format(parseISO(execution.startedAt), 'HH:mm');

  formattedString += `${start}`;

  if (!execution.endedAt) return formattedString;

  const end = format(parseISO(execution.endedAt), 'HH:mm');

  formattedString += ` até ${end}`;

  return formattedString;
}

export const isShiftExecutionWithContestation = (
  execution: ShiftExecutionModel
) => execution.timeContestations && execution.timeContestations.length > 0;
