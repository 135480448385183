import styled from 'styled-components';

export const Container = styled.div`
  white-space: nowrap;
  display: flex;

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const LogoImage = styled.img`
  height: 25px;
  width: 25px;
  margin: 2px;
`;
