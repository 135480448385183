import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledBuyButton = styled(StyledButton)`
  margin: ${({ theme }) => theme.spacing(2)}px;
`;
