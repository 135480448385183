import { PatientModel, ProfessionalReportModel } from '@cuidador/database';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ProfessionalReportList from '../../components/ProfessionalReportList';
import StyledButton from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import useProfessionalReport from '../../hooks/UseProfessionalReport';
import { Container } from './styles';
import { Header } from '../../components/Header'
import { BackButton } from '../../components/BackButton'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'
const ProfessionalReport: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { getByPatientId, loading, total } = useProfessionalReport();
  const params = useParams<{ patientId: string }>();
  const patientId = parseInt(params.patientId);
  const [patient, setPatient] = useState<PatientModel>();
  const [page, setPage] = useState(0);
  const [professionalReports, setProfessionalReports] = useState<
    ProfessionalReportModel[]
  >([]);

  useEffect(() => {
    handlePatientName();
  }, []);

  useEffect(() => {
    loadProfessionalReports();
  }, [page]);

  const loadProfessionalReports = async () => {
    if (patientId) {
      getByPatientId(patientId, {
        page,
        limit: 10,
        orderBy: 'date',
        order: 'DESC',
      })
        .then((data) => {
          const loadedReports = data.results;
          if (page === 0) {
            setProfessionalReports(loadedReports);
          } else {
            setProfessionalReports([...professionalReports, ...loadedReports]);
          }
        })
        .catch(() =>
          toast.error('Erro ao carregar avaliações multiprofissional.')
        );
    }
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const handlePatientName = () => {
    const guardianPatientes = userInfo?.user?.patients;
    const patient = guardianPatientes?.find(
      (patient) => patient.id === patientId
    );
    setPatient(patient);
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title={`Avaliação Multiprofissional ${patient?.name || ''}`} />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <ProfessionalReportList reports={professionalReports} />
        {loading && <LoadingBackdrop loading />}
        {professionalReports.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="default"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
    </>
  );
};

export default ProfessionalReport;
