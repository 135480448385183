import React from 'react';

import {
  Container,
  StyledBlankDiv,
  StyledDiv,
  StyledElipseBlueBot,
  StyledElipseBlueTop,
  StyledElipseGreenBot,
  StyledElipseGreenTop,
  Toolbar,
} from './styles';

export type HeaderProps = {
  leftContent?: React.ReactNode;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export function Header(props: HeaderProps) {
  const { leftContent, centerContent, rightContent } = props;

  return (
    <Container>
      <Toolbar>
        {leftContent ? leftContent : <StyledBlankDiv />}
        {centerContent ? centerContent : <StyledBlankDiv />}
        {rightContent ? rightContent : <StyledBlankDiv />}
      </Toolbar>
      <StyledDiv>
        <StyledElipseBlueTop />
        <StyledElipseGreenTop />
        <StyledElipseBlueBot />
        <StyledElipseGreenBot />
      </StyledDiv>
    </Container>
  );
}
