import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const Button = styled(StyledButton)`
  width: 40%;
`;
