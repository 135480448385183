import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MedicBalloon from '../../components/MedicBalloon';
import StyledButton from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import { Centralizer, Container } from './styles';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'

const PatientPreInterview: React.FC = () => {
  const history = useHistory();

  const { userInfo } = useContext(AuthContext);
  const patient = userInfo?.activePatient;

  const patientReference = patient?.nickname || patient?.name || '';

  // in the future, ballonTexts will be received from a fetch endpoint
  const MEDIC_BALLOON_TEXTS = [
    `Para que possamos criar um plano de cuidado que atenda às necessidades de forma específica, precisamos conhecer alguns dados de saúde de ${patientReference}.`,
    'Tenha em mãos informações como: doenças e cirurgias prévias e atuais, alergias, mobilidade, quedas, restrição hídrica, etc.',
  ];

  return (
    <>
      <Header
        centerContent={
          <PageTitle title='Perfil de saúde' />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <MedicBalloon text={MEDIC_BALLOON_TEXTS} />
        <Centralizer>
          <StyledButton
            type="submit"
            size="medium"
            color="inherit"
            onClick={() => history.push('/entrevista/responder')}
          >
            Continuar
          </StyledButton>
        </Centralizer>
      </Container>
    </>
  );
};

export default PatientPreInterview;
