import { ReportModel } from '@cuidador/database';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import StyledButton from '../../../components/StyledButton';
import useReport from '../../../hooks/useReport';
import { resolveErrorMessage } from '../../../utils/error';
import {
  Card,
  CardHeader,
  ReportText,
  StyledRightTypography,
  TitleContainer,
} from '../styles';
import { formatReportDate } from '../utils';

const ReportListItem: React.FC<ReportModel & { patientId: Id }> = ({
  id,
  name,
  startDate,
  patientId,
  isDraft,
}) => {
  const { getReportUrl } = useReport();
  const [loading, setLoading] = useState(false);

  const handleViewReport = async () => {
    try {
      setLoading(true);
      const {
        data: { signedUrl },
      } = await getReportUrl(patientId, id!);

      const blob = await (await fetch(signedUrl)).blob();
      const a = document.createElement('a');
      a.setAttribute('href', URL.createObjectURL(blob));
      a.setAttribute('target', '_blank');
      a.setAttribute('download', String(name));
      a.click();
    } catch (err) {
      toast.error(resolveErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card key={id}>
      <CardHeader>
        <TitleContainer>
          <ReportText>{name}</ReportText>
        </TitleContainer>
        <ReportText>{formatReportDate(startDate!)}</ReportText>
      </CardHeader>
      {!isDraft && (
        <StyledButton
          data-testid={`download-report-${id}`}
          variant="text"
          disabled={loading}
          onClick={handleViewReport}
        >
          Baixar PDF
        </StyledButton>
      )}
      {!!isDraft && (
        <StyledRightTypography>Aguardando finalização</StyledRightTypography>
      )}
    </Card>
  );
};

export default ReportListItem;
