import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SubTitleContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: ${({ theme }) => theme.spacing(9)}px;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
  margin-left: auto;
  margin-right: auto;
`;

export const DateContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.main};
  justify-content: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  align-self: center;
`;
