import { ShiftModel } from '@cuidador/database';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React, { useState } from 'react';
import useCanAccess from '../../hooks/useCanAccess';
import ExportModal from './ExportModal';
import ShiftCard from './ShiftCard';
import ShiftReportListDialog from './ShiftReportListDialog';
import {
  ButtonContainer,
  ContainerDayShifts,
  ContainerShifts,
  StyledButton,
  StyledDD,
  StyledYYYYMM,
} from './styles';
import { getDayGroup } from './utils';

interface ShiftsHistoryProps {
  shifts?: Map<string, Map<string, ShiftModel[]>>;
  onExpelCaregiver: () => void;
}

const ShiftsHistory: React.FC<ShiftsHistoryProps> = ({
  shifts,
  onExpelCaregiver,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [showShiftReportList, setShowShiftReportList] = useState(false);
  const {
    isAllowedToRead: isAllowedToReadReport,
    isAllowedToCreate: isAllowedToCreateReport,
  } = useCanAccess('report/shift');

  return (
    <>
      <ButtonContainer>
        {isAllowedToCreateReport && (
          <StyledButton onClick={() => setVisibleModal(true)}>
            Gerar relatório
          </StyledButton>
        )}

        {isAllowedToReadReport && (
          <StyledButton onClick={() => setShowShiftReportList(true)}>
            Consultar relatórios
          </StyledButton>
        )}
      </ButtonContainer>
      {Array.from(shifts?.entries() || []).map(([mapKeyYYYYMM, innerMap]) => {
        const anyShift = Array.from(innerMap.values())[0][0];
        const date = new Date(String(anyShift.plannedToStartAt));
        return (
          <ContainerShifts key={mapKeyYYYYMM}>
            <StyledYYYYMM>
              {format(date, 'MMMM, yyyy', { locale: ptBrLocale })}
            </StyledYYYYMM>
            {Array.from(innerMap.entries()).map(([mapKeyDD, shifts]) => {
              const anyShift = shifts[0];
              const date = new Date(String(anyShift.plannedToStartAt));
              return (
                <ContainerDayShifts key={`${mapKeyYYYYMM}${mapKeyDD}`}>
                  <StyledDD>{getDayGroup(date)}</StyledDD>
                  {shifts.map((shift) => {
                    return (
                      <ShiftCard
                        key={shift.id}
                        shift={shift}
                        onExpelCaregiver={onExpelCaregiver}
                      />
                    );
                  })}
                </ContainerDayShifts>
              );
            })}
          </ContainerShifts>
        );
      })}

      <ExportModal
        onClose={() => setVisibleModal(false)}
        opened={visibleModal}
      />

      {showShiftReportList && (
        <ShiftReportListDialog
          open={showShiftReportList}
          onClose={() => setShowShiftReportList(false)}
        />
      )}
    </>
  );
};
export default ShiftsHistory;
