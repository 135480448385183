import React, { useMemo } from 'react';
import { LinkProps, useLocation } from 'react-router-dom';
import { StyledLink } from './styles';

export interface MenuItemProps extends LinkProps {
  $active?: boolean;
  to: string;
  exact?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, children, exact }) => {
  const location = useLocation();

  const isActive = useMemo(() => {
    if (exact) return location.pathname === to;
    return location.pathname.startsWith(to);
  }, [location.pathname]);

  return (
    <StyledLink to={to} $active={isActive}>
      {children}
    </StyledLink>
  );
};

export default MenuItem;
