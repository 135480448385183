import React, { useEffect, useState } from 'react'
import { DayHistoryContainer, DayHistoryHeader, DayHistoryBody, EventListItem, EventTime, EventStatusIcon, EventCategoryIcon, EventTitle, DayMessage, EventCardPopUp } from '../../../../pages/Follow/CareHistory/styles'
import { categoryIdMapper } from '../../../ShiftEventList/utils'
import { EventModel, EventSubCategoryModel } from '@cuidador/database'
import { parseISO, intlFormat, isBefore, isAfter, startOfDay } from 'date-fns'
import { capitalize } from 'lodash'

import IconAccomplished from '../../../../assets/Icon-flat-accomplished.svg'
import IconAccomplishedWarning from '../../../../assets/icon-flat-accomplished-warning.svg'
import IconAppointment from '../../../../assets/Icon-flat-appointment.svg'
import IconAwaiting from '../../../../assets/Icon-flat-awaiting.svg'
import IconComplication from '../../../../assets/Icon-flat-complication.svg'
import IconMeasurement from '../../../../assets/Icon-flat-measurement.svg'
import IconMedication from '../../../../assets/Icon-flat-medication.svg'
import IconNotAccomplished from '../../../../assets/Icon-flat-not-accomplished.svg'
import IconRoutine from '../../../../assets/Icon-flat-routine.svg'
import { RenderActivityCardByEvent } from '../../../ShiftEventList'

const resolveCategoryIcon = (event: EventModel) => {
  const subCategory = event.subCategory as EventSubCategoryModel
  switch (subCategory.categoryId) {
    case categoryIdMapper.MEDICATION:
      return IconMedication
    case categoryIdMapper.MEASUREMENT:
      return IconMeasurement
    case categoryIdMapper.COMPLICATION:
      return IconComplication
    case categoryIdMapper.GENERAL:
      return subCategory.isRoutine ? IconRoutine : IconAppointment
  }
}

const resolveStatusIcon = (event: EventModel) => {
  const subCategory = event.subCategory as EventSubCategoryModel
  switch (event.status) {
    case 'accomplished':
      if (subCategory.categoryId === categoryIdMapper.COMPLICATION || event.measurement?.isDangerousComplication) {
        return IconAccomplishedWarning
      } else {
        return IconAccomplished
      }
    case 'not_accomplished':
      return IconNotAccomplished
    default:
      return IconAwaiting
  }
}

const formatTimelineDate = (
  date: string | null | undefined,
  format: 'weekday' | 'month' | 'day_number' | 'time' | 'year',
) => {
  if (!date) return 'Data inválida'

  const zonedDate = parseISO(date)

  switch (format) {
    case 'weekday':
      return capitalize(
        intlFormat(
          zonedDate,
          { weekday: 'long' },
          { locale: navigator.language },
        ),
      )
    case 'month':
      return capitalize(
        intlFormat(
          zonedDate,
          { month: '2-digit' },
          { locale: navigator.language },
        ),
      )
    case 'year':
      return capitalize(
        intlFormat(
          zonedDate,
          { year: 'numeric' },
          { locale: navigator.language },
        ),
      )
    case 'day_number':
      return intlFormat(
        zonedDate,
        { day: '2-digit' },
        { locale: navigator.language },
      )
    case 'time':
      return intlFormat(
        zonedDate,
        { hour: '2-digit', minute: '2-digit' },
        { locale: navigator.language },
      )
    default:
      return 'Formato inválido'
  }
}

export type EventDate = {
  date: string
  events: Event[]
}

type Event = {
  id: number
  name: string
  description: string
  eventHappensAt: string
  eventNotifiedAt: any
  status: string
  subCategoryId: number
  patientId: number
  eventScheduleId: number
  shiftId: any
  isDeleted: number
  createdAt: string
  updatedAt: string
  medicationId?: number
  measurementId: any
  isResolved: number
  startingSoonNotificationSent: number
  updatedTimeHappensAt: any
  comment: any
  shiftExecutionId: any
  caregiverId: any
  categoryId?: number
  category: any
  measurement: any
  caregiver: any
  subCategory: SubCategory
  medication?: Medication
}

type SubCategory = {
  id: number
  categoryId: number
  name: string
  isRoutine: number
  isAppointment: number
  isDeleted: number
  createdAt: string
  updatedAt: string
  isEditDisabled: number
  medicationCatalogItem?: MedicationCatalogItem
}

type MedicationCatalogItem = {
  eventSubCategoryId: number
  barcode: string
  name: string
  description: string
}

type Medication = {
  id: number
  dosageDescription: any
  medicalNotes: string
  dosageQuantity: number
  dosageFormat: string
  administeredBy: string
  subCategoryId: number
  isDeleted: number
  createdAt: string
  updatedAt: string
  eventScheduleId: number
  patientId: number
  ifNecessary: number
  previousId: number
  updatedBy: any
}


type EvolutionListProps = {
  eventData: EventDate[]
  onOccurrencesUpdate?: (occurrencesOfPatient?: number[]) => void
  fromDate: Date
  toDate: Date
}

export const getFilteredEventsByOccurrences = (events: Event[], fromDate: Date , toDate: Date) => {
  const eventsResult: Event[] = []
  const occurrences = [0, 0, 0, 0, 0]

  events.forEach(event => {
    if (event.subCategory.categoryId === 3) {
      eventsResult.push(event)
      occurrences[0] += 1
    }
    if (event.medication) {
      if (event.status === 'not_accomplished') {
        eventsResult.push(event)
        occurrences[1] += 1
      } else if (event.status === 'awaiting' && (isBefore(parseISO(event.eventHappensAt), new Date()) && isAfter(parseISO(event.eventHappensAt), startOfDay(fromDate)))) {
        eventsResult.push(event)
        occurrences[1] += 1
      } else if (event.status === 'accomplished' && isBefore(parseISO(event.eventHappensAt), parseISO(event.updatedTimeHappensAt))) {
        eventsResult.push(event)
        occurrences[1] += 1
      }
    }
    if (event.measurement?.complicationLevel && Math.abs(event.measurement?.complicationLevel) > 1) {
      eventsResult.push(event)
      occurrences[2] += 1
    }
    if (event.subCategory.categoryId === 1 && !event.subCategory.isRoutine) {
      eventsResult.push(event)
      occurrences[4] += 1
    }
  })

  return { eventsResult, occurrences }
}

export const getFormattedEventDate = (eventData: EventDate[], fromDate: Date , toDate: Date): { formattedEventData: EventDate[], occurrencesFormatted: number[] } => {
  const formattedEventData: EventDate[] = []
  let occurrencesFormatted: number[] = [0, 0, 0, 0, 0]

  if (eventData) {
    for (var i = 0; i < eventData.length; i++) {
      const currEventData = eventData[i]
      const { eventsResult, occurrences } = getFilteredEventsByOccurrences(currEventData.events, fromDate, toDate)

      occurrencesFormatted.forEach((val, idx) => {
        occurrencesFormatted[idx] = val + occurrences[idx]
      })
      formattedEventData.push({ date: currEventData.date, events: eventsResult })
    }
  }

  return { formattedEventData, occurrencesFormatted }
}

export function EvolutionList (props: EvolutionListProps) {
  const { eventData, onOccurrencesUpdate, fromDate, toDate } = props
  const [formattedEventData, setFormattedData] = useState<EventDate[]>([])
  const [activePopUpEvent, setActivePopUpEvent] = useState<EventModel | null>(
    null,
  )


  useEffect(() => {
    const { formattedEventData, occurrencesFormatted } = getFormattedEventDate(eventData, fromDate, toDate)
    setFormattedData(formattedEventData)
    onOccurrencesUpdate?.(occurrencesFormatted)
  }, [])

  return (
    <div>
      {formattedEventData?.map((dayHistory: any, dayHistoryIndex: number) => (
        <DayHistoryContainer
          key={dayHistory.date}
          data-testid={`date-${dayHistory.date}`}
          style={{ paddingTop: 0, marginInline: 0 }}
        >
          <DayHistoryHeader style={{ marginLeft: 0, marginBottom: '4px' }}>

            {formatTimelineDate(dayHistory.date, 'day_number')}/{formatTimelineDate(dayHistory.date, 'month')}/{formatTimelineDate(dayHistory.date, 'year')}
          </DayHistoryHeader>
          <DayHistoryBody style={{ marginInline: 0 }}>
            {dayHistory.events && dayHistory.events.length > 0 ? (
              dayHistory.events.map((event: any) => (
                <EventListItem
                  key={event.id}
                  onClick={() => setActivePopUpEvent(event)}
                  style={{ marginBlock: '8px', columnGap: '2px' }}
                >
                  <EventTime>
                    {formatTimelineDate(
                      event.updatedTimeHappensAt || event.eventHappensAt,
                      'time',
                    )}
                  </EventTime>
                  <EventStatusIcon
                    data-testid={`event-${event.id}-status-${event.status}-icon`}
                    src={resolveStatusIcon(event)}
                    isOddDayHistory={dayHistoryIndex % 2}
                  />
                  <EventCategoryIcon
                    data-testid={`event-${event.id}-category-${event.subCategory?.categoryId}-icon`}
                    src={resolveCategoryIcon(event)}
                    isOddDayHistory={dayHistoryIndex % 2}
                  />
                  <EventTitle style={{ whiteSpace: 'normal' }}>
                    {event.subCategory?.name ?? ''}
                    {event.subCategory?.categoryId ===
                      categoryIdMapper.MEASUREMENT &&
                      event.measurement?.measurementValue &&
                      ` - (${event.measurement?.measurementValue})`}
                  </EventTitle>
                </EventListItem>
              ))
            ) : (
              <DayMessage>Sem registros</DayMessage>
            )}
          </DayHistoryBody>
        </DayHistoryContainer>
      ))}
      {activePopUpEvent && (
        <EventCardPopUp
          onClick={() => setActivePopUpEvent(null)}
          data-testid={`eventCardPopUp-${activePopUpEvent.id}`}
        >
          {RenderActivityCardByEvent(activePopUpEvent)}
        </EventCardPopUp>
      )}
    </div>
  )
}
