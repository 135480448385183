import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import BackgroundImage from '../../assets/loginBackground.png';

export const Background = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})`
  margin: ${({ theme }) => theme.spacing(2)}px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  button,
  a {
    flex: 1;
    margin: ${({ theme }) => theme.spacing(0, 1)};
  }
`;

export const SuccessContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    align-self: center;
  }
`;

export const GrandparentsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  & img:last-child {
    margin: ${({ theme }) => theme.spacing(4, 0, 0, -1)};
  }
`;
