import { Field } from 'formik';
import styled from 'styled-components';

export const StyledRow = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 5% 0 5%;
`;

export const StyledField = styled(Field)`
  display: none;
`;

type StyledSpanProps = {
  checked?: boolean;
};

export const StyledSpan = styled.span<StyledSpanProps>`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  background: ${({ checked, theme }) =>
    checked ? theme.palette.secondary.main : 'white'};
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  color: ${({ checked, theme }) =>
    checked ? 'white' : theme.palette.secondary.main};
`;
