import {
  EventScheduleCustomTimeModel,
  EventScheduleModel,
  MedicationModel,
} from '@cuidador/database';
import { differenceInMinutes, format } from 'date-fns';
import {
  convertTimeToDate,
  getLocalWeeklySchedule,
  weekdayMap,
} from '../../../../utils/date';
import { EVERYDAY, FrequencyType, MONDAY_TO_FRIDAY } from '../DaysSelector';
import { PeriodType } from '../PeriodSelector';
import { FormValues } from '../utils';

export const medicationModelToFormData = (medication: MedicationModel) => {
  const eventSchedule = medication.eventSchedule as EventScheduleModel;
  const { formattedDate, formattedTime, formattedEndDate } = parseDates(
    eventSchedule
  );

  let timeSchedule;
  if (
    !medication.ifNecessary &&
    eventSchedule?.scheduleType === 'custom_frequency'
  ) {
    timeSchedule = getFrequencyRule(eventSchedule?.customTimes || []);
  } else if (
    !medication.ifNecessary &&
    eventSchedule?.scheduleType === 'frequency'
  ) {
    timeSchedule = eventSchedule?.frequencyRule;
  } else {
    timeSchedule = undefined;
  }

  const customTimes =
    !medication.ifNecessary && eventSchedule.customTimes
      ? parseCustomTimes(eventSchedule)
      : undefined;
  const days = !medication.ifNecessary
    ? getSelectedWeekDays(eventSchedule as EventScheduleModel)
    : undefined;
  const dayOptions = !medication.ifNecessary
    ? getDayOptions(days!, eventSchedule as EventScheduleModel)
    : undefined;

  const { rangeInDays, customRangeInDays } = getRangesInDays(
    eventSchedule?.frequencyRule || ''
  );

  const periodType = !medication.ifNecessary
    ? getPeriodType(
        eventSchedule.frequencyStartsAt!,
        eventSchedule.frequencyEndsAt!
      )
    : PeriodType.AsNeeded;

  const data = {
    dosageFormat: medication.dosageFormat,
    dosageQuantity: medication.dosageQuantity,
    administeredBy: medication.administeredBy,
    subCategory: medication.subCategory,
    subCategoryId: medication.subCategoryId,
    name: medication?.subCategory?.name || '',
    scheduleType: eventSchedule?.scheduleType || 'custom_frequency',
    frequencyRule: eventSchedule?.frequencyRule,
    ifNecessary: !!medication.ifNecessary,
    medicalNotes: medication.medicalNotes,
    eventScheduleId: medication.eventScheduleId,
    frequencyStartsAt: formattedDate || '',
    frequencyEndsAt: formattedEndDate || '',
    time: formattedTime || '',
    timeSchedule,
    customTimes,
    days,
    dayOptions,
    rangeInDays,
    customRangeInDays,
    periodType,
  } as FormValues;
  return data;
};

const getPeriodType = (frequencyStartsAt: string, frequencyEndsAt: string) => {
  if (frequencyStartsAt && frequencyEndsAt) {
    return PeriodType.DeterminedTime;
  }
  return PeriodType.Continuously;
};

const getRangesInDays = (frequencyRule: string) => {
  let rangeInDays;
  let customRangeInDays;
  const notCustomIntervalOptions = ['2d', '3d', '5d', '7d', '15d', '30d'];
  if (notCustomIntervalOptions.includes(frequencyRule)) {
    rangeInDays = frequencyRule;
  } else {
    rangeInDays = 'custom';
    customRangeInDays = Number(frequencyRule.split('d')[0]);
  }

  return {
    rangeInDays,
    customRangeInDays,
  };
};

const getDayOptions = (days: string[], eventSchedule: EventScheduleModel) => {
  const frequencyRule = eventSchedule.frequencyRule || '1d';
  const scheduleType = eventSchedule.scheduleType || 'custom_frequency';

  const isEveryDay =
    days.length === 7 && days.every((day) => EVERYDAY.includes(day));
  const isMondayToFriday =
    days.length === 5 && days.every((day) => MONDAY_TO_FRIDAY.includes(day));
  const isSomeDays = !isEveryDay && !isMondayToFriday;
  if (
    (frequencyRule === '1d' && scheduleType === 'custom_frequency') ||
    scheduleType === 'frequency'
  ) {
    if (isEveryDay) return FrequencyType.EveryDay;
    if (isMondayToFriday) return FrequencyType.MondayToFriday;
    if (isSomeDays) return FrequencyType.SomeDays;
  }
  return FrequencyType.Custom;
};

export const getSelectedWeekDays = (eventSchedule: EventScheduleModel) => {
  const { mon, tue, wed, thu, fri, sat, sun } = eventSchedule || {};

  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
  const date = new Date(eventSchedule?.frequencyStartsAt || '');
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const days: string[] = [];
  if (localSchedule.mon) days.push(weekdayMap.mon);
  if (localSchedule.tue) days.push(weekdayMap.tue);
  if (localSchedule.wed) days.push(weekdayMap.wed);
  if (localSchedule.thu) days.push(weekdayMap.thu);
  if (localSchedule.fri) days.push(weekdayMap.fri);
  if (localSchedule.sat) days.push(weekdayMap.sat);
  if (localSchedule.sun) days.push(weekdayMap.sun);

  return days;
};

/**
 * calculates the difference in minutes between the times of the eventSchedule, to fill frequency field in medication form;
 * it doesn't make sense to calculate the differences between times, when there is only one time for the event/medication happen.
 * in this case, the frequency is 24 hours, when the event has only one time to happen in the day.
 */
const getFrequencyRule = (times: EventScheduleCustomTimeModel[]) => {
  const ONE_HOUR_IN_MINUTES = 60;
  const TWO_HOURS_IN_MINUTES = 120;
  const FOUR_HOURS_IN_MINUTES = 240;
  const SIX_HOURS_IN_MINUTES = 360;
  const EIGHT_HOURS_IN_MINUTES = 480;
  const TWELVE_HOURS_IN_MINUTES = 720;
  const TWENTY_FOUR_HOURS_IN_MINUTES = 1440;
  const validDifferencesInMinutes = [
    TWO_HOURS_IN_MINUTES,
    FOUR_HOURS_IN_MINUTES,
    SIX_HOURS_IN_MINUTES,
    EIGHT_HOURS_IN_MINUTES,
    TWELVE_HOURS_IN_MINUTES,
    TWENTY_FOUR_HOURS_IN_MINUTES,
  ];

  const customTimes = times.map((time) => time.happensAt).sort();
  const differences: number[] = [];
  customTimes.forEach((time, index) => {
    if (customTimes.length === 1) {
      differences.push(TWENTY_FOUR_HOURS_IN_MINUTES);
    } else {
      const hour = new Date(
        2022,
        1,
        1,
        Number(customTimes[index]?.split(':')[0]),
        Number(customTimes[index]?.split(':')[1])
      );
      const nextHour = new Date(
        2022,
        1,
        1,
        Number(customTimes[index + 1]?.split(':')[0]),
        Number(customTimes[index + 1]?.split(':')[1])
      );
      const difference = differenceInMinutes(nextHour, hour);
      if (!isNaN(difference)) {
        differences.push(Math.abs(difference));
      }
    }
  });

  const allEqualAndValid = (arr: number[]) =>
    arr.every((v) => v === arr[0] && validDifferencesInMinutes.includes(v));

  const firstDifference = differences[0] / ONE_HOUR_IN_MINUTES;

  const HOURS_IN_DAY = 24;
  const timesInFrequency = HOURS_IN_DAY / firstDifference;

  const isIrregular =
    times.length !== timesInFrequency || !allEqualAndValid(differences);
  const frequencyRule = isIrregular
    ? 'custom'
    : `${differences[0] / ONE_HOUR_IN_MINUTES}h`;

  return frequencyRule;
};

const parseCustomTimes = (eventSchedule: EventScheduleModel) => {
  if (eventSchedule?.scheduleType === 'custom_frequency') {
    const customTimes = eventSchedule.customTimes?.map((time) => {
      if (time.happensAt) {
        return format(convertTimeToDate(time.happensAt), 'HH:mm');
      }
    });
    return customTimes;
  }
};

const parseDates = (eventSchedule: EventScheduleModel) => {
  let formattedDate;
  let formattedTime;
  let formattedEndDate;

  if (eventSchedule?.frequencyStartsAt) {
    formattedDate = format(
      new Date(eventSchedule?.frequencyStartsAt),
      'yyyy-MM-dd'
    );
    formattedTime = format(new Date(eventSchedule?.frequencyStartsAt), 'HH:mm');
    if (eventSchedule?.frequencyEndsAt) {
      formattedEndDate = format(
        new Date(eventSchedule?.frequencyEndsAt),
        'yyyy-MM-dd'
      );
    }
  }

  return {
    formattedDate,
    formattedTime,
    formattedEndDate,
  };
};
