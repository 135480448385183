import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APIError } from '@cuidador/lib';
import MedicationInsertForm from '../../../components/MedicationInsertForm';
import {
  FormValues,
  MedicationInsertFormStep,
} from '../../../components/MedicationInsertForm/MedicationSecondStep/utils';
import { formDataToMedicationModel } from '../../../components/MedicationInsertForm/MedicationSecondStep/utils/parseFormDataToModel';
import { medicationModelToFormData } from '../../../components/MedicationInsertForm/MedicationSecondStep/utils/parseModelToFormData';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import useCanAccess from '../../../hooks/useCanAccess';
import useMedication from '../../../hooks/useMedication';
import { resolveErrorMessage } from '../../../utils/error';
import { Header } from '../../../components/Header'
import { PageTitle } from '../../../components/PageTitle'
import { RemoveButton } from '../../../components/RemoveButton'
import { BackButton } from '../../../components/BackButton'

function MedicationUpdateV2() {
  const [dialogStatus, setDialogStatus] = useState(false);

  const params = useParams<{ id: string }>();
  const { getById, byId, loading, patch, remove } = useMedication();
  const { isAllowedToUpdate, isAllowedToDelete } = useCanAccess(
    'care/medication'
  );

  const id = parseInt(params.id);
  const medication = byId[id];
  const history = useHistory();

  useEffect(() => {
    getById(id);
  }, [id]);

  const handleSubmit = (values: FormValues) => {
    return patch(
      id,
      formDataToMedicationModel(
        values,
        medication.patientId!,
        medication.subCategoryId!
      )
    )
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: number) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle
            title={medication?.subCategory?.name || ''}
          />
        }
        rightContent={
          isAllowedToDelete && (
            <RemoveButton action={() => setDialogStatus(true)} />
          )
        }
      />
      {medication && (
        <>
          <StyledFieldset disabled={!isAllowedToUpdate}>
            <MedicationInsertForm
              step={MedicationInsertFormStep.FillDetails}
              initialValues={medicationModelToFormData(medication)}
              handleSubmit={handleSubmit}
              id={id}
              disabled={!isAllowedToUpdate}
            />
          </StyledFieldset>
          <StyledSimpleDialog
            open={dialogStatus}
            handleNo={() => setDialogStatus(false)}
            handleYes={() => handleDelete(id)} // since we have medication with truthy value, id definitely has a valid id
            title="Excluir medicamento"
            subTitle="Tem certeza que deseja excluir o medicamento?"
          />
        </>
      )}
    </>
  );
}

export default MedicationUpdateV2;
