import { Checkbox, Typography } from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BlackTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.palette.secondary.main};
`;
