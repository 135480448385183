import { CaregiverModel } from '@cuidador/database';
import { DialogTitle, Button as TextButton } from '@material-ui/core';
import React, { useState } from 'react';
import useShift from '../../hooks/useShift';
import StyledButton from '../StyledButton';
import StyledDialog from '../StyledDialog';
import {
  CenterLayout,
  StyledDialogContent,
  StyledRadio,
  StyledRadioGroup,
  StyledFormControlLabel,
} from './styles';
type ExpelCaregiverDialogProps = {
  onClose: () => void;
  onConfirm: (option) => void;
  isOpen: boolean;
  isLoading: boolean;
  caregiver: CaregiverModel;
};

export default function ExpelCaregiverDialog({
  isOpen,
  onConfirm,
  onClose,
  isLoading,
  caregiver,
}: ExpelCaregiverDialogProps) {
  const [currentOption, setCurrentOption] = useState<
    'keep' | 'erase' | undefined
  >();
  const { loading: isExpelLoading } = useShift();

  if (!caregiver) return null;

  return (
    <StyledDialog open={isOpen} onClose={onClose} data-testid="expel-dialog">
      <StyledDialogContent>
        <DialogTitle>
          Você está prestes a remover {caregiver.user?.name} do plantão
        </DialogTitle>
        <StyledRadioGroup
          aria-label="erase-records"
          onChange={(e) => setCurrentOption(e.target.value)}
          color="inherit"
        >
          {/* TODO: enable this option when shift reopening is over */}
          {/* <StyledFormControlLabel
            value="erase"
            control={<StyledRadio size="medium" />}
            label="Remover e limpar registros"
          /> */}
          <StyledFormControlLabel
            value="keep"
            control={<StyledRadio size="medium" />}
            label="Remover e manter registros"
          />
        </StyledRadioGroup>
        <CenterLayout>
          <TextButton
            onClick={onClose}
            color="inherit"
            data-testid="close-dialog"
            disabled={isLoading || isExpelLoading}
          >
            Voltar
          </TextButton>
          <StyledButton
            onClick={() => {
              onConfirm(currentOption);
            }}
            color="inherit"
            data-testid="confirm-dialog"
            disabled={isLoading || isExpelLoading || !currentOption}
          >
            Remover
          </StyledButton>
        </CenterLayout>
      </StyledDialogContent>
    </StyledDialog>
  );
}
