import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const ImageWithLeftMargin = styled.img`
  margin-left: 23px;
`;
