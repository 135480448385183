import qs from 'query-string';
import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../contexts/auth';

interface AuthenticatedRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<RouteComponentProps<any, any, unknown>>;
}

const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: AuthenticatedRouteProps): JSX.Element => {
  const { user, userInfo, promotionPlanId, companyPlanId } = useContext(
    AuthContext
  );
  const { state, search } = useLocation<{ selectedPlan: string }>();
  const { plan: qsPlanId } = qs.parse(search);

  const isUserOrganizationOwner =
    userInfo?.id === userInfo?.user?.organization?.createdById;
  const userHasOrganizationId = userInfo?.user?.organizationId;
  const userHasRelatedPatients = Number(userInfo?.user?.patients?.length) > 0;
  const userOrganizationHasRelatedPatients =
    Number(userInfo?.user?.organization?.patients?.length) > 0;
  const userOrganizationHasNoSubscription =
    userInfo?.user?.organization?.subscriptionId == null;
  const userOrganizationSubscriptionStatus =
    userInfo?.user?.organization?.subscription?.status;
  const userStatus = userInfo?.user?.status;

  return (
    <Route
      {...rest}
      render={(props) => {
        // redirect unauthenticated user to login
        if (!user)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );

        // redirect users with promotional plan, without company plan and without subscription to /subscription-activation
        if (
          promotionPlanId &&
          !companyPlanId &&
          userOrganizationHasNoSubscription &&
          props.location.pathname !== '/subscription-activation'
        ) {
          return <Redirect to="/subscription-activation" exact />;
        }

        // redirect users with company plan, without promotional plan and without subscription to /company
        if (
          (companyPlanId || qsPlanId) &&
          !promotionPlanId &&
          userOrganizationHasNoSubscription &&
          props.location.pathname !== '/company'
        ) {
          return <Redirect to="/company" exact />;
        }

        // redirect user who do not have an organization_id
        if (
          !userHasOrganizationId &&
          props.location.pathname !== '/usuario-sem-organizacao'
        ) {
          return <Redirect to="/usuario-sem-organizacao" exact />;
        }

        // redirect users without promotional plan, without company plan and without subscription to /planos
        if (
          !promotionPlanId &&
          !companyPlanId &&
          !state &&
          userOrganizationHasNoSubscription &&
          userHasOrganizationId &&
          props.location.pathname !== '/planos' &&
          props.location.pathname !== '/company' &&
          props.location.pathname !== '/usuario-sem-organizacao'
        ) {
          return <Redirect to="/planos" exact />;
        }

        // redirect users without promotional plan, without company plan and without subscription who already selected their plan to /assinatura
        if (
          !promotionPlanId &&
          !companyPlanId &&
          userOrganizationHasNoSubscription &&
          state &&
          state.selectedPlan &&
          props.location.pathname !== '/assinatura'
        ) {
          return <Redirect to="/assinatura" exact />;
        }

        // redirect users with a subscription with null status to the awaiting payment page
        if (
          userOrganizationSubscriptionStatus === null &&
          props.location.pathname !== '/processando-pagamento'
        ) {
          return <Redirect to="/processando-pagamento" exact />;
        }

        // redirect user who own an organization, are not related to any patients and have more than one patient in your organization
        if (
          !userHasRelatedPatients &&
          userOrganizationHasRelatedPatients &&
          isUserOrganizationOwner &&
          props.location.pathname !== `/organizacao/usuario/${userInfo?.id}`
        ) {
          toast.warning(
            'Você precisa estar vinculado à pelo menos 1 paciente para usar o app'
          );
          // Note that `push` props is important to avoid user being thrown out of the
          // application on "goBack" click after this condition is no longer satisfied
          return (
            <Redirect push to={`/organizacao/usuario/${userInfo?.id}`} exact />
          );
        }

        // redirect users who do not own an organization and are not related to any patients
        if (
          !userHasRelatedPatients &&
          !isUserOrganizationOwner &&
          props.location.pathname !== '/usuario-sem-vinculo'
        ) {
          return <Redirect to="/usuario-sem-vinculo" exact />;
        }

        // redirect to onboarding if user hasn't any patient registered
        if (
          !userHasRelatedPatients &&
          !userOrganizationHasRelatedPatients &&
          (userOrganizationSubscriptionStatus === 'active' ||
            userOrganizationSubscriptionStatus === 'future') &&
          props.location.pathname !== '/onboarding' &&
          props.location.pathname !== '/cadastro-psc'
        )
          return <Redirect to={{ pathname: '/onboarding' }} />;

        // redirect to awaiting review
        if (
          userHasRelatedPatients &&
          (userOrganizationSubscriptionStatus === 'active' ||
            userOrganizationSubscriptionStatus === 'future') &&
          userStatus === 'awaitingReview' &&
          props.location.pathname !== '/aguardando-revisao'
        )
          return <Redirect to={{ pathname: '/aguardando-revisao' }} />;

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
