import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import {
  default as FormikSelect,
  default as StyledSelect,
} from './Forms/FormikSelect';
import FormikTextField from './Forms/FormikTextField';
import PasswordTextField from './PasswordField';

export const FormCardContainer = styled.div`
  width: 80%;
  min-height: ${({ theme }) => theme.spacing(10)}px;
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledBoldTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: bold;
`;

export const NewStyledSelect = styled(StyledSelect)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.7)}px;
`;

interface TextFieldInterface {
  disabled?: boolean;
}

export const StyledFormikTextField = styled(
  FormikTextField
)<TextFieldInterface>`
  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(0.7)}px;
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  & .Mui-disabled,
  & label.Mui-disabled,
  & .MuiInputBase-root.Mui-disabled,
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 65%;
  }
`;

export const StyledFormikTextPassword = styled(PasswordTextField)`
  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(0.7)}px;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  & input {
    color: ${({ theme }) => theme.palette.secondary.main};
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
  & textarea {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.main};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.main};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

export const StyledFormikSelect = styled(FormikSelect)`
  border-radius: ${({ theme }) => theme.spacing(0.7)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const HelperContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

export const HelperText = styled.span`
  color: ${({ theme }) => theme.palette.common.black};
`;
