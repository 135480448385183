import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { EventModel } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/event/category';

const initialData: ReducerData<EventModel> = {
  byId: {} as Record<string, Item<EventModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useSubCategory = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventModel>(),
    initialData
  );

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/sub`);
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getOnlyForRoutines = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/sub?isRoutine=1&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getOnlyForAppointments = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/sub?isAppointment=1&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getOnlyForMedication = useCallback(
    async (params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(`${endpoint}/sub`, {
          params: {
            ...params,
            categoryId: 2, // CategoryId 2 = Medication Id
          },
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return response.data.results;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  return {
    ...state,
    getAll,
    getOnlyForRoutines,
    getOnlyForAppointments,
    getOnlyForMedication,
  };
};

export default useSubCategory;
