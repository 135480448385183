import React from 'react';
import { MenuItemProps } from '..';
import { StyledMenuItem } from '../styles';

const MenuItem: React.FC<MenuItemProps> = ({
  label,
  icon,
  handleClickItem,
}) => {
  return (
    <StyledMenuItem onClick={handleClickItem}>
      {icon}
      {label}
    </StyledMenuItem>
  );
};

export default MenuItem;
