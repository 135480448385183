import { Accordion, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { ReactComponent as AccordionDone } from '../../assets/Icon-AccordionDone.svg';
import { ReactComponent as AccordionError } from '../../assets/Icon-AccordionError.svg';
import { StyledFormikTextField } from '../../components/FormCardContainer';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fafafa;
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const StyledIcon = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  vertical-align: middle;
`;

export const StyledPrice = styled.span`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  float: right;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const TypographyContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTitle = styled(StyledTypography)`
  font-weight: 550;
`;

export const StyledPlan = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: #f3f3f3;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledChangePlan = styled(StyledButton)`
  float: right;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.dark};
`;

export const StyledPlanDescription = styled.div`
  margin-left: ${({ theme }) => theme.spacing(5)}px;
`;

export const StyledLine = styled.hr`
  width: 85%;
  color: #cccccc;
  margin: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledLineSimple = styled.hr`
  width: 85%;
  color: #cccccc;
`;

export const StyledPay = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  border: 3px solid #cccccc;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledChangeCard = styled(StyledButton)`
  width: 30%;
  margin-left: 70%;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  border: none;
`;

export const StyledChangeContract = styled(StyledButton)`
  align-self: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  border: none;
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
  width: 90%;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledCoupun = styled.div`
  width: 90%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  /* margin: ${({ theme }) => theme.spacing(2)}px; */
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
`;

export const StyledInfo = styled.div`
  width: 90%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  text-decoration: none;
  list-style: none;
  vertical-align: text-bottom;
`;

export const StyledFirstInRow = styled(StyledFormikTextField)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const StyledPayButton = styled(StyledButton)`
  width: 50%;
  align-self: center;
`;

export const StyledInfoPrice = styled.span`
  float: right;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const AccordionDoneIcon = styled(AccordionDone)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.extra.color.green};
`;

export const AccordionNotDoneIcon = styled(AccordionDone)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const AccordionErrorIcon = styled(AccordionError)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
`;
