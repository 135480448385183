import { APIError } from '@cuidador/lib';
import { Checkbox, FormHelperText } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import qs from 'query-string';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormCardContainer,
  StyledBoldTitle,
  StyledFormikSelect,
  StyledFormikTextField,
  StyledFormikTextPassword,
} from '../../components/FormCardContainer';
import FormikSelectField from '../../components/Forms/FormikSelect';
import FormikTextField from '../../components/Forms/FormikTextField';
import MedicBalloon from '../../components/MedicBalloon';
import PasswordRules from '../../components/PasswordRules';
import StyledButton from '../../components/StyledButton';
import StyledKeyboardDatePicker from '../../components/StyledKeyboardDatePicker';
import StyledMenuItem from '../../components/StyledMenuItem';
import { AuthContext } from '../../contexts/auth';
import useUser from '../../hooks/useUser';
import { resolveErrorMessage } from '../../utils/error';
import { cpfMask, numberMask, phoneMask } from '../../utils/inputs';
import {
  ButtonContainer,
  Container,
  ContentContainer,
  FooterContainer,
  Logo,
  LogoContainer,
  MessageContainer,
  Screen,
  StyledFormControlLabel,
} from './styles';
import {
  formDataToCompleteSignupRequest,
  formInitialValues,
  FormValues,
  genderOptions,
  resolvePendingSignupJWTData,
  resolveValidationSchema,
} from './utils';
import { Header } from '../../components/Header'
import { PscButton } from '../../components/PscButton'

const CompleteSignup: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const formikFormRef = useRef<FormikProps<FormValues> | null>(null);

  const { completeSignup, uploadProfilePicture, checkSignupStatus } = useUser();
  const { signIn, error: signInError } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const { token: completeSignupToken } = qs.parse(location.search);

  const completeSignupTokenPayload = useMemo(() => {
    // avoids access without valid token
    if (completeSignupToken == null) {
      redirectToHome();
      return;
    }

    const payload = resolvePendingSignupJWTData(String(completeSignupToken));

    if (!payload) {
      // Invalid token
    }

    return payload;
  }, [completeSignupToken]);

  const userIdFromTokenPayload = Number(completeSignupTokenPayload?.id);

  function redirectToHome() {
    history.replace('/');
  }

  useEffect(
    function removeLoadingOnSignInError() {
      if (signInError) {
        toast.error(resolveErrorMessage(signInError));
        setLoading(false);
      }
    },
    [signInError]
  );

  useEffect(() => {
    checkSignupStatus(userIdFromTokenPayload)
      .then(({ hasCompletedSignup }) => {
        if (hasCompletedSignup) history.replace('/login');
      })
      .catch(console.error);
  }, [userIdFromTokenPayload]);

  const handleSignIn = () => {
    const { cpf } = formikFormRef.current?.values as FormValues;
    const { password } = formikFormRef.current?.values as FormValues;
    return signIn(numberMask(cpf || ''), password);
  };

  const handleUploadProfilePicture = (picture: File, userId: number) => {
    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        try {
          await uploadProfilePicture(picture, userId);
          resolve();
        } catch (err) {
          toast.error(
            'Houve um erro durante o envio de sua imagem, tente novamente acessando seu perfil'
          );
          Sentry.captureException(err);
        }
      }, 0);
    });
  };

  const handleCompleteSignupSubmit = async () => {
    setLoading(true)
    const formData = formikFormRef.current?.values as FormValues;

    return completeSignup(
      formDataToCompleteSignupRequest(formData),
      String(completeSignupToken)
    )
      .then(async (response) => {
        await handleSignIn();
        return response;
      })
      .then(async ({ data }) => {
        if (formData?.picture != null) {
          await handleUploadProfilePicture(formData.picture, data.id);
        }
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        const fieldError = err.response?.data.context?.key;
        if (fieldError) {
          if (Object.keys(formInitialValues).includes(fieldError)) {
            formikFormRef.current?.setFieldError(
              fieldError,
              displayMessage || ''
            );
          }
        } else {
          toast.error(displayMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header
        centerContent={
          <LogoContainer>
            <Logo />
          </LogoContainer>
        }
        rightContent={<PscButton />}
      />
      <Screen>
        <MessageContainer>
          <MedicBalloon
            text={
              'Olá, eu sou a Augusta!\nSeja bem-vindo ao Cuidador de Confiança.\n\nPara começar, crie sua conta! '
            }
          />
        </MessageContainer>
        <Container>
          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikFormRef.current = ref)}
            initialValues={{
              ...formInitialValues,
              ...completeSignupTokenPayload,
            }}
            validationSchema={resolveValidationSchema()}
            onSubmit={handleCompleteSignupSubmit}
          >
            {({ values, setFieldValue, errors, handleBlur }) => (
              <Form>
                <ContentContainer>
                  <PersonalDataStepContainer
                    setFieldValue={setFieldValue}
                    values={values}
                    handleBlur={handleBlur}
                    errors={errors}
                  />

                  <PasswordStepContainer
                    values={values}
                    validationError={validationError}
                  />

                  <FooterContainer>
                    <div>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="termsAccepted"
                            value={values.termsAccepted}
                            onChange={() =>
                              setFieldValue(
                                'termsAccepted',
                                !values.termsAccepted
                              )
                            }
                          />
                        }
                        label={
                          <a
                            href="https://www.cuidadordeconfianca.com.br/termos-e-politicas"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Eu li e aceito os Termos de Uso e Políticas de
                            Privacidade
                          </a>
                        }
                      />
                      {errors.termsAccepted && (
                        <FormHelperText error>
                          {errors.termsAccepted}
                        </FormHelperText>
                      )}
                    </div>

                    <ButtonContainer>
                      <StyledButton
                        disabled={loading}
                        type="submit"
                        size="large"
                        color="inherit"
                        data-testid="submit"
                        onClick={() => setValidationError(true)}
                      >
                        Criar conta
                      </StyledButton>
                    </ButtonContainer>
                  </FooterContainer>
                </ContentContainer>
              </Form>
            )}
          </Formik>
        </Container>
      </Screen>
    </>
  );
};

const PersonalDataStepContainer: React.FC<{
  setFieldValue: (field: string, value: string | File | Date | null) => void;
  values: FormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBlur: any;
  errors: FormikErrors<FormValues>;
}> = ({ values, setFieldValue, handleBlur }) => {
  const finalDate = format(new Date('12/31/9999'), 'yyyy-MM-dd');

  return (
    <>
      {/* TODO: Add profile picture for appF? */}
      <FormCardContainer>
        <StyledBoldTitle variant="subtitle2">
          Informações pessoais
        </StyledBoldTitle>

        <FormikTextField
          as={StyledFormikTextField}
          name="name"
          color="secondary"
          label="Nome completo"
          margin="normal"
          inputProps={{ 'data-testid': 'name' }}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="cpf"
          color="secondary"
          label="CPF"
          type="tel" // numeric keyboard without parsing to number
          margin="normal"
          inputProps={{ 'data-testid': 'cpf' }}
          value={cpfMask(values.cpf)}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="email"
          color="secondary"
          type="email"
          label="E-mail"
          margin="normal"
          inputProps={{ 'data-testid': 'email' }}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="phoneNumber"
          color="secondary"
          label="Telefone"
          type="tel"
          margin="normal"
          inputProps={{ 'data-testid': 'phoneNumber' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('phoneNumber', phoneMask(e.target.value));
          }}
        />
        <FormikSelectField
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={(props: any) => <StyledFormikSelect {...props} />}
          name="gender"
          label="Sexo"
          color="secondary"
          SelectDisplayProps={{
            'data-testid': 'gender',
          }}
        >
          {genderOptions.map((item) => (
            <StyledMenuItem key={item.value} value={item.value}>
              {item.label}
            </StyledMenuItem>
          ))}
        </FormikSelectField>
        <StyledKeyboardDatePicker
          name="dateOfBirth"
          label="Data de nascimento"
          color="secondary"
          value={values.dateOfBirth}
          onChange={(date: Date | null) => {
            setFieldValue('dateOfBirth', date);
          }}
          placeholder="dd/mm/aaaa"
          margin="normal"
          size="medium"
          inputProps={{
            'data-testid': 'dateOfBirth',
            max: finalDate,
          }}
          onBlur={handleBlur}
          InputLabelProps={{ shrink: true }}
          borderType="flat"
        />
      </FormCardContainer>
    </>
  );
};

const PasswordStepContainer: React.FC<{
  values: FormValues;
  validationError: boolean;
}> = ({ values, validationError }) => {
  return (
    <FormCardContainer>
      <StyledBoldTitle variant="subtitle2">Crie sua senha</StyledBoldTitle>

      <FormikTextField
        as={StyledFormikTextPassword}
        color="secondary"
        name="password"
        label="Senha"
        margin="normal"
        autoComplete="off"
        inputProps={{
          'data-testid': 'password',
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <FormikTextField
        as={StyledFormikTextPassword}
        color="secondary"
        name="passwordConfirmation"
        label="Confirmar senha"
        margin="normal"
        autoComplete="off"
        inputProps={{
          'data-testid': 'passwordConfirmation',
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <PasswordRules
        password={values.password}
        validationError={validationError}
      />
    </FormCardContainer>
  );
};

export default CompleteSignup;
