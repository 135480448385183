import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
`;

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
