import React from 'react'
import { ChevronLeftRounded } from '@material-ui/icons'
import { StyledIconButton } from '../StyledIconButton'
import { useHistory } from 'react-router-dom'

export type BackButtonProps = {
  action?: () => void
}

export function BackButton (props: BackButtonProps) {
  const history = useHistory()
  const { action = () => history.goBack() } = props

  return (
    <StyledIconButton
      icon={
        <ChevronLeftRounded style={{
          fontSize: 40,
          color: 'white',
        }} />
      }
      action={() => action()}
    />
  )
}
