import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

export const CardBox = styled(StyledPaper)`
  margin: ${({ theme }) => theme.spacing(2, 2, 2, 2)};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const TitleBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.palette.extra.color.grey.main};
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const MeasurementBox = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.extra.color.grey.main};
`;

export const StyledUl = styled.ul`
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  line-height: 1.5;
`;

export const DetailsBox = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)}px;

  & p:nth-child(-n + 3) {
    line-height: 2;
  }
  & p {
    line-height: 1.75;
  }
`;
