import styled from 'styled-components';

export const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  // !important flag is necessary to override color from StyledTextField and StyledSelectField
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.secondary.main + '!important' : ''};
    ${({ disabled }) => (disabled ? 'opacity: 65%' : '')};
  }
  & input {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.secondary.main + '!important' : ''};
    ${({ disabled }) => (disabled ? 'opacity: 65%' : '')};
  }
  & .MuiSelect-nativeInput {
    ${({ disabled }) => (disabled ? 'opacity: 0' : '')};
  }
  & label {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.secondary.main + '!important' : ''};
    ${({ disabled }) => (disabled ? 'opacity: 65%' : '')};
  }
  & .MuiSelect-root {
    ${({ disabled }) => (disabled ? 'opacity: 65%' : '')};
  }
  & .MuiIconButton-label input {
    ${({ disabled }) => (disabled ? 'opacity: 0' : '')};
  }
`;
