import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: ${({ theme }) => theme.spacing(2)} px;
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const StyledCardContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
