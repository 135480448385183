import React from 'react';
import { ShiftExecution, ShiftModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';

interface ShiftCardMessageProps {
  shift: ShiftModel;
  execution: ShiftExecution;
}

const ShiftCardMessage: React.FC<ShiftCardMessageProps> = ({
  shift,
  execution,
}) => {
  if (shift.status === 'not_done') {
    return <Typography>Plantão não iniciado.</Typography>;
  }

  return (
    <>
      {!!shift.isAnnulled && (
        <Typography>
          <s>| {execution.caregiver?.user?.name}</s>
        </Typography>
      )}
      {!shift.isAnnulled && (
        <Typography>| {execution.caregiver?.user?.name}</Typography>
      )}
    </>
  );
};

export default ShiftCardMessage;
