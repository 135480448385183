import MuiTextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import { colorMapping, ColorsType } from '../../styles/colorMapping';

export const TextField = styled(MuiTextField)<{ $color: ColorsType }>`
  & label:not(.Mui-error, .Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & input:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & textarea:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiInputAdornment-root .MuiIconButton-label:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  &
    .MuiInputAdornment-root:not(.Mui-disabled)
    .MuiIconButton-label:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled) {
    & fieldset,
    & textarea,
    &:hover fieldset,
    &.Mui-focused fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
  }
  & label.Mui-disabled,
  & .MuiInputBase-root.Mui-disabled,
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 65%;
  }
`;
