import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-weight: 700;
  text-align: center;
  margin-block: ${({ theme }) => theme.spacing(2)}px;
`;
