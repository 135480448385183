import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  box-sizing: border-box;
  width: 100%;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const StyledCardName = styled(Typography)`
  font-weight: bold;
`;
