import { Button } from '@material-ui/core'
import React from 'react'

type PaginationProps = {
  count: number
  page: number
  onChange: (newPage: number) => void
}

const MAX_PAGES = 3

export function Pagination (props: PaginationProps) {
  const { count, page, onChange } = props
  const pagesArr = Array.from(Array(count), (_, i) => i + 1)
  let visibleArr: number[] = []
  const halfMax = MAX_PAGES / 2

  if (page === 1) {
    visibleArr = pagesArr.filter(n => n <= MAX_PAGES)
  } else if (page === count) {
    visibleArr = pagesArr.filter(n => n > count - MAX_PAGES)
  } else {
    visibleArr = pagesArr.filter(n => n >= page - halfMax && n < page + halfMax)
  }


  return (
    <div style={{ display: 'flex', gap: '4px', paddingInline: '4px' }}>
      {count > MAX_PAGES && page > Math.ceil(halfMax) &&
        <Button
          style={{ borderRadius: '1000px', padding: '4px', maxWidth: '24px' }}
          onClick={() => onChange(page - Math.ceil(halfMax))}
        >
          ...
        </Button>
      }
      {visibleArr.map((n) => (
        <Button
          style={{ borderRadius: '1000px', padding: '4px' }}
          onClick={() => onChange(n)} variant={page === n ? 'outlined' : 'text'}
        >
          {n}
        </Button>
      ))}
      {count > MAX_PAGES && page < (count - Math.floor(halfMax))&&
        <Button
          style={{ borderRadius: '1000px', padding: '4px', maxWidth: '24px' }}
          onClick={() => onChange(page + Math.ceil(halfMax))}
        >
          ...
        </Button>
      }
    </div>
  )
}
