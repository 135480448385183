import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

import StyledButton from '../../../../components/StyledButton';
import StyledDialog from '../../../../components/StyledDialog';

type UnrelateFromOrganizationDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
};

const UnrelateFromOrganizationDialog: React.FC<UnrelateFromOrganizationDialogProps> = ({
  onClose,
  onConfirm,
  open,
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogTitle>
          <Typography align="center" variant="h3">
            <WarningIcon fontSize="inherit" />
          </Typography>
          Tem certeza que deseja remover esse usuário?
        </DialogTitle>
        <Typography align="center">
          Ao confirmar, este usuário será desvinculado da sua organização e não
          terá mais acesso a nenhum paciente.
        </Typography>
        <DialogActions>
          <StyledButton onClick={onClose} color="inherit">
            Voltar
          </StyledButton>
          <StyledButton onClick={onConfirm} variant="text" color="inherit">
            Confirmar
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default UnrelateFromOrganizationDialog;
