import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core'
import { ClearRounded, Search } from '@material-ui/icons'
import { useDebouncedCallback } from 'use-debounce'
import qs from 'query-string'

type SearchTextFieldProps = {
  fieldName: string
  onChangeDebounced?: (value: string) => void
}

const getSearchValueFromUrl = (url: string) => url.replace(/^\%|\%$/g, '')

export function SearchTextField (props: SearchTextFieldProps) {
  const { fieldName, onChangeDebounced } = props
  const history = useHistory()
  const [searchText, setSearchText] = useState(getSearchValueFromUrl((qs.parse(history.location.search)[fieldName] as string) || ''))

  const theme = useTheme()

  const debounced = useDebouncedCallback((value: string) => {
    const newSearch = {
      ...qs.parse(history.location.search),
      [fieldName]: value ? `%${value}%` : undefined,
    }
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    })
    history.push(newUrl)
    if (onChangeDebounced) onChangeDebounced(value)
  }, 500)

  return (
    <TextField
      fullWidth
      value={searchText}
      placeholder='Procurar por...'
      autoFocus={true}
      variant='outlined'
      size='small'
      type='text'
      color='secondary'
      onChange={(e) => {
        debounced.callback(e.target.value)
        setSearchText(e.target.value)
      }}
      InputProps={{
        style: {
          borderColor: theme.palette.primary.main,
          color: '#666666',
          paddingLeft: '14px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        endAdornment: (
          <InputAdornment position='end'>
            {searchText.length === 0
              ? <Search style={{ color: theme.palette.primary.main }} />
              : (
                <IconButton
                  onClick={() => {
                    setSearchText('')
                    debounced.callback('')
                  }}
                  style={{ padding: 0 }}
                >
                  <ClearRounded style={{ color: theme.palette.primary.main }} />
                </IconButton>
              )
            }
          </InputAdornment>
        ),
      }}
      style={{
        marginBlock: theme.spacing(2),
        marginInline: theme.spacing(3),
        padding: theme.spacing(1),
        maxWidth: '800px',
        alignSelf: 'center',
      }}
    />
  )
}
