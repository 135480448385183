import { UserModel } from '@cuidador/database';
import * as yup from 'yup';
import {
  cpfMask,
  numberMask,
  phoneMask,
  validateCpf,
} from '../../../utils/inputs';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Por favor, insira o nome')
    .min(3, 'O nome deve conter ao menos 3 caracteres'),
  cpf: yup
    .string()
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      value !== '' && value !== undefined ? validateCpf(value || '') : true
    ),
  phoneNumber: yup
    .string()
    .required('Por favor, insira um celular')
    .min(15, 'Por favor, insira um celular válido')
    .max(15, 'Por favor, insira um celular válido'),
  roleId: yup.number().required('Por favor, selecione um tipo de perfil'),
});

export type FormValues = {
  name: string;
  cpf: string;
  phoneNumber: string;
  roleId?: number;
  email?: string;
};

export const initialValues: FormValues = {
  name: '',
  cpf: '',
  phoneNumber: '',
  roleId: undefined,
  email: '',
};

export const formDataToUserModel = (
  values: FormValues,
  patientId?: number | null
) => {
  return {
    name: values.name,
    cpf: values.cpf !== '' ? numberMask(values.cpf) : undefined,
    phoneNumber: numberMask(`55${values.phoneNumber}`),
    roleId: Number(values.roleId),
    patientId,
  };
};

export const userModelToFormData = (data: UserModel) =>
  ({
    name: data.name,
    cpf: cpfMask(data.cpf || ''),
    phoneNumber: phoneMask(data.phoneNumber || ''),
    roleId: data.role?.id,
    email: data.email,
  } as FormValues);

export const getShareMessage = (
  caregiverName: string,
  shortUrl: string
) => `Olá, ${caregiverName}, você foi convidado(a) e agora faz parte do Cuidador de Confiança. Para começar, acesse o link e cadastre-se.

${shortUrl}`;
