import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledButton from '../StyledButton';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  margin-top: 0;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.common.black};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  border-bottom: 2px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
`;

export const Titles = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
`;

export const ValueTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DiscountContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const DiscountTypography = styled(Typography)`
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.extra.color.red.main};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const DescriptionList = styled.ul`
  margin-left: ${({ theme }) => theme.spacing(-1.5)}px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const Button = styled(StyledButton)`
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;
