import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Centralizer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
