import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  inviteLink: yup.string(),
});

export type dialogPhoneFormValues = {
  phoneNumber?: string;
  inviteLink?: string;
};
