import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  margin: ${({ theme }) => theme.spacing(1, 0)};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
