import React from 'react'
import { Fab, useTheme } from '@material-ui/core'

type FabStyledProps = {
  children: JSX.Element
  onClick: () => void
  color?: string
  backgroundColor?: string
  borderColor?: string
}

export function FabStyled (props: FabStyledProps) {
  const { children, onClick, color, backgroundColor, borderColor } = props
  const theme = useTheme()

  return (
    <Fab style={{
      position: 'fixed',
      right: 10,
      marginRight: theme.spacing(2),
      bottom: 10,
      marginBottom: theme.spacing(2),
      color: color,
      backgroundColor: backgroundColor,
      border: `1px solid ${borderColor}`,
    }}
    onClick={onClick}
    >
      {children}
    </Fab>
  )
}
