import { EventScheduleModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store/index';

const endpoint = '/care/event';

const initialData: ReducerData<EventScheduleModel> = {
  byId: {} as Record<string, Item<EventScheduleModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

export interface MeasurementScheduleModelDTO extends EventScheduleModel {
  eventDescription?: {
    subCategoryId: string | number;
    description: string;
  }[];
}

const useMeasurement = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventScheduleModel>(),
    initialData
  );

  const getAllByPatientId = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/category/sub?categoryId=4&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const post = useCallback(async (data: MeasurementScheduleModelDTO) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}/schedule`, data);
      dispatch({ type: 'CREATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(
    async (id: Id, data: Partial<EventScheduleModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/schedule/${id}`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/schedule/measurement/${id}`
      );
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/schedule/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getAllByPatientId,
    post,
    patch,
    getById,
    remove,
  };
};

export default useMeasurement;
