import styled from 'styled-components';
import { InputBase } from '@material-ui/core';

export const StyledInput = styled(InputBase)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(1, 1)};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  width: 90%;
  input {
    font-size: ${({ theme }) => theme.typography.overline.fontSize};
    width: 90%;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }
  .MuiInputAdornment-positionEnd {
    margin: 0;
  }
`;
