import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth';
import {
  Container,
  CardContainer,
  CardText,
  StyledWarningIcon,
} from '../styles';
import { Header } from '../../../components/Header'
import LogoutDialog from '../../../components/LogoutDialog'
import { BackButton } from '../../../components/BackButton'
import { PscButton } from '../../../components/PscButton'

const NoPatientRelationsBlock: React.FC = () => {
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const history = useHistory();
  const [signOutDialogOpen, setSignOutDialogOpen] = useState<boolean>(false);

  const { signOut } = useContext(AuthContext);

  const handleToggleDialog = () => {
    setSignOutDialogOpen(!signOutDialogOpen);
  };

  const userHasRelatedPatients = Number(userInfo?.user?.patients?.length) > 0;

  const handleRefreshUserInfo = async () => {
    try {
      await refreshUserInfo();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleRefreshUserInfo();

    if (userHasRelatedPatients) {
      history.push('/');
    }
  }, [userHasRelatedPatients]);

  return (
    <>
      <Header
        leftContent={
          <BackButton
            action={() => setSignOutDialogOpen(true)}
          />
        }
        rightContent={<PscButton />}
      />
      <LogoutDialog
        open={signOutDialogOpen}
        onClose={handleToggleDialog}
        onConfirm={signOut}
      />
      <Container>
        <CardContainer>
          <StyledWarningIcon color="primary" />
          <CardText color="primary" data-testid="first-block">
            Você não está vinculado a nenhuma rede de cuidado ainda.
          </CardText>
          <CardText color="primary" data-testid="second-block">
            Para começar a usar o app, entre em contato com a pessoa que
            adicionou você e solicite o vínculo.
          </CardText>
        </CardContainer>
      </Container>
    </>
  );
};

export default NoPatientRelationsBlock;
