import { AppBar, Toolbar as MaterialToolbar } from '@material-ui/core';
import styled from 'styled-components';
import ElipseBlue from '../../assets/Elipseblue.svg';
import ElipseGreen from '../../assets/Elipsegreen.svg';

export const Toolbar = styled(MaterialToolbar)`
  justify-content: space-between;
`;

export const Container = styled(AppBar).attrs({
  elevation: 0,
  position: 'sticky',
})`
  & .MuiToolbar-root {
    height: 72px;
    z-index: 5;
  }
`;

export const StyledElipseBlueTop = styled.img.attrs({
  src: ElipseBlue,
})`
  width: 250px;
  height: 250px;
  top: -200px;
  right: 30px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenTop = styled.img.attrs({
  src: ElipseGreen,
})`
  width: 250px;
  height: 250px;
  top: -180px;
  right: -80px;
  position: absolute;
  z-index: 1;
`;

export const StyledElipseBlueBot = styled.img.attrs({
  src: ElipseBlue,
})`
  width: 250px;
  height: 250px;
  bottom: -150px;
  left: -120px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenBot = styled.img.attrs({
  src: ElipseGreen,
})`
  width: 250px;
  height: 250px;
  bottom: -220px;
  left: 0px;
  position: absolute;
  z-index: 1;
`;

export const StyledDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // [TODO]: change to the right images if necessary
  filter: brightness(0.1) opacity(0.1);
`;

export const StyledBlankDiv = styled.div`
  height: 40px;
  width: 40px;
`;
