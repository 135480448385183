import React from 'react';

export function getMedicBalloonText(
  mandatoryTotals: number[],
  importantTotals: number[]
) {
  const biggerThanZero = (n: number) => n > 0;
  if (mandatoryTotals.every(biggerThanZero)) {
    if (importantTotals.every(biggerThanZero)) {
      return 'Muito bem! Você finalizou todos os cadastros essenciais e importantes.';
    }
    return 'Muito bem! Você finalizou os cadastros essenciais. Veja que existem outros cadastros importantes para fazer.';
  }
  return (
    <span>
      Vamos agora cadastrar informações como o{' '}
      <b>roteiro diário, rotinas, medicamentos e rede de cuidado</b>, que serão
      essenciais para orientar o cuidado.
    </span>
  );
}
