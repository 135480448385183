import { format } from 'date-fns';
import { ShiftExecution } from '@cuidador/database';

export const handleFormatShiftExecutionTime = (execution?: ShiftExecution) => {
  if (!execution || !execution.startedAt) return '';

  const startedAt = new Date(execution.startedAt);

  const start = format(startedAt, 'HH:mm');

  if (!execution.endedAt) return start;

  const endedAt = new Date(execution.endedAt);

  const end = format(endedAt, 'HH:mm');

  return `${start} até ${end}`;
};

export const isExecutionWithContestation = (
  timeContestations: ShiftExecution['timeContestations']
) => {
  return timeContestations && timeContestations.length > 0;
};
