import { Typography } from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import FormikTextField from '../Forms/FormikTextField';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SearchContainer = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(5)}px;
  border: none;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1, 0)};
`;

export const CardContainer = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TypograpyContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.button`
  width: 90%;
  min-height: ${({ theme }) => theme.spacing(10)}px;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  word-break: break-word;
  text-align: left;
  box-shadow: 0px 2px 4px 0px #00000040;
`;

export const StyledButtonText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const StyledBoldTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: bold;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const DoseTextRow = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => theme.spacing(14)}px;
  justify-content: space-between;
`;

export const DosageFormikTextField = styled(FormikTextField)`
  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(0.7)}px;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  margin-top: 16px;
  width: ${({ theme }) => theme.spacing(15)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;
