import { EventScheduleModel } from '@cuidador/database';
import { format, parse } from 'date-fns';
import * as Yup from 'yup';
import {
  getLocalWeeklySchedule,
  getUTCWeeklySchedule,
  weekdayMap,
} from '../../utils/date';
import { FormValues } from './index';

export enum CustomInterval {
  WeekDays = 'Apenas alguns dias',
  Custom = 'Personalizado',
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().optional(),
  subCategoryId: Yup.string().required('Por favor, insira o tipo da rotina.'),
  time: Yup.string().trim().required('Por favor, insira a hora.'),
  frequencyRule: Yup.string().when('controlFrequency', {
    is: CustomInterval.Custom,
    then: Yup.string().required('Por favor, insira uma frequência.'),
  }),
  frequencyStartsAt: Yup.string().required(
    'Por favor, insira uma data inicial.'
  ),
  frequencyEndsAt: Yup.string().when('continuous', {
    is: false,
    then: Yup.string().required('Por favor, insira uma data final.'),
  }),
  days: Yup.array().when('customInterval', {
    is: CustomInterval.WeekDays,
    then: Yup.array().min(1, 'Por favor, escolha pelo menos 1 dia.'),
  }),
  description: Yup.string().max(2000, 'Deve conter no máximo 2000 caracteres.'),
});

export const frequencyOptions = [
  { value: '1w', name: 'Semanal' },
  { value: '2w', name: 'Quinzenal' },
  { value: '1M', name: 'Mensal' },
];

export const routineModelToFormValues = (
  routine: EventScheduleModel
): FormValues => {
  let formattedDate;
  let formattedTime;
  let formattedEndDate;

  if (routine?.frequencyStartsAt) {
    formattedDate = format(new Date(routine?.frequencyStartsAt), 'yyyy-MM-dd');
    formattedTime = format(new Date(routine?.frequencyStartsAt), 'HH:mm');
    if (routine?.frequencyEndsAt) {
      formattedEndDate = format(
        new Date(routine?.frequencyEndsAt),
        'yyyy-MM-dd'
      );
    }
  }

  const subCategoryId = routine.subCategory?.length
    ? routine?.subCategory[0]?.id
    : '';

  return {
    name: routine.name || '',
    description: routine.description || '',
    subCategoryId,
    time: formattedTime || '',
    frequencyStartsAt: formattedDate || format(new Date(), 'yyyy-MM-dd'),
    frequencyEndsAt: formattedEndDate || '',
    days: getSelectedWeekDays(routine),
    frequencyRule: routine?.frequencyRule || '',
    customInterval: getCustomInterval(routine.frequencyRule),
    continuous: !formattedEndDate,
  } as FormValues;
};

export const formDataToRoutineModel = (
  formData: FormValues,
  patientId: Id
): EventScheduleModel => {
  const frequencyStartsAt = parse(
    `${formData.frequencyStartsAt} ${formData.time}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  ).toISOString();

  let frequencyEndsAt = undefined;
  if (formData.frequencyEndsAt) {
    frequencyEndsAt = parse(
      `${formData.frequencyEndsAt}`,
      'yyyy-MM-dd',
      new Date()
    ).toISOString();
  }

  const localSchedule = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };
  if (formData.days && formData.days.length > 0) {
    localSchedule.mon = formData.days.includes(weekdayMap.mon);
    localSchedule.tue = formData.days.includes(weekdayMap.tue);
    localSchedule.wed = formData.days.includes(weekdayMap.wed);
    localSchedule.thu = formData.days.includes(weekdayMap.thu);
    localSchedule.fri = formData.days.includes(weekdayMap.fri);
    localSchedule.sat = formData.days.includes(weekdayMap.sat);
    localSchedule.sun = formData.days.includes(weekdayMap.sun);
  }
  const utcSchedule = getUTCWeeklySchedule(
    localSchedule,
    String(formData.time)
  );

  return {
    scheduleType: 'frequency',
    name: formData.name,
    description: formData.description,
    subCategory: [{ id: Number(formData.subCategoryId) }],
    patientId: patientId as number,
    ...utcSchedule,
    frequencyStartsAt,
    frequencyRule: formData.frequencyRule || '1d',
    frequencyEndsAt,
  };
};

export const getCustomInterval = (frequencyRule: string | undefined) => {
  if (!frequencyRule) {
    return CustomInterval.WeekDays;
  }

  const isWeekDayInterval = frequencyRule === '1d';

  if (isWeekDayInterval) {
    return CustomInterval.WeekDays;
  } else {
    return CustomInterval.Custom;
  }
};

export const getSelectedWeekDays = (routine: EventScheduleModel) => {
  const customInterval = getCustomInterval(routine.frequencyRule);

  if (customInterval !== CustomInterval.WeekDays) {
    return undefined;
  }

  const { mon, tue, wed, thu, fri, sat, sun } = routine || {};

  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
  const date = new Date(routine?.frequencyStartsAt || '');
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const selectedWeekDays: string[] = [];
  if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
  if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
  if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
  if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
  if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
  if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
  if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);

  return selectedWeekDays;
};
