import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledExecutionCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const StyledExecutionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;

  svg {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const StyledExecutionContainer = styled.p<{ strikethrough?: boolean }>`
  font-size: 1rem;
  font-family: 'Work sans';
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  margin-block: 0;
  max-width: 80vw;
  ${({ strikethrough }) => !!strikethrough && 'text-decoration: line-through;'}
`;

export const StyledHorizontalLine = styled.hr`
  background-color: ${({ theme }) => theme.palette.common.black};
  border-width: 1px;
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
`;
