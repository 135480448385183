import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(3)}px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
`;

export const CardText = styled(Typography)`
  text-align: center;
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledWarningIcon = styled(Warning)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  width: 60px;
  height: 60px;
`;
