import styled from 'styled-components';

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(1)}px;

  svg {
    font-size: 4rem;
  }

  h6 {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }

  h6 + div {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  button {
    width: 100%;
    color: ${({ theme }) => theme.palette.common.white};
  }

  button + button {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    border-color: ${({ theme }) => theme.palette.common.white};
  }

  button:nth-child(1) {
    border-color: transparent;
  }
`;
