import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.palette.extra.color.red.main};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    margin-right: 0.3rem;
  }
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.palette.extra.color.red.main};
  font-size: ${({ theme }) => theme.typography.fontSize}px;
`;
