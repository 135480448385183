import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  dateFrom: yup
    .date()
    .required('Por favor, insira uma data')
    .nullable()
    .typeError('Por favor, insira uma data válida'),
  dateUntil: yup
    .date()
    .required('Por favor, insira uma data')
    .nullable()
    .min(yup.ref('dateFrom'), 'A data final deve ser maior que a data inicial')
    .typeError('Por favor, insira uma data válida'),
});

export type ModalProps = {
  onClose: () => void;
  opened: boolean;
};

export type FormValues = {
  dateFrom: Date;
  dateUntil: Date;
};

export const initialValues: FormValues = {
  dateFrom: new Date(),
  dateUntil: new Date(),
};
