import React, { useContext, useEffect, useState } from 'react'
import { clientSideScheme } from '@cuidador/whitelabel'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import MedicBalloon from '../../components/MedicBalloon'
import RegistrationDashboardCard from '../../components/RegistrationDashboardCard'
import { AuthContext } from '../../contexts/auth'
import { RegistrationDashboardContext } from '../../contexts/registrationDashboard'
import useCanAccess from '../../hooks/useCanAccess'
import { CategoryTitle, Container } from './styles'
import { getMedicBalloonText } from './utils'
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'
import { BackButton } from '../../components/BackButton'
import { MenuButton } from '../../components/MenuButton'
import { SideMenu } from '../../components/SideMenu'

const RegistrationDashboard: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const { appFPalette } = clientSideScheme()
  const { userInfo } = useContext(AuthContext)
  const { count, isCounting, triggerRefresh } = useContext(
    RegistrationDashboardContext
  )
  const {
    isAllowedToRead: isAllowedToReadAppointment,
    isAllowedToCreate: isAllowedToCreateAppointment,
  } = useCanAccess('care/event/appointment')
  const {
    isAllowedToRead: isAllowedToReadEmergencyContact,
    isAllowedToCreate: isAllowedToCreateEmergencyContact,
  } = useCanAccess('care/emergency-contact')
  const {
    isAllowedToRead: isAllowedToReadMedication,
    isAllowedToCreate: isAllowedToCreateMedication,
  } = useCanAccess('care/medication')
  const { isAllowedToRead: isAllowedToReadCaregiver } = useCanAccess(
    'user/caregiver'
  )
  const { isAllowedToInvoke: isAllowedToRelateUser } = useCanAccess(
    'user/relate-to-patient'
  )
  const { isAllowedToCreate: isAllowedToCreateUser } = useCanAccess('user')
  const { isAllowedToRead: isAllowedToReadMeasurement } = useCanAccess(
    'care/event/measurement'
  )
  const { isAllowedToCreate: isAllowedToCreateEvent } = useCanAccess(
    'care/event/schedule'
  )
  const {
    isAllowedToRead: isAllowedToReadShift,
    isAllowedToCreate: isAllowedToCreateShift,
  } = useCanAccess('care/shiftschedule')
  const {
    isAllowedToRead: isAllowedToReadAdditionalShift,
    isAllowedToCreate: isAllowedToCreateAdditionalShift,
  } = useCanAccess('care/shift.additional-coverage')

  const isAllowedToReadSomeShift =
    isAllowedToReadShift || isAllowedToReadAdditionalShift
  const isAllowedToCreateSomeShift =
    isAllowedToCreateShift || isAllowedToCreateAdditionalShift

  const isShiftCardVisible = isAllowedToReadSomeShift
  const isOrganizationCardVisible = isAllowedToReadCaregiver
  const isMedicationCardVisible = isAllowedToReadMedication

  const isMeasurementCardVisible = isAllowedToReadMeasurement
  const isRoutineCardVisible = isAllowedToReadMeasurement
  const isAppointmentCardVisible = isAllowedToReadAppointment
  const isEmergencyCardVisible = isAllowedToReadEmergencyContact

  const isImportantRegistersVisible =
    isMeasurementCardVisible ||
    isRoutineCardVisible ||
    isAppointmentCardVisible ||
    isEmergencyCardVisible

  const { name = '', id: patientId } = userInfo?.activePatient || {}

  useEffect(() => {
    triggerRefresh()
  }, [])

  if (Object.values(isCounting).every((counting) => counting === true)) {
    return <LoadingBackdrop loading={true} />
  }

  return (
    <>
      <SideMenu
        isSideMenuOpen={isMenuOpen}
        handleToggleSideMenu={() => setMenuOpen(false)}
      />
      <Header
        leftContent={<MenuButton action={() => setMenuOpen(true)} />}
        centerContent={
          <PageTitle title={name} patientId={patientId!} />
        }
        rightContent={
          <PscButton />
        }
      />
      <Container>
        <MedicBalloon>
          {getMedicBalloonText(
            [count.shiftSchedule, count.medication, count.caregiver],
            [
              count.routine,
              count.appointment,
              count.emergencyContact,
              count.measurementSchedule,
            ]
          )}
        </MedicBalloon>

        <CategoryTitle>Cadastros essenciais</CategoryTitle>
        <RegistrationDashboardCard
          color="#157836"
          title="Pessoa sob cuidado"
          itemQuantity={userInfo?.user?.patients?.length || 0}
          creationRoute="/cadastro-psc"
          isAllowedToCreate={true} // TODO: Add permission
          visualizationRoute="/psc"
          mandatory
        />
        {isShiftCardVisible && (
          <RegistrationDashboardCard
            color="#9B3818"
            title="Plantões"
            statusTitleSingular="plantão"
            statusTitlePlural="plantões"
            itemQuantity={count.shiftSchedule}
            creationRoute={
              isAllowedToReadShift
                ? '/plantoes/proximos'
                : 'plantoes/cobertura-adicional'
            }
            isAllowedToCreate={isAllowedToCreateSomeShift}
            visualizationRoute="/plantoes/proximos"
            titleGender="male"
            mandatory
            loading={isCounting.shiftSchedule}
          />
        )}
        {isOrganizationCardVisible && (
          <RegistrationDashboardCard
            color="#7571DB"
            title="Rede de cuidado"
            statusMessage={false}
            itemQuantity={count.caregiver}
            creationRoute="/usuarios/novo"
            isAllowedToCreate={isAllowedToCreateUser || isAllowedToRelateUser}
            visualizationRoute="/cuidadores"
            titleGender="male"
            mandatory
            loading={isCounting.caregiver}
          />
        )}
        {isMedicationCardVisible && (
          <RegistrationDashboardCard
            color={appFPalette.info.main}
            title="Medicamentos"
            statusTitleSingular="medicamento"
            statusTitlePlural="medicamentos"
            itemQuantity={count.medication}
            creationRoute="/medicamentos_v2/novo"
            isAllowedToCreate={isAllowedToCreateMedication}
            visualizationRoute="/medicamentos"
            titleGender="male"
            mandatory
            loading={isCounting.medication}
          />
        )}

        {isImportantRegistersVisible && (
          <CategoryTitle>Cadastros importantes</CategoryTitle>
        )}

        {isMeasurementCardVisible && (
          <RegistrationDashboardCard
            color="#D48F14"
            title="Controles e Sinais Vitais"
            statusTitleSingular="controle e sinal vital"
            statusTitlePlural="controles e sinais vitais"
            itemQuantity={count.measurementSchedule}
            creationRoute="/controles/novo"
            isAllowedToCreate={isAllowedToCreateEvent}
            visualizationRoute="/controles"
            titleGender="male"
          />
        )}

        {isRoutineCardVisible && (
          <RegistrationDashboardCard
            color={appFPalette.extra.color.ocean}
            title="Rotinas"
            statusTitleSingular="rotina"
            statusTitlePlural="rotinas"
            itemQuantity={count.routine}
            creationRoute="/agendar/rotina/nova"
            isAllowedToCreate={isAllowedToCreateEvent}
            visualizationRoute="/agendar/rotina"
            titleGender="female"
            loading={isCounting.routine}
          />
        )}
        {isAppointmentCardVisible && (
          <RegistrationDashboardCard
            color={appFPalette.extra.color.green}
            title="Compromissos"
            statusTitleSingular="compromisso"
            statusTitlePlural="compromissos"
            itemQuantity={count.appointment}
            creationRoute="/agendar/compromisso/novo"
            isAllowedToCreate={isAllowedToCreateAppointment}
            visualizationRoute="/agendar/compromisso"
            titleGender="male"
            loading={isCounting.appointment}
          />
        )}
        {isEmergencyCardVisible && (
          <RegistrationDashboardCard
            color={appFPalette.extra.color.red.dark}
            title="Contatos de Emergência"
            statusTitleSingular="contato"
            statusTitlePlural="contatos"
            itemQuantity={count.emergencyContact}
            creationRoute="/contatos/novo"
            isAllowedToCreate={isAllowedToCreateEmergencyContact}
            visualizationRoute="/contatos"
            titleGender="male"
            loading={isCounting.emergencyContact}
          />
        )}
      </Container>
    </>
  )
}

export default RegistrationDashboard
