import { Delete } from '@material-ui/icons'
import { StyledIconButton } from '../StyledIconButton'
import React from 'react'

export type RemoveButtonProps = {
  action: () => void
}

export function RemoveButton (props: RemoveButtonProps) {
  const { action } = props

  return (
    <StyledIconButton
      icon={
        <Delete style={{
          fontSize: 40,
          color: 'inherit',
        }} />
      }
      action={action}
    />
  )
}
