import { ShiftAnnullationModel, ShiftModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';

const endpoint = '/care';

const initialData: ReducerData<ShiftModel> = {
  byId: {} as Record<string, Item<ShiftModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useShift = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftModel>(),
    initialData
  );

  const getLastShiftByPatientId = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/lastshift/by-patient/${id}`
      );
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data as ShiftModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/shift/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data as ShiftModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getByPatientIdPaginated = useCallback(
    async (patientId: number, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<ShiftModel>[];
          total: number;
        }>(`${endpoint}/shift/by-patient/${patientId}`, {
          params,
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const patchAnnulledShiftExecution = useCallback(
    async (shiftExecutionId: Id, body: Partial<ShiftAnnullationModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(
          `${endpoint}/shift/execution/${shiftExecutionId}/annulled`,
          body
        );
        dispatch({ type: 'SUCCESS' });
        return response.data as ShiftAnnullationModel;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const expelCaregiver = useCallback(
    async (shiftId: number, shouldKeepRecords: boolean) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.post(
          `${endpoint}/shift/expel/${shiftId}`,
          { shouldKeepRecords }
        );
        dispatch({ type: 'SUCCESS' });
        return response.data;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        throw err;
      }
    },
    []
  );

  const getTimeline = useCallback(async (shiftId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/shift/timeline/${shiftId}`);
      dispatch({ type: 'SUCCESS' });
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  return {
    ...state,
    getLastShiftByPatientId,
    getById,
    expelCaregiver,
    getByPatientIdPaginated,
    patchAnnulledShiftExecution,
    getTimeline,
  };
};

export default useShift;
