import { EventModel, EventSubCategoryModel } from '@cuidador/database'
import { intlFormat, parseISO } from 'date-fns'
import { capitalize } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import SvgFilter from '../../../assets/Icon-filter.svg'
import IconAccomplished from '../../../assets/Icon-flat-accomplished.svg'
import IconAccomplishedWarning from '../../../assets/icon-flat-accomplished-warning.svg'
import IconAppointment from '../../../assets/Icon-flat-appointment.svg'
import IconAwaiting from '../../../assets/Icon-flat-awaiting.svg'
import IconComplication from '../../../assets/Icon-flat-complication.svg'
import IconMeasurement from '../../../assets/Icon-flat-measurement.svg'
import IconMedication from '../../../assets/Icon-flat-medication.svg'
import IconNotAccomplished from '../../../assets/Icon-flat-not-accomplished.svg'
import IconRoutine from '../../../assets/Icon-flat-routine.svg'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import { RenderActivityCardByEvent } from '../../../components/ShiftEventList'
import { categoryIdMapper } from '../../../components/ShiftEventList/utils'
import { CareHistoryFilterContext } from '../../../contexts/CareHistoryFilter'
import { AuthContext } from '../../../contexts/auth'
import FilterDrawer from './FilterDrawer'
import { ReportButton } from './ReportButton'
import {
  ActiveFilterIndicator,
  CenteredLayout,
  Container,
  DateBox,
  DayHistoryBody,
  DayHistoryContainer,
  DayHistoryHeader,
  DayMessage,
  EventCardPopUp,
  EventCategoryIcon,
  EventListItem,
  EventStatusIcon,
  EventTime,
  EventTitle,
  FloatingButton,
  FloatingButtonContainer,
  PureParagraph,
  TimelineVerticalStroke,
} from './styles'

const EventHistory: React.FC = () => {
  const { userInfo } = useContext(AuthContext)
  const [activePopUpEvent, setActivePopUpEvent] = useState<EventModel | null>(
    null,
  )
  const { useEventCareHistory } = useContext(CareHistoryFilterContext)
  const {
    getAll,
    params,
    eventHistory,
    loading,
    hasActiveFilters,
    createCareHistoryReport,
    getCareHistoryReportDownloadUrl,
  } = useEventCareHistory

  const patient = userInfo?.activePatient

  const patientId = userInfo?.activePatientId

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    if (
      !patientId ||
      !params.patientId ||
      params.patientId !== String(patientId)
    )
      return

    getAll(patientId)
  }, [patientId, params.patientId])

  const resolveCategoryIcon = (event: EventModel) => {
    const subCategory = event.subCategory as EventSubCategoryModel
    switch (subCategory.categoryId) {
      case categoryIdMapper.MEDICATION:
        return IconMedication
      case categoryIdMapper.MEASUREMENT:
        return IconMeasurement
      case categoryIdMapper.COMPLICATION:
        return IconComplication
      case categoryIdMapper.GENERAL:
        return subCategory.isRoutine ? IconRoutine : IconAppointment
    }
  }

  const resolveStatusIcon = (event: EventModel) => {
    const subCategory = event.subCategory as EventSubCategoryModel
    switch (event.status) {
      case 'accomplished':
        if (subCategory.categoryId === categoryIdMapper.COMPLICATION || event.measurement?.isDangerousComplication) {
          return IconAccomplishedWarning
        } else {
          return IconAccomplished
        }
      case 'not_accomplished':
        return IconNotAccomplished
      default:
        return IconAwaiting
    }
  }

  const formatTimelineDate = (
    date: string | null | undefined,
    format: 'weekday' | 'short_month' | 'day_number' | 'time',
  ) => {
    if (!date) return 'Data inválida'

    const zonedDate = parseISO(date)

    switch (format) {
      case 'weekday':
        return capitalize(
          intlFormat(
            zonedDate,
            { weekday: 'long' },
            { locale: navigator.language },
          ),
        )
      case 'short_month':
        return capitalize(
          intlFormat(
            zonedDate,
            { month: 'short' },
            { locale: navigator.language },
          ),
        )
      case 'day_number':
        return intlFormat(
          zonedDate,
          { day: '2-digit' },
          { locale: navigator.language },
        )
      case 'time':
        return intlFormat(
          zonedDate,
          { hour: '2-digit', minute: '2-digit' },
          { locale: navigator.language },
        )
      default:
        return 'Formato inválido'
    }
  }

  if (!loading && (!eventHistory || eventHistory.length === 0))
    return (
      <CenteredLayout>
        <PureParagraph>
          Ainda não foram registrados dados de cuidado para esse paciente
        </PureParagraph>
      </CenteredLayout>
    )

  const toggleFilterDrawer = () => {
    if (isFilterDrawerOpen) getAll(patientId!)
    setIsFilterDrawerOpen((state) => !state)
  }

  return (
    <>
      {!loading ? (
        <Container>
          <FloatingButtonContainer>
            <FloatingButton
              data-testid='filter-fab'
              variant='contained'
              color='secondary'
              startIcon={<img src={SvgFilter} />}
              onClick={toggleFilterDrawer}
            >
              Filtros
              {hasActiveFilters && <ActiveFilterIndicator />}
            </FloatingButton>
          </FloatingButtonContainer>
          <TimelineVerticalStroke />
          {eventHistory?.map((dayHistory, dayHistoryIndex) => (
            <DayHistoryContainer
              key={dayHistory.date}
              data-testid={`date-${dayHistory.date}`}
            >
              <DayHistoryHeader>
                <DateBox>
                  <PureParagraph>
                    {formatTimelineDate(dayHistory.date, 'day_number')}
                  </PureParagraph>
                  <PureParagraph>
                    {formatTimelineDate(dayHistory.date, 'short_month')}
                  </PureParagraph>
                </DateBox>
                <PureParagraph>
                  {formatTimelineDate(dayHistory.date, 'weekday')}
                </PureParagraph>
              </DayHistoryHeader>
              <DayHistoryBody>
                {dayHistory.events && dayHistory.events.length > 0 ? (
                  dayHistory.events.map((event) => (
                    <EventListItem
                      key={event.id}
                      onClick={() => setActivePopUpEvent(event)}
                    >
                      <EventTime>
                        {formatTimelineDate(
                          event.updatedTimeHappensAt || event.eventHappensAt,
                          'time',
                        )}
                      </EventTime>
                      <EventStatusIcon
                        data-testid={`event-${event.id}-status-${event.status}-icon`}
                        src={resolveStatusIcon(event)}
                        isOddDayHistory={dayHistoryIndex % 2}
                      />
                      <EventCategoryIcon
                        data-testid={`event-${event.id}-category-${event.subCategory?.categoryId}-icon`}
                        src={resolveCategoryIcon(event)}
                        isOddDayHistory={dayHistoryIndex % 2}
                      />
                      <EventTitle>
                        {event.subCategory?.name ?? ''}
                        {event.subCategory?.categoryId ===
                          categoryIdMapper.MEASUREMENT &&
                          event.measurement?.measurementValue &&
                          ` - (${event.measurement?.measurementValue})`}
                      </EventTitle>
                    </EventListItem>
                  ))
                ) : (
                  <DayMessage>Sem registros</DayMessage>
                )}
              </DayHistoryBody>
            </DayHistoryContainer>
          ))}
          {activePopUpEvent && (
            <EventCardPopUp
              onClick={() => setActivePopUpEvent(null)}
              data-testid={`eventCardPopUp-${activePopUpEvent.id}`}
            >
              {RenderActivityCardByEvent(activePopUpEvent)}
            </EventCardPopUp>
          )}
        </Container>
      ) : (
        <LoadingBackdrop loading={loading} />
      )}
      <FilterDrawer
        isDrawerOpen={isFilterDrawerOpen}
        handleToggleDrawer={toggleFilterDrawer}
      />
      <ReportButton
        createReport={createCareHistoryReport}
        getReportDownloadUrl={getCareHistoryReportDownloadUrl}
        patient={patient}
      />
    </>
  )
}

export default EventHistory
