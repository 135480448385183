import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.warning.main};
  color: black;
  width: 100%;
  height: ${({ theme }) => theme.spacing(8)}px;
  display: grid;
`;

export const MessageContainer = styled.div`
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  text-align: center;
  align-items: center;
`;
