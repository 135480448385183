import { EventSubCategoryModel, MedicationModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { format, parse } from 'date-fns';
import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSimpleCircular from '../../../components/LoadingSimpleCircular';
import MedicationInsertForm from '../../../components/MedicationInsertForm';
import { FrequencyType } from '../../../components/MedicationInsertForm/MedicationSecondStep/DaysSelector';

import { PeriodType } from '../../../components/MedicationInsertForm/MedicationSecondStep/PeriodSelector';
import {
  MedicationInsertFormStep,
  FormValues,
  MedicationItem,
} from '../../../components/MedicationInsertForm/MedicationSecondStep/utils';
import { formDataToMedicationModel } from '../../../components/MedicationInsertForm/MedicationSecondStep/utils/parseFormDataToModel';
import { AuthContext } from '../../../contexts/auth';
import useMedication from '../../../hooks/useMedication';
import useSubCategory from '../../../hooks/useSubCategory';
import { resolveErrorMessage } from '../../../utils/error';
import { Header } from '../../../components/Header'
import { BackButton } from '../../../components/BackButton'
import { PageTitle } from '../../../components/PageTitle'
import { PscButton } from '../../../components/PscButton'

const formInitialValue: FormValues = {
  dosageFormat: 'pill' as MedicationModel['dosageFormat'],
  dosageQuantity: 1,
  administeredBy: 'oral' as MedicationModel['administeredBy'],
  ifNecessary: false,
  time: '',
  days: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
  periodType: PeriodType.Continuously,
  frequencyStartsAt: format(new Date(), 'yyyy-MM-dd'),
  frequencyEndsAt: '',
  customTimes: [],
  scheduleType: 'custom_frequency',
  dayOptions: FrequencyType.EveryDay,
  timeSchedule: '',
};

const MedicationInsertV2: React.FC = () => {
  const [allMedication, setAllMedication] = useState<MedicationItem[]>([]);
  const [medication, setMedication] = useState<MedicationItem>();
  const [step, setStep] = useState<MedicationInsertFormStep>(
    MedicationInsertFormStep.SelectMedication
  );

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const { post } = useMedication();

  const { getOnlyForMedication, loading } = useSubCategory();
  const history = useHistory();

  const handleMedicationSearch = async (textValue: string) => {
    if (textValue === '') return [];
    const params = {
      'eventSubCategory.name': `%${textValue}%`,
    };
    const data = await getOnlyForMedication({ ...params, limit: 30 });
    return data?.map((medication: EventSubCategoryModel) => ({
      id: medication.id,
      name: medication.medicationCatalogItem?.name || medication.name,
      description: medication.medicationCatalogItem?.description,
    }));
  };

  const handleChangeMedicationText = (textValue: string) => {
    handleMedicationSearch(textValue).then((data) => {
      setAllMedication(data);
    });
  };

  const handleStepChange = (step: MedicationInsertFormStep) => {
    setStep(step);
  };

  const handleSelectMedication = (medication: MedicationItem) => {
    setMedication(medication);
    handleStepChange(MedicationInsertFormStep.FillDetails);
  };

  useEffect(() => {
    const params = qs.parse(history.location.search);
    handleChangeMedicationText(params.q ? `${params.q}` : '');
  }, []);

  const handleSubmit = (values: FormValues) => {
    const today = new Date();
    const date = !values.ifNecessary
      ? parse(
          `${values.frequencyStartsAt} 23:59`,
          'yyyy-MM-dd HH:mm',
          new Date()
        )
      : '';

    if (!values.ifNecessary && today > date) {
      toast.error('Coloque a data de início hoje ou em um dia futuro');
      return false;
    }
    if (!patientId) return;
    return post(formDataToMedicationModel(values, patientId, medication!.id))
      .then(() => {
        history.replace('/medicamentos');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton
            action={() => step === MedicationInsertFormStep.SelectMedication
              ? history.push('/medicamentos')
              : setStep(step - 1)
            }
          />
        }
        centerContent={
          <PageTitle
            title={step === MedicationInsertFormStep.SelectMedication
              ? 'Adicionar Medicamento'
              : medication?.name || ''
            }
          />
        }
        rightContent={
          <PscButton />
        }
      />
      <MedicationInsertForm
        step={step}
        allMedication={allMedication}
        handleChangeMedicationText={handleChangeMedicationText}
        handleSelectMedication={handleSelectMedication}
        handleStepChange={handleStepChange}
        handleSubmit={handleSubmit}
        initialValues={formInitialValue as FormValues}
      />
      {step === MedicationInsertFormStep.SelectMedication && (
        <LoadingSimpleCircular loading={loading} />
      )}
    </>
  );
};

export default MedicationInsertV2;
