import React from 'react';
import MedicBalloonComponent from '../../MedicBalloon';
import { MedicBalloonContainer } from './styles';

const MedicBalloon: React.FC = () => {
  return (
    <MedicBalloonContainer>
      <MedicBalloonComponent text="O registro de controles e sinais vitais  é essencial para  o acompanhamento da saúde e antecipação de eventuais  problemas. Incluem dosagem de glicemia, oximetria, pressão arterial,  curativos, evacuação, dor, ingestão hídrica, entre outros. Incluir  observações pode facilitar o trabalho da pessoa responsável pelo cuidado." />
    </MedicBalloonContainer>
  );
};

export default MedicBalloon;
