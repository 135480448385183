import React, { useContext, useState } from 'react'
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom'
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner'
import StyledTabs from '../../components/StyledTabs'
import useCanAccess from '../../hooks/useCanAccess'
import History from './History'
import EventHistory from './CareHistory'
import Reports from './Reports'
import Shift from './Shift'
import { ButtonContainer, Container, StyledTabItem } from './styles'
import { CareHistoryFilterProvider } from '../../contexts/CareHistoryFilter'
import { AuthContext } from '../../contexts/auth'
import { PageTitle } from '../../components/PageTitle'
import { Header } from '../../components/Header'
import { BackButton } from '../../components/BackButton'
import { PscButton } from '../../components/PscButton'
import { MenuButton } from '../../components/MenuButton'
import { SideMenu } from '../../components/SideMenu'

const Follow: React.FC<RouteComponentProps<{ tabId?: string }>> = (props) => {
  const currentTab = props.match.params.tabId
  const history = useHistory()
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.push(`/acompanhar/${newValue}`)
  }
  const { isAllowedToRead: isAllowedToReadShift } = useCanAccess('care/shift')
  const { isAllowedToRead: isAllowedToReadShiftTab } = useCanAccess('care/shift/event')
  const {
    isAllowedToRead: isAllowedToReadReport,
    isAllowedToCreate: isAllowedToCreateReport,
  } = useCanAccess('report/shift')

  const { isAllowedToRead: isAllowedToReadEvolutionTab } = useCanAccess('care/shift/event')

  const { isAllowedToRead: isAllowedToReadPatientReport } = useCanAccess('report/patient')
  const { isAllowedToRead: isAllowedToReadProfessionalReport } = useCanAccess('report/professional')

  const isHistoricTabDisabled = !isAllowedToReadShift && !isAllowedToReadReport && !isAllowedToCreateReport

  const isReportTabDisabled = !isAllowedToReadPatientReport && !isAllowedToReadProfessionalReport

  const tabOptions = [
    { key: 'plantao', label: 'Plantão', disabled: !isAllowedToReadShiftTab },
    { key: 'historico', label: 'Histórico', disabled: isHistoricTabDisabled },
    { key: 'evolucao', label: 'Evolução', disabled: !isAllowedToReadEvolutionTab },
    { key: 'relatorios', label: 'Relatórios', disabled: isReportTabDisabled },
  ]

  if (!currentTab) {
    const firstTab = tabOptions.find((tab) => !tab.disabled)
    return <Redirect to={`/acompanhar/${firstTab?.key}`} />
  }

  const { userInfo } = useContext(AuthContext)

  const { name = '', id: patientId } = userInfo?.activePatient || {}

  return (
    <>
      <SideMenu
        isSideMenuOpen={isMenuOpen}
        handleToggleSideMenu={() => setMenuOpen(false)}
      />
      <Header
        leftContent={<MenuButton action={() => setMenuOpen(true)} />}
        centerContent={<PageTitle title={name} patientId={patientId} />}
        rightContent={<PscButton />}
      />
      <PendingRegistrationBanner />
      <Container>
        <ButtonContainer>
          <StyledTabs
            variant='scrollable'
            value={currentTab}
            onChange={handleChange}
          >
            {tabOptions.map(
              ({ key, label, disabled }) => disabled
                ? null
                : (<StyledTabItem key={key} value={key} label={label} />
                ),
            )}
          </StyledTabs>
        </ButtonContainer>
        {currentTab === 'plantao' && <Shift />}
        {currentTab === 'historico' && <History />}
        {currentTab === 'relatorios' && <Reports />}

        {currentTab === 'evolucao' && (
          <CareHistoryFilterProvider>
            <EventHistory />
          </CareHistoryFilterProvider>
        )}
      </Container>
    </>
  )
}

export default Follow
