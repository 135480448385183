import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { ImageWithLeftMargin, MainContainer } from './styles';
import DevelopmentInProgressIcon from '../../assets/development-in-progress.svg';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { BackButton } from '../../components/BackButton'
import { PscButton } from '../../components/PscButton'

type DevelopmentInProgressPageType = 'produtos-de-saude' | 'servicos-de-saude';

const DevelopmentInProgress: React.FC = () => {
  const params: { page: DevelopmentInProgressPageType } = useParams();
  const history = useHistory();

  const page: DevelopmentInProgressPageType = params.page;

  const title = useMemo(
    () =>
      page === 'produtos-de-saude' ? 'Produtos de saúde' : 'Serviços de saúde',
    [page]
  );

  return (
    <>
      <Header
        leftContent={
          <BackButton />
        }
        centerContent={
          <PageTitle title={title} />
        }
        rightContent={<PscButton />}
      />
      <MainContainer>
        <br />
        <ImageWithLeftMargin src={DevelopmentInProgressIcon} />
        <br />
        <Typography>Esta página está em contrução.</Typography>
        <br />
        <br />
        <Typography>
          Em breve você encontrará aqui o acesso fácil a{' '}
          {page === 'produtos-de-saude' ? 'produtos' : 'serviços'} de saúde para
          quando precisar.
        </Typography>
      </MainContainer>
    </>
  );
};

export default DevelopmentInProgress;
