import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';

import { ColorsType } from '../../styles/colorMapping';
import { TextField } from './styles';

export type StyledTextFieldProps = Omit<TextFieldProps, 'color'> & {
  color?: ColorsType;
};

const StyledTextField: React.FC<StyledTextFieldProps> = ({
  color = 'primary',
  variant = 'outlined',
  ...props
}) => <TextField $color={color} variant={variant} {...props} />;

export default StyledTextField;
