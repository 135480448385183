import React from 'react';
import {
  Container,
  LeftSide,
  RightSide,
  StyledTypography,
  StyledIcon,
} from './styles';

interface Props {
  id: number;
  title: string;
  subTitle?: string;
  onClick: () => void;
}

const MedicationFirstStepCard: React.FC<Props> = ({
  title,
  subTitle,
  id,
  onClick,
}) => {
  return (
    <Container data-testid={`medication-card-${id}`} onClick={() => onClick()}>
      <LeftSide>
        <StyledTypography variant="subtitle2">{title}</StyledTypography>
        <StyledTypography variant="caption">{subTitle}</StyledTypography>
      </LeftSide>
      <RightSide>
        <StyledIcon />
      </RightSide>
    </Container>
  );
};

export default MedicationFirstStepCard;
