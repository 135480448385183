import {
  EventScheduleCustomTimeModel,
  EventScheduleModel,
} from '@cuidador/database';
import { differenceInMinutes, format } from 'date-fns';
import _ from 'lodash';
import { FormValues, Measurement } from '..';
import { getLocalWeeklySchedule, weekdayMap } from '../../../utils/date';
import { convertTimeToDate } from '../../../utils/date/index';
import { EVERYDAY, FrequencyType, MONDAY_TO_FRIDAY } from '../DaysSelector';
import { PeriodType } from '../PeriodSelector';

export const eventScheduleModelToFormData = (
  eventSchedule: EventScheduleModel
) => {
  const measurements = parseMeasurements(eventSchedule);

  const { formattedDate, formattedTime, formattedEndDate } = parseDates(
    eventSchedule
  );

  const timeSchedule = getFrequencyRule(eventSchedule.customTimes!);
  const customTimes = parseCustomTimes(eventSchedule);
  const days = getSelectedWeekDays(eventSchedule);
  const dayOptions = getDayOptons(days, eventSchedule.frequencyRule || '1d');
  const { rangeInDays, customRangeInDays } = getRangesInDays(
    eventSchedule.frequencyRule || ''
  );
  const periodType = getPeriodType(
    eventSchedule.frequencyStartsAt!,
    eventSchedule.frequencyEndsAt!
  );

  const data = {
    measurements,
    frequencyStartsAt: formattedDate || '',
    frequencyEndsAt: formattedEndDate || '',
    time: formattedTime || '',
    timeSchedule,
    customTimes,
    days,
    dayOptions,
    rangeInDays,
    customRangeInDays,
    periodType,
  } as FormValues;
  return data;
};

const getPeriodType = (frequencyStartsAt: string, frequencyEndsAt: string) => {
  if (frequencyStartsAt && frequencyEndsAt) {
    return PeriodType.DeterminedTime;
  }
  return PeriodType.Continuously;
};

const getRangesInDays = (frequencyRule: string) => {
  let rangeInDays;
  let customRangeInDays;
  const notCustomIntervalOptions = ['2d', '3d', '5d', '7d', '15d', '30d'];
  if (notCustomIntervalOptions.includes(frequencyRule)) {
    rangeInDays = frequencyRule;
  } else {
    rangeInDays = 'custom';
    customRangeInDays = Number(frequencyRule.split('d')[0]);
  }

  return {
    rangeInDays,
    customRangeInDays,
  };
};

const getDayOptons = (days: string[], frequencyRule: string) => {
  const isEveryDay =
    days.length === 7 && days.every((day) => EVERYDAY.includes(day));
  const isMondayToFriday =
    days.length === 5 && days.every((day) => MONDAY_TO_FRIDAY.includes(day));
  const isSomeDays = !isEveryDay && !isMondayToFriday;
  if (frequencyRule === '1d') {
    if (isEveryDay) return FrequencyType.EveryDay;
    if (isMondayToFriday) return FrequencyType.MondayToFriday;
    if (isSomeDays) return FrequencyType.SomeDays;
  }
  return FrequencyType.Custom;
};

export const getSelectedWeekDays = (eventSchedule: EventScheduleModel) => {
  const { mon, tue, wed, thu, fri, sat, sun } = eventSchedule || {};

  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
  const date = new Date(eventSchedule?.frequencyStartsAt || '');
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const selectedWeekDays: string[] = [];
  if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
  if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
  if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
  if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
  if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
  if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
  if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);

  return selectedWeekDays;
};

/**
 * calculates the difference in minutes between the times of the eventSchedule, to fill frequency field in medication form;
 * it doesn't make sense to calculate the differences between times, when there is only one time for the event/medication happen.
 * in this case, the frequency is 24 hours, when the event has only one time to happen in the day.
 */
const getFrequencyRule = (times: EventScheduleCustomTimeModel[]) => {
  const ONE_HOUR_IN_MINUTES = 60;
  const TWO_HOURS_IN_MINUTES = 120;
  const FOUR_HOURS_IN_MINUTES = 240;
  const SIX_HOURS_IN_MINUTES = 360;
  const EIGHT_HOURS_IN_MINUTES = 480;
  const TWELVE_HOURS_IN_MINUTES = 720;
  const TWENTY_FOUR_HOURS_IN_MINUTES = 1440;
  const validDifferencesInMinutes = [
    TWO_HOURS_IN_MINUTES,
    FOUR_HOURS_IN_MINUTES,
    SIX_HOURS_IN_MINUTES,
    EIGHT_HOURS_IN_MINUTES,
    TWELVE_HOURS_IN_MINUTES,
    TWENTY_FOUR_HOURS_IN_MINUTES,
  ];

  const customTimes = times.map((time) => time.happensAt).sort();
  const differences: number[] = [];
  customTimes.forEach((time, index) => {
    if (customTimes.length === 1) {
      differences.push(TWENTY_FOUR_HOURS_IN_MINUTES);
    } else {
      const hour = new Date(
        2022,
        1,
        1,
        Number(customTimes[index]?.split(':')[0]),
        Number(customTimes[index]?.split(':')[1])
      );
      const nextHour = new Date(
        2022,
        1,
        1,
        Number(customTimes[index + 1]?.split(':')[0]),
        Number(customTimes[index + 1]?.split(':')[1])
      );
      const difference = differenceInMinutes(nextHour, hour);
      if (!isNaN(difference)) {
        differences.push(Math.abs(difference));
      }
    }
  });

  const allEqualAndValid = (arr: number[]) =>
    arr.every((v) => v === arr[0] && validDifferencesInMinutes.includes(v));

  const firstDifference = differences[0] / ONE_HOUR_IN_MINUTES;

  const HOURS_IN_DAY = 24;
  const timesInFrequency = HOURS_IN_DAY / firstDifference;

  const isIrregular =
    times.length !== timesInFrequency || !allEqualAndValid(differences);

  const frequencyRule = isIrregular
    ? 'custom'
    : `${differences[0] / ONE_HOUR_IN_MINUTES}h`;

  return frequencyRule;
};

const parseCustomTimes = (eventSchedule: EventScheduleModel) => {
  if (eventSchedule.scheduleType === 'custom_frequency') {
    const customTimes = eventSchedule.customTimes?.map((time) => {
      if (time.happensAt) {
        return format(convertTimeToDate(time.happensAt), 'HH:mm');
      }
    });
    return customTimes;
  }
};

const parseMeasurements = (eventSchedule: EventScheduleModel) => {
  const events =
    eventSchedule.events &&
    eventSchedule.events.map((event) => ({
      id: String(event.subCategoryId),
      instruction: event.description,
    }));

  const measurements = _.uniqBy(events, 'id');
  return measurements as Measurement[];
};

const parseDates = (eventSchedule: EventScheduleModel) => {
  let formattedDate;
  let formattedTime;
  let formattedEndDate;

  if (eventSchedule?.frequencyStartsAt) {
    formattedDate = format(
      new Date(eventSchedule?.frequencyStartsAt),
      'yyyy-MM-dd'
    );
    formattedTime = format(new Date(eventSchedule?.frequencyStartsAt), 'HH:mm');
    if (eventSchedule?.frequencyEndsAt) {
      formattedEndDate = format(
        new Date(eventSchedule?.frequencyEndsAt),
        'yyyy-MM-dd'
      );
    }
  }

  return {
    formattedDate,
    formattedTime,
    formattedEndDate,
  };
};
