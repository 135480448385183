import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonContainer } from './styles';
import { ReactComponent as LinkIcon } from '../../../assets/link-icon.svg';

interface PatientRelationButtonProps {
  buttonStyle: 'light' | 'dark';
  onClick?: () => void;
}

const PatientRelationButton: React.FC<PatientRelationButtonProps> = ({
  buttonStyle,
  onClick,
}) => {
  return (
    <ButtonContainer
      buttonStyle={buttonStyle}
      onClick={onClick}
      data-testid="member-relation-button"
    >
      <Button
        aria-controls="button-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
      >
        <LinkIcon fontSize="large" />
      </Button>
    </ButtonContainer>
  );
};

export default PatientRelationButton;
