import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import {
  clientSideScheme,
  setFavicon,
  setManifest,
} from '@cuidador/whitelabel'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import ScrollToTop from './routes/ScrollToTop'

import ErrorBoundary from './components/ErrorBoundary'
import Layout from './components/Layout'
import Toast from './components/Toast'
import './config/hotjar'
import './config/sentry'
import { AuthProvider } from './contexts/auth'
import { PermissionProvider } from './contexts/permission'
import RegistrationDashboardProvider from './contexts/registrationDashboard'
import { GlobalLoadingProvider } from './contexts/RequestInterceptor'
import GlobalStyle from './styles/globalStyle'
import { resolveTheme } from './styles/theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ptBr from 'date-fns/locale/pt-BR'

const App: React.FC = () => {
  const { muiTheme, styledTheme } = resolveTheme()
  const { appFFaviconUrl, appFJsonManifest } = clientSideScheme()

  useEffect(() => {
    setFavicon(appFFaviconUrl)
    setManifest(appFJsonManifest)
  }, [])

  return (
    <ErrorBoundary>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={styledTheme}>
            <GlobalStyle />
            <GlobalLoadingProvider>
              <BrowserRouter>
                <AuthProvider>
                  <PermissionProvider>
                    <RegistrationDashboardProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                        <ScrollToTop />
                        <Toast />
                        <Layout />
                      </MuiPickersUtilsProvider>
                    </RegistrationDashboardProvider>
                  </PermissionProvider>
                </AuthProvider>
              </BrowserRouter>
            </GlobalLoadingProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  )
}
export default App
