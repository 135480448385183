import styled from 'styled-components';
import { ContentProps } from '.';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ withTabs }) =>
    withTabs ? '56px' : '0px'}; // 56 = Tabbar height
  background-color: ${({ theme }) => theme.palette.common.white};
`;
