import React from 'react';
import { toast } from 'react-toastify';
import { DialogActions, DialogContent } from '@material-ui/core';

import StyledButton from '../../../../components/StyledButton';
import StyledDialog from '../../../../components/StyledDialog';
import { Formik, Form } from 'formik';
import FormikTextField from '../../../../components/Forms/FormikTextField';
import { validationSchema, dialogPhoneFormValues } from './utils';

import { ReactComponent as CopyIcon } from '../../../../assets/copy-icon.svg';

import {
  StytledDialogTitle,
  StyledTypography,
  StyledInputAdornment,
} from './styles';

type ShareActivationLinkDialogProps = {
  onClose: () => void;
  onConfirm: (values: dialogPhoneFormValues) => void;
  open: boolean;
  userName?: string;
  completeSignupUrl?: string;
};

const ShareActivationLinkDialog: React.FC<ShareActivationLinkDialogProps> = ({
  onClose,
  onConfirm,
  open,
  userName,
  completeSignupUrl,
}) => {
  const initialValues = {
    inviteLink: completeSignupUrl || '',
  };

  const handleCopyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Link copiado!');
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <StytledDialogTitle>Convite exclusivo gerado!</StytledDialogTitle>
        <StyledTypography align="center">
          Compartilhe o link com <strong>{userName}</strong>, para terminar o
          cadastro e acessar o aplicativo.
        </StyledTypography>
        <StyledTypography align="center">
          Você pode copiar ou enviar pelo o WhatsApp
        </StyledTypography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={onConfirm}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikTextField
                color="white"
                name="inviteLink"
                label="Link de convite"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'inviteLink' }}
                InputProps={{
                  endAdornment: (
                    <StyledInputAdornment
                      position="end"
                      onClick={() =>
                        handleCopyTextToClipboard(String(completeSignupUrl))
                      }
                    >
                      <div>
                        <CopyIcon />
                        <span>Copiar</span>
                      </div>
                    </StyledInputAdornment>
                  ),
                  readOnly: true,
                }}
              />

              <DialogActions>
                <StyledButton
                  disabled={isSubmitting}
                  onClick={onClose}
                  variant="text"
                  color="inherit"
                >
                  Voltar
                </StyledButton>
                <StyledButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  color="inherit"
                  data-testid="share-button"
                >
                  Enviar pelo whatsapp
                </StyledButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ShareActivationLinkDialog;
